import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";
import Loader from "./Loader";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { GrUserExpert } from "react-icons/gr";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { BiLogoTelegram } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { FaArrowRotateRight } from "react-icons/fa6";

import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

const Floater = () => {

  const [ErrorName, setErrorName] = useState("");
  const [ErrorNumber, setErrorNumber] = useState("");
  const [ErrorMail, setErrorMail] = useState("");
  const [ErrorPolicyType, setErrorPolicyType] = useState("");
  const [PolicyStatusError, setPolicyStatusError] = useState("");
  const [ErrorEndDate, setErrorEndDate] = useState("");


 const [generatedCaptcha, setGeneratedCaptcha] = useState("");
  const [userInput, setUserInput] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    policy_type: "*",
    policy_number: "",
    expiry_date: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("userToken");

  const [formDataCall, setFormDataCall] = useState({
    name: "",
    product_name: "",
    mobile_number: "",
    time_slot: "",
    message: "",
    code: "",
  });

  const [captcha, setCaptcha] = useState("");
  const [popup, setPopup] = useState({ isOpen: false });

  // Function to generate a random alphanumeric string
  const generateRandomString = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Function to generate and set a new CAPTCHA
  const generateCaptcha = () => {
    const captchaText = generateRandomString(6); // Adjust the length as needed
    setGeneratedCaptcha(captchaText);
    setCaptcha(captchaText);
    setResultMessage("");
  };

  // Generate CAPTCHA on initial render
  useEffect(() => {
    generateCaptcha();
  }, []);

  // Handle input changes and update state
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate CAPTCHA
    if (userInput !== generatedCaptcha) {
      setResultMessage("Captcha is wrong");
      setUserInput("");
      return;
    }
    setResultMessage("Correct");

    // Validate form fields
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.phone_number) errors.phone_number = "Mobile Number is required";
    if (!formData.email) errors.email = "Email is required";
    if (formData.policy_type === "*") errors.policy_type = "Choose a Policy";
    if (!formData.policy_number) errors.policy_number = "Policy Number is required";
    if (!formData.expiry_date) errors.expiry_date = "End Date must be required";

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/dashboard/set_reminder`,
        formData,
        { params: { token } }
      );

      if (response.data.status === true) {
        Swal.fire({
          title: "Success!",
          text: "Your Request Submitted Successfully",
          icon: "success",
          confirmButtonText: "Done",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message || "Error submitting request",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRegenerate = () => {
    generateCaptcha();
  };

  const handleButtonClick = async () => {
    // Check for empty fields
    const hasEmptyFields = Object.values(formDataCall).some(
      (value) => !value?.trim()
    );
    if (hasEmptyFields) {
      Swal.fire({
        title: "Error!",
        text: "All fields are required. Please fill in the missing details.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
  
    // Validate CAPTCHA
    if (userInput !== captcha) {
      Swal.fire({
        title: "Error!",
        text: "Invalid captcha code.",
        icon: "error",
        confirmButtonText: "Back",
      }).then(() => {
        handleRegenerate(); // Regenerate CAPTCHA
      });
      return;
    }
  
    // Submit the form
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}cms/user_callback_request`,
        formDataCall
      );
  
      if (response.data.status === true) {
        Swal.fire({
          title: "Success!",
          text: "Form submitted successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          // Close modal and reset form
          document.querySelector(".modal-close-home")?.click();
          setFormDataCall({
            name: "",
            product_name: "",
            mobile_number: "",
            time_slot: "",
            message: "",
            code: "",
          });
          setUserInput("");
          generateCaptcha(); // Regenerate CAPTCHA
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message || "Something went wrong. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      // Handle errors from the API call
      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || error.message || "An error occurred.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  





  const handleInputChangeCallback = (event) => {
    const { name, value } = event.target;
    setFormDataCall({
      ...formDataCall,
      [name]: value,
    });
  };






  return (
    <>
      <section className="float">
        <div className="float-icon-sub">
          <Link
            to="#"
            className="float-icon flaot-icon-clock"
            target="_blank"
            data-toggle="modal"
            data-target="#reminderpopup"
          >
            <FastImage
              url={"Website%20Img/clock.gif?updatedAt=1718788143758"}
              width={auto}
              height={auto}
              alt={"clock"}
              src={"Website%20Img/clock.gif?updatedAt=1718788143758"}
            />
          </Link>
          <Link
            to="#"
            className="float-icon flaot-icon-phone"
            target="_blank"
            data-toggle="modal"
            data-target="#reminderpopupcalling"
          >
            <FastImage
              url={"Website%20Img/float-phone.svg?updatedAt=1718787185084"}
              width={auto}
              height={auto}
              alt={"phone"}
              src={"Website%20Img/float-phone.svg?updatedAt=1718787185084"}
            />
          </Link>
          <Link to="#" className="float-icon flaot-icon-chat" target="_blank">
            <FastImage
              url={"Website%20Img/float-chat.svg?updatedAt=1718787185081"}
              width={auto}
              height={auto}
              alt={"chat"}
              src={"Website%20Img/float-chat.svg?updatedAt=1718787185081"}
            />
          </Link>
        </div>
      </section>

      {/* Reminder Popup Clock */}
      {loading ? <Loader /> : null}

      <div
        class="modal fade"
        id="reminderpopup"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reminderpopupTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered extrawidthpopup modal-media"
          role="document"
        >
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle"></h5>
              <button
                type="button"
                class="close close-float"
                data-dismiss="modal"
                aria-label="Close"
                id="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body ">
              <h2 className="floater-h2">Get a Quick Reminder</h2>
              {/* <p style={{color:'red',fontSize:'8pt',textAlign:"center"}}>{SetError}</p> */}

              <FastImage
                className={"floater-img-data"}
                url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                width={auto}
                height={auto}
                alt={"mashquad"}
                src={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
              />

              <div className="container">
                <form action="" className="row">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="name">
                      Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="floater-inp"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      onError={ErrorName}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorName ? false : true}
                    >
                      {ErrorName}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="mobile">
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="phone_number"
                      className="floater-inp"
                      placeholder="Mobile Number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorNumber ? false : true}
                    >
                      {ErrorNumber}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="email">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="floater-inp"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorMail ? false : true}
                    >
                      {ErrorMail}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="insuranceType">
                      Insurance Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="policy_type"
                      className="floater-inp"
                      value={formData.policy_type}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    >
                      <option value="*">--Select--</option>
                      <option value="Health-insurance">Health Insurance</option>
                      <option value="term-insurance">Term Insurance</option>
                      <option value="Travel-insurance">Travel Insurance</option>
                      <option value="Car-insurance">Car Insurance</option>
                      <option value="Bike-insurance">Bike Insurance</option>
                      <option value="General-insurance">
                        General Insurance
                      </option>
                      <option value="Life-insurance">Life Insurance</option>
                    </select>
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorPolicyType ? false : true}
                    >
                      {ErrorPolicyType}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="policyNumber">
                      Policy Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="policy_number"
                      className="floater-inp"
                      placeholder="Policy Number"
                      value={formData.policy_number}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={PolicyStatusError ? false : true}
                    >
                      {PolicyStatusError}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="startDate">
                      Policy End Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      id="expiry_date"
                      className="floater-inp"
                      value={formData.expiry_date}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorEndDate ? false : true}
                    >
                      {ErrorEndDate}
                    </p>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="containerdivcaptcha">
                      <div className="captchaSection">
                        <div
                          id="captcha"
                          className="genarateCaptcha float-captcha"
                        >
                          {generatedCaptcha}
                        </div>
                        <button
                          onClick={(event) => generateCaptcha(event)}
                          style={{ border: "none" }}
                        >
                          <FaArrowRotateLeft />
                        </button>
                      </div>
                      <div id="inputSection_captcha">
                        <input
                          className="floater-inp"
                          type="text"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          placeholder="Enter CAPTCHA"
                          style={{ border: "none" }}
                        />

                        <p id="result">{resultMessage}</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="popupmainsubmit">
              <button type="button" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*End Hear */}

      <div
        className="modal fade"
        id="ScheduleCallBack"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reminderpopupTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="call-modle-img">
                <FastImage
                  url={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                  width={auto}
                  height={auto}
                  alt={"call-modal"}
                  src={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                />
              </div>
              <div className="call-modal-content">
                <h3>Need help on deciding the right insurance plan for you?</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and</p>
                <Link className="toll-free-number" to="tel:+91 80000 88888">
                  <div className="left-icon">
                    <FastImage
                      url={
                        "Website%20Img/toll-free-icon.svg?updatedAt=1718787185542"
                      }
                      width={auto}
                      height={auto}
                      alt={"toll-free"}
                      src={
                        "Website%20Img/toll-free-icon.svg?updatedAt=1718787185542"
                      }
                    />
                  </div>
                  <div className="tollfree-text">
                    <p>Toll Free No.</p>
                    <a href="tel:+91 3323593438">
                    +91 3323593438
</a>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="reminderpopupcalling"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reminderpopupTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <FastImage
              url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
              width={auto}
              height={auto}
              alt={"mashquad"}
              src={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
              className={"call-modal-photo"}
            />

            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="modal-body">
                <div className="call-modle-img callingnmodal">
                  <FastImage
                    url={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                    width={auto}
                    height={auto}
                    alt={"phone"}
                    src={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                   
                  />
                </div>
                <div className="call-modal-content">
                  <h3>
                    Need help on deciding the right insurance plan for you?
                  </h3>
                  <p />
                  <a className="toll-free-number" href="tel:+91 3323593438">
                    <div className="tollfree-text">
                      <p>Toll Free No.</p>
                      <a href="tel:+91 3323593438">
    <p>+91 3323593438</p>
</a>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      {/* mobile popup */}






      <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                data-bs-backdrop="static"
                aria-hidden="true"
                style={{backgroundColor:"rgba(0, 0, 0, 0.5)"}}
              >
                <div
                  class="modal-dialog modal-dialog-centered callback-modal-adj"
                  role="document"
                >
                  <div class="modal-content" style={{ padding: "0px" }}>
                    <div class="modal-header callback-header-adj">
                      <button
                        type="button"
                        class="close"
                        className="modal-close-home"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body " style={{ padding: "0px" }}>
                      <div className="row six-section-of-fifth photohide-area">
                        <div className="col-sm-12 col-md-4 col-lg-5 hide-mobile-img callbackphoto photohide">
                          <FastImage
                            className={"backphoto-home"}
                            url={
                              "Website%20Img/call-back-agent.png?updatedAt=1718704826908"
                            }
                            width={auto}
                            height={auto}
                            alt={"backphoto-logo"}
                            src={
                              "Website%20Img/call-back-agent.png?updatedAt=1718704826908"
                            }
                          />
                          <h3>Request a Callback for Expert Advice</h3>
                          <hr className="callback-hr"></hr>
                          <div className="callback-photo-section">
                            <div className="callback-icon-div">
                              <div className="callback-icon-round">
                                <MdOutlineSupportAgent className="callback-icon-main" />
                              </div>
                              <p>
                              24/7 Customer Care Service Available
                              </p>
                            </div>

                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <BiSupport className="callback-icon-main" />
                              </div>
                              <p>
                              Supportive Customer Care Service Team
                              </p>
                            </div>

                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <BiLogoTelegram className="callback-icon-main" />
                              </div>
                              <p>
                              Experienced Team of Experts Shall Answer Your Queries
                              </p>
                            </div>

                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <CgWebsite className="callback-icon-main" />
                              </div>
                              <p>
                              Consistent Customer Service Communications and Quicker Response Time
                              </p>
                            </div>
                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <GrUserExpert className="callback-icon-main" />
                              </div>
                              <p>
                              Book A Call Back for Free Consultations With Our Experts
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-8 col-lg-7 callbacktext calbacktext-adj">
                          <div className="call-back-agent-form">
                            <h6>Request a Call Back</h6>

                            <form className="callbackForm">
                              <div className="d-flex align-items-center form-input">
                                <div className="form-inner-input form-group callform">
                                  <label
                                    htmlFor="form_name"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Name <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="_name"
                                    name="name"
                                    className="form-control"
                                    placeholder="Enter your name"
                                    onChange={handleInputChangeCallback}
                                    value={formDataCall.name}
                                  />
                                </div>
                                <div className="form-inner-input form-group callback callform">
                                  <label
                                    htmlFor="form_need"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Product{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="product_name"
                                    name="product_name"
                                    className="form-control call-option"
                                    onChange={handleInputChangeCallback}
                                    value={formDataCall.product_name}
                                  >
                                    <option
                                      selected=""
                                      disabled=""
                                      className="call-option"
                                    >
                                      Select product
                                    </option>
                                    <option
                                      value="Health Insurance"
                                      className="call-option"
                                    >
                                      Health Insurance
                                    </option>
                                    <option
                                      value="Motor Insurance"
                                      className="call-option"
                                    >
                                      Motor Insurance
                                    </option>
                                    <option
                                      value="Term Insurance"
                                      className="call-option"
                                    >
                                      Term Insurance
                                    </option>
                                    <option
                                      value="Travel Insurance"
                                      className="call-option"
                                    >
                                      Travel Insurance
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex align-items-center form-input ">
                                <div className="form-inner-input form-group callform">
                                  <label
                                    htmlFor="phone"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Mobile Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="mobile_number"
                                    name="mobile_number"
                                    placeholder="Enter your mobile number"
                                    className="form-control "
                                    maxLength={10}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/(\..*?)\..*/g, "$1");
                                    }}
                                    onChange={handleInputChangeCallback}
                                    required=""
                                    value={formDataCall.mobile_number}
                                  />
                                </div>
                                <div className="form-inner-input form-group callback ">
                                  <label
                                    htmlFor="Time Slot"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                    className="call-option"
                                  >
                                    Time Slot{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="time_slot"
                                    name="time_slot"
                                    className="form-control call-option"
                                    onChange={handleInputChangeCallback}
                                    value={formDataCall.time_slot}
                                  >
                                    <option
                                      selected=""
                                      disabled=""
                                      className="call-option"
                                    >
                                      Select preferred time slot
                                    </option>
                                    <option value="10:00 AM">10:00 AM</option>
                                    <option value="11:00 AM">11:00 AM</option>
                                    <option value="12:00 PM">12:00 PM</option>
                                    <option value="01:00 PM">01:00 PM</option>
                                    <option value="02:00 PM">02:00 PM</option>
                                    <option value="03:00 PM">03:00 PM</option>
                                    <option value="04:00 PM">04:00 PM</option>
                                    <option value="05:00 PM">05:00 PM</option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex align-items-center form-input">
                                <div className="form-inner-input form-group text-area-group">
                                  <label
                                    htmlFor="form_message"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Message{" "}
                                    <span className="text-danger txtOnly">
                                      *
                                    </span>
                                  </label>
                                  <textarea
                                    id="message"
                                    name="message"
                                    className="form-control"
                                    placeholder="Type message here..."
                                    rows={6}
                                    defaultValue={""}
                                    onChange={handleInputChangeCallback}
                                 value={formDataCall.message}
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center form-input">
                                <div className="form-inner-input form-group callback-detail">
                              
                                  <div className="captcha-div callback-captcha-div">
                                    <p className="captcha-para callback-para">{captcha}</p>
                                    <FaArrowRotateRight
                                      style={{
                                        height: "20px",
                                        width: "30px",
                                        color: "#555252",
                                        marginLeft: "9px",
                                      }}
                                      onClick={() => {
                                        handleRegenerate();
                                      }}
                                    />
                                  </div>
                                  <input
                                    name="code"
                                    id="code"
                                    className="form-control codeinp call-option"
                                    placeholder="Enter captcha code"
                                    type="text"
                                    value={userInput}
                                    onChange={(event) => {
                                      setUserInput(event.target.value);
                                      handleInputChangeCallback(event);
                                    }}
                                    style={{ fontFamily: "Poppins!important" , marginRight:"-20px", marginLeft:"20px"}}
                                  />
                                </div>
                                <label id="captchaMsg" className="error" />
                              </div>



                              <div className="blue-btn" id="callBackFormButton">
                                <a
                                  href="javascript:void(0)"
                                  id="formSbt"
                                  onClick={handleButtonClick}
                                  className="call-option"
                                >
                                  Submit
                                  <FastImage
                                    url={
                                      "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                                    }
                                    width={"auto"}
                                    height={"auto"}
                                    alt={"arrow"}
                                    src={
                                      "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                                    }
                                  />
                                </a>
                              </div>
                            </form>





                          </div>
                        </div>

                        {popup.isOpen ? (
                          <div className="alert alert-success" role="alert">
                            <strong>Successful: </strong>Your Callback Request
                            is Successfully Submitted.
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </>
  );
};

export default Floater;
