import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import ErrorDisplay from './ErrorDisplay';


export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }

    static getDerivedStateFromError(error) {
        return { error: error };
    }

    componentDidCatch(error, errorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);

        this.sendErrorEmail(error, errorInfo)
            .then((response) => {
                //console.log("Error message sent successfully:", response);
            })
            .catch((err) => {
                console.error("Failed to send error email:", err);
            });

        this.setState({ error: error });
    }

    async sendErrorEmail(error, errorInfo) {
        let serviceID, templateID, publicKey;
        if (process.env.NODE_ENV === 'production') {
            serviceID = process.env.REACT_APP_EMAILJSserviceID;
            templateID = process.env.REACT_APP_EMAILJStemplateID;
            publicKey = process.env.REACT_APP_EMAILJSpublicKey;
        } else {
            // Provide default values or handle the case for non-production environments
            serviceID = 'defaultServiceID';
            templateID = 'defaultTemplateID';
            publicKey = 'defaultPublicKey';
        }
        
        const componentURL = window.location.href; 
    
        //console.log("The Env Key is", serviceID);
        //console.log("The Env Key is", templateID);
        //console.log("The Env Key is", publicKey);
        
        const templateParams = {
            from_name: 'Beema1 Frontend',
            to_name: 'Beema1 Team',
            error_message: error.toString(),
            stack_trace: errorInfo.componentStack,
            component_url: componentURL
        };
    
        try {
            const response = await emailjs.send(serviceID, templateID, templateParams, publicKey);
            //console.log("Error message sent successfully:", response);
        } catch (err) {
            console.error("Failed to send error email:", err);
        }
    }
    

    render() {
        if (this.state.error) {
            return <ErrorDisplay error={this.state.error}/>
        }
        return this.props.children;
    }
}
