import React,{useState,useEffect} from 'react';
import { AddonApi } from '../../../Api/Api';

const Relincemedical = (medicaldetails) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const unique_id = params.get("req_id");
    let plan_id = params.get("plan_id");
    const c_id = params.get("c_id");
    
    const [digitQustion, setdigitQustion] = useState([])
    
    const getAddon = async () => {
      try {
          const response = await AddonApi(c_id, plan_id);
          const data = response.question_answer.data;
          const parsedData = data.map(item => {
              try {
                  if (typeof item === 'string') {
                      return JSON.parse(item);
                  }
                  return item;
              } catch (error) {
                  console.error('Error parsing item:', item);
                  return item;
              }
          });
          setdigitQustion(parsedData);
      } catch (error) {
          console.error('Error fetching addon data:', error);
      }
    };
    
        useEffect(() => {
          getAddon(); 
        }, [medicaldetails]); 
    
        function getNestedKeys(obj) {
            let result = {};
            for (let key in obj) {
              if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null) {
                const nested = obj[key];
                for (let nestedKey in nested) {
                  if (nested.hasOwnProperty(nestedKey) && typeof nested[nestedKey] === 'object') {
                    result[nestedKey] = nested[nestedKey]; 
                  }
                }
              }
            }
          
            return result;
          }
          
          const medicalData = medicaldetails.medicaldetails[0];
          const nestedKeys = getNestedKeys(medicalData);
          const InsureDetailsList = medicaldetails?.userfetchdata?.proposal_data?.InsuredDetail;


          const [found7, setfound7 ] = useState('notfound')

          return (
            <>
 <div>
 {Object.entries(nestedKeys).map(([key, value,index]) => {
     return (
         <div key={key}>
      {Object.entries(value).map(([innerKey, data], outerIndex) => {
             console.log('relince value',data)
        // console.log('experc',data,innerKey)

        const insureName = InsureDetailsList?.[innerKey]?.InsuredName;
        return (
          <div key={innerKey}>
            {data.map((item, index) => {
            // console.log('the q',item)
            if(item.QuestionId == '71'){
                setfound7('found')
            }
            const innerQuestion = item;  
            const mainQuestion = innerQuestion.Options;  
            const firstOption = mainQuestion[0]; 
            // console.log('firstopen',firstOption)
              return (
                <>
                <div className="qustionset">

<span className="insured-name" key={index}>{insureName}</span>
<br />
<span className='mainqustion'>Q.{item.QuestionText}</span>
<br hidden={!firstOption?.ConsultationDate}/>
<span hidden={!firstOption?.ConsultationDate} className='childqustion'>ConsultationDate:-{firstOption?.ConsultationDate}</span>
<br hidden={!firstOption?.ConsultationDate}/>
<span hidden={!firstOption?.DetailsOfYourPastMedicalCondition} className='childqustion'>Details Of Your Past Medical Condition:-{firstOption?.DetailsOfYourPastMedicalCondition}</span>
<br hidden={!firstOption?.DetailsOfYourPastMedicalCondition}/>
<span hidden={!firstOption?.DiagnosisDate} className='childqustion'>Diagnosis Date:-{firstOption?.DiagnosisDate}</span>
<br hidden={!firstOption?.DiagnosisDate}/>
<span hidden={!firstOption?.ExactDiagnosis} className='childqustion'>Exact Diagnosis:-{firstOption?.ExactDiagnosis}</span>
<br hidden={!firstOption?.ExactDiagnosis}/>
<span hidden={!firstOption?.HospitalName} className='childqustion'>HospitalName:-{firstOption?.HospitalName}</span>
<br hidden={!firstOption?.HospitalName}/>
<span hidden={!firstOption?.DateOfTests} className='childqustion'>DateOfTests:-{firstOption?.DateOfTests}</span>
<br hidden={!firstOption?.DateOfTests}/>
<span hidden={!firstOption?.FindingsOfTests} className='childqustion'>FindingsOfTests:-{firstOption?.FindingsOfTests}</span>
<br hidden={!firstOption?.FindingsOfTests}/>
<span hidden={!firstOption?.TypeOfTests} className='childqustion'>TypeOfTests:-{firstOption?.TypeOfTests}</span>
<br hidden={!firstOption?.ExpectedDeliveryDate}/>
<span hidden={!firstOption?.ExpectedDeliveryDate} className='childqustion'>Expected Delivery Date:-{firstOption?.ExpectedDeliveryDate}</span>
<span hidden={!firstOption?.HasFiledClaimWithCurrPreviousInsured} className='childqustion'>Has Filed Claim With Curr Previous Insured:-{firstOption?.HasFiledClaimWithCurrPreviousInsured}</span>

{/* {found7 ? <span className='childqustion'>Anwsar:- Yes </span>: null} */}

                </div>

                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
})}

    </div>
          </>
          
          );
          
}
export default Relincemedical