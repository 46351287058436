import React,{useState} from 'react';
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import CarInsuranceForm from './CarInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import Redirect from '../AllPageComponents/Rederict';
import { Helmet } from 'react-helmet-async';

const ClaimSettlement = () => {

  
    const [isExpanded, setIsExpanded] = useState(false);
  
    const text = `
      The settlement of claim is the benefaction of compensation to policyholders for damage or loss to their cars. 
      The car insurance claim can be settled in two ways which are cashless and reimbursement claim settlement, 
      where the former is more favored. First of all, an insurance claim is an official request, from the policyholder 
      to the insurance provider, demanding compensation for the damage or loss incurred. Once the claim is processed and 
      verified, the insurance provider offers compensation which is in accordance with the coverage guaranteed to the 
      customer, under the insurance agreement. First of all, a car insurance claim settlement appeal is an official request, 
      from the policyholder to the insurance company, demanding coverage and benefit for the damage or loss incurred. When 
      the claim is processed and verified, the insurer provides compensation which is aligned with the coverage/insurance 
      guaranteed to the customer, under the agreement of the insurance company certified and guaranteed through an 
      insurance policy.
    `;
    const visibleText = text.split(' ').slice(0, 60).join(' ');



    const [activeStep, setActiveStep] = useState(0);

    const steps = [
      "Intimate Insurance",
      "Submit Notice",
      "Submit Documents",
      "Insurance Assessment",
      "Third-Party Payment"
    ];
  
    const stepContent = [
      "Without intimating your insurance provider, do not correspond or connect with the party in case a third party has sent you a legal notice.",
      "Submit a copy of the notice to your insurance company.",
      "You also need to submit the FIR, driving license, and the RC book of the car.",
      "The insurance provider will authenticate the documents, and shall appraise and estimate the severity of the accident, and if found gratifying, a lawyer would be appointed to you by your insurance company itself.",
      "If the Motor Accidents Claims Tribunal directs you to pay the damages to the third party thereafter, the insurance company will directly pay the charges due to the third party. The amount of claim under a third-party claim is dependent on the age, profession, and income of the third party."
    ];


    const [currentStep, setCurrentStep] = useState(0);

    const claimSteps = [
      "Assess Your Condition and Vehicle",
      "Record Other Party's Details",
      "Collect Witness Contact Information",
      "Inform Your Insurance Provider",
      "Choose Cashless Claim Option",
      "Submit Claim Within 24 Hours",
      "Organize Required Documents"
    ];
  
    const claimStepContent = [
      "As soon as possible, assess yourself and your vehicle for damages. Based on your condition, decide whether you need medical assistance.",
      "It is essential to record the details of the other party involved in the accident. This helps in identifying them quickly.",
      "Make sure to collect the contact information of any witnesses nearby, as they can help verify your account.",
      "Notify your insurance provider and request an assessor to evaluate the damages.",
      "If you have access to a partner garage, choose the correct cashless claims option to make the process smoother.",
      "Most insurance providers require claims to be submitted within 24 hours of the accident, so avoid any delays.",
      "Ensure all necessary documents are organized and ready to guarantee your claim is processed efficiently."
    ];
  
    const handleStepClick = (step) => {
      setCurrentStep(step);
    };





  return (
    <>
      <Helmet>
        <title>Car Insurance Claim Settlement</title>
        <meta name="description" content="Find a complete guide on how to settle your car insurance claim successfully. Learn the steps, documents required, and tips to navigate the claims process." />
        <meta name="keywords" content="Car Insurance Claim Settlement, Car accident claims, Insurance claim process, Car damage claim, Insurance settlement steps, Third-party insurance claims, Car claim documentation, File car insurance claim, Motor insurance claims, Insurance dispute resolution, Car accident insurance settlement, Car claim tips, Claim settlement process, Best car insurance claim, Car insurance provider, Cashless claim process, Car accident compensation, Car claim requirements, Car insurance fraud, Claim approval process, Vehicle damage insurance claim" />
      </Helmet>
    <Redirect/>
  <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car_description">

        <h1>Car Insurance Companies With Best Claim Settlement Ratio</h1>
   
          <p className='textformat' >{isExpanded ? text : `${visibleText}...`}
            <a 
        className="see-more-btn"
        onClick={() => setIsExpanded(!isExpanded)}
      />
   
        {isExpanded ? 'See Less' : 'See More'}
      </p>

        </div>


      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/carImagMascord.png?updatedAt=1718773961090"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <CarInsuranceForm/>
   

    </div>
  </div>
</section>
<div className="fixwidthDivtext">

<div className="container">
    <h4 className='healthHeading'>What is Car Insurance Claim Settlement ?
</h4>
    <div className="yellowline"></div>
    <p className='textformat'>Car Insurance Claim is the process under which a car insurance policyholders requests the insurer to compensate for the expenses/damage incurred by him/her due to an unfortunate event involving his/her car. The amount and extent of the claim amount depend on the Insured Declared Value (IDV) of your car and the type of car insurance policy bought by you - third party, own damage or comprehensive.
            </p>    
  </div>
</div>




<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
                <div className="row car-claim-settlement-row-two justify-content-center align-items-center">




            <div className="fixwidthDivtext">

                    <div className="container">
                        <h4 className='healthHeading'>A guide for car insurance claim settlement process through claim settlement ratio comparison:</h4>
                           <div className="yellowline"></div>
                          <div className="row">
                            <div className="col-md-6 text-justify ">
                            <p className="textformat">
                         Car insurance products are the most purchased insurance products as the growth in sales of new cars have significantly increased in the last 10 years. To know more about the process of claim settlement for car accidents, we will go through the step-by-step guiding process so that you don’t have to face claim rejections. Here, we will know how does car insurance necessitates. Hence, the number of motor insurance claims are also increasing every month.
The main purpose of buying a car insurance policy is to ensure financial security in your moment of need. The insurance money can help you meet the associated expenses. From that perspective, when it comes to insurance, the ‘moment of truth’ is defined by the ease of the claims and settlement process.
When you decide to purchase a car and sign up to a comprehensive insurance cover, policy holders are concerned about the nitty grities of claim process in case of an accident or any other apparent incident. Here is a definite analysis of all the fundamentals one must keep in mind when it comes to the car insurance claims process.
                        </p>
                            </div>
                            <div className="col-md-6 text-center">
                            <FastImage width={auto} height={auto} className="checklistimage" url='/Website%20Img/Car%20crash-pana.svg?updatedAt=1734338345974'/>
                            </div>
                          </div>
            
                    </div>
            </div>
              </div>

         
                   <div className="fixwidthDivtext">
                    <div className="insurance-less-farage">
                      <div className="simple-text-block">
                        <h4 className='healthHeading'>
                      How To Claim Insurance For Your Car Damage?
                        </h4>
                        <div className="yellowline"></div>
                  
  <p className='textformat'>
                         Car Insurance Claim is the process under which a car insurance policyholder requests the insurer to compensate for the expenses/damage incurred by him/her due to an unfortunate event involving his/her car. The amount and extent of the claim amount depend on the Insured Declared Value (IDV) of your car and the type of car insurance policy bought by you - third party, own damage or comprehensive. The amount and extent of the claim amount depend on the Insured Declared Value (IDV) of your car and the type of car insurance policy bought by you - third party, own damage or comprehensive. A detailed process is laid before you to read through the different guiding steps of raising a car insurance claim under comprehensive car insurance while your car meets an unfortunate incident:
                        </p>
                        
                        <p className='textformat'>
                            <FastImage url={"Website%20Img/Hand.png?updatedAt=1718777726806"} width={auto} height={auto} alt={"ca-img"}/>
                          &nbsp; &nbsp;
                        <b># Notifying the insurance company:</b>  First, we need to inform the insurance company about the accident through a phone call or via an email at the earliest. One must submit a duly filled in claim form and the required documents to the insurance company. Then one needs to take the car to the workshop for an estimation of the damaged car. You can source the forms from the website of the insurance provider or from the offices of the respective insurance providers.
                        </p>


                        <p className='textformat'>
                         <FastImage url={"Website%20Img/Hand.png?updatedAt=1718777726806"} width={auto} height={auto} alt={"ca-img"} />
                          &nbsp; &nbsp;
                         <b># Vehicle audit or examination:</b>  The amount and extent of the claim amount depend on the Insured Declared Value (IDV) of the car and the type of car insurance policy bought. - a third party, own damage or a comprehensive insurance policy.
                        </p>


                        <p className='textformat'>
                        <FastImage url={"Website%20Img/Hand.png?updatedAt=1718777726806"} width={auto} height={auto} alt={"ca-img"} />
                          &nbsp; &nbsp;
                        <b># Reimbursement Claim:</b>   One can first make the payment to the garage from where you got your car repaired if it’s a case of reimbursement. The amount spent by the policy holder for the purpose of repairing will be credited to his/her account based on the terms and conditions of the policy. Once you immediately submit the repair bills and invoices to the insurer after the car gets repaired, the amount of money will be compensated. It is an instant process and no delay regarding this won’t be allowed.
                        </p>


                      
                  



                      </div>
                    </div>
                    </div>
       



        <div className="fixwidthDivtext">
       <div className="container">
        <h4 className='healthHeading'>The motor insurance claim process in the case of third-party insurance is marginally different.</h4>
        <div className="yellowline"></div>
        <p className='textformat'>n case you met an accident that has caused injury to third-party or damage to third-party property, please follow the below mentioned steps to file the claim:</p>
        <div>
      <div className="car_steppername_stepper">
        {steps.map((step, index) => (
          <div 
            key={index}
            className={`car_steppername_stepper-step ${activeStep === index ? 'car_steppername_active' : ''}`}
            onClick={() => setActiveStep(index)}
          >
            <div className="car_steppername_step-number">{index + 1}</div>
            <div className="car_steppername_step-title">{step}</div>
          </div>
        ))}
      </div>

      <div className="car_steppername_step-content">
        {stepContent.map((content, index) => (
          <div 
            key={index}
            className={`car_steppername_step-content-item ${activeStep === index ? 'car_steppername_active' : ''}`}
          >
            <p className='textformat'>{content}</p>
          </div>
        ))}
      </div>
    </div>
       </div>
       </div>





    <div className="fixwidthDivtext">
    <div className="container">
  <h4 className="healthHeading">Steps to File a Claim for Theft or a Stolen Car</h4>
  <div className="yellowline"></div>
  <table className="car_insurance-table car_insurance-table-claims">
    <thead>
      <tr>
        <th className="car_insurance-table-header">Step</th>
        <th className="car_insurance-table-header">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="car_insurance-table-step">1</td>
        <td className="car_insurance-table-description">
          Lodge a complaint at the police station and file a FIR. Then, send a copy
          of the FIR to your insurance company along with the final police report
          (non-traceable report stamped by the court) and the registration
          certificate.
        </td>
      </tr>
      <tr>
        <td className="car_insurance-table-step">2</td>
        <td className="car_insurance-table-description">Submit it to the Insurance Company.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-step">3</td>
        <td className="car_insurance-table-description">
          The insurance company will assign an investigator. Cooperate with the
          investigator for further claim-related investigations.
        </td>
      </tr>
      <tr>
        <td className="car_insurance-table-step">4</td>
        <td className="car_insurance-table-description">
          Once the documents are verified, the insurance company will indemnify
          your sum insured.
        </td>
      </tr>
      <tr>
        <td className="car_insurance-table-step">5</td>
        <td className="car_insurance-table-description">Wait for the claim to be approved.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-step">6</td>
        <td className="car_insurance-table-description">
          Once the claim is approved, submit the RC Book of your stolen car to the
          insurer, and the name of the owner will be changed to the insurer’s name.
        </td>
      </tr>
      <tr>
        <td className="car_insurance-table-step">7</td>
        <td className="car_insurance-table-description">
          Submit the duplicate car keys, a subrogation letter, and a notarized
          indemnity on stamp paper to the insurance company.
        </td>
      </tr>
      <tr>
        <td className="car_insurance-table-step">8</td>
        <td className="car_insurance-table-description">
          Once all formalities are complete, the insurer will disburse the claim.
          The amount will equal the Insured Declared Value (IDV) of the vehicle.
          If you have availed a return-to-invoice cover, you will receive the
          invoice price.
        </td>
      </tr>
    </tbody>
  </table>

</div>
    </div>


  <div className="fixwidthDivtext">
  <div className="container">
  <h4 className="healthHeading">Types of Claims in Car Insurance</h4>
  <div className="yellowline"></div>
  <p className="textformat">
    Claims can be raised in two ways: the Cashless Method and the Reimbursement
    Method. In the Cashless Method, if you get your car repaired at a partner
    garage, your insurance claim is settled directly with the garage by the
    insurance company. For the Reimbursement Method, if you get your car
    repaired at a non-partner garage, you must pay first and submit the bills
    to get reimbursed. For third-party claims, reimbursement depends on the
    terms and conditions of your policy.
  </p>
  </div>
  </div>


  <div className="fixwidthDivtext">
    <div className="container">
  <h4 className="healthHeading">Documents Required to File a Car Insurance Claim</h4>
  <div className="yellowline"></div>
  <table className="car_insurance-table car_insurance-table-documents">
    <thead>
      <tr>
        <th className="car_insurance-table-header">Document</th>
        <th className="car_insurance-table-header">Purpose</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="car_insurance-table-doc-name">Driver’s License</td>
        <td className="car_insurance-table-doc-purpose">To validate the driver’s identity.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-doc-name">Car Insurance Policy</td>
        <td className="car_insurance-table-doc-purpose">To verify policy coverage.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-doc-name">First Information Report (FIR)</td>
        <td className="car_insurance-table-doc-purpose">To report theft or incidents.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-doc-name">Registration Certificate (RC)</td>
        <td className="car_insurance-table-doc-purpose">To confirm vehicle ownership.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-doc-name">Damage Evaluation</td>
        <td className="car_insurance-table-doc-purpose">To estimate repair costs.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-doc-name">Medical Report (if applicable)</td>
        <td className="car_insurance-table-doc-purpose">For claims involving physical injuries.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-doc-name">Expense Records</td>
        <td className="car_insurance-table-doc-purpose">To support additional claims.</td>
      </tr>
      <tr>
        <td className="car_insurance-table-doc-name">Photographs</td>
        <td className="car_insurance-table-doc-purpose">To document damages.</td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>



  <div className="fixwidthDivtext">
  <div className="carinsurence_claimsettlement">
      <h4 className='healthHeading'>What Should Be Done After a Car Accident?</h4>
      <div className="yellowline"></div>
      <p className='textformat'>
        Accidents are unexpected and stressful. The following steps will help you file your insurance claim effectively:
      </p>

      {/* Progress Bar */}
      <div className="carinsurence_claimsettlement_progress-bar">
        <div
          className="carinsurence_claimsettlement_progress"
          style={{ width: `${(currentStep / (claimSteps.length - 1)) * 100}%` }}
        />
      </div>

      {/* Stepper */}
      <div className="carinsurence_claimsettlement_stepper">
        <div className="carinsurence_claimsettlement_stepper-steps">
          {claimSteps.map((step, index) => (
            <div
              key={index}
              className={`carinsurence_claimsettlement_stepper-step ${
                currentStep === index ? 'carinsurence_claimsettlement_active' : ''
              }`}
              onClick={() => handleStepClick(index)}
            >
              <div className="carinsurence_claimsettlement_step-number">
                {index + 1}
              </div>
              <div className="carinsurence_claimsettlement_step-title">
                {step}
              </div>
            </div>
          ))}
        </div>

        {/* Step Content */}
        <div className="carinsurence_claimsettlement_step-content">
          <p className='textformat'>{claimStepContent[currentStep]}</p>
        </div>

        {/* Navigation Buttons */}
        <div className="carinsurence_claimsettlement_navigation">
          <button
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 0}
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={currentStep === claimSteps.length - 1}
          >
            Next
          </button>
        </div>
      </div>
    </div>
    </div>


<div className="fixwidthDivtext">
<div className="essence-of-the-matter">
  <h4 className='healthHeading'>The Essence of The Matter Is as Follows:</h4>
  <div className="yellowline"></div>
  <p className='textformat'>
    Being the owner of a car is a contentment and a liability at the same time. Most importantly, it is very crucial to be entirely adapted and knowledgably equipped at the time of an uncertain accident, and comprehensive car insurance is the only window for you to be completely prepared.
  </p>
  <p className='textformat'>
    It is also essential to be well-versed with the claims process as sometimes we become tensed and completely miss out on vital details after getting into an accident. Therefore, the crux of the matter is that, first, we need to make the process easier for your insurer to reimburse your claims. Hence, stay prepared with all possible steps with an assurance that you can file an effective claim following an adverse incident.
  </p>
</div>
</div>



          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>
    </>
  )
}

export default ClaimSettlement