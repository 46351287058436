import React from 'react';
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import { VehicalApi, vehicleInsur } from "../Health/redux/Slice";
import { useState } from "react";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'

import { useDispatch } from "react-redux";
import CarInsuranceForm from './CarInsuranceForm';
import Redirect from '../AllPageComponents/Rederict';
import { Helmet } from 'react-helmet-async';

const Renewal = () => {
    const dispath = useDispatch();

  const [formData, setFormData] = useState({
    car_number: "",
    phone_no: "",
    pin: "",
    car_type: "fourwheeler",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Log the entire form data
    dispath(vehicleInsur(formData));
    dispath(VehicalApi(formData))
      .then((result) => {
        //console.log("Data Send");
      })
      .catch((err) => {
        throw err;
      });
    }
  return (
    <>
 <Helmet>
  <title>Car Insurance Renewal</title>
  <meta 
    name="description" 
    content="Renew your car insurance policy online with ease. Compare and find the best options tailored to your needs, and ensure uninterrupted coverage for your vehicle." 
  />
  <meta 
    name="keywords" 
    content="car insurance renewal, renew car insurance, online car insurance renewal, car insurance quotes, compare car insurance, best car insurance rates, affordable car insurance, car insurance policies, vehicle insurance, auto insurance quotes, car insurance coverage, car insurance comparison" 
  />
</Helmet>


    <Redirect/>
    <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
          <h4 style={{color:"#0166B3",fontFamily:"Poppins",fontWeight:500,fontSize:"18pt"}}>Renew Car Insurance Policy Online - Up to 75%* Off</h4>
          
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer too2
          </p>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/carImagMascord.png?updatedAt=1718773961090"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <CarInsuranceForm/>
   
   
    </div>
  </div>
</section>

<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Know Your Benefits of Car Insurance Renewal Online</h4>
<div className="yellowline"></div>
<p className='normaltext'>A new car insurance has a validity period after which it needs to be renewed. That's why it's essential to renew your policy before it expires. Renewing your car insurance online offers a host of benefits, making it a convenient and wise choice.</p>
<p className='normaltext'>You can get your car insurance policy renewed by any car insurance provider - be it the one from which you previously purchased a policy or a new insurer.</p>
</div>
</div>



<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Easy Comparison of Four-Wheeler Renewal Online</h4>
<div className="yellowline"></div>
 <p className='normaltext'> Renewing your insurance online offers a plethora of advantages. Firstly, it allows you to effortlessly compare plans from various insurers, giving you a clear picture of what each offers. You can also explore additional services like free pick-up and drop facility, 3-day repair assistance, cashless assurance, and self-video claims.</p>
<h6 className='car_renualHeading'>Easy Customization</h6>
<p className='normaltext'> You can easily modify the coverage of your car insurance policy during renewal by adding or removing the add-on covers.  However, keep in mind that these add-ons can impact your car's premium.</p>
<h6 className='car_renualHeading'> Easy to Switch the Insurer</h6>
<p className='normaltext'>Switching insurers is another benefit of online renewal. If you're not satisfied with your current provider or find better features elsewhere, you can easily make the switch from the comfort of your home.</p>
<h6 className='car_renualHeading'> Secured & Transparent </h6>
<p className='normaltext'>The process is not only convenient but also secure and transparent. Secure payment gateways ensure your transactions are safe, and you can make premium payments using credit cards, debit cards, or UPI.</p>
<h6 className='car_renualHeading'> Must Highlights from Car Insurance Renewal</h6>
<p className='normaltext'>In defiance of the fact, that car insurance is a mandate in India and all across the globe for quite a sometime, more than one third of the cars maneuvering in India without having even the mandatory Third-Party Insurance coverage according to an investigative analysis by Insurance Companies. Sad but true, that many cars have been traced on the roads of India carrying fake insurance papers or old insurance papers just for the sake of regular norms. Therefore, many genuine claimants of third-party liability do not receive appropriate and sufficient claim as approximately more than around 35% of four-wheelers and 70% of two wheelers in India do not have proper motor insurance policies thatâ€™s active and valid, more importantly in smaller cities and rural areas. The issue lies with the general insurance agents and advisers as well. Motor insurance policies are not highly egged on with incentives, thatâ€™s the primary reason of demotivation for the insurance advisers for not selling, pushing or renewing old insurance policies. Even though the third-party car insurance liability is a mandate in India. Most of the vehicle owners choose comprehensive car insurance, with the purpose to safeguard themselves from the unanticipated damages caused by unprecedented accidents on Indian roads.</p>
</div>
</div>

<div className="fixwidthDivtext">
<div className="container">
                <div className="row">

                <h4 className='healthHeading'>Few Highlighted Points You Should Pay Attention While Purchasing A Car Insurance Policy</h4>
                <div className="yellowline"></div>
                <p className='normaltext'>You should be generally informed about few key points before you renew your car insurance policy. Itâ€™s specifically for policy renewals and not for fresh purchase of cars from the car dealers. As because when you buy a brand-new car, you buy it from the car dealerâ€™s showroom and hardly any thought goes into it regarding insuring your new vehicle. It is only from the next year renewal when you consciously study and analyze about multiple auto insurance plans available with the insurance companies all across India, and hatâ€™s how you select the best online auto renewal plan for your car that suits your need most.</p>
   
                <b>Follow the below mentioned points:</b>
            
                <p className='normaltext'><b>#Type of plan</b> You need to take a decision, whether you want a Comprehensive Car Insurance Policy or a Stand-alone Third-Party Liability Insurance. Third Party Liability Motor Insurance policy is a mandatory car insurance policy that covers injuries caused to other people in an accident but not damage caused to any property or vehicle. A Standalone Third-Party Liability.</p>
                
                A Comprehensive Car Insurance Policy covers self-vehicle damage as well.
                
                <p className='normaltext'><b>#Add on Features </b>There are few riders and additional benefits that are available with Standard Car Insurance Policies and some with much helpful features such as, Zero Depreciation Cover and Hydrostatic Cover.</p>
                
                <p className='normaltext'>
                <b>1.ZERO DEPRECIATION COVER </b> This rider is significantly must in a car insurance policy, most importantly for higher end cars where the depreciation amount is much higher than the additional premium payable
                </p>
                
                <p className='normaltext'><b>2.HYDROSTATIC COVER  </b>This rider covers the loss that happens for water-logging.</p>
                
                <p><b>#Cashless facility at affiliated garages:</b> Cashless settlements are hassle free if you follow certain steps. You can go for your most trusted garage for repairing your car and for the further claim settlement if your insurance provider has a tie-up with that garage, then cashless claim can be quickly settled rather than running around from pillar to post for reimbursement.</p>
                
                <p className='normaltext'>
                  <b>#Insured Declared Value (IDV):</b>
                  Insured Declared Value is the most basic amount which is decided and set by the insurance provider for that specific yearâ€™s current market value of the vehicle in case the vehicle is plundered or total loss of vehicle.
                </p>
                
                <p className='normaltext'>
                  <b>#Compulsory and Discretionary Deductibles:</b>
                  This is the basic and the most minimum amount of claim that has to be paid in each and every claim raised for auto insurance. For an instance, if it is Rs. 1000 or Rs. 2000 which is the minimum guaranteed amount that you need to pay to make each and every claim. Comprising of the deductibles usually minimize the premium so that false or small claims are steer clear of or reduced.
                </p>
                
                
                <p className='normaltext'>
                  <b>#No Claim Bonus or NCB:</b>
                  This is an additional coverage provided to the policies that remains unclaimed and unused for an entire policy year. If for one whole year you have not claimed even for once in your car insurance policy, 5%-10% of IDV is given as a bonus or renewal. Hence, at the time of renewal NCB is meticulously checked that it has been precisely and faultlessly calculated, even more specifically, if thereâ€™s been no claim in the previous policy year. NCB gets carry forwarded in such scenarios. NCB gets proliferated or multiplied up to a maximum of 50%.
                </p>
                
                <p className='normaltext'>
                  <b>#Renewal Quotations:</b>-he renewal quote can vary based on variable quotations as follows: 
                </p>
                <p className='normaltext'>
                    a. The basic value of the car depends on the IDV.
                    
                    b. Additional riders are either chosen or omitted
                    
                    c. Compulsory or Volunteered deductibles depend upon the type of plan.
                    
                    d. No Claim Bonus is carried forward.
                </p>
                
                <p className='normaltext'>
               <b>#Claim Procedure:</b>  The most significant aspect of a good motor insurance policy is a convenient and hassle-free four-wheeler claim procedure. Therefore, being consciously cognizant is much essentially important while opting for your car insurance policy. It will definitely help and guide you to make the right choice in buying the insurance renewal.
                </p>
                
                <p className='normaltext'>
                  <b>#Compare The Quotes Online:</b>
                  While exploring and shopping for the car insurance policy renewal, its always wise to fill in your details and request few insurance quotes according to your need and compare the quotes to evaluate the most reasonable pricing, that would be beneficial for you and within your budget meeting up your requirements.
                </p>
                
              
                </div>
</div>
</div>

<div className="fixwidthDivtext">
  <div className="container">
    <h4 className='healthHeading'>How to Renew Car Insurance Online?</h4>
    <p>Car insurance renewal online from Beema1.com is quick and easy. Just follow these simple steps to get an instant quote in no time:</p>
    <ul className='car_renuwalList_ul'>
      <li className='car_renuwalList_li'>Go to the car insurance renewal section on the website.</li>
      <li className='car_renuwalList_li'>Fill in the required details like your policy number, mobile number, date of birth, etc., and submit.</li>
      <li className='car_renuwalList_li'>Choose the car insurance plan that suits your needs.</li>
      <li className='car_renuwalList_li'>Decide on any additional riders or add-on covers you want to include or remove.</li>
      <li className='car_renuwalList_li'>The premium amount will be displayed on the page for your review.</li>
      <li className='car_renuwalList_li'>Pay the insurance premium securely online using a credit card, debit card, or net banking.</li>
      <li className='car_renuwalList_li'>Once the payment is processed, your car insurance policy will be renewed.</li>
      <li className='car_renuwalList_li'>You'll receive the policy document for your renewed car insurance via email on</li>
      <li className='car_renuwalList_li'>your registered email address. You can also download and print a copy of the policy document anytime you need it.</li>
    </ul>
  </div>
</div>
            
<PartnerCompany/>
<FAQ/>
    </>
  )
}

export default Renewal