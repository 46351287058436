import React, { useEffect ,useState} from 'react'
import { AddonApi } from '../../../Api/Api';
import { parseData } from '../../../helper/common';

const Caremedical = (medicaldetails,userfetchdata) => {
const [medicalQustion, setmedicalQustion] = useState([])
const Insuredpersonindex = medicaldetails?.userfetchdata?.proposal_data?.InsuredDetail;

console.log('the medicaldetails',medicaldetails)

const search = window.location.search;
const params = new URLSearchParams(search);
const unique_id = params.get("req_id");
const plan_id = params.get("plan_id");
const c_id = params.get("c_id");


// THis is for calling api medicalqustions
const getAddon = async () => {
        try {
          const response = await AddonApi(c_id, plan_id).then((response) => {
            setmedicalQustion(response.question_answer.data);
          });
        } catch (error) {
          console.log("the error in addonlist");
        }
      };
    
useEffect(()=>{
getAddon()
},[medicaldetails])



// Assuming 'medicalQustion' is the array of JSON strings
const parsedQuestions = medicalQustion.map(item => parseData(item));

// console.log("Parsed Medical Questions:", userfetchdata);


    return (
        <>
      {Object.keys(medicaldetails || {}).map((key) => (
  <div key={key} className="medical-key py-2">
    {Array.isArray(medicaldetails[key]) ? (
      medicaldetails[key].map((questionSetArray, index) => (
        <div key={index} className="question-set">
          {typeof questionSetArray === 'object' && !Array.isArray(questionSetArray) ? (
            <>
              {Object.entries(questionSetArray).map(([nestedKey, questionSet], subIndex) => (
  <div key={subIndex} className="question">








{Array.isArray(questionSet) ? (
  questionSet.map((question, subSubIndex) => {
    // Check if the question object is valid
    if (!question || Object.keys(question).length === 0) {
      return null; // Skip this iteration if question is empty
    }
    return (
      <div className="user" key={subSubIndex}>
        {/* Check if Insuredpersonindex is valid and render Proposal Name */}
        {Array.isArray(Insuredpersonindex) &&
          Insuredpersonindex[subSubIndex] && (
            <p className="proposalname">
              Proposal Name:{" "}
              <span className="mainproposalname">
                {Insuredpersonindex[subSubIndex].ProposalName || Insuredpersonindex[subSubIndex].InsuredName}
              </span>
            </p>
          )}
        {Array.isArray(question) ? (
          question.map((data, idx) => (
            <div className="col-md-12" key={idx}>
              {Array.isArray(parsedQuestions) &&
                parsedQuestions.map((parsedQuestion, parsedIndex) => {
                  if (parsedQuestion.question_id === data.questionCd) {
                    return (
                      <div key={parsedIndex}>
                        <p className="qustionsbyID">
                          Q: <span className="mainqustion">{parsedQuestion.question}</span>
                        </p>
                      </div>
                    );
                  }
                  return null;
                })}
              <div className="row d-flex">
                <div className="col-md-12">
                  {data.response !== "YES" && (
                    <p className="datetime">
                      Date:&nbsp; <span className="mainqustion">{data.response}</span>
                    </p>
                  )}
                </div>
              </div>
              <h5></h5>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>
    );
  })
) : (
  <p>No questions in this set.</p>
)}













  </div>
))}

            </>
          ) : (
            <p>No valid data in this question set.</p>
          )}
        </div>
      ))
    ) : (
      <p>No data for ID: {key}</p>
    )}
  </div>
))}

        </>
      )
      
      
}

export default Caremedical