import React, { useEffect, useState } from 'react'
import { AddonApi } from '../../../Api/Api';

const Digitmedical = (medicaldetails) => {
const search = window.location.search;
const params = new URLSearchParams(search);
const unique_id = params.get("req_id");
let plan_id = params.get("plan_id");
const c_id = params.get("c_id");

const [digitQustion, setdigitQustion] = useState([])

const getAddon = async () => {
  try {
      const response = await AddonApi(c_id, plan_id);
      const data = response.question_answer.data;
      const parsedData = data.map(item => {
          try {
              if (typeof item === 'string') {
                  return JSON.parse(item);
              }
              return item;
          } catch (error) {
              console.error('Error parsing item:', item);
              return item;
          }
      });
      setdigitQustion(parsedData);
  } catch (error) {
      console.error('Error fetching addon data:', error);
  }
};

    useEffect(() => {
      getAddon(); 
    }, [medicaldetails]); 

    function getNestedKeys(obj) {
        let result = {};
        for (let key in obj) {
          if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null) {
            const nested = obj[key];
            for (let nestedKey in nested) {
              if (nested.hasOwnProperty(nestedKey) && typeof nested[nestedKey] === 'object') {
                result[nestedKey] = nested[nestedKey]; 
              }
            }
          }
        }
      
        return result;
      }
      
      const medicalData = medicaldetails.medicaldetails[0];
      const nestedKeys = getNestedKeys(medicalData);
      const InsureDetailsList = medicaldetails?.userfetchdata?.proposal_data?.InsuredDetail;

 

// // console.log('digit medicalQustion',digitQustion)
// // console.log("the digit responce",nestedKeys)

// console.log('the index name',InsureDetailsList)



  return (
<>
{Object.entries(nestedKeys).map(([key, value], index) => {
  let showName = true; 
console.log('the digit buddy',value)
  if (Array.isArray(value)) {
    return value.map((item, subIndex) => {
      const matchedQuestion = digitQustion.find(
        (dq) => dq.question_id === item.questionCode
      );
      const insureName = InsureDetailsList?.[index]?.InsuredName;

      return matchedQuestion ? (
         <div className="col-md-6">
        <div key={`${key}-${index}-${subIndex}`}>
          {showName && insureName && (
            <p className="insured-name">
              {insureName}
            </p>
          )}
          {showName = false} {/* Set the flag to false after the name is displayed */}
          
          <p className="qustionsbyID">
            Q: <span className="mainqustion">{matchedQuestion.question}</span>
          </p>
          {item.subQuestions?.length > 0 && (
            <div>
              {item.subQuestions.map((subQuestion, subSubIndex) => {
                const jsondata = matchedQuestion.get_child_question || [];
                const matchedChildQuestion = jsondata.find(
                  (child) => child.question_id === subQuestion.questionCode
                );
                const childAnswer = matchedChildQuestion?.answers?.find(
                  (ans) => ans.answer_code === subQuestion.detailAnswer
                );

                return matchedChildQuestion ? (
                  <p
                    className="childqustion"
                    key={`${key}-${index}-${subIndex}-${subSubIndex}`}
                  >
                    Q: {matchedChildQuestion.question}
                    <br />
                    A: {childAnswer?.answer_description || "Answer not found"}
                  </p>
                ) : null;
              })}
            </div>
          )}
        </div>
        </div>
      ) : null;
    });
  } else if (typeof value === "object" && value !== null) {
    const matchedQuestion = digitQustion.find(
      (dq) => dq.question_id === value.questionCode
    );

    return matchedQuestion ? (
      <div key={`${key}-${index}`}>
        {index === 0 && InsureDetailsList?.[index]?.InsuredName && (
          <p className="insured-name">{InsureDetailsList[index].InsuredName}</p>
        )}
        <p>{matchedQuestion.question}</p>
        {value.subQuestions?.length > 0 && (
          <div>
            {value.subQuestions.map((subQuestion, subIndex) => (
              <p key={`${key}-${index}-sub-${subIndex}`}>
                {subQuestion.questionCode}
              </p>
            ))}
          </div>
        )}
      </div>
    ) : null;
  }
  return null;
})}


</>




  )
}

export default Digitmedical