import {feetAndInchesToCm} from "./common";

const payload = (proposerData,apidata,kycdata,addonsArray,medQue , c_id ,kycdoc, kycPresent , kycDocUpload,goGreenCheck ) =>
{

	console.log('proposerData in payload',proposerData)
	console.log("kycdata", kycdata)
	// //console.log("PlanData ",PlanData)

	const heightdata = feetAndInchesToCm(proposerData.height_feet , proposerData.height_inch);

	//console.log("the bheightdata", heightdata);

	let tenure =  1;
  
	if(apidata.response.hasOwnProperty('premiumBreakup'))
	{
		tenure =  apidata.response.premiumBreakup.term
	}

	// let ped = JSON.parse(localStorage.getItem('PED'));
	// console.log("ped",ped.join(','))

	let kycUploadStatus = kycDocUpload.address_front.kyc_file_upload_path || kycDocUpload.address_back.kyc_file_upload_path ||
	kycDocUpload.identity_front.kyc_file_upload_path || kycDocUpload.identity_back.kyc_file_upload_path

	// {
	// 	"address_front": {
	// 		"file_extension": "",
	// 		"kyc_file_upload_path": "",
	// 		"doc_type": "AADHAAR"
	// 	},
	// 	"address_back": {
	// 		"file_extension": "",
	// 		"kyc_file_upload_path": "",
	// 		"doc_type": "AADHAAR"
	// 	},
	// 	"identity_front": {
	// 		"file_extension": "",
	// 		"kyc_file_upload_path": "",
	// 		"doc_type": "PAN"
	// 	},
	// 	"identity_back": {
	// 		"file_extension": "",
	// 		"kyc_file_upload_path": "",
	// 		"doc_type": "PAN"
	// 	}
	// }

   let output = {
		    "ProposalName": kycdata?.middlename ? `${kycdata?.firstname} ${kycdata?.middlename} ${kycdata?.lastname}` : `${kycdata?.firstname} ${kycdata?.lastname}`,
		    "Proposalphonenumber": proposerData.Proposalphonenumber,
		    "street": kycdata.street,
		    "PinCode": kycdata.PinCode,
		    "District": proposerData.District,
		    "City": proposerData.District,
		    "State": proposerData.Sate,
		    "PED": proposerData.PED,
		    "Email": kycdata.Email,
		    "dateOfbirth": kycdata.KycDateOfbirth,
		    "heightcm": proposerData.heightcm, 
			"height":proposerData.height,
		    "weight":  proposerData.weight,
		    "Occupation": proposerData.Occupation,
		    "GrossIncome": proposerData.AnnualIncome,
		    "Gender":(proposerData.Gender == 'M' ? 'Male' : 'Female'),
		    "Premium": apidata.response.premiumBreakup.totalPremium,
			"BasePremium" : apidata.response.premiumBreakup.basePremium,
			"TotalDiscount" : apidata.response.premiumBreakup.discountAmount,
			"cdb_limit":"",
		    "SumInsured": apidata.response.sumInsured,
		    "PlanCode": apidata.response.planCode,
		    "PlanName": apidata.response.planName,
			"reductioninwaitingperiodlimit": "",
			"voluntarydeductiblelimit": 0,
			"roomrent_type": 0,
		    "Tenure": tenure+" Year",
		    "InsuredDetail": InsuredDetail(proposerData.InsuredDetail,kycdata,medQue,c_id,proposerData),
			"MaritalStatus":proposerData.MaritalStatus,
			"hdfc_premium" :apidata.response.hdfc_premium,
			"physical_dispatch": !goGreenCheck,

			"bank_account_umber": kycdata.accountNumber,
			"name_as_per_bank_account": kycdata.Name,
			"bank_name": kycdata.bankName,
			"ifsc_code": kycdata.ifscCode,
			
			"kyc_upload" : kycUploadStatus ? "yes" : "no",
			"kyc_upload_data" : kycDocUpload,
			// "addressDoc" : kycdata?.addressDoc || "",
			// "identityDoc" : kycdata?.identityDoc || "",

		    "nominee": {
		        "firstName": extractNames(kycdata.nomineeName).fname,
		        "middleName": null,
		        "lastName": extractNames(kycdata.nomineeName).lname,
		        "dateOfbirth": kycdata.nomineeDateOfbirth,
		        "profession": null,
		        "gender": kycdata.nomineerelationship == 'Self' ? proposerData.Gender : getGender(kycdata.nomineerelationship),
		        "relationship": kycdata.nomineerelationship,
				"street" : kycdata.nomineeAddress,
				"City" : proposerData.District,
				"PhoneNumber":kycdata?.nomineePhoneNumber,
				"PinCode":kycdata.PinCode,
				"State":proposerData.Sate
		    },
			"ApointeeDetails": {
				"FullName": proposerData.ProposalName,
				"RelationWithNominee": proposerData.RelationWithNominee,
				"street": proposerData.street,
				"PinCode": proposerData.PinCode,
				"StateName": proposerData.Sate,
				"CityName": proposerData.District,
				"street":kycdata.street
			},
		    "kycId": kycdata.kycId,
		    "cardNum": kycdata.cardNum,
		    "addons": addonsArray ? addonsArray : "",
			"GUID": "97180dc3-8d32-41d5-b033-5ad8395cc349",
			"CorrelationId": "97180dc3-8d32-41d5-b033-5ad8395cc349",

			"deductible": addonsArray ? addonsArray.includes("deductible")?(apidata.request.deductible ? apidata.request.deductible : 0):0 : 0,
		'HDCRiderSI' : addonsArray ? addonsArray.includes("HDCRiderSI")?(apidata.request.HDCRiderSI ? apidata.request.HDCRiderSI : 0):0 : 0,
		'QuoteNo': apidata.response.QuoteNo,
		'quoteId': apidata.response.quoteId,
		'HDCRiderPremium' : apidata.response.HDCRiderPremium,
        "userToken":localStorage.getItem('userToken'),
		}
	

	return output;
}

const InsuredDetail = (data,userdata,medque,c_id,proposerData) => 
{
  let result = [];


  for (let i = 0; i < data.length; i++) 
  {
	let medicalDetails;
	if(c_id == 'reliance'){
		medicalDetails = MedicalQuestionDetailsReliance(i, medque);

	}




	

  	let obj = {
  		         InsuredName:data[i].InsuredName,
  		         InsuredRelation:data[i].InsuredRelation,
  		         dateOfbirth:data[i].dateOfbirth,
  		         Gender: data[i].InsuredRelation == 'Self' ? (proposerData.Gender == 'M' ? 'Male' : 'Female') : getGender(data[i].InsuredRelation),
  		         InsuredAge:data[i].InsuredAge,
  		         heightcm:data[i].heightcm,
					height:data[i].height,
  		         weight:data[i].weight,
  		         IPED: true,
  		         MaritalStatus:data[i].MaritalStatus,
  		        //  medicalQuestions:medque[i]
				  medicalQuestions:c_id == "icici" ? MedicalQuestionDetails(i,medque) : c_id == 'reliance' ? 
				 { DiseaseList: medicalDetails.DiseaseList,
				  InsureQuestionsList: medicalDetails.InsureQuestionsList
				}
				  : c_id == 'hdfc' ? MedicalQuestionDetailsHDFC(i , medque) :(medque?.length !=0 ? medque?medque[i]:[] : [])

  		      };
  	result.push(obj);
  }
  return result;
}

const extractNames = (fullName) =>
{
  // Split the full name into an array of words
  const nameArray = fullName.split(' ');

  // Extract the first name (fname)
  const fname = nameArray[0];

  // Extract the last name (lname) if available
  const lname = nameArray.length > 1 ? nameArray.slice(1).join(' ') : '';

  return { fname, lname };
}
 
const getGender = (relation) =>
{
  const output = {
  	"Mother":'Female',
  	"Father":'Male',
  	"Grand Father":'Male',
  	"Grand Mother":'Female',
  	"Brother":'Male',
  	"Sister":'Female',
  	"Husband":'Male',
  	"Wife":'Female',
  	"Father In Law":'Male',
  	"Mother In Law":'Female',
  	"Uncle":'Male',
  	"Aunt":'Female',
  	"Grand Daughter":'Female',
  	"Grand Son":'Male',
  	"Son":'Male',
  	"Daughter":'Female'

  }
  return output[relation];
} 



const MedicalQuestionDetails = (index,HDFCcheckedQuestion) => 
	{
		let array = [];
	  for (let key in HDFCcheckedQuestion) {
		   if(HDFCcheckedQuestion[key].hasOwnProperty(index))
		   {
			   array.push(key);
			   
		   }
	   } 
		return array;
	
	}


	const MedicalQuestionDetailsHDFC = (index,HDFCcheckedQuestion) => 
		{
			let array = [];
			
		  for (let key in HDFCcheckedQuestion) {
			   if(HDFCcheckedQuestion[key].hasOwnProperty(index))
			   {
		
				array.push(HDFCcheckedQuestion[key][index])
			
			   }
		   } 
		   if(array.length != 0){
			let data = array.map((item)=>{
				console.log("the item", item)
		
				item = item[0];
				return item
			})
			console.log("the data", data)
			// array.map(item => item[0])
			return data
		   }
		   else{
			return array;
		
		   }
		
		}



	function convertToNormalDate(dateStr) {
		if(dateStr){
			const dateObj = new Date(dateStr);
		  
			// Extract the day, month, and year
			const day = String(dateObj.getDate()).padStart(2, '0'); // Ensure two digits for the day
			const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
			const year = dateObj.getFullYear();
		  
			// Return in dd/mm/yyyy format
			return `${day}/${month}/${year}`;
		}
		  }
		
		const convertDateFormat = (dateString) => {
			if(dateString){// Split the input date by "/"
		
				console.log("the dateString",convertToNormalDate(dateString));
			const [day, month, year] = convertToNormalDate(dateString).split('/');
		  
			// Return the date in mm/yyyy format
			return `${month}/${year}`;
		}
		else{
			return ""
		}
		  };
		  
		
		const MedicalQuestionDetailsReliance = (index, ReliancecheckedQuestion) => {
			let DiseaseList = [];
			let InsureQuestionsList = [];
		  
			for (let key in ReliancecheckedQuestion) {
			  if (ReliancecheckedQuestion[key].hasOwnProperty(index)) {
				let questions = ReliancecheckedQuestion[key][index];
				questions.forEach(question => {
				  if (question.QuestionId == "1") { // Assuming "1" is for DiseaseList
					question.Options.forEach(option => {
					  let disease = {
						DiseaseID: option.OptionId,
						SufferingSince:option.SufferingSince || "",
						OtherDisease: option.OtherDisease || "",
						TreatmentTaken: option.TreatmentTaken || "",
						ExactDiagnosis: option.ExactDiagnosis || "",
						HospitalName: option.HospitalName || "",
						ConsultationDate: option.ConsultationDate || ""
					  };
					  DiseaseList.push(disease);
					});
				  } 
				  else {
					// Check if an entry already exists
					let existingQuestion = InsureQuestionsList.find(
					  iq => iq.QuestionId === question.QuestionId
					);
		  
					if (!existingQuestion) {
					  // Create a new entry if it doesn't exist
					  existingQuestion = {
						IsSelected: "true", // Assuming IsSelected should be true, adjust if needed
						QuestionId: question.QuestionId,
						QuestionDetails: {}
					  };
					  InsureQuestionsList.push(existingQuestion);
					}
		  
					question.Options.forEach(option => {
					  let optionDetails = {
						ExactDiagnosis: option.ExactDiagnosis || "",
						DiseaseName : option.DiseaseName || "",
						Description : option.Description || "",
		
						SurgeryDate:option.SurgeryDate || "",				
						DiagnosisDate: option.DiagnosisDate || "",
						ConsultationDate: option.ConsultationDate || "",
						HospitalName: option.HospitalName || "",
						MedicineName: option.MedicineName || "",
						TypeOfTests: option.TypeOfTests || "",
						DateOfTests: option.DateOfTests || "",
						FindingsOfTests: option.FindingsOfTests || "",
						DetailsOfYourPastMedicalCondition: option.DetailsOfYourPastMedicalCondition || "",
						ExpectedDeliveryDate: option.ExpectedDeliveryDate || "",
						HasFiledClaimWithCurrPreviousInsured: option.HasFiledClaimWithCurrPreviousInsured || "",
						HasChargedCancelledHighPremium: option.HasChargedCancelledHighPremium || "",
						
					  };
		  
					  // Update the existing QuestionDetails
					  Object.keys(optionDetails).forEach(key => {
						if (optionDetails[key]) {
						  existingQuestion.QuestionDetails[key] = optionDetails[key];
						}
					  });
					});
				  }
				});
			  }
			}
		  
				
		  
			return { DiseaseList, InsureQuestionsList };
		  }

export {payload}