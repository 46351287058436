import React, { useState, useEffect } from "react";
import '../MedicalDetailsPage/MedicalDetails.css';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Proposal, ProposerForm } from "../ProposalPage/Proposal";
import { HDFCQuestionAnswer, HDFCPersion } from "../../commonComponents/HDFC";
import { useLocation } from 'react-router-dom';
import Alert from "@mui/material/Alert";
import {
  ICICIQuestionAnswer,
  ICICIPersion,
} from "../../commonComponents/ICICI";
import {
  findSelf,
  companyId,
  getMonthYear,
  changeDateFormat,
} from "../../helper/common";
import {
  RelianceQuestion,
  ReliancePersion,
} from "../../commonComponents/RelianceQuestion";
import { payload } from "../../helper/godigit";
import Summary from "../../commonComponents/Summary";
import Loader from "../../../AllPageComponents/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { afterPlanFetchApi, AddonApi } from "../../Api/Api";
import Swal from "sweetalert2";

import {parseData} from "../../helper/common";
import axios from "axios";

import {
  selectProposal,
  updateData,
  addInsuredData,
  updateInsuredData,
  updatecheckedQuestion,
  selectcheckedQuestion,
  updatecheckedPersion,
  selectcheckedPersion,
  updateCareQAlist,
  updateHDFCQAlist,
  updateGodigitQAlist,
} from "../../redux/Slice";
import "../../commonComponents/HDFC.css";
import RelianceQuestionAnswer from "../../commonComponents/RelianceQuestion";
import Redirect from "../../../AllPageComponents/Rederict";
import { Helmet } from "react-helmet-async";
import { getReduxData, settingReduxData } from "../../Api/Api";

const MedicalDetails = () => {
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };



  const [questionData, setQuestionData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [stopLoading, setStopLoading] = useState(false);

  const [loadingData, setLoadingData] = useState(false);


  const { userdata, fetchPlandata } = useSelector((state) => {
    return state.FetchPlan;
  });

  //   let insuredData = userdata.InsuredDetail; -----commented out this because not full data of insureddetails is coming here

  // ---------using this proposerData because here the whole data of insureddetails are coming

  // let insuredData = proposerData.InsuredDetail;

  const dispatch = useDispatch();

  const getSelfKey = () => {
    for (let i = 0; i < insuredData.length; i++) {
      if (insuredData[i].InsuredRelation == "Self") {
        return i;
        break;
      }
    }
    return null;
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  let plan_id = params.get("plan_id");
  const c_id = params.get("c_id");
  const company = companyId();

  const [addonArr, setAddonArr] = useState([]);

  const [insuredData, setInsuredData] = useState("");

  // const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState("");
  const [errordata, setErrorData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [addonDataList, setAddonDataList] = useState([]);

  const [proposerData, setProposerData] = useState();

  const [ICICIcheckedQuestion, setICICICheckedQuestion] = useState({});
  const [ReliancecheckedQuestion , setRelianceCheckedQuestion] = useState({});
  const [HDFCcheckedQuestion , setHDFCcheckedQuestion] = useState({});

  const [checkedQuestion, setCheckedQuestion] = useState([]);
  const [checkedPersion , setCheckedPersion] = useState();
  const [finalAddonList, setFinalAddonList] = useState();
  

  const [medData , setMedData] = useState();


  // const [userdata , setUserData] = useState('')

  const set_error = (error, message) => {
    setErrorData(error);
    setErrorMessage(message);
  };

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  const getData = async () => {
    try {
      setStopLoading(true);
      let response = await getReduxData(unique_id).then((response) => {
        setLoadingData(true);

        // console.log("the med get", response.data.data[0].medical_question[c_id][plan_id])

        if (response?.data?.data[0]?.proposal_data) {
          setProposerData(response.data.data[0].proposal_data);
        }

        let medData = response?.data?.data[0]?.medical_question[c_id][plan_id];
        if(medData){
          setMedData(medData)
        }

        console.log("questiondat in getdata", questionData)


        if (
          c_id == "icici" &&
          response?.data?.data[0]?.medical_question[c_id][plan_id]
        ) {
          setICICICheckedQuestion(
            response?.data?.data[0]?.medical_question[c_id][plan_id]
          );
        }

        if(c_id == 'reliance' &&  response?.data?.data[0]?.medical_question[c_id][plan_id]){
          setRelianceCheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
        }

        if(c_id == 'hdfc' &&  response?.data?.data[0]?.medical_question[c_id][plan_id]){
          setHDFCcheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
        }

        if(
          c_id == 'godigit' && response?.data?.data[0]?.medical_question[c_id][plan_id]
        ){
          let medicalQuestionsArray = response?.data?.data[0]?.medical_question[c_id][plan_id];

          setProposerData(prevState => {
            const updatedInsuredDetail = prevState.InsuredDetail.map((insured, index) => {
              // Add/update medicalQuestions for each object in InsuredDetail
              if (medicalQuestionsArray[index]) {
                return {
                  ...insured,
                  medicalQuestions: medicalQuestionsArray[index]
                };
              }
              return insured; // If no medicalQuestions for this index, return the object as is
            });
      
            return {
              ...prevState,
              InsuredDetail: updatedInsuredDetail // Update the InsuredDetail with the modified objects
            };
          });
        }

        if(
          c_id == "care" && response?.data?.data[0]?.medical_question[c_id][plan_id]
        ){
      
          let medicalQuestionsArray = response?.data?.data[0]?.medical_question[c_id][plan_id];

          setProposerData(prevState => {
            const updatedInsuredDetail = prevState.InsuredDetail.map((insured, index) => {
              // Add/update medicalQuestions for each object in InsuredDetail
              if (medicalQuestionsArray[index]) {
                return {
                  ...insured,
                  partyQuestionDOList: medicalQuestionsArray[index]
                };
              }
              return insured; // If no medicalQuestions for this index, return the object as is
            });
      
            return {
              ...prevState,
              InsuredDetail: updatedInsuredDetail // Update the InsuredDetail with the modified objects
            };
          });

        }


        setStopLoading(false);
      });
    } catch (error) {
      console.log("the error", error);
    }
  };

  const settingData = async () => {
    try {
      console.log("the proposerData", proposerData);

      let phNo = localStorage.getItem("Proposalphonenumber");
      let payloadData ; 

      if(c_id == 'icici'){
        payloadData = {
          medical_question: ICICIcheckedQuestion,
          phone_no: phNo,
          company: c_id,
          plan_code: plan_id,
        };
      }
      else if(c_id == 'godigit'){

        const medicalQuestionsArray = proposerData.InsuredDetail.map(insured => 
          insured.medicalQuestions && insured.medicalQuestions.length > 0 
            ? insured.medicalQuestions 
            : [] // Insert empty object if medicalQuestions is empty or missing
        );
        console.log("medicalQuestionsArray",medicalQuestionsArray);

        payloadData = {
          medical_question: medicalQuestionsArray,
          phone_no: phNo,
          company: c_id,
          plan_code: plan_id,
        };
      }
      else if(c_id == 'care'){

        const medicalQuestionsArray = proposerData.InsuredDetail.map(insured => 
          insured.partyQuestionDOList && insured.partyQuestionDOList.length > 0 
            ? insured.partyQuestionDOList 
            : [] // Insert empty object if medicalQuestions is empty or missing
        );
        console.log("medicalQuestionsArray",medicalQuestionsArray);

        payloadData = {
          medical_question: medicalQuestionsArray,
          phone_no: phNo,
          company: c_id,
          plan_code: plan_id,
        };
      }
      else if(c_id == "reliance"){
        payloadData = {
          medical_question: ReliancecheckedQuestion,
          phone_no: phNo,
          company: c_id,
          plan_code: plan_id,
        };
      }
      else if(c_id == "hdfc"){
        payloadData = {
          medical_question: HDFCcheckedQuestion,
          phone_no: phNo,
          company: c_id,
          plan_code: plan_id,
        };
      }

      let response = await settingReduxData(unique_id, payloadData).then(
        (response) => {
          console.log("the response in set reduxdata", response);
        }
      );
    } catch (error) {
      console.log("the error", error);
    }
  };

  const fetchPlan = async () => {
    try {
      // Start loading
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id).then(
        (response) => {
          setLoader(false);

          if (response.data.response == null) {
            setStopLoading(true);
            handleError("Oops there is an error !");
          }

          console.log("the response in medicaldetails", response);
          setApiResponse(response.data);
          setFinalAddonList(response.data.request.addonPrice);
          setAddonDataList(response.data.request.addonPrice);

          if (response.data.request.addons) {
            let currentAddons = response.data.request.addons
              ? response.data.request.addons.split(",")
              : [];

            setAddonArr(currentAddons);
          }
        }
      );
    } catch (error) {
      console.log("the error in afterfetchplan", error);
    } finally {
      // setLoading(false); // Stop loading
    }
  };

  const getAddon = async () => {
    try {
      const response = await AddonApi(c_id, plan_id).then((response) => {
        // setAddonList(response.addon_data.data);
        setQuestionData(response.question_answer.data);
      });
    } catch (error) {
      console.log("the error in addonlist");
    }
  };

  useEffect(() => {
    getAddon();
    fetchPlan();
    getData();
  }, []);

 

  function getQuestionData() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_URL +
        "health/fetch_question/" +
        company[c_id] +
        "/" +
        plan_id,
      headers: {},
      async: false,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.question_answer.status == true) {
          setQuestionData(response.data.question_answer.data);
          setLoader(false);
        } else {
          setQuestionData([]);
        }
      })
      .catch((error) => {
        setQuestionData([]);
        // console.log(error);
      });
  }

  const medicalQsubmit = (e) => {
    e.preventDefault();
    // console.log('medicalQsubmit')
    const diseaseNameValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Disease Name")
      .map((element) => element.value);
    const surgeryDateValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Surgery Date")
      .map((element) => element.value);
    const dateOfDiagnosisValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Date of Diagnosis")
      .map((element) => element.value);
    const lastConsultationDateValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Last Consultation Date")
      .map((element) => element.value);

    const formData = {
      diseaseName: diseaseNameValues,
      surgeryDate: surgeryDateValues,
      dateOfDiagnosis: dateOfDiagnosisValues,
      lastConsultationDate: lastConsultationDateValues,
    };

    console.log("formData", formData);
  };

  useEffect(() => {
    if (apiResponse && apiResponse.response == null) {
      setStopLoading(true);
      handleError("Oops there is an Error");
    }
  }, [apiResponse]);




  useEffect(() => {
    if (medData && questionData &&  ['godigit' , 'care' ].includes(c_id)) {
      console.log("que", questionData);
      console.log("med", medData);

     

      const parsedData = questionData.map((item) => parseData(item));
      console.log("the data of parsed", parsedData);

      const updatedCheckedQuestions = [...checkedQuestion];

      parsedData.forEach((item, index) => {
        medData.forEach((value) => {
         
            // Add index to updatedCheckedQuestions if not already present

            if(c_id == 'godigit' && Array.isArray(value)){
              value?.map((inval , indexval)=>{
                if (item.question_id === inval.questionCode){
  
              if (!updatedCheckedQuestions.includes(index)) {
                updatedCheckedQuestions.push(index);
  
              
                
  
              }
                }
              })
            }

            if(c_id == 'care' && Array.isArray(value)){
              value?.map((inval , indexval)=>{
                if (item.question_id === inval.questionCd){
  
              if (!updatedCheckedQuestions.includes(index)) {
                updatedCheckedQuestions.push(index);
  
              
                
  
              }
                }
              })
            }

       
 
        });
      });

      const stringifiedArray = updatedCheckedQuestions.map((item) => item.toString())

   // Update the state with the new checkedQuestion array
      setCheckedQuestion(stringifiedArray);


      if(c_id == 'godigit'){
        const formattedResult = {};

        // Iterate over each person (outer array)
        medData.forEach((personArray, personIndex) => {
          // Iterate over each question object for the person
         if(Array.isArray(personArray)){
          personArray.forEach((question) => {
  
            // let data = questionData.map((val,ind)=>{
            //   if(val.question_id == question){
            //     return ind
            //   }
            // })
  
            
  
            const { questionCode } = question;
    
            // Initialize the questionCode key if it doesn't exist
            if (!formattedResult[questionCode]) {
              formattedResult[questionCode] = [];
            }
    
            // Add the personIndex to the array for that questionCode
            formattedResult[questionCode].push(personIndex);
          });
         }
        });
  
        console.log("formatted person", formattedResult)
       let accData={};
  
        parsedData.map((item, index) => {
          const updatedObject = Object.keys(formattedResult).reduce((acc, key) => {
            if (!acc) acc = {}; // Ensure `acc` is initialized properly
        
            let newKey;
            // Determine the new key based on the condition
            if (item.question_id == key) {
              newKey = index; // Assign the index to newKey
              acc[newKey] = formattedResult[key];
              // accData = acc
              accData[newKey] = formattedResult[key];
  
              
              // Assign the value to the new key
            } else {
              acc[key] = formattedResult[key]; // Preserve other keys
            }
        
            return acc; // Always return the accumulator
          }, {}); // Initial value for `acc` is an empty object
        
          console.log("updatedObject", accData);
        
          if (updatedObject) {
            setCheckedPersion(accData); // Perform your state update here
          }
        });
        

      }

      if(c_id == 'care'){
        const formattedResult = {};

        // Iterate over each person (outer array)
        medData.forEach((personArray, personIndex) => {
         if(Array.isArray(personArray)){
           // Iterate over each question object for the person
           personArray.forEach((question) => {
  
            // let data = questionData.map((val,ind)=>{
            //   if(val.question_id == question){
            //     return ind
            //   }
            // })
  
            
  
            const { questionCd } = question;
    
            // Initialize the questionCode key if it doesn't exist
            if (!formattedResult[questionCd]) {
              formattedResult[questionCd] = [];
            }
    
            // Add the personIndex to the array for that questionCode
            formattedResult[questionCd
            ].push(personIndex);
          });
         }
        });
  
        console.log("formatted person", formattedResult)
  
       
  
        let accData={};
  
        parsedData.map((item, index) => {
          const updatedObject = Object.keys(formattedResult).reduce((acc, key) => {
            if (!acc) acc = {}; // Ensure `acc` is initialized properly
        
            let newKey;
            // Determine the new key based on the condition
            if (item.question_id == key) {
              newKey = index; // Assign the index to newKey
              acc[newKey] = formattedResult[key];
              // accData = acc
              accData[newKey] = formattedResult[key];
  
              
              // Assign the value to the new key
            } else {
              acc[key] = formattedResult[key]; // Preserve other keys
            }
        
            return acc; // Always return the accumulator
          }, {}); // Initial value for `acc` is an empty object
        
          console.log("updatedObject", accData);
        
          if (updatedObject) {
            setCheckedPersion(accData); // Perform your state update here
          }
        });
        

      }
   
      

    }
  }, [medData, questionData]);



  // if (!proposerData) {
  //   return (
  //     <div>
  //       <Loader />
  //     </div>
  //   );
  // }

  // if(!proposerData){
  //   return (
  //     <div>
  //       <Loader />
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (proposerData) {
      setLoadingData(true);
    }
  }, [proposerData]);

  return loadingData == true && proposerData ? (
    <>






      <Helmet>
        <title>Medical details</title>

        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta
          name="keywords"
          content="Insurance Plan, Insurance Quotes, buy insurance"
        />
      </Helmet>
      <div className="container">
        <Redirect />
        <h1 className="mainheading">
          We Sell Happyness* <span className="middelheading">We Buy</span>
          <span className="endheading">Your Fear</span>{" "}
        </h1>
        {errordata && (
          <Alert
            variant="filled"
            severity="error"
            style={{ width: "770px" }}
            onClose={() => {
              set_error(false, "");
            }}
          >
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={medicalQsubmit}>
          <div className="row">
            <div className="col-lg-8 medicalform">
              <div className="nextsection">
                <h1 className="Proposalheader">Proposal form (step 2 of 3)</h1>

                <p className="Medicalheaderp">Medical Details</p>
                {/* <div className="row ppv10 my-3"> */}
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="col-md-12"
                >
                  {c_id == "hdfc"
                    ? questionData.map((data, index) => {
                        return (
                          <>
                            <HDFCQuestionAnswer
                               data={parseData(data)}
                              index={index}
                              insuredData={proposerData?.InsuredDetail}
                              HDFCcheckedQuestion={HDFCcheckedQuestion}
                              setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                            />

                            <div className="med-person-div col-md-12">
                              {proposerData?.InsuredDetail.map(
                                (persion, pindex) => {
                                  return (
                                    <>
                                      <HDFCPersion
                                        data={parseData(data)}
                                        persion={persion}
                                        pindex={pindex}
                                        proposerData={proposerData && proposerData}
                                        HDFCcheckedQuestion={HDFCcheckedQuestion}
                                        setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                                      />
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </>
                        );
                      })
                    : c_id == "icici"
                    ? questionData.map((data, index) => {
                        return (
                          <>
                            <ICICIQuestionAnswer
                              data={parseData(data)}
                              index={index}
                              insuredData={proposerData?.InsuredDetail}
                              proposerData={proposerData && proposerData}
                              ICICIcheckedQuestion={ICICIcheckedQuestion}
                              setICICICheckedQuestion={setICICICheckedQuestion}
                            />

                            {proposerData.InsuredDetail.map(
                              (persion, pindex) => {
                                return (
                                  <>
                                    <ICICIPersion
                                      data={parseData(data)}
                                      persion={persion}
                                      pindex={pindex}
                                      proposerData={
                                        proposerData && proposerData
                                      }
                                      ICICIcheckedQuestion={
                                        ICICIcheckedQuestion
                                      }
                                      setICICICheckedQuestion={
                                        setICICICheckedQuestion
                                      }
                                    />
                                  </>
                                );
                              }
                            )}
                          </>
                        );
                      })
                    : c_id == "reliance"
                    ? questionData.map((data, index) => {
                        return (
                          <>
                            <RelianceQuestionAnswer
                              data={parseData(data)}
                              index={index}
                              insuredData={proposerData?.InsuredDetail}
                              ReliancecheckedQuestion={ReliancecheckedQuestion}
                              setRelianceCheckedQuestion={setRelianceCheckedQuestion}
                            />

                            {proposerData?.InsuredDetail.map(
                              (persion, pindex) => {
                                return (
                                  <>
                                    <ReliancePersion
                                      data={parseData(data)}
                                      persion={persion}
                                      pindex={pindex}
                                      proposerData={
                                        proposerData && proposerData
                                      }
                                      ReliancecheckedQuestion={ReliancecheckedQuestion}
                                      setRelianceCheckedQuestion={setRelianceCheckedQuestion}
                                    />
                                  </>
                                );
                              }
                            )}
                          </>
                        );
                      })
                    : questionData.map((data, index) => {
                        return (
                          <>
                          {/* {alert(checkedQuestion)} */}
                          {console.log("the checkedQuestion", checkedQuestion)}
                            <DigitQuestionAnswer
                              data={parseData(data)}
                              index={index}
                              insuredData={proposerData?.InsuredDetail}
                              proposerData={proposerData && proposerData}
                              setProposerData={setProposerData}
                              setPropo
                              checkedQuestion={checkedQuestion}
                              setCheckedQuestion={setCheckedQuestion}
                            />
                            {/* data, insuredData, index, proposerData, checkedQuestion, setCheckedQuestion */}
                            <Persion
                              data={parseData(data)}
                              insuredData={proposerData?.InsuredDetail}
                              index={index}
                              proposerData={proposerData && proposerData}
                              checkedQuestion={checkedQuestion}
                              setCheckedQuestion={setCheckedQuestion}
                              setProposerData={setProposerData}
                              checkedPersion={checkedPersion}
                              setCheckedPersion={setCheckedPersion}
                            />
                          </>
                        );
                      })}
                  {/* </div> */}
                </div>
              </div>
              <div></div>
            </div>

            <div className="col-lg-4">
              <Summary
                settingData={settingData}
                set_error={set_error}
                nextPage="kyc"
                addondataList={addonDataList}
                setAddonDataList={setAddonDataList}
                addonsArray={addonArr}
                setAddonArr={setAddonArr}
                planInfo={apiResponse ? apiResponse : ""}
                apiResponse={apiResponse}
                setApiResponse={setApiResponse}
                finalAddonList={finalAddonList}
                setFinalAddonList={setFinalAddonList}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  ) : (
    <>
  <Redirect/>
<div className="container">
  <div className="row">
  <div className="col-lg-8 ">

<div className="medicalsklition_formsklition">
<div className="headersklition_propsal">
<Skeleton variant="text" animation="wave" className="skallitonform1"/>
</div>
 <div className="medicalfullwidth">
 <Skeleton variant="text" animation="wave" className="medicalfullwidthQustions"/>

 </div>
 <div className="medicalfullwidth">
 <Skeleton variant="text" animation="wave" className="medicalfullwidthQustions"/>

 </div>
 <div className="medicalfullwidth">
 <Skeleton variant="text" animation="wave" className="medicalfullwidthQustions"/>

 </div>
 <div className="medicalfullwidth">
 <Skeleton variant="text" animation="wave" className="medicalfullwidthQustions"/>

 </div>
 <div className="medicalfullwidth">
 <Skeleton variant="text" animation="wave" className="medicalfullwidthQustions"/>

 </div>
 <div className="medicalfullwidth">
 <Skeleton variant="text" animation="wave" className="medicalfullwidthQustions"/>

 </div>
 <div className="medicalfullwidth">
 <Skeleton variant="text" animation="wave" className="medicalfullwidthQustions"/>

 </div>

</div>



  </div>
  <div className="col-lg-4">
<div className="summerySklition">
  <div className="headerSklition">
  <Skeleton variant="rectangular" animation="wave" className="summeryheadtext"/>
  </div>
  <div className="summerysklitonbody">
  <Skeleton variant="circular" animation="wave"  className="summeryAfterFetchimages" />
  <Skeleton variant="rectangular" animation="wave" className="priceandtanureSummerySkalition"/>
  </div>
  <div className="sklitionbody2">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>

  </div>

  <div className="summeryfooterbody">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText3"/>

  </div>
</div>
  </div>
  </div>
  </div>
  </>
  );
};

export default MedicalDetails;

const DigitQuestionAnswer = ({
  data,
  index,
  insuredData,
  proposerData,
  checkedQuestion,
  setCheckedQuestion,
  setProposerData,
}) => {
  // const dispatch = useDispatch();

  // const checkedQuestion = useSelector(selectcheckedQuestion);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const c_id = params.get("c_id");




  // const updateCheckedQuestion = (payloadData, insuredData) => {
  //   let storeData = [...checkedQuestion]; // Create a copy of checkedQuestion
  //   let c_id = payloadData.c_id;

  //   // Check if the value already exists in checkedQuestion
  //   if (storeData.includes(payloadData.val)) {
  //     let index = storeData.indexOf(payloadData.val);
  //     storeData.splice(index, 1); // Remove the value from storeData

  //     // If c_id is "Care"
  //     if (c_id === "care") {
  //       let questionSetCd = payloadData.questionSetCode;
  //       let updatedInsuredData = insuredData.map((insuredItem) => {
  //         if (insuredItem.hasOwnProperty("partyQuestionDOList")) {
  //           let data = insuredItem.partyQuestionDOList;
  //           let qa;

  //           if(Object.keys(data).length === 0 && data.constructor === Object){
  //             qa = []
  //           }
  //           else{
  //             qa = data;
  //           }

  //           let tempArray
  //           if(Array.isArray(qa) && qa?.length !=0){
  //             tempArray = insuredItem.partyQuestionDOList.filter(
  //               (question) => question.questionCode !== questionSetCd
  //             );
  //             console.log("tempArray", tempArray)
  //           }
  //           else{
  //             tempArray = qa;
  //           }
  //           // if(!(Array.isArray(qa))){
  //           //   return { ...insuredItem, partyQuestionDOList: {} };
  //           // }
  //           // let tempArray = qa.filter(
  //           //   (question) => question.questionSetCd !== questionSetCd
  //           // );
  //           return { ...insuredItem, partyQuestionDOList: tempArray.length == 0 ? {} : tempArray  };
  //         }
  //         return insuredItem;
  //       });
      

  //       // setInsuredData(updatedInsuredData);
  //       // Update insuredData state

  //       setProposerData((prevData) => ({
  //         ...prevData,
  //         InsuredDetail: updatedInsuredData,
  //       }));
  //     }
  //     // If c_id is "GoDigit"
  //     else if (c_id === "godigit") {
  //       let question_id = payloadData.question_id;
  //       let updatedInsuredData = insuredData.map((insuredItem) => {
  //         if (insuredItem.hasOwnProperty("medicalQuestions")) {
  //           let qa = insuredItem.medicalQuestions;
  //           let tempArray
  //           if(Array.isArray(qa)){
  //             tempArray = qa.filter(
  //               (question) => question.questionCode !== question_id
  //             );
  //           }
  //           else{
  //             tempArray = qa;
  //           }
          
  //           return { ...insuredItem, medicalQuestions: tempArray };
  //         }
  //         return insuredItem;
  //       });
  //       // setInsuredData(updatedInsuredData);  // Update insuredData state

  //       setProposerData((prevData) => ({
  //         ...prevData,
  //         InsuredDetail: updatedInsuredData,
  //       }));
  //     }
  //   } else {
  //     storeData.push(payloadData.val); // Add value to checkedQuestion
  //   }
    
    

  //   setCheckedQuestion(storeData); // Update checkedQuestion state
  // };


  const updateCheckedQuestion = (payloadData, insuredData) => {
    let storeData = [...checkedQuestion]; // Create a copy of checkedQuestion
    let c_id = payloadData.c_id;
  
    // Check if the value already exists in checkedQuestion
    if (storeData.includes(payloadData.val)) {
      let index = storeData.indexOf(payloadData.val);
      storeData.splice(index, 1); // Remove the value from storeData
  
      // If c_id is "care"
      if (c_id === "care") {
        let questionSetCd = payloadData.questionSetCode;
        let updatedInsuredData = insuredData.map((insuredItem) => {
          if (insuredItem.hasOwnProperty("partyQuestionDOList")) {
            let data = insuredItem.partyQuestionDOList;
            
            // Ensure partyQuestionDOList is an array or empty object
            let qa = Array.isArray(data) ? data : (data && Object.keys(data).length === 0 ? [] : data);
            
            // Log the state of partyQuestionDOList for debugging
            console.log("Before filtering: ", qa);
  
            // Filter out the question from partyQuestionDOList
            let tempArray = qa.filter(
              (question) => question.questionCode !== questionSetCd
            );
  
            // If tempArray is empty, set it back to an empty object
            return {
              ...insuredItem,
              partyQuestionDOList: tempArray.length === 0 ? {} : tempArray,
            };
          }
          return insuredItem;
        });
  
        // Update proposerData state with the modified insuredData
        setProposerData((prevData) => ({
          ...prevData,
          InsuredDetail: updatedInsuredData,
        }));
  
      }
      // If c_id is "godigit"
      else if (c_id === "godigit") {
        let question_id = payloadData.question_id;
        let updatedInsuredData = insuredData.map((insuredItem) => {
          if (insuredItem.hasOwnProperty("medicalQuestions")) {
            let qa = insuredItem.medicalQuestions;
            let tempArray;
  
            // Ensure qa is an array before filtering
            if (Array.isArray(qa)) {
              tempArray = qa.filter(
                (question) => question.questionCode !== question_id
              );
            } else {
              tempArray = qa;
            }
  
            return { ...insuredItem, medicalQuestions: tempArray };
          }
          return insuredItem;
        });
  
        // Update proposerData state with the modified insuredData
        setProposerData((prevData) => ({
          ...prevData,
          InsuredDetail: updatedInsuredData,
        }));
      }
    } else {
      storeData.push(payloadData.val); // Add value to checkedQuestion
    }
  
    // Update checkedQuestion state
    setCheckedQuestion(storeData); 
  };
  
  

  
  




  return (
    <>
      <div className="row col-md-12">
        <div className="col pmd">
          {/*<span className='nfh1 cash'>Q.{data.question}</span>*/}
          <FormControlLabel
            value={index}
            onChange={
              (e) => {
                updateCheckedQuestion(
                  {
                    val: e.target.value,
                    questionSetCode: data.QuestionSetCode,
                    c_id: c_id,
                    question_id: data.question_id,
                  },
                  insuredData
                );
              }
              // dispatch(
              //   updatecheckedQuestion({
              //     val: e.target.value,
              //     questionSetCode: data.QuestionSetCode,
              //     c_id: c_id,
              //     question_id: data.question_id,
              //   })
              // )
            }
            // style={{alignItems:"flex-start"}}
            className="checkbox-div"
            control={<Checkbox className='checkbox-div-box' />}
            label={"Q." + data.question}
            checked={checkedQuestion.includes(index.toString())}
          />
        </div>
      </div>
    </>
  );
};





const DigitSubQuestionAnswer = ({ data, qid, pid, parentData , proposerData , setProposerData ,checkedPersion , checkedQuestion }) => {
  const [objData, setObjData] = useState();
  const [fieldData, setFieldData] = useState("");
  // const checkedPersion = useSelector(selectcheckedPersion);
  // const proposerData = useSelector(selectproposerData);
  const dispatch = useDispatch();

  console.log("proposerData", proposerData);



  console.log(
    "the med questiondata",
    proposerData.InsuredDetail[pid].medicalQuestions
  );

  console.log("the q id", qid);

  console.log("the p id", pid);

  console.log("the parentdata", parentData);

  console.log("the data", data);

  // useEffect(() => {
  //   if (proposerData?.InsuredDetail[pid]?.medicalQuestions) {
  //     let valdata = proposerData?.InsuredDetail[pid]?.medicalQuestions;

  //     console.log("the val", valdata);

  //     let val = [valdata];

  //     val.map((item, index) => {
  //       if (item.questionCode == parentData.question_id) {
  //         console.log(
  //           "item.questionCode , parentData.question_id",
  //           item.questionCode,
  //           parentData.question_id
  //         );

  //         item.subQuestions.map((value, index) => {
  //           if (value.questionCode == data.question_id) {
  //             setObjData(value);
  //           }
  //         });
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (proposerData?.InsuredDetail[pid]?.medicalQuestions && Array.isArray(proposerData?.InsuredDetail[pid]?.medicalQuestions)) {
      let valdata = proposerData?.InsuredDetail[pid]?.medicalQuestions;

   

      console.log("the val", valdata);

      let val = [valdata];


      val.map((item, index) => {
        item.map((info , ind)=>{
          if (info.questionCode == parentData?.question_id) {
            // console.log(
            //   "item.questionCode , parentData.question_id",
            //   item.questionCode,
            //   parentData.question_id
            // );
            // setCheckedQuestion((prevData)=>{
            //   console.log("the prevdta in checkedquestion", prevData)
            //   // return(
            //     prevData.push(qid)   
            // // )
            // }
            //  )
  
            // setCheckedQuestion((prevData) => {
            //   const safePrevData = prevData || []; // Ensure it is an array
            //   console.log("The safePrevData in checkedQuestion:", safePrevData);
            //   return [...safePrevData, qid];
            // });
  
            info?.subQuestions.map((value, index) => {
              if (value.questionCode == data.question_id) {
                setObjData(value);
              }
            });
          }
        })
      
      });
    }

    // console.log("the objdata here", objData)
  }, []);

  if (objData) {
    console.log("objData", objData);
  }

  const check = () => {
    // console.log("check")
    if (checkedPersion?.hasOwnProperty(qid)) {
      if (checkedPersion[qid].includes(pid)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  function godigitquestionExist(qa, qid) {
    for (let i = 0; i < qa.length; i++) {
      if (qa[i].questionCode == qid) {
        return true;
        break;
      }
    }
    return false;
  }


  const updateGodigitQAlist = (payloadData) => {
    let storeData = proposerData.InsuredDetail;
    let persionData = storeData[payloadData.pid]; // Get the person data based on pid
    let qdata = payloadData.qdata; // Question data
    let value = payloadData.value; // The answer to the question
    let parentqData = payloadData.parentqData; // Parent question data

    // Ensure the person has a `medicalQuestions` array
    if (!persionData.hasOwnProperty("medicalQuestions")) {
      persionData.medicalQuestions = [];
    }

    let qa = persionData.medicalQuestions;

    // If the parent question already exists in the `medicalQuestions` array
    if (godigitquestionExist(qa, parentqData.question_id)) {
      let key = qa.findIndex(
        (element) => element.questionCode === parentqData.question_id
      );

      let keyone = qa[key].subQuestions.findIndex(
        (element) => element.questionCode === qdata.question_id
      );

      // If the sub-question doesn't exist, push it into the `subQuestions` array
      if (keyone === -1) {
        qa[key].subQuestions.push({
          questionCode: qdata.question_id,
          answerType: "Text_Descript",
          isApplicable: true,
          detailAnswer: value,
          subQuestions: [],
        });
      } else {
        // Otherwise, update the `detailAnswer` for the existing sub-question
        qa[key].subQuestions[keyone].detailAnswer = value;
      }
    } else {
      // If the parent question doesn't exist, add it along with the sub-question
      qa.push({
        questionCode: parentqData.question_id,
        answerType: "BOOLEAN",
        isApplicable: true,
        detailAnswer: "FALSE",
        subQuestions: [
          {
            questionCode: qdata.question_id,
            answerType: "Text_Descript",
            isApplicable: true,
            detailAnswer: value,
            subQuestions: [],
          },
        ],
      });
    }

    // Update the proposerData state
    setProposerData((prevData) => ({
      ...prevData,
      InsuredDetail: prevData.InsuredDetail.map((person, index) => {
        if (index === payloadData.pid) {
          return persionData; // Update the specific person's data
        }
        return person;
      }),
    }));
  };

  // const updateGodigitQAlist = (payloadData) => {
  //   let storeData = proposerData.InsuredDetail || [];
  //   let persionData = storeData[payloadData.pid]; 
  //   if (!persionData) {
  //     console.error("Invalid person data for pid:", payloadData.pid);
  //     return;
  //   }
  
  //   let qdata = payloadData.qdata;
  //   let value = payloadData.value;
  //   let parentqData = payloadData.parentqData;
  
  //   if (!Array.isArray(persionData.medicalQuestions)) {
  //     persionData.medicalQuestions = [];
  //   }
  
  //   let qa = persionData.medicalQuestions;
  
  //   if (godigitquestionExist(qa, parentqData.question_id)) {
  //     let key = qa.findIndex(
  //       (element) => element.questionCode === parentqData.question_id
  //     );
  
  //     let keyone = qa[key].subQuestions.findIndex(
  //       (element) => element.questionCode === qdata.question_id
  //     );
  
  //     if (keyone === -1) {
  //       qa[key].subQuestions.push({
  //         questionCode: qdata.question_id,
  //         answerType: "Text_Descript",
  //         isApplicable: true,
  //         detailAnswer: value,
  //         subQuestions: [],
  //       });
  //     } else {
  //       qa[key].subQuestions[keyone].detailAnswer = value;
  //     }
  //   } else {
  //     qa.push({
  //       questionCode: parentqData.question_id,
  //       answerType: "BOOLEAN",
  //       isApplicable: true,
  //       detailAnswer: "FALSE",
  //       subQuestions: [
  //         {
  //           questionCode: qdata.question_id,
  //           answerType: "Text_Descript",
  //           isApplicable: true,
  //           detailAnswer: value,
  //           subQuestions: [],
  //         },
  //       ],
  //     });
  //   }
  
  //   setProposerData((prevData) => {
  //     const updatedInsuredDetail = prevData.InsuredDetail.map((person, index) =>
  //       index === payloadData.pid ? { ...persionData } : person
  //     );
  //     return {
  //       ...prevData,
  //       InsuredDetail: updatedInsuredDetail,
  //     };
  //   });
  // };
  


  return (
    <>
      <div className="row col-md-12"
       hidden={check()}
       >
        <div className="col pmd">
          <span className="nfh1 cash">Q.{data.question}</span>

          <FormGroup>
            <TextField
              id="standard-select-currency-native"
              select
              label=""
              value={fieldData ? fieldData : objData?.detailAnswer}
              SelectProps={{
                native: true,
              }}
              variant="standard"
              onChange={(e) => {
                setFieldData(e.target.value);
                // dispatch(
                //   updateGodigitQAlist({
                //     qid: qid,
                //     pid: pid,
                //     qdata: data,
                //     parentqData: parentData,
                //     value: e.target.value,
                //   })
                // );

                updateGodigitQAlist(
                  {
                        qid: qid,
                        pid: pid,
                        qdata: data,
                        parentqData: parentData,
                        value: e.target.value,
                      }
                )
              }}
            >
              <option>Select</option>

              {data.answers.map((data) => {
                return (
                  <option value={data.answer_code}>
                    {data.answer_description}
                  </option>
                );
              })}
            </TextField>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

const convertDateFormat = (dateString) => {
  // Split the date string into day, month, and year components
  const parts = dateString.split("-");

  // Ensure that there are three parts (day, month, and year)
  if (parts.length !== 3) {
    throw new Error("Invalid date format");
  }
  const formattedDate = `${parts[1]}-${parts[0]}`;

  return formattedDate;
};

const getCurrentMonthYear = () => {
  // Get the current date using dayjs
  const currentDate = dayjs();

  // Format the current date to "MM-YYYY" format
  const currentMonthYear = currentDate.format("MM-YYYY");

  return currentMonthYear;
};

const CareSubQuestionAnswer = ({ data, qid, pid, parentData, dobdata , proposerData, checkedPersion, setProposerData ,checkedQuestion}) => {
  // console.log("the stringdata in care", toString(dobdata));
  const monthdata = convertDateFormat(dobdata);

  // console.log("the monthdata in care", monthdata);
  const currentmonthyear = getCurrentMonthYear();
  // console.log("the currentmonthyear", currentmonthyear);

  // console.log("the dobdata in CareSubQuestionAnswer", dobdata);
  // const checkedPersion = useSelector(selectcheckedPersion);
  const dispatch = useDispatch();
  // const proposerData = useSelector(selectProposal);
  // console.log("proposerData", proposerData);
  const oldqdata = proposerData.InsuredDetail[pid];
  let value = null;
  if (oldqdata.hasOwnProperty("partyQuestionDOList")) {
    let partyQuestionDOList = oldqdata.partyQuestionDOList;

    for (let i = 0; i < partyQuestionDOList.length; i++) {
      if (partyQuestionDOList[i].questionCd == data.question_id) {
        value = partyQuestionDOList[i].response;
        // console.log('questionCd',data.questionCd)
      }
    }
  }





  const check = () => {
    // console.log("check")
    if (checkedPersion?.hasOwnProperty(qid)) {
      if (checkedPersion[qid].includes(pid)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  


  let yes_no_array = ["pedYesNo", "H001", "H002", "H003", "H004"];



  function questionExist(qa, qid) {
    for (let i = 0; i < qa.length; i++) {
      if (qa[i].questionCd == qid) {
        return true;
      }
    }
    return false;
  }

  function addZeroToMonth(dateString) {
    try {
      let parts = dateString.split('/');
  
      // Convert the month part to a zero-padded string if necessary
      let month = parseInt(parts[0]);
      let zeroPaddedMonth = month < 10 ? "0" + month : month;
  
      // Reconstruct the date string
      let formattedDateString = zeroPaddedMonth + '/' + parts[1];
      return formattedDateString;
    } catch (err) {
      return dateString; // If any error occurs, return the original date string
    }
  }

  // qid: qid,
  // pid: pid,
  // qdata: data,
  // parentqData: parentData,
  // value: e.target.value,
  console.log("the proposerdata in caresubquestion ", proposerData)

  // const updateCareQAlist = (payloadData) => {
  //   const { pid, qdata, value, parentqData } = payloadData;

  //   // Find the person data by pid
  //   // let persionData = proposerData.InsuredDetail.find((person) => person.id === pid);
   

  //   let persionData = proposerData.InsuredDetail[pid]

  //   // Ensure 'partyQuestionDOList' exists
  //   if (!persionData?.hasOwnProperty("partyQuestionDOList")) {
  //     if(persionData){
  //       persionData.partyQuestionDOList = [];

  //     }
  //   }

  //   let qa = persionData.partyQuestionDOList


  //   let yes_no_array = ["pedYesNo", "H001", "H002", "H003", "H004"];

  //   // If the question already exists, update its response
  //   if (questionExist(qa, qdata.question_id)) {
  //     for (let i = 0; i < qa.length; i++) {
  //       if (qa[i].questionCd === qdata.question_id) {
  //         qa[i].response =
  //           qdata.question_id === "OtherSmokeDetails" ||
  //           qdata.question_id === "otherDiseasesDescription" ||
  //           yes_no_array.includes(qdata.question_id)
  //             ? value
  //             : addZeroToMonth(value); // Format the date if necessary
  //         break;
  //       }
  //     }
  //   } else {
  //     // If the question doesn't exist, check and add the parent question if necessary
  //     if (!questionExist(qa, parentqData.question_id)) {
  //       qa.push({
  //         questionSetCd: parentqData.QuestionSetCode,
  //         questionCd: parentqData.question_id,
  //         response: "YES",
  //       });
  //     }

  //     // If the question is not in the yes_no_array, add it with the proper response
  //     if (!yes_no_array.includes(qdata.question_id)) {
  //       qa.push({
  //         questionSetCd: qdata.QuestionSetCode,
  //         questionCd: qdata.question_id,
  //         response:
  //           qdata.question_id === "OtherSmokeDetails" ||
  //           qdata.question_id === "otherDiseasesDescription"
  //             ? value
  //             : addZeroToMonth(value), // Format the date if necessary
  //       });
  //     }
  //   }

  //   // Update the proposerData state
  //   setProposerData((prevData) => ({
  //     ...prevData,
  //     InsuredDetail: prevData.InsuredDetail.map((person) => {
  //       if (person.id === pid) {
  //         return persionData; // Update the specific person's data
  //       }
  //       return person;
  //     }),
  //   }));
  // };


  const updateCareQAlist = (payloadData) => {
    const { pid, qdata, value, parentqData } = payloadData;
  
    // Find the person data by pid
    let persionData = proposerData.InsuredDetail[pid];
  
    // Ensure 'partyQuestionDOList' exists and is an array
    if (!persionData?.hasOwnProperty("partyQuestionDOList") || !Array.isArray(persionData.partyQuestionDOList)) {
      persionData.partyQuestionDOList = [];
    }
  
    let qa = persionData.partyQuestionDOList;
  
    let yes_no_array = ["pedYesNo", "H001", "H002", "H003", "H004"];
  
    // If the question already exists, update its response
    if (questionExist(qa, qdata.question_id)) {
      for (let i = 0; i < qa.length; i++) {
        if (qa[i].questionCd === qdata.question_id) {
          qa[i].response =
            qdata.question_id === "OtherSmokeDetails" ||
            qdata.question_id === "otherDiseasesDescription" ||
            yes_no_array.includes(qdata.question_id)
              ? value
              : addZeroToMonth(value); // Format the date if necessary
          break;
        }
      }
    } else {
      // If the question doesn't exist, check and add the parent question if necessary
      if (!questionExist(qa, parentqData.question_id)) {
        qa.push({
          questionSetCd: parentqData.QuestionSetCode,
          questionCd: parentqData.question_id,
          response: "YES",
        });
      }
  
      // If the question is not in the yes_no_array, add it with the proper response
      if (!yes_no_array.includes(qdata.question_id)) {
        qa.push({
          questionSetCd: qdata.QuestionSetCode,
          questionCd: qdata.question_id,
          response:
            qdata.question_id === "OtherSmokeDetails" ||
            qdata.question_id === "otherDiseasesDescription"
              ? value
              : addZeroToMonth(value), // Format the date if necessary
        });
      }
    }
  
    // Update the proposerData state
    setProposerData((prevData) => ({
      ...prevData,
      InsuredDetail: prevData.InsuredDetail.map((person) => {
        if (person.id === pid) {
          return persionData; // Update the specific person's data
        }
        return person;
      }),
    }));
  };
  













 






  return (
    <>
      <div className="row ppv10 my-3 col-12" hidden={check()}>
        <div className="col px-0">
          {/* <div className="prpfbd"> */}
          {/* {console.log("the data in medical", data)} */}
          {data.question_id == "OtherSmokeDetails" ||
          data.question_id == "otherDiseasesDescription" ? (
            <TextField
              id="standard-helperText"
              label={data.question}
              defaultValue={value}
              variant="standard"
              onChange={(e) =>
                // dispatch(
                //   updateCareQAlist({
                //     qid: qid,
                //     pid: pid,
                //     qdata: data,
                //     parentqData: parentData,
                //     value: e.target.value,
                //   })
                // )
                updateCareQAlist({
                      qid: qid,
                      pid: pid,
                      qdata: data,
                      parentqData: parentData,
                      value: e.target.value,
                    })
              }
            />
          ) : yes_no_array.includes(data.question_id) ? (
            <TextField
              id="standard-select-currency-native"
              select
              label={data.question}
              SelectProps={{
                native: true,
              }}
              variant="standard"
              onChange={(e) =>
                // dispatch(
                //   updateCareQAlist({
                //     qid: qid,
                //     pid: pid,
                //     qdata: data,
                //     parentqData: parentData,
                //     value: e.target.value,
                //   })
                // )
                updateCareQAlist({
                  qid: qid,
                      pid: pid,
                      qdata: data,
                      parentqData: parentData,
                      value: e.target.value,e,
                })
              }
            >
              <option value="NO" selected={value == "NO"}>
                NO
              </option>
              <option value="YES" selected={value == "YES"}>
                YES
              </option>
            </TextField>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DesktopDatePicker
                  variant="standard"
                  id="standard-helperText"
                  label={data.question}
                  defaultValue={dayjs(
                    value != null ? changeDateFormat(value) : null,
                    "MM-YYYY"
                  )}
                  onChange={(e) =>
                    // dispatch(
                    //   updateCareQAlist({
                    //     qid: qid,
                    //     pid: pid,
                    //     qdata: data,
                    //     parentqData: parentData,
                    //     value: getMonthYear(e),
                    //   })
                    // )
                    updateCareQAlist({
                      qid: qid,
                        pid: pid,
                        qdata: data,
                        parentqData: parentData,
                        value: getMonthYear(e),
                    })
                  }
                  // Specify the minimum selectable date
                  minDate={dayjs(monthdata, "MM-YYYY")}
                  // Specify the maximum selectable date
                  maxDate={dayjs(currentmonthyear, "MM-YYYY")}
                  format="MM-YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
          )}
        </div>
      </div>
    </>
  );
};

const Persion = ({ data, insuredData, index, proposerData, checkedQuestion, setCheckedQuestion , setProposerData,
  checkedPersion , setCheckedPersion
}) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  // const checkedQuestion = useSelector(selectcheckedQuestion);
  const [clickPersion, setClickPersion] = useState(false);

  // const [checkedPersion , setCheckedPersion] = useState();

  

  const updateCheckedPersion = (payloadData) => {
    let storeData = { ...checkedPersion };  // Create a shallow copy of checkedPerson
    let c_id = payloadData.c_id;

    // Check if the qid exists in storeData
    if (storeData.hasOwnProperty(payloadData.qid)) {
      // Check if pid is in the array for the given qid
      if (storeData[payloadData.qid].includes(payloadData.pid)) {
        let index = storeData[payloadData.qid].indexOf(payloadData.pid);
        storeData[payloadData.qid].splice(index, 1);  // Remove pid from the array

        // let persionData = { ...proposerData.InsuredDetail.find(person => person.id === payloadData.pid) };

        let persionData = proposerData.InsuredDetail[payloadData.pid]
        // alert(JSON.stringify(persionData))

        // If c_id is "Care"
        if (c_id === "care") {
          let qa = persionData.partyQuestionDOList || [];
          let tempArray = [];
          let questionSetCd = payloadData.questionSetCd;

          qa.forEach((question) => {
            if (question.questionSetCd !== questionSetCd) {
              tempArray.push(question);
            }
          });

          persionData.partyQuestionDOList = tempArray;  // Update partyQuestionDOList
        } 
        // If c_id is "godigit"
        else if (c_id === "godigit") {
          let qa = persionData.medicalQuestions || [];
          let tempArray = [];
          let question_id = payloadData.question_id;

          qa.forEach((question) => {
            if (question.questionCode !== question_id) {
              tempArray.push(question);
            }
          });

          persionData.medicalQuestions = tempArray;  // Update medicalQuestions
        }

        // Update the proposerData state by modifying the InsuredDetail
        let newInsuredData = proposerData.InsuredDetail.map((person) => {
          if (person.id === payloadData.pid) {
            return persionData;  // Update the specific person's data
          }
          return person;
        });

        setProposerData((prevData) => ({
          ...prevData,
          InsuredDetail: newInsuredData
        }));

      } else {
        storeData[payloadData.qid].push(payloadData.pid);  // Add pid to the array
      }
    } else {
      storeData[payloadData.qid] = [payloadData.pid];  // Create a new array for qid with pid
    }

    // Update the checkedPerson state
    setCheckedPersion(storeData);
  };

  const handleClick = (pindex) => {
    // dispatch(
    //   updatecheckedPersion({
    //     qid: index,
    //     pid: pindex,
    //     question_id: data.question_id,
    //     questionSetCd: data.QuestionSetCode,
    //     c_id: c_id,
    //     qdata: data,
    //   })
    // );
   
    updateCheckedPersion({
          qid: index,
          pid: pindex,
          question_id: data.question_id,
          questionSetCd: data.QuestionSetCode,
          c_id: c_id,
          qdata: data,
        },checkedPersion, setCheckedPersion, insuredData, proposerData)
    
  };


  const dispatch = useDispatch();


  console.log("checkedPerson", checkedPersion)

  return (
    <>
      <div
        className="col-md-12"
        hidden={!checkedQuestion.includes(index.toString())}
      >
        {insuredData.map((persion, pindex) => {
          // console.log("the persion in persion component", persion);
          const check = () => {
            // console.log("check")
            if (checkedPersion?.hasOwnProperty(index)) {
              if (checkedPersion[index].includes(pindex)) {
                return "sumboxone";
              } else {
                return "";
              }
            } else {
              return "";
            }
          };

      

          return (
            <>
              <div className="center">
                <div className="scroll">
                  <div
                    className={"row boxdiv " + check()}
                    onClick={() => handleClick(pindex)}
                  >
                    <div className="col-12">
                      <span className="proposalname">
                        {proposerData.InsuredDetail[pindex].InsuredName} (
                        {persion.InsuredRelation}){" "}
                      </span>
                    </div>
                    <div className="col-3 text-right"></div>
                  </div>

                  {data.get_child_question.map((child_data) => {
                    if (c_id == "godigit") {
                      return (
                        <DigitSubQuestionAnswer
                          data={child_data}
                          parentData={data}
                          qid={index}
                          pid={pindex}
                          proposerData={proposerData}
                          setProposerData={setProposerData}
                          checkedPersion={checkedPersion}
                          checkedQuestion={checkedQuestion}
                        />
                      );
                    } else if (c_id == "care") {
                      return (
                        <CareSubQuestionAnswer
                          data={child_data}
                          parentData={data}
                          qid={index}
                          pid={pindex}
                          dobdata={persion.dateOfbirth}
                          proposerData={proposerData}
                          setProposerData={setProposerData}
                          checkedPersion={checkedPersion}
                          checkedQuestion={checkedQuestion}
                        />
                      );
                    } else {
                    }
                  })}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
