
import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Grid } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './AnswerSetNine.css'; // Importing the CSS file
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { updateRelianceQAlist , selectReliancecheckedQuestion} from "../../redux/Slice";

const AnswerSetNine = ({ pindex, qdata, parentqData ,  updateRelianceQAlist,
  ReliancecheckedQuestion,
  setRelianceCheckedQuestio }) => {
  const [radioValue, setRadioValue] = useState('');

  // const dispatch = useDispatch();

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const convertMMDate = (dateString) =>{
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
  
    return `${month}/${year}`;
  }

//   useEffect(() => {
//     // Dispatch the action when the component mounts
//     dispatch(
//       updateRelianceQAlist({
//         name: "",
//         value: radioValue,
//         pindex: pindex,
//         qdata: qdata,
//         parentqData: parentqData,
//       })
//     );
//   }, [dispatch, pindex, qdata, parentqData, radioValue]);



const [dataObj, setDataObj] = useState();

// const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

console.log("the answer set one data",ReliancecheckedQuestion)

console.log("the pindex", pindex);

console.log("the q data in set one", qdata)




useEffect(() => {
  if (ReliancecheckedQuestion) {

    console.log("hdfc checked question",ReliancecheckedQuestion )
    const keys = Object.keys(ReliancecheckedQuestion);
    const numbersToCheck = [7];
    const anyNumberPresent = numbersToCheck.some((number) =>
      keys.includes(number.toString())
    );

    const resultObjects = numbersToCheck
      .filter((number) => keys.includes(number.toString()))
      .map((number) => ReliancecheckedQuestion[number.toString()]);


    console.log("the resultObjects", resultObjects) 
   
    let val;
    

    for (let key in resultObjects) {
      if (resultObjects[key].hasOwnProperty(pindex))
      {
        let data_one = resultObjects[key][pindex];
        // console.log("data_one",qdata.question_id)
        let val = data_one.find(item => item.Options[0].OptionId == qdata.QuestionSetCode );
        if (val != undefined)
        {
           console.log("val in for",val)
           let newobject = val;
           console.log("the newObject", newobject);
   
           if (newobject) {
   
             newobject.Options.map((item , index)=>{
               if(item.OptionId == qdata.QuestionSetCode){
                
                 
                   setDataObj(item);
                   return
                 }
             })
           }
        }
      }
   }

 
    
  }
}, [ReliancecheckedQuestion]);

console.log("the final data in set nine", dataObj);







  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={radioValue ? radioValue : dataObj?.OptionText ? 'yes' : ""}
          onClick={(e) => {
            handleClick(e);
          }}
          onChange={(e) => {
            setRadioValue(e.target.value);
            // dispatch(
              updateRelianceQAlist({
                name: "",
                value: e.target.value,
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              })
            // );
          }}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default AnswerSetNine;

