// import React, { useEffect, useState } from "react";

// import axios from "axios";
// import { useRef } from "react";
// import { IoChevronDownOutline } from "react-icons/io5";
// import { FaChevronUp } from "react-icons/fa6";

// const FAQ = () => {
//   const [currentPageType, setCurrentPageType] = useState("general");
//   const [activeTab, setActiveTab] = useState("General");
//   const [FaqData, setFaqData] = useState([]);

//   const carButtonRef = useRef(null);
//   const bikeButtonRef = useRef(null);
//   const healthButtonRef = useRef(null);
//   const termButtonRef = useRef(null);
//   const generalButtonRef = useRef(null);
//   const travelButtonRef = useRef(null);

//   const Tabs = [
//     {
//       id: 0,
//       name: "General",
//     },
//     {
//       id: 1,
//       name: "Car",
//     },
//     {
//       id: 2,
//       name: "Bike",
//     },
//     {
//       id: 3,
//       name: "Health",
//     },
//     {
//       id: 4,
//       name: "Term",
//     },
//     // {
//     //   id: 5,
//     //   name: "Travel",
//     // },
//     {
//       id: 6,
//       name: "Life",
//     },
//   ];

//   const handleTabChange = (name) => {};

//   //console.log("CatagoryName", activeTab);



//   useEffect(() => {
//     const path = window.location.pathname;
//     //console.log("the path", path);


//     const cardata = path.search("car");
//     const bikedata = path.search("bike");
//     const healthdata = path.search("health");
//     const termdata = path.search("term");
//     const traveldata = path.search("travel");
//     ////console.log("the cardata", cardata);

//     if (cardata != -1) {
//       setCurrentPageType("car");
//       setActiveTab("Car");
//     } else if (bikedata != -1) {
//       setCurrentPageType("bike");
//       setActiveTab("Bike");
//     } else if (healthdata != -1) {
//       setCurrentPageType("Health");
//       setActiveTab("Health");
//     } else if (termdata != -1) {
//       setCurrentPageType("term");
//       setActiveTab("Term");
//     } else if (traveldata != -1) {
//       setCurrentPageType("travel");
//       setActiveTab("Travel");
//     } else {
//       setCurrentPageType("general");
//       setActiveTab("General");
//     }
//   }, [window.location.pathname]);

//   useEffect(() => {
//     const getFaqApi = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}cms/get_faq`
//         );
//         setFaqData(response.data.data);
//       } catch (err) {
//         console.error(err);
//       }
//     };
//     getFaqApi();
//   }, []);

//   const [showAll, setShowAll] = useState(false);

//   // Function to handle the "Show More" button click
//   const handleToggle = () => {
//     setShowAll(prevShowAll => !prevShowAll);
//   };






//   return (
//     <div className="fixwidthDivtext">
//       <div className="container">
   
     
//             <div className="tile-text-with-text-shadow">
//               <h2>FAQ</h2>
//             </div>

// <div className="commonone">


//             <h4 className="theheadingcolor">
//                   Frequently Asked Questions About Insurance
//               </h4>
//               <span className="textformat">
//                 Beema1 comes with questions and answers which is a good thing,
//                 especially when you’re trying to get the most out of a policy or
//                 pick up additional coverage. But sometimes, consumers aren’t
//                 even sure where to start or what to ask. Whenever you need
//                 clarification or a second opinion, our experts are here. We’ll
//                 start a frank conversation with you to sort through the clutter
//                 and get to the heart of coverage.
//               </span>
//               </div>


//               <br />
       
      
//         <div className="row second-section-of-ten">
//           <div className="col-sm-12 col-md-12 col-lg-12">
//             {/* tab view start */}
//             <section className="tab-view">
//               <article className="tabbed-content">
//                 {/* tab navbar start */}




// <div className="overflowscrollcontainer">
// <div className="navbartabs">
//                   {Tabs.map((tab, index) => {
//                     return (
                 
//                      <div
//   ref={
//     tab.name === "Car"
//       ? carButtonRef
//       : tab.name === "Bike"
//       ? bikeButtonRef
//       : tab.name === "Health"
//       ? healthButtonRef
//       : tab.name === "Term"
//       ? termButtonRef
//       : tab.name === "General"
//       ? generalButtonRef
//       : tab.name === "Travel"
//       ? travelButtonRef
//       : null
//   }
//   onClick={() => {
//     handleTabChange(tab.name);
//     setActiveTab(tab.name);
//   }}
//   className={activeTab === tab.name ? "tabnameactive" : "tabnamedeactive"}
// >
//   {tab.name}
// </div>

 
//                     );
//                   })}
//                 </div>
// </div>
              




//                 {/* tab content start */}
//                 <section>
//                   <div className="item-content">
//                     <div className="row faq-accordion">
//                       <div className="col-sm-12 col-md-12 col-lg-12">
//                         <div className="accordion">
//                           <div className="accordion-item FAQRESGeneral-left">
//                             <div>
//                             {Object.keys(FaqData).map((category) => (
//         <div key={category} hidden={category !== activeTab}>
//           <div className="row">
//             {FaqData[category].slice(0, showAll ? FaqData[category].length : 10).map((item, index) => (
//               <div key={index} className="col-sm-12 col-md-6 col-lg-6">
//                 <div className="accordion" id={`accordion-${index}`}>
//                   <div className="accordion-item">
//                     <button
//                       className="accordion-button"
//                       type="button"
//                       data-bs-toggle="collapse"
//                       data-bs-target={`#collapse-${index}`}
//                       aria-expanded="false"
//                     >
//                       <span className="accordion-title">
//                         {item.question}
//                       </span>
//                       <span className="icon" aria-hidden="true" />
//                     </button>
//                     <div
//                       id={`collapse-${index}`}
//                       className="accordion-collapse collapse"
//                       data-bs-parent={`#accordion-${index}`}
//                     >
//                       <div className="accordion-body">
//                       <p className="normaltext">{item.answer}</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>

//         </div>
//       ))}
   
//                             </div>
//                           </div>
                          
//                         </div>
//                         <div className="conatiner textcenter">

                    
//                         <button onClick={handleToggle} className="showmorefaq">
//   {showAll ? (
//     <>
//       Show Less <FaChevronUp /> 
//     </>
//   ) : (
//     <>
//       Show More <IoChevronDownOutline />
//     </>
//   )}
// </button>
// </div>


//                       </div>
//                     </div>
//                   </div>
//                 </section>

//                 {/* Collaps Div start hear */}
//               </article>
//             </section>
//           </div>
//         </div>
//       </div>
//       </div>
  
//   );
// };

// export default FAQ;


import React, { useEffect, useState } from "react";
import { Events, scrollSpy } from "react-scroll";
import "./FAQ.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MdOutlineExpandMore } from "react-icons/md";
import { FAQS } from "./FAQs";
import { useNavigate } from 'react-router-dom';

const FAQ = () => {
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("Scroll start");
    });
    Events.scrollEvent.register("end", function () {
      console.log("Scroll end");
    });
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const [expanded, setExpanded] = useState(null);
  const [activecatagory, setActiveCategory] = useState(FAQS[0].catagory);
  const [activeSubcategory, setActiveSubcategory] = useState("");
  const [questionShow , setQuestionShow] = useState(10)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const filteredFAQs = FAQS.find((faq) => faq.catagory === activecatagory);
  const handleViewToggle = () => {
    setQuestionShow((prev) => prev + 10);
  };

  useEffect(() => {
    const path = window.location.pathname;

    if (path.includes('/health-insurance')) {
      setActiveCategory('Health');
    } else if (path.includes('/bike-insurance')) {
      setActiveCategory('Bike');
    } else if (path.includes('/term-insurance')) {
      setActiveCategory('Term');
    } else if (path.includes('/car-insurance')) {
      setActiveCategory('Car');
    } else {
      setActiveCategory('General');
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    if (filteredFAQs?.subcategories && filteredFAQs.subcategories.length > 0 && !activeSubcategory) {
      setActiveSubcategory(filteredFAQs.subcategories[0].name); 
    }
  }, [filteredFAQs, activeSubcategory]);
  

  return (
    <div className="fixwidthDivtext">
      <div className="container">
        <div className="tile-text-with-text-shadow">
          <h2>FAQ</h2>
        </div>

        <div className="commonone">
          <h4 className="theheadingcolor">
            Frequently Asked Questions About Insurance
          </h4>
          <span className="textformat">
            Beema1 comes with questions and answers which is a good thing,
            especially when you’re trying to get the most out of a policy or
            pick up additional coverage. But sometimes, consumers aren’t
            even sure where to start or what to ask. Whenever you need
            clarification or a second opinion, our experts are here. We’ll
            start a frank conversation with you to sort through the clutter
            and get to the heart of coverage.
          </span>
        </div>

        <div className="container">
          {/* Header Categories */}
          <div className="headercatagory">
            {FAQS.map((data) => (
              <li
                key={data.id}
                className={activecatagory === data.catagory ? "headerlist" : "headerlistnone"}
                onClick={() => {
              
                  setActiveCategory(data.catagory)
                  // Set active subcategory to the first one in case of subcategories
                  setActiveSubcategory(data.subcategories ? data.subcategories[0].name : "");
                }}
                
              >
                {data.catagory}
              </li>
            ))}
          </div>

          <div className="faqsection">
            {/* Sidebar Subcategories, only show if subcategories exist */}
            {filteredFAQs?.subcategories && filteredFAQs.subcategories.length > 0 && (
              <div className="sidebar-faq">
                <div className="catagorytab">
                  {filteredFAQs.subcategories.map((sub) => (
                    <div key={sub.name} className="subcategory-item">
                      <li
                        className={activeSubcategory === sub.name ? "listcatagory" : "delistcatagory"}
                        onClick={() => setActiveSubcategory(sub.name)}
                      >
                        {sub.name}
                      </li>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* FAQ Content */}
      <div className="gurdpage">
              {/* If subcategories are present, display the questions from the selected subcategory */}
              {filteredFAQs?.subcategories && filteredFAQs.subcategories.length > 0
                ? filteredFAQs.subcategories
                    .find((sub) => sub.name === activeSubcategory)
                    ?.questions.slice(0, questionShow)
                    .map((question, index) => (
                      <Accordion
                        expanded={expanded === `panel${question.id}`}
                        onChange={handleChange(`panel${question.id}`)}
                        key={question.id}
                        className="accordingback"
                      >
                        <AccordionSummary
                          expandIcon={<MdOutlineExpandMore />}
                          aria-controls="panel-content"
                          id={`panel-header-${index}`}
                        >
                          <li className="faqqustion">{question.qustion}</li>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="faqtext">{question.anwser}</p>
                        </AccordionDetails>
                      </Accordion>
                    ))
                : filteredFAQs?.questions
                    .slice(0, questionShow)
                    .map((question, index) => (
                      <Accordion
                        expanded={expanded === `panel${question.id}`}
                        onChange={handleChange(`panel${question.id}`)}
                        key={question.id}
                        className="accordingback"
                      >
                        <AccordionSummary
                          expandIcon={<MdOutlineExpandMore />}
                          aria-controls="panel-content"
                          id={`panel-header-${index}`}
                        >
                          <li className="faqqustion">{question.qustion}</li>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="faqtext">{question.anwser}</p>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    <div className="mainbtnfaqallqustion">
              <button onClick={handleViewToggle} className="view-toggle-button">
                Load More
              </button>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
