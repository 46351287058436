import React, { useState,useEffect } from 'react';
import Chart from 'react-apexcharts';
import '../SIP/SIP.css';
import Slider from '@mui/material/Slider';
import { Link, useNavigate } from 'react-router-dom';
import Redirect from '../../../AllPageComponents/Rederict';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import { Helmet } from 'react-helmet-async';

const FDcalculator = () => {

  var convertRupeesIntoWords = require('convert-rupees-into-words');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

const [YearlyInvestment, setYearlyInvestment] = useState(100000)


const handleLoanAmountChange = (event) => {
  const value = event.target.value;
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    setYearlyInvestment(''); // Clear the input if it's not a valid number
  } else {
    setYearlyInvestment(parsedValue); // Update the state with the valid number
  }
  if(parsedValue > 100000000){
    setYearlyInvestment(100000000)
  }

};



const [ExpectrdReturn, setExpectrdReturn] = useState(6.5)

const handleInterestRateChange = (event) => {
  const value = event.target.value;
  if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
    if (parseFloat(value) > 30) {
      setExpectrdReturn(30);
    } else {
      setExpectrdReturn(value);
    }
  }
};








const [Timperiod, setTimperiod] = useState(5)

const handleTenurePeriodChange = (event) => {
  const value = event.target.value;
  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) {
    setTimperiod(''); // Clear the input if it's not a valid number
  } else {
    setTimperiod(parsedValue); // Update the state with the valid number
  }
  if(parsedValue > 40){
    setTimperiod(40)
  }
};









const [maturity, setMaturity] = useState(0);

    useEffect(() => {
        calculateMaturity();
    }, [YearlyInvestment, ExpectrdReturn, Timperiod ]);

    const calculateMaturity = () => {
     const maturityAmount = parseFloat(YearlyInvestment) * Math.pow(1 + ExpectrdReturn / (100 * 4), 4 * Timperiod);
     setMaturity(maturityAmount);
    };

    //console.log(maturity)

  const totalinvested = () =>{
    const monthly = 12
    const times = Timperiod * monthly
    const res = YearlyInvestment * times
    return res
  }

  const estimatedReturn = () => {
        return   maturity - YearlyInvestment 
    };



const InvestedAmountToWord = convertRupeesIntoWords(Math.ceil(YearlyInvestment))
const ESTreturntoWord = convertRupeesIntoWords(Math.ceil(estimatedReturn()))
const TotalValueToWord = convertRupeesIntoWords(Math.ceil(maturity))






    return (
        <div>
<Helmet>
        <title>Online fd calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="fd interest calculator ,fd calculator" />
      </Helmet>








      {/* Divided the Div */}
      <section className="margincalculator"></section>
      <div>
      <div className="headingcalculator">
      <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>FD calculator</span></p>

    <h1 className="titlecalculator">Fixed deposit <span className='Mainheading'>Calculator</span></h1>
</div>

        <div className="calculatorhead">
          <div className="underdiv">
            <div className="calculatorse">
              {/* Calculator start div start hear */}
              <div className="calculaterse">
                <div className="calculator">
                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Total Investment</p>
                      <input
                        type="text"
                        value={YearlyInvestment}
                        onChange={
                          handleLoanAmountChange
                        }
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={YearlyInvestment}
                        onChange={
                          handleLoanAmountChange
                        }
                        min={100000}
                        max={100000000}
                        step={10000}
                        aria-label="Loan Amount"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Rate of interest % (P.A)</p>
                      <input
                        type="text"
                        value={ExpectrdReturn}
                        onChange={
                          handleInterestRateChange
                        }
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={ExpectrdReturn}
                        onChange={
                          handleInterestRateChange
                        }
                        min={1}
                        max={30}
                        step={0.1}
                        aria-label="Interest Rate"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p> Time period (Year) </p>
                      <input
                        type="text"
                        value={Timperiod}
                        onChange={
                          handleTenurePeriodChange
                        }
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={Timperiod}
                        onChange={
                          handleTenurePeriodChange
                        }
                        min={1}
                        max={40}
                        aria-label="Time Period"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>



            
                  <div className="calculateamountdiv">
                    <div>
                      <p>
                        Invested Amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(YearlyInvestment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>

                      <p className='textword'>{InvestedAmountToWord}</p>
             
                      <p>
                        Est. Return:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(estimatedReturn())).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
                      <p className='textword'>{ESTreturntoWord}</p>
                      
                      <p>
                        Total Value:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(maturity)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>

  

                      <p className='textword'>{TotalValueToWord}</p>
                      
                    </div>
                  </div>






                </div>
                <div className="graphdiv">
              
               <Chart
        type='donut'
        width={400}
        height={445}
        series={[totalinvested(), estimatedReturn()]}
        options={{
          labels:['Total investment', ' Est return']
        }}
      />
                </div>
              </div>
            </div>
            {/* Result part start hear */}
            <div className="resultse">
           <Calculatorlist/>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};

export default FDcalculator;
