import React, { useEffect, useState } from 'react'
import { AddonApi } from '../../../Api/Api';

const Icicimedical = (medicaldetails) => {

const [iciciQustion, seticiciQustion] = useState([])

const search = window.location.search;
const params = new URLSearchParams(search);
const unique_id = params.get("req_id");
let plan_id = params.get("plan_id");
const c_id = params.get("c_id");

  


const getAddon = async () => {
    try {
      const response = await AddonApi(c_id, plan_id);
      const data = response.question_answer.data;
      const parsedData = data.map(item => JSON.parse(item)); 
      seticiciQustion(parsedData)
    } catch (error) {
      console.error('Error fetching addon data:', error);
    }
  };
useEffect(() => {
  getAddon(); 
}, [medicaldetails]); 
function getNestedKeys(obj) {
    let result = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null) {
        const nested = obj[key];
        for (let nestedKey in nested) {
          if (nested.hasOwnProperty(nestedKey) && typeof nested[nestedKey] === 'object') {
            result[nestedKey] = nested[nestedKey]; 
          }
        }
      }
    }
  
    return result;
  }
  
  const medicalData = medicaldetails.medicaldetails[0];
  const nestedKeys = getNestedKeys(medicalData);
  const InsureDetailsList = medicaldetails?.userfetchdata?.proposal_data?.InsuredDetail;
   console.log('icici medical qustion',nestedKeys)

  return (
<div>
  {InsureDetailsList && Array.isArray(InsureDetailsList) ? (
    InsureDetailsList.map((data, index) => {
      const isIndexAvailable = Object.entries(nestedKeys).some(
        ([key, value]) => nestedKeys[key] && nestedKeys[key][index]
      );
      if (isIndexAvailable) {
        return (
          <div key={index}>
            <p>{data.InsuredName}</p>
            {Object.entries(nestedKeys).map(([key, value]) => {
              if (nestedKeys[key] && nestedKeys[key][index]) {
                const matchedQuestion = iciciQustion.find(item => item.question_id === key);
                if (matchedQuestion) {
                  return (
                    <div key={key} className='mainicici'>
                      <span className='qustionsbyID'>Q: <span className='mainqustion'>{matchedQuestion.question}</span></span>
                      <br />
                      <button className='isselectedicici'>Selected</button>
                    </div>
                  );
                }
              }
              return null;
            })}
          </div>
        );
      }
      return null;
    })
  ) : (
    <p>No insured details available.</p>
  )}
</div>





    
  )
}

export default Icicimedical