import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectReliancecheckedQuestion } from "../redux/Slice";
import { updateRelianceQuestion } from "../redux/Slice";
import { updateReliancePersion } from "../redux/Slice";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { selectProposal } from "../redux/Slice";
import AnswerSetOne from "../commonComponents/RelianceAnswerSet/AnswerSetOne";
import AnswerSetTwo from "../commonComponents/RelianceAnswerSet/AnswerSetTwo";
import AnswerSetThree from "../commonComponents/RelianceAnswerSet/AnswerSetThree";
import AnswerSetFour from "../commonComponents/RelianceAnswerSet/AnswerSetFour";
import AnswerSetFive from "../commonComponents/RelianceAnswerSet/AnswerSetFive";
import AnswerSetSix from "../commonComponents/RelianceAnswerSet/AnswerSetSix";
import AnswerSetSeven from "../commonComponents/RelianceAnswerSet/AnswerSetSeven";
import AnswerSetEight from "../commonComponents/RelianceAnswerSet/AnswerSetEight";
import AnswerSetNine from "./RelianceAnswerSet/AnswerSetNine";
import { useState } from "react";
import { useEffect } from "react";

const RelianceQuestionAnswer = ({ data, index, insuredData ,ReliancecheckedQuestion,setRelianceCheckedQuestion}) => {
  // const dispatch = useDispatch();
  // const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  console.log("the reliance checked questtion", ReliancecheckedQuestion)


  const updateRelianceQuestion = (payloadData) => {
    setRelianceCheckedQuestion((prevData) => {
      // Create a copy of the current state
      const updatedData = { ...prevData };

      // Check if the key exists, delete it if present; otherwise, add it
      if (updatedData.hasOwnProperty(payloadData.val)) {
        delete updatedData[payloadData.val];
      } else {
        updatedData[payloadData.val] = {};
      }

      return updatedData; // Return the updated state
    });
  };
  
  

  return (
    <>
      <div className="ppv10 my-3 col-md-12 " style={{paddingLeft:"0px", paddingRight:"0px"}}>
        <div className="col-md-12 col-sm-12 col-12 pmd">
          <FormControlLabel
            value={data.question_id}
            onChange={(e) =>
              // dispatch(updateRelianceQuestion({ val: e.target.value }))
              updateRelianceQuestion({ val: e.target.value })
            }
            control={<Checkbox />}
            label={"Q." + data.question}
            checked={ReliancecheckedQuestion.hasOwnProperty(data.question_id)}
          />
        </div>
      </div>
    </>
  );
};

export default RelianceQuestionAnswer;

export const ReliancePersion = ({ data, persion, pindex ,
  proposerData,
  ReliancecheckedQuestion,
  setRelianceCheckedQuestion

}) => {
  // const proposalData = useSelector(selectProposal);
  // console.log("the reliance proposaldata", proposalData);
  // const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  // const dispatch = useDispatch();


  const updateReliancePerson = (payloadData) => {
    const { question_id, pindex } = payloadData;

    setRelianceCheckedQuestion((prevData) => {
      // Clone the previous state
      const updatedData = { ...prevData };

      // Check if the question_id exists, initialize it if it doesn't
      if (!updatedData[question_id]) {
        updatedData[question_id] = {};
      }

      // Toggle the presence of pindex within the question
      if (updatedData[question_id].hasOwnProperty(pindex)) {
        delete updatedData[question_id][pindex];
      } else {
        updatedData[question_id][pindex] = [];
      }

      return updatedData; // Return the updated state
    });
  };


 




  const check = () => {
    if (ReliancecheckedQuestion.hasOwnProperty(data.question_id)) {
      if (ReliancecheckedQuestion[data.question_id].hasOwnProperty(pindex)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div
      hidden={!ReliancecheckedQuestion.hasOwnProperty(data.question_id)}
      onClick={(e) =>
      {

        e.stopPropagation();
        // dispatch(
        //   updateReliancePersion({
        //     pindex: pindex,
        //     question_id: data.question_id,
        //   })
        // )

        updateReliancePerson({
          pindex: pindex,
          question_id: data.question_id,
        })
      }
      }
     className="col-md-6"
    >
      <div
        className={
          check() ? "row sumbox sumboxone col-md-12" : "row sumbox hdfc-addon col-md-12"
        }
        style={{ justifyContent: "center" , marginLeft:"0px" , width:"100%" , marginTop:"0px" , cursor:"pointer"}}
      >
        <div>
          <span className="para">
            {proposerData.InsuredDetail[pindex].InsuredName} (
            {persion.InsuredRelation}){" "}
          </span>
        </div>
        <div className="col-3  text-right"></div>
      </div>
      <div style={{display:"flex", flexWrap:"wrap"}} >
      {data.get_child_question.map((child_data) => {
        
        return (
          <RelianceSubQuestionAnswer
            data={child_data}
            parentData={data}
            pindex={pindex}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}

          />
        );
      })}
      </div>
      
    </div>
  );
};




const RelianceSubQuestionAnswer = ({ data, pindex, parentData , 
  proposerData,
  ReliancecheckedQuestion,
  setRelianceCheckedQuestion
 }) => {
  // const proposalData = useSelector(selectProposal);
  // const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);
  const dispatch = useDispatch();

  // console.log("the proposalData in reliance", proposalData)




  const [first, setFirst] = useState([]);
  // const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  useEffect(() => {
    // console.log('ReliancecheckedQuestion:', ReliancecheckedQuestion);
    const keysToCheck = [6, 19];
    const filteredObjects = keysToCheck
      .filter((key) => ReliancecheckedQuestion.hasOwnProperty(key))
      .map((key) => ReliancecheckedQuestion[key])
      .flat();

    // console.log('Filtered Objects:', filteredObjects);
    setFirst(filteredObjects);
  }, [ReliancecheckedQuestion]);

  const getDeliveryDate = () => {
    if (first.length > 0) {
      return first[0];
    }
  };

  const datadate = getDeliveryDate();

  // console.log("ReliancecheckedQuestion", first);
  // console.log("The Qustionset HDFC",ReliancecheckedQuestion)

  let deliveryDates = [];
  for (const key in datadate) {
    if (datadate.hasOwnProperty(key)) {
      const questions = datadate[key];

      questions.forEach((question) => {
        if (question.Options && question.Options.length > 0) {
          question.Options.forEach((option) => {
            if (option.DeliveryDate) {
              deliveryDates.push(option.DeliveryDate);
            }
          });
        }
      });
    }
  }


  const check = () => {
    if (ReliancecheckedQuestion.hasOwnProperty(parentData.question_id)) {
      if (
        ReliancecheckedQuestion[parentData.question_id].hasOwnProperty(pindex)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };




  const updateRelianceQAlist = (payloadData) => {
    setRelianceCheckedQuestion((prevData) => {
      const updatedData = { ...prevData };
      const { parentqData, pindex, qdata, name, value } = payloadData;

      const parentQid = parentqData.question_id;
      if (!updatedData[parentQid]) {
        updatedData[parentQid] = {};
      }

      if (!updatedData[parentQid][pindex]) {
        updatedData[parentQid][pindex] = [];
      }

      const qa = updatedData[parentQid][pindex];
      const { exists, questionIndex, optionIndex } = ReliancequestionExist(qa, parentQid, qdata);

      if (!exists) {
        const newQuestion = {
          QuestionId: parentQid,
          QuestionText: parentqData.question,
          Options: [ReliancegetAnswerSet(qdata, name, value, parentQid)],
        };
        qa.push(newQuestion);
      } else {
        const option = qa[questionIndex].Options[optionIndex];
        option[name] = value;
      }

      return updatedData;
    });
  };


  function ReliancequestionExist(qa, qid, qdata) {
    for (let i = 0; i < qa.length; i++) {
      if (qa[i].QuestionId == qid) {
        for (let j = 0; j < qa[i].Options.length; j++) {
          if (qa[i].Options[j].OptionText == qdata.question) {
            return { exists: true, questionIndex: i, optionIndex: j };
          }
        }
      }
    }
    return { exists: false };
  }
  
  
  
  function ReliancegetAnswerSet(qdata, name, value, parentQid) {
    if (parentQid == "1") {
      return {
     
        OptionId: qdata.QuestionSetCode,
        OptionIndex:1,
        OptionText: qdata.question,
        SufferingSince: name == "SufferingSince" ? value : "",
        OtherDisease: name == "OtherDisease" ? value : "",
        TreatmentTaken: name == "TreatmentTaken" ? value : "",
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        HospitalName: name == "HospitalName" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
      };
    } else if (parentQid == "2") {
      return {
        OptionId: qdata.question_id,
        OptionText: qdata.question,
        OptionIndex:2,
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "", 
        HospitalName: name == "HospitalName" ? value : "",
    
      };
    } else if (parentQid == "3") {
      return {
        OptionId: qdata.question_id,
        OptionIndex:3,
        OptionText: qdata.question,
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        MedicineName: name == "MedicineName" ? value : "",
       
      };
    } else if (parentQid == "4") {
      return {
        OptionId: qdata.question_id,
        OptionIndex:4,
        OptionText: qdata.question,
        TypeOfTests: name == "TypeOfTests" ? value : "",
        DateOfTests: name == "DateOfTests" ? value : "",
        FindingsOfTests: name == "FindingsOfTests" ? value : "",
     
      };
    } else if (parentQid == "5") {
      return {
        OptionId: qdata.question_id,
        OptionIndex:5,
        OptionText: qdata.question,
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        HospitalName: name == "HospitalName" ? value : "",
        DetailsOfYourPastMedicalCondition: name == "DetailsOfYourPastMedicalCondition" ? value : "",
  
      };
    } 
    else if (parentQid == "6") {
      // alert(parentQid)
      return {
        OptionId: qdata.question_id,
        OptionIndex:6,
        OptionText: qdata.question,
        ExpectedDeliveryDate: name == "ExpectedDeliveryDate" ? value : "",
      };
    }
  
    else if (parentQid == "7") {
   
      return {
        OptionId: qdata.question_id,
        OptionText: qdata.question,
        OptionIndex:7,
       
      };
    }
  
    else if (parentQid == "8") {
   
      return {
        OptionId: qdata.question_id,
        OptionText: qdata.question,
        OptionIndex:8,
        HasFiledClaimWithCurrPreviousInsured: name == "HasFiledClaimWithCurrPreviousInsured" ? value : "",
      };
    }
  
    else if (parentQid == "9") {
     
      return {
        OptionId: qdata.question_id,
        OptionIndex:9,
        OptionText: qdata.question,
        HasChargedCancelledHighPremium: name == "HasChargedCancelledHighPremium" ? value : "",
      };
    }
  }







console.log("Reliance Checked question", ReliancecheckedQuestion)

  

  return (
    <>{
      !check() == false ?
      <div
      className="row ppv10 my-3 col-md-12"
      style={{
        flexWrap: "wrap",
        height: "auto",
        marginLeft: "0px",
        marginRight: "0px",
        paddingLeft:"0px",
        paddingRight:"0px"
      }}
      hidden={!check()}
      
    >
      <div className="col pmd" style={{ display:"flex",padding:"6px", flexDirection:"column"  }}>
        <span className="nfh1 cash" style={{ fontWeight: "600" }}>
          Q.{data.question}
        </span>
        {parentData.question_id == "1" ? (
          <AnswerSetOne
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}
          
          />
        ) : parentData.question_id == "2" ? (
          <AnswerSetTwo
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) : parentData.question_id == "3" ? (
          <AnswerSetThree
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) : parentData.question_id == "4" ? (
          <AnswerSetFour
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) : parentData.question_id == "5" ? (
          <AnswerSetFive
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) : parentData.question_id == "6" ? (
          <AnswerSetSix
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) : 
        parentData.question_id == "7" ? (
          <AnswerSetNine
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) 
        :parentData.question_id == "8" ? (
          <AnswerSetSeven
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) : parentData.question_id == "9" ? (
          <AnswerSetEight
            pindex={pindex}
            qdata={data}
            parentqData={parentData}
            proposerData={proposerData}
            ReliancecheckedQuestion={ReliancecheckedQuestion}
            setRelianceCheckedQuestion={setRelianceCheckedQuestion}
            updateRelianceQAlist={updateRelianceQAlist}

          />
        ) : null}
      </div>
    </div>
      : null
    }
     
    </>
  );
};

