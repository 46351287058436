import React,{useEffect, useState} from 'react';
import PartnerCompany from '../../../../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../../../../AllPageComponents/FAQ/FAQ';
import { Link } from 'react-router-dom';
import FastImage from '../../../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import Redirect from '../../../../AllPageComponents/Rederict';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { Helmet } from 'react-helmet-async';





const HealthInsurenceforperents = () => {


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };




  const [Readmore, setReadmore] = useState(true);

  const HandelReadmore = () =>{
    setReadmore(!Readmore)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


const Whaover = [
  {
    "id": 1,
    "heading": "Hospitalization Expenditures",
    "description": "Nursing charges, room charges, bed charges, doctor & ICU fees"
  },
  {
    "id": 2,
    "heading": "Pre and Post Hospitalization is Covered",
    "description": "All medical expenses 30 days before hospitalization & 90 days post."
  },
  {
    "id": 3,
    "heading": "Day Care Treatments are Considered",
    "description": "Medical treatment expenses needing hospitalization for less than 24 hours."
  },
  {
    "id": 4,
    "heading": "Certain Ailments are Covered",
    "description": "Specific diseases within certain predetermined limits."
  },
  {
    "id": 5,
    "heading": "Medical Care at Home",
    "description": "In case medical treatment has to be carried on at home because of unavailability of bed."
  },
  {
    "id": 6,
    "heading": "Recharge of Your Cover Amount",
    "description": "A recharge happens once, when your current health cover amount gets exhausted."
  },
  {
    "id": 7,
    "heading": "Organ Donor Costs",
    "description": "The policy covers organ donor expenses, if you find a suitable organ donor."
  },
  {
    "id": 8,
    "heading": "Recovery Benefit",
    "description": "If the insured individual is hospitalized for more than 10 days, the policy pays for financial losses."
  },
  {
    "id": 9,
    "heading": "AYUSH Benefits are Provided",
    "description": "Pays for Homeopathy, Siddha, Unani & Ayurvedic hospitalization costs."
  },
  {
    "id": 10,
    "heading": "Lifelong Renewability",
    "description": "As you purchase health insurance for your parents, you can renew it for a lifetime."
  },
  {
    "id": 11,
    "heading": "Free Health Check-Up",
    "description": "Avail free health check-up once annually."
  }
]



const midpoint = Math.ceil(Whaover.length / 2);
const firstHalf = Whaover.slice(0, midpoint);
const secondHalf = Whaover.slice(midpoint);



  return (
    <>
        <Helmet>
        <title>Features & Benifits of medical insurance for parents</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Mediclaim policy for parents, Health insurance plans for parents, Insurance policy for parents" />
      </Helmet>


 <section className="car-insurance insurance-all main-health main-health mainnargin-insurance">

  <div className="container">
      <Redirect/>

    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one ">
        <div className="healthdiscription">
          {/* <h1>Benefitess of Health of Insurence For Perents</h1> */}
          <h1>Features & Benifits of medical insurance for parents</h1>

          <span>
          With Beema1, get top-rated health insurance plans online. You can compare online and buy the most suitable insurance plan for you and your family. You can also get your queries answered by our team of experts.
          </span>
        </div>
        <div className="insurance-btn-set">
          <button
            type="button"
            className="reminderbutton"
            data-toggle="modal"
            data-target="#reminderpopup"
          >
            {" "}
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              fontSize="15pt"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "rgb(251, 167, 27)", margin: "0px 5px" }}
            >
              <circle cx={12} cy={13} r={8} />
              <path d="M12 9v4l2 2" />
              <path d="M5 3 2 6" />
              <path d="m22 6-3-3" />
              <path d="M6.38 18.7 4 21" />
              <path d="M17.64 18.67 20 21" />
            </svg>{" "}
            Set Reminder
          </button>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
               <FastImage
                  url={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                  width={auto}
                  height={auto}
                  alt={"arrow"}
                  src={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                />
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
        <div className="form-main">
          <form id="insurance_form_get_plan">
            <div className="d-flex align-items-center form-input">
              <h6>
                Compare &amp; Buy Customized Health Plans starting at just
                <span className='specialprice'>Rs ₹257/month*</span>
              </h6>
              <div className="blue-btn">
                <Link to={"/healthStep1"}>
               
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "white",
                      fontWeight: 400
                    }}
                  >
                    Get Your Quotes
                  </button>{" "}
                  <img alt="" src="" className="" />
         
                </Link>
                
              </div>
              <p className="insurance-text-light">
                By clicking, I agree to{" "}
                <a href="/termsandcondition">*terms &amp; conditions</a> and{" "}
                <a href="/privacy-policy">privacy policy</a>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


<div className="inbulitmargine"></div>








<div className="fixwidthDivtext">
  <div className="container">
  <h4  className='healthHeading'>Safeguarding Your Parents’ Retirement Savings</h4>
  <div className="yellowline"></div>
    <div className="row">
      <div className="col-md-12">
      <p  className='textformat'>
Inflated healthcare expenses can consistently destroy your parents’ retirement corpus. By purchasing a separate health insurance policy, you can provide assurance that their retirement corpus remains intact for their targeted purpose of various regular and occasional expenditures, rather than being reduced by exorbitant medical bills. These mediclaim policies for parents are financial cushions that works as a monetary safeguard providing peace of mind and security for both, you and your parents.
</p>
      </div>
    </div>
  </div>


</div>


<div className="fixwidthDivtext">
<div className="container">
<h4  className='healthHeading'> You Can Save Tax on Health Insurance for Parents</h4>
<div className="yellowline"></div>
  <div className="row">
    <div className="col-md-6 d-flex justify-content-center align-items-center">
<div>



<Accordion
  expanded={expanded === 'panel1'}
  onChange={handleChange('panel1')}

>
  <AccordionSummary
    expandIcon={<MdOutlineExpandMore />}
    aria-controls="panel2-content"
    id="panel2-header"
    className="accordingback"
  >
    <IoMdAddCircle style={{ color: '#2959B8', marginLeft: '5px', marginRight: '5px' }} fontSize={'15pt'} />
    <Typography>
      <li className='listcol'>
        Deduction on annual health check-ups
      </li>
    </Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
    <p className='textformat'>
      Tax exemption benefits implemented on OPD consultation fees & diagnostic tests. You can get tax benefits even when you pay in cash.
    </p>
    </Typography>
  </AccordionDetails>
</Accordion>


      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
          aria-controls="panel3-content"
          id="panel3-header"
          className="accordingback"
        >
        
          <div>
          <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
          </div>
          <Typography>
            <div>
            <li className='listcol'>
            Reduction on health insurance premium paid for parent health insurance policies
          </li>
            </div>
  
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className='textformat'>
          Expenses for annual health check-ups are entitled for tax benefits. The tax exemption limit here is 5,000/-.
          </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
          aria-controls="panel4-content"
          id="panel4-header"
          className="accordingback"
        >
          <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
          <Typography>
          <li className='listcol'>
            Tax Benefit is much higher on Health Insurance for parents
            </li>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className='textformat'>

          Tax deduction limit for expenses associated to specific ailments of parents is up to 1,00,000/-.
          </p>
          </Typography>
        </AccordionDetails>
      </Accordion>




      </div>






    </div>
    <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
      <FastImage url={'Website%20Img/Tax-pana.png?updatedAt=1721288803552'} src={'Website%20Img/Tax-pana.png?updatedAt=1721288803552'} height={auto} width={auto} className='fmailimagegifs'/>
    </div>
  </div>
</div>

</div>  


<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Tax Benefits implemented on Single Premium Health Insurance Plans:</h4>
<div className="yellowline"></div>
 <p className='textformat'>
According to IT laws, the Mediclaim policy premium you pay for a multi-year plan as a lump sum is entitled for a tax deduction. This is under Section 80D. The tax-deductible amount is based on the total premium amount paid for the policy term. This is subjected to the limits of 25,000/- or 50,000/-.
</p>
  
 <div className="row">



<div className="col-md-3 infographic-cards">
    <li className="color-1">
      <h5>
        For
         parents
      </h5>
      <h6>Rs. 25,000</h6>
      <div className="number-box">&lt;60 Years</div>
    </li>
    </div>
    <div className="col-md-3 infographic-cards">
    <li className="color-2">
      <h5>
        For
         parents
      </h5>
      <h6>Rs. 50,000</h6>
      <div className="number-box">&gt;60 Years</div>
    </li>
    </div>
    <div className="col-md-3 infographic-cards">


    <li className="color-3 ">
      <h5>
        For self
         and spouse
      </h5>
      <h6>Rs. 25,000</h6>
      <div className="number-box">&lt;60 Years</div>
    </li>
    </div>
    <div className="col-md-3 infographic-cards">

  
    <li className="color-4">
      <h5>
        For dependent
         children
      </h5>
      <h6>Rs. 25,000</h6>
      <div className="number-box">&lt;25 Years</div>
    </li>
    </div>
  

    </div>












<i>**Note: Source: The above-mentioned tax benefits according to the current tax ordinances conventional in the country. Tax benefits changes subject to tax laws. It is recommended to recheck and reassure the same with the tax consultant.</i>
  
</div>


</div>




<div className="fixwidthDivtext">


<div className="container">
<h4 className='healthHeading'>How To Buy Health Insurence For Parents </h4>
<div className="yellowline"></div>
 <p  className='textformat'>
We examine customers about their past medical conditions. We provide them eloquent and suggestive advice on how to select the best Mediclaim policy for parents with a well-informed decision. We treat people with care and ethics, like one of our own.
</p>
  <div className="row">
    <div className="col-md-6 d-flex justify-content-center align-items-center">
      <div>
      <div className="steponeparents">
 <div className="numberStepper">1</div> Go to beema1.com click &nbsp; <Link to={'/health-insurance'}>Health</Link>&nbsp; tab
</div>
<div className="steptwoparents">
 Provide Your Details  <div className="numberStepper">2</div>
</div>
<div className="steponeparents">
<div className="numberStepper">3</div> Comapre Quotes
</div>
<div className="steptwoparents">
 Pay For Your Policy Digitally  <div className="numberStepper">4</div>
</div>
<div className="steponeparents">
<div className="numberStepper">5</div> Enjoy Your Policy
</div>
      </div>
    </div>
    <div className="col-md-6 d-flex justify-content-center align-items-center">
      <FastImage url='/Website%20Img/couple_437519.png?updatedAt=1728294126344' className='fmailimagegifs' width={auto} height={auto}/>
    </div>
  </div>
</div>




</div>



<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>What Kind of Health Insurance Policies Are the Best for Your Parents?</h4>
<div className="yellowline"></div>
 <p  className='textformat'>
While you pick a health insurance policy for your parents, you are given two options: a family floater policy or individual policies for each parent. Each option has its own set of advantages and confines.
</p>
  <div className="row">

<div className="col-md-6 d-flex justify-content-center align-items-center">
<p  className='textformat' style={{textAlign:'justify'}}>
A family floater policy provides a shared or split sum insured for your both parents. This states that the entire sum insured can be availed by any one member or shared among both of them. Family floater plans are generally more reasonably pocket friendly than individual plans, as the risk is dispensed across multiple members. A floater plan can be very useful for your parents if both are considerably in good health and improbably need to get simultaneously hospitalized. This kind of insurance policy conveniently streamlines the renewal process, as you will need to follow and track only one policy.
</p>
</div>
<div className="col-md-6 d-flex justify-content-center align-items-center">
<FastImage url='/Website%20Img/protection_3490959.png?updatedAt=1729840594252' width={auto} height={auto} className='fmailimagegifs'/>
</div>
</div>
</div>
</div>












<div className="fixwidthDivtext">
<div className="container">
<span className='healthHeading'>Joint Or Individual Policies For Parents ?</span>
<div className="yellowline"></div>
<p  className='textformat'>
Going for individual policies for each parent guarantees that both your parents have their own medical insurance policies, which can be significantly beneficial if one or both your parents have requirements for pre-existing conditions or are likely to be hospitalized repeatedly.


Individual policies might be comparatively more expensive than a family floater plan, but they provide better coverage for each parent with separate sum insured. This is more specifically crucial for elderly parents who might have to pass through higher volume of medical expenses. Individual policies also extend a higher total coverage than a shared sum insured under a floater plan. While going for the best combination of policies, consider your financial status, your parents' health conditions, and the maximum tax benefits available.
</p>
  <div className="row">
 
    <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
  <FastImage url={'/Website%20Img/insurance_2514905.png?updatedAt=1729840129737'} width={auto} height={auto} className='fmailimagegifs' />
</div>
<div className="col-md-6">
  

<span className='specialprice'>Importeance Of Health Insurence For Your Parents</span>
 <p className='textformat'>In India, the best medical insurance policies for parents are tailor-made, curated with convenience to avail of covers that are best fit for their health needs and financial abilities and proficiencies, ensuring a customized safety shield.</p>



<span className='specialprice'>
Availing of Good Quality Healthcare
</span>
 <p  className='textformat'>
Your parents can avail of a vast network of topnotch hospitals and medical professionals by choosing the right health insurance policy, ensuring that they can access the best healthcare facilities and medical treatments available around you.
</p>






<span className='specialprice'>
Operates as A Financial Safeguard 
</span>
 <p  className='textformat'>
Health insurance provides a safety shield against spiking medical costs, safeguarding your parents’ health and life from exhausting their life long savings on unanticipated health issues.
</p>




<span className='specialprice'>
Preventative Care for Your Parents
</span>
 <p  className='textformat'>
Medical insurance plans for parents are all inclusive preventative services, providing the facilities of frequent medical check-ups, early detection of ailments, resulting into betterment and well-being of your parents’ health. 
</p>




    </div>

  </div>
</div>





</div>



<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>
What does Health Insurance for Parents Cover?
</h4>
<div className="yellowline"></div>
 <p  className='textformat'>
A lot of research in this subject has been recently conducted as we all want to insure the health and medical expenses of our family. There is a total of 22 health insurance companies in India offering various products with well-crafted features and benefits on health insurance for parents depending on the needs and requirement. There are some vital parameters to be checked, verified and compared while applying for health insurance. 
</p>
  <div className="row">
    <div className="col-md-6">
      {firstHalf.map((data) => (
        <Accordion expanded={expanded === `panel${data.id}`} onChange={handleChange(`panel${data.id}`)} key={data.id}>
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="accordingback"
          >
            <li className='listcol'>
              <IoMdAddCircle style={{ color: '#2959B8', marginLeft: '5px', marginRight: '5px' }} fontSize={'13pt'} />
              {data.heading}
            </li>
          </AccordionSummary>
          <AccordionDetails className="AnwsarSpan">
            {data.description}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
    <div className="col-md-6">
      {secondHalf.map((data) => (
        <Accordion expanded={expanded === `panel${data.id}`} onChange={handleChange(`panel${data.id}`)} key={data.id}>
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="accordingback"
          >
            <li className='listcol'>
              <IoMdAddCircle style={{ color: '#2959B8', marginLeft: '5px', marginRight: '5px' }} fontSize={'13pt'} />
              {data.heading}
            </li>
          </AccordionSummary>
          <AccordionDetails className="AnwsarSpan">
            {data.description}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  </div>
</div>
</div>




<div className="fixwidthDivtext">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
      <p  className='textformat'>
An important point is whichever company you opt for, ensure that you declare all the existing medical declaration properly and particularly without hiding anything. Whichever company you opt for, keep in mind, in general there are 4 kind of exclusions (waiting period) which is applicable for all the health insurance policies. They are:
</p>

 <p  className='textformat'>
    <span className='specialprice'> A Cool-Off Period Up To 30 days:-</span>
    A Cool-Off Period Up To 30 days -30 days cool off or waiting period means that once the policy is purchased, for the next 30 days, no claims can be made other than accidental claim.
    
    </p>
<p  className='textformat'>
    <span className='specialprice'> Waiting Period For Specific Ailmen:- </span>
    What is “Waiting period of Specific Ailment”?  There are a number of diseases whose treatment already have a 2years waiting period in almost all the companies.
    </p>
<p  className='textformat'>

    <span className='specialprice'>
How Does It Operate ?
</span>
Names of all such diseases already exist in the policy wording and this list matches the clauses with almost all the insurance providers across India. From the date of inception of the policy for the next 2 years, you will not be eligible for any claim related to some specific treatment. Few examples of such diseases are any treatment related to ENT (Ear, Nose, Teeth), cataract, issues associated piles, congenital internal disease, knee replacement surgery etc. For further information, refer to the policy wording.
    </p>

<p  className='textformat'>


<span className='specialprice'>
What’s The Catch ?
</span>
It's just that you should be aware about the names and types of ailments. It has been observed with many facts that certain times, claims get declined because of this. If an insured individual is not aware about this 2years of waiting period and has availed of the treatment which is there in the above-mentioned list within the 2 years of availing the policy, then his/her claim gets rejected.
</p>
      </div>
    </div>
  </div>



</div>



<div className="fixwidthDivtext">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
      <p  className='textformat'>
 <h4 className='healthHeading'>
 Pre-Existing Disease (PED) Associated waiting period
 </h4>
<div className="yellowline"></div>


<i>What’s PED?</i>
Any medical complexities which is prevalent at the time of purchasing health insurance for your parent or was there earlier is called PED.
For an example- A person has BP at the time of availing the health insurance policy. So, at the time of policy issuance, PED waiting period would be implemented stating that any claim arising due to direct or indirect relation associated to BP isn’t settled for 2/3/4 years (from the policy inception date) varying from insurer to insurer.


Point to be thoroughly noted - Never ever dare to hide any medical complications or pre-existing medical condition at the time of taking up the health insurance plan. Many a time unintentionally due to lack of awareness, we do not disclose the same. But during hospitalization when doctors ask we are supposed to declare all kind of medical conditions
starting from our childhood till date. That's where information clashes with immense gap of mismatch and the claim gets rejected.

For Example: A man had mild diabetes when he had availed the policy on 1st of August 2018 but unknowingly and without any intentions he did not reveal the same because he thought felt that it’s just a mild issue which doesn’t required to be judged with high importance, hence need not be disclosed.


He was hospitalized in the middle of August 2019. When the doctor asked regarding his past medical history, he had to mention regarding existence of diabetes since 2015. This record of medical history also goes to the insurance company. Consequently, the insurance provider will decline the claim stating that although diabetes was there since 2015, but the same was not disclosed by the policyholder while availing of the health insurance plan was in August 2018.

</p>
      </div>
    </div>
  </div>

</div>

<div className="fixwidthDivtext">
  <div className="container">
  <h4 className='healthHeading'>
Permanent Exclusions from Health Insurance of Parents
</h4>
<div className="yellowline"></div>
<p  className='textformat'>

There are certain exclusions which are permanently discarded from health insurance policies of parents and are not approved for any claim, such things. They are called permanent exclusions.
</p>
    <div className="row">
      <div className="col-md-12">

      <table class="custom-table" border="1" cellpadding="10" cellspacing="0">

<tbody>
  <tr>
    <td>Pre-existing illnesses before waiting period</td>
  </tr>
  <tr>
    <td>Any illnesses within 30 days of policy purchase</td>
  </tr>
  <tr>
    <td>Self-inflicted injuries</td>
  </tr>
  <tr>
    <td>Drug abuse</td>
  </tr>
  <tr>
    <td>
      Dental treatment - Dental expenses in general are not covered, but dental expenses because of accidents will be covered.
    </td>
  </tr>
  <tr>
    <td>Lenses or spectacles</td>
  </tr>
  <tr>
    <td>Injuries due to war</td>
  </tr>
  <tr>
    <td>Treatment of AIDS/HIV</td>
  </tr>
  <tr>
    <td>Cosmetic surgery</td>
  </tr>
  <tr>
    <td>Hospitalization only for evaluation purposes in which no treatment was given.</td>
  </tr>
  <tr>
    <td>OPD Treatment - When you go to a doctor just to consult for something.</td>
  </tr>
  <tr>
    <td>Treatment of Obesity</td>
  </tr>
  <tr>
    <td>Service charge/Registration charge in a hospital</td>
  </tr>
  <tr>
    <td>Treatment received outside India</td>
  </tr>
  <tr>
    <td>Accidents caused due to alcohol, drug abuse, adventure sports, etc.</td>
  </tr>
  <tr>
    <td>Treatment from unrecognized physician or facility</td>
  </tr>
</tbody>
</table>
      </div>
    </div>
  </div>
</div>


<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Conclusion</h4>
<div className="yellowline"></div>
<div className="row">
<div className="col-md-12">
<p  className='textformat'>
Several health insurance plans you can avail of which particularly cater to the needs of parents and senior citizens. Having satisfactory or sufficient health insurance for your parents is for their contentment, protection and your stress less mind. While corporate health insurance policies provide some coverage, they usually have limitations that make additional insurance necessary. Understanding and considering the benefits and hindrances, you can take a decision that boosts your insurance coverage and diminishes your expenses.
</p>
 <p  className='textformat'>
    <span className='specialprice'>A Cool-Off Period Up To 30 Days</span>
    A cool-off period up to 30 days -30 days cool off or waiting period means that once the policy is purchased, for the next 30 days, no claims can be made other than accidental claim.
</p>
    </div>
  </div>
</div>




</div>








<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default HealthInsurenceforperents