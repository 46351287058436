const CryptoJS = require("crypto-js");

const findSelf = (data) => {
	
	for (let i = 0; i < data.length; i++) {
		
		 if(data[i].InsuredRelation == 'Self')
		 {
		 	return data[i];
		 	break;
		 }
	}

	return false;
	
}
const companyId = () => {
	const data = {'GoDigit':'GO_DIGIT','Care':'CARE_HEALTH', 'HDFC':'HDFC', 'ICICI':'ICICI','Reliance':'Reliance'};
	return data;
}

const getDate = (e) => {
        let month = Number(e.$M) + 1;
        let year = e.$y;
        let day = e.$D;
        let date = year+'-'+month.toString().padStart(2, '0')+'-'+day
        return date;
    }
const getMonthYear = (e) => {
        let month = Number(e.$M) + 1;
        let year = e.$y;
        let day = e.$D;
        let date = month+'/'+year;
        return date;
    }    
 const feetAndInchesToCm = (feet, inches) => {
   
    // Convert feet to centimeters
    const feetInCm = feet * 30.48;

    // Convert inches to centimeters
    const inchesInCm = inches * 2.54;

    // Total length in centimeters
    const totalCm = feetInCm + inchesInCm;

    return totalCm;
}    

export const changeDateFormat = (inputDate) => {

  // Split the input date into month and year
  var parts = inputDate.split('-');
 
  // Rearrange the parts to form the desired format
  var outputDate = parts[1] + '-' + parts[0];
  
  return outputDate;
}

export const sha512 = async (input) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-512', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashedString = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('').toUpperCase();
  return hashedString;
}

export {findSelf,companyId,getDate,feetAndInchesToCm,getMonthYear}


export const cmToFeetAndInch = (cm) => {
    
    if(!cm)
    {
        return {
        feet: null,
        inches: null
    }

    }
    // 1 inch = 2.54 cm
    const inches = cm / 2.54;

    // 1 foot = 12 inches
    const feet = Math.floor(inches / 12);
    
    // Calculate remaining inches
    const remainingInches = inches % 12;

    return {
        feet: feet,
        inches: remainingInches
    };
}

export const proposerFormValidation = (data) =>
{
    // //console.log('proposalFormValidation',data)

    const field_list = {
                        'AnnualIncome':'Annual Income',
                        'District':'District',
                        'Education':'Education',
                        'MaritalStatus':'Marital Status',
                        'Occupation':'Occupation',
                  
                        'ProposalName':'Proposal Name',
                        'Proposalphonenumber':'Proposal Phone Number',
                        'dateOfbirth':'Date Of Birth',
                        'height_feet':'Height In Feet',
                        'weight':'Weight'
                      };

    const checkKey = (obj, key) =>
      {
        let result = obj[key] ?? false;
        return result == "" || 0 ? false : result;
      }

        for (let key in field_list) 
        {
            if(checkKey(data,key) == false)
            {
                // alert(field_list[key])
                return field_list[key];
                break;
            }
                              
        } 

        return true;
}

export const proposalFormValidation = (data) =>
{
    const field_list = {
                        'InsuredName':'Name',
                        'MaritalStatus':'Marital Status',
                        'Salutation':'Salutation',
                        'dateOfbirth':'Date Of Birth',
                        'height_feet':'Height In Feet',
                        'weight':'Weight'
                      };
    const checkKey = (obj, key) =>
    {
     let result = obj[key] ?? false;
     return result == "" || 0 ? false : result;
    } 

    for (let i = 0; i < data.length; i++) {
        if (data[i].InsuredRelation !== 'Self') 
        {
            for (let key in field_list) 
            {
                if(checkKey(data[i],key) == false)
                {
                    // alert(field_list[key])
                    return {field_name : field_list[key],relation:data[i].InsuredRelation};
                    break;
                }
                                  
            }  
        }
        
    }

    return true;
}


export const kycFormValidation = (data , c_id) =>
{
    // //console.log('proposalFormValidation',data)

    let field_list;

    if(c_id == "HDFC"){
      field_list = {
        'ProposalName':'KYC Name',
        'kycId':'KYC Doc Type',
        'KycDateOfbirth':'KYC Date Of Birth',
        'cardNum':'KYC Doc Number',
        'nomineeName':'Nominee Name',
        'nomineeDateOfbirth':'Nominee Date Of Birth',
        'nomineerelationship':'Nominee Relationship',
        'nomineeAddress':'Nominee Address',
        'Proposalphonenumber':'Mobile Number',
        'Email':'Email',
        'street':'Address',
        'Sate':'State',
        'District':'District',
        'PinCode':'PinCode',
        'Name' : 'Name',
        'accountNumber' : 'Account Number',
        'ifscCode' : 'IFSC Code',
        

      };
    }
    else if(c_id == "Reliance"){
       field_list = {
        'ProposalName':'KYC Name',
        'kycId':'KYC Doc Type',
        'KycDateOfbirth':'KYC Date Of Birth',
        // 'RelationWithNominee': 'Relation with Nominee',
        'cardNum':'KYC Doc Number',
        'nomineeName':'Nominee Name',
        'nomineeDateOfbirth':'Nominee Date Of Birth',
        'nomineerelationship':'Nominee Relationship',
        'nomineePhoneNumber' : 'Nominee Phone Number',
        'nomineeAddress':'Nominee Address',
        'Proposalphonenumber':'Mobile Number',
        'Email':'Email',
        'street':'Address',
        'Sate':'State',
        'District':'District',
        'PinCode':'PinCode',

      };

    }
    else{
      field_list = {
       'ProposalName':'KYC Name',
       'kycId':'KYC Doc Type',
       'KycDateOfbirth':'KYC Date Of Birth',
       'cardNum':'KYC Doc Number',
       'nomineeName':'Nominee Name',
       'nomineeDateOfbirth':'Nominee Date Of Birth',
       'nomineerelationship':'Nominee Relationship',
       'nomineeAddress':'Nominee Address',
       'Proposalphonenumber':'Mobile Number',
       'Email':'Email',
       'street':'Address',
       'Sate':'State',
       'District':'District',
       'PinCode':'PinCode',

     };

   }

   
    const checkKey = (obj, key) =>
      {
        let result = obj[key] ?? false;
        return result == "" || 0 ? false : result;
      }

        for (let key in field_list) 
        {
            if(checkKey(data,key) == false)
            {
                // alert(field_list[key])
                return field_list[key];
                break;
            }
                              
        } 

        return true;
}


export const getLastKey = (persion,data) =>
{
  let key = false;  
  for (let i = 0; i < data.length; i++) {
      
      if(data[i].InsuredRelation == persion)
      {
        key = i;
      }
  }

  return key;
}

export const insuredDataSequence = (data) =>
{
      let output = [];
      const relation=["Self","Wife","Husband","Son","Daughter","Father","Mother","Grand Father","Grand Mother","Father In Law","Mother In Law","Brother","Sister","Uncle","Aunt","Grand Daughter","Grand Son"]

      for (let i = 0; i < relation.length; i++) {
          let persion = findPersion(relation[i],data);
          if(persion)
          {
            for (let j = 0; j < persion.length; j++) {
                output.push(persion[j])
            }
          }
      }
      
      return output;
}

const findPersion = (persion,data) =>
{
   let output = [];
   

//    alert(data)


   for (let i = 0; i < data.length; i++) {
       if(data[i].InsuredRelation == persion)
       {
        output.push(data[i]);
       }
   }

    if(output.length == 0)
    {
       return false;
    }
    else
    {
       return output;
    }

}

export const companyWise = (oldData,newData,cname,planCode) => {
    // alert(JSON.stringify(cname))
   let oldDataone = JSON.parse(JSON.stringify(oldData))
    if(oldData.length != 0 && newData.length != 0 && cname != '' && planCode != '')
    {
    let oldcdata = oldData[cname];
    let newcdata = newData[cname];
// //console.log('companyWise',oldcdata,newcdata)

    let oldkey = oldcdata.findIndex((element) => element.planCode == planCode)
    let newkey = newcdata.findIndex((element) => element.planCode == planCode)
    
    oldDataone[cname][oldkey] = newcdata[newkey]
    //console.log('newcdata',newcdata[newkey])
    return oldDataone; 
    }
    else
    {
        return newData;
    }
  
  }



//   Currency Converter

export function formatCurrency(value) {
    const formattedValue = parseFloat(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    return formattedValue;
  }
  

export function encryption()
{
    let data = Date.now();
    let CryptoJSAesJson = {
        stringify: function (cipherParams) {
            let j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            let j = JSON.parse(jsonStr);
            let cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
            return cipherParams;
        }
    }

    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data),process.env.REACT_APP_ENC_KEY, {format: CryptoJSAesJson}).toString();
    
    

    function encodeToBase64(input) 
    {
        return btoa(unescape(encodeURIComponent(input)));
    }

    return encodeToBase64(encrypted);

}  


export function getMaxAge(array) {
    let ages = [];

    array.forEach(item => {
        ages.push(item['InsuredAge']);
    });

    ages.sort((a, b) => b - a);

    return ages[0];
}

export function addZeroToMonth(dateString) {
    // Split the string into month and year
    try {
      
        let parts = dateString.split('/');

        // Convert the month part to a zero-padded string if necessary
        let month = parseInt(parts[0]);
        let zeroPaddedMonth = month < 10 ? "0" + month : month;

        // Reconstruct the date string
        let formattedDateString = zeroPaddedMonth + '/' + parts[1];
        
        return formattedDateString;

    }
    catch(err) {
      return dateString;
    }

    
}

export function formatDate(dateString) {
    const parts = dateString.split('-');
    if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2].padStart(2, '0'); // Ensure day has two digits
        return `${year}-${month}-${day}`;
    }
    // If the input format is invalid, return the original string
    return dateString;
}

export function parseData(data) {
  // Check if the data is a string
  if (typeof data === 'string') {
    try {
      // Try to parse the string into a JSON object
      return JSON.parse(data);
    } catch (error) {
      // If parsing fails, log an error and return null or handle accordingly
      console.error('Invalid JSON string:', error);
      return null;
    }
  }
  
  // If data is already an object, just return it
  if (typeof data === 'object' && data !== null) {
    return data;
  }

  // If the data is neither a string nor a valid object, return null or handle accordingly
  console.error('Invalid input: data is neither a string nor an object');
  return null;
}

export function filterCompany(data,c_name,plan_code)
{

	let c_data = data[c_name];
	let filter_data = c_data.find(e => e.planCode == plan_code);
	return filter_data;

}

export function replaceCompany(data,c_name,plan_code,filtered_data)
{

	let c_data = data[c_name];
	let index = c_data.findIndex(e => e.planCode == plan_code);
    data[c_name][index] = filtered_data
	return data;

}

export function countUniquePlans(planData) {
    const planCodes = new Set();
  
    for (const company in planData) {
      if (Array.isArray(planData[company])) {
        planData[company].forEach(plan => {
          planCodes.add(plan.planCode);
        });
      }
    }
  
    return planCodes.size;
  }


  // export function premiumDetails(totalPremium) {
  //   // Ensure totalPremium is a string
  //   const cleanedPremium = String(totalPremium).replace(/,/g, '');
    
  //   // Convert the cleaned string to a number
  //   const numericPremium = parseFloat(cleanedPremium);
  
  //   // Check if numericPremium is a valid number
  //   if (isNaN(numericPremium)) {
  //     throw new Error('Invalid totalPremium value');
  //   }
  
  //   const gst = numericPremium * 0.18;
  //   const grossAmount = numericPremium - gst;
  
  //   return {
  //     grossAmount: parseFloat(grossAmount.toFixed(2)),
  //     gst: parseFloat(gst.toFixed(2))
  //   };
  // }
  
  export function premiumDetails(totalPremium) {
    // Ensure totalPremium is a string and remove commas
    const cleanedPremium = String(totalPremium).replace(/,/g, '');
    
    // Convert the cleaned string to a number
    const numericPremium = parseFloat(cleanedPremium);
    
    // Check if numericPremium is a valid number
    if (isNaN(numericPremium)) {
      throw new Error('Invalid totalPremium value');
    }
  
    // GST rate
    const gstRate = 18;
  
    // Calculate gross amount (base premium) and GST
    const grossAmount = numericPremium / (1 + gstRate / 100);
    const gst = numericPremium - grossAmount;
  
    // Return the results rounded to 2 decimal places
    return {
      grossAmount: parseFloat(grossAmount.toFixed(2)),
      gst: parseFloat(gst.toFixed(2))
    };
  }
  

  export const updateInsuredData = (insuredData, setInsuredData, payloadData) => {
    const { data, index } = payloadData;
    setInsuredData((prevData) => {
        const updatedInsuredDetail = [...prevData.InsuredDetail]; // Copying array for immutability

        for (let i = 0; i < data.length; i++) {
            if (updatedInsuredDetail[index]) {
                updatedInsuredDetail[index][data[i]["key"]] = data[i]["value"];
            } else {
                if (index != null) {
                    updatedInsuredDetail[index] = { [data[i]["key"]]: data[i]["value"] };
                }
            }
        }

        return {
            ...prevData,
            InsuredDetail: updatedInsuredDetail
        };
    });
};
