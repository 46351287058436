import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import "../SIP/SIP.css";
import { Link } from "react-router-dom";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import { Helmet } from "react-helmet-async";


const NSP = () => {

  var convertRupeesIntoWords = require('convert-rupees-into-words');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [monthlyInvestment, setMonthlyInvestment] = useState(10000);

    const handleLoanAmountChange = (event) => {
      const value = event.target.value;
      const parsedValue = parseFloat(value);
      if (isNaN(parsedValue)) {
        setMonthlyInvestment(''); // Clear the input if it's not a valid number
      } else {
        setMonthlyInvestment(parsedValue); // Update the state with the valid number
      }
      if(parsedValue > 100000){
        setMonthlyInvestment(100000)
      }
    
    };







    const [expectedReturn, setExpectedReturn] = useState(9);

    const handleInterestRateChange = (event) => {
      const value = event.target.value;
      if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
        if (parseFloat(value) > 15) {
          setExpectedReturn(15);
        } else {
          setExpectedReturn(value);
        }
      }
    };



    const [currentAge, setCurrentAge] = useState(20);

    const handleTenurePeriodChange = (event) => {
      const value = event.target.value;
      const parsedValue = parseFloat(value);
    
      if (isNaN(parsedValue)) {
        setCurrentAge(''); // Clear the input if it's not a valid number
      } else {
        setCurrentAge(parsedValue); // Update the state with the valid number
      }
      if(parsedValue > 60){
        setCurrentAge(60)
      }
    };




    // Separate This in this line 



    const [totalInvestment, setTotalInvestment] = useState(0);
    const [interestEarned, setInterestEarned] = useState(0);
    const [maturityAmount, setMaturityAmount] = useState(0);
    const [minAnnuity, setMinAnnuity] = useState(0);
  
    const calculatePension = () => {
      const retirementAge = 60
      const investmentPeriod = retirementAge - currentAge;
      const interestRatePerMonth = expectedReturn / 1200;
      const totalPeriods = investmentPeriod * 12;
  
      const futureValue = monthlyInvestment * ((1 + interestRatePerMonth) ** totalPeriods - 1) / interestRatePerMonth;
  
      setTotalInvestment(monthlyInvestment * totalPeriods);
      setInterestEarned(futureValue - (monthlyInvestment * totalPeriods));
      setMaturityAmount(futureValue);
      setMinAnnuity(futureValue * 0.4);
    };
    useEffect(()=>{
        calculatePension()
    },[monthlyInvestment,expectedReturn,currentAge])


const TotalInvestmentToWord = convertRupeesIntoWords(Math.ceil(totalInvestment))
const interestEarnedToWord = convertRupeesIntoWords(Math.ceil(interestEarned))
const maturityAmountToWord = convertRupeesIntoWords(Math.ceil(maturityAmount))
const minAnnuityToWord = convertRupeesIntoWords(Math.ceil(minAnnuity))

  return (


<div>

<Helmet>
        <title>Net promoter score calculation</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="nps score calculation ,nps calculation formula , national pension scheme calculator" />
      </Helmet>



<section className="margincalculator"></section>
<div className="headingcalculator">
<p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>NPS</span></p>

<h1 className="titlecalculator">National Pension System <span className='Mainheading'>Calculator</span></h1>
</div>
<div className="calculatorhead">


<div className="underdiv">
<div className="calculatorse">

{/* Calculator start div start hear */}
<div className="calculaterse">
<div className="calculator">

{/* Amount calculator */}
<div className="onecalculator">
<div className="amountdives">
<p>Monthly Investment </p>
<input type="text" value={monthlyInvestment}    onChange={handleLoanAmountChange}/>
</div>
<div className="amountselectordiv">
<Slider
onChange={handleLoanAmountChange}
value={monthlyInvestment}
min={500}
max={100000}
step={500} // optional, set the step value
aria-label="Default"
valueLabelDisplay="auto"
/>
</div>
</div>

{/* Amount calculator */}
<div className="onecalculator">
<div className="amountdives">
<p>Expected return Year % (P.A)</p><input type="text" value={expectedReturn} onChange={handleInterestRateChange}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={50} 
aria-label="Default" 
valueLabelDisplay="auto" 
onChange={handleInterestRateChange}
min={1}
max={15}
step={0.1} // Step remains at 0.5
value={expectedReturn}
/>

</div>
</div>

{/* Amount calculator */}
<div className="onecalculator">
<div className="amountdives">
<p>Time Period (Year) </p><input type="text" value={currentAge} onChange={handleTenurePeriodChange}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={50} 
aria-label="Default" 
valueLabelDisplay="auto" 
onChange={handleTenurePeriodChange}
min={18}
max={60}
value={currentAge}
/>
</div>
</div>
<div className="calculateamountdiv">
<div>

<div>
<p>Investment Amount: <span style={{ color: '#0166B3' }}>{String(totalInvestment.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })).slice(0,-3)}</span></p>
<p className='textword'>{TotalInvestmentToWord}</p>
<p>Interest Earn: <span style={{ color: '#0166B3' }}>{String(interestEarned.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })).slice(0,-3)}</span></p>
<p className='textword'>{interestEarnedToWord}</p>
<p>Maturity amount: <span style={{ color: '#0166B3' }}>{String(maturityAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })).slice(0,-3)}</span></p>
<p className='textword'>{maturityAmountToWord}</p>
<p>annuity amount: <span style={{ color: '#0166B3' }}>{String(minAnnuity.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })).slice(0,-3)}</span></p>
<p className='textword'>{minAnnuityToWord}</p>
</div>


</div>
</div>
</div>
<div className="graphdiv">

<Chart
    type='donut'
    width={400}
    height={445}
    series={[totalInvestment,interestEarned]}
    options={{
      labels:['Invested Amount', 'Return Value']
    }}
  />



</div>
</div>
</div>
{/* Result part start hear */}
<div className="resultse">
<Calculatorlist/>
</div>


</div>
</div>
</div>
  )
}

export default NSP