import React,{useEffect, useState} from 'react';
import PartnerCompany from '../../../../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../../../../AllPageComponents/FAQ/FAQ';
import { Link } from 'react-router-dom';
import FastImage from '../../../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import Redirect from '../../../../AllPageComponents/Rederict';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { Helmet } from 'react-helmet-async';











const HealthTopupPlan = () => {
  const initialText =
    'With Beema1, get top-rated health insurance plans online. You can compare online and buy the most suitable insurance plan for you and your family. You can also get your queries answered by our team of experts.';

  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };



const [Readmoere, setReadmoere] = useState(true)
const handelReadmore = () =>{
  setReadmoere(!Readmoere)
}



const NeedPlane = [
  {id:0,Q:"Flexible top ups are the coverage benefits to increase the coverage for you and your family",A:'Increase your sum insured and stay secured throughout your future. Choose a health insurance plan with a coverage starting from 5 Lakh + up to 1 Crore + with individual and floater plans for your family.'},
  {id:1,Q:'Processing Claims Without Hassles',A:'Cashless claims across all the network hospitals. Top ups also include progressive and modern treatments, Wide coverage, Ambulance expenses and Delivery Expenses.'},
  {id:2,Q:'Through Beema1, Get The Options To Stay Covered For Pre-existing Conditions',A:'Lowest waiting period of 12 months for pre-existing diseases'},
  {id:3,Q:'Convenient Payment Options',A:"There are flexible payment options like quarterly, half yearly and options for paying premiums in advance."},
  {id:4,Q:'Pre and Post Hospitalizations are also Covered Through Us',A:"We secure you for countless medical investigations in case of both pre & post hospitalizations."}
]


const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };


  const HowToBuyHealthInsurence = [
    {
        step: 1,
        "title": "Provide Your Details",
        "description": "Enter your information to get instant access to competitive insurance quotes. Easily compare insurance policy premiums from the top-rated insurers."
    },
    {
        step: 2,
        "title": "Compare Quotes & Select The Right Health Insurance Plan",
        "description": "Compare plans based on features and costs aligned with your needs, and your budget, and then choose the best-fit plan for yourself."
    },
    {
        step: 3,
        "title": "Pay and Get Policy Within Minutes",
        "description": "Buy your policy online and receive it instantly in your email inbox."
    },
    {
        step: 4,
        "title": "Get Your Policy",
        "description": "Get your policy – Once you make the payment towards your insurance policy, your policy will be issued immediately in no time, only if there are no underwriting requirements."
    },
    {
      step: 5,
      "title": "Constant Customer Support",
      "description": "Our customer service team is dedicated to helping you with any queries."
  },
  
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <>
            <Helmet>
        <title>Affordable health top up plans with upto 18% discount</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="best top-up health insurance, top-up mediclaim policy, Mediclaim top-up plan" />
      </Helmet>
   <section className="car-insurance insurance-all main-health mainnargin-insurance">
  <div className="container">
   <Redirect/>
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one ">
        <div className="healthdiscription">
          {/* <h1>Affordable Super Health Top-Up Plans with Up to 18% Discount</h1> */}
          <h1>Affordable health top up plans with upto 18% discount</h1>
          <div>
      <span style={{ textAlign: 'justify' }}>
        {initialText}
      </span>
    </div>

        </div>
        <div className="insurance-btn-set">
          <button
            type="button"
            className="reminderbutton"
            data-toggle="modal"
            data-target="#reminderpopup"
          >
            {" "}
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              fontSize="15pt"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "rgb(251, 167, 27)", margin: "0px 5px" }}
            >
              <circle cx={12} cy={13} r={8} />
              <path d="M12 9v4l2 2" />
              <path d="M5 3 2 6" />
              <path d="m22 6-3-3" />
              <path d="M6.38 18.7 4 21" />
              <path d="M17.64 18.67 20 21" />
            </svg>{" "}
            Set Reminder
          </button>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
               <FastImage
                  url={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                  width={auto}
                  height={auto}
                  alt={"arrow"}
                  src={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                />
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
        <div className="form-main">
          <form id="insurance_form_get_plan">
            <div className="d-flex align-items-center form-input">
              <h6>
                Compare &amp; Buy Customized Health Plans starting at just
                <span className='specialprice'>Rs ₹257/month*</span>
              </h6>
              <div className="blue-btn">
              <Link to={"/healthStep1"}>
               
               <button
                 style={{
                   backgroundColor: "transparent",
                   border: "none",
                   color: "white",
                   fontWeight: 400
                 }}
               >
                 Get Your Quotes
               </button>{" "}
               <img alt="" src="" className="" />
      
             </Link>
                
              </div>
              <p className="insurance-text-light">
                By clicking, I agree to{" "}
                <a href="/termsandcondition">*terms &amp; conditions</a> and{" "}
                <a href="/privacy-policy">privacy policy</a>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


<div className="inbulitmargine"></div>





<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>What Is A Top-up Health Insurance Policy?</h4>
<div className="yellowline"></div>
  <div className="row">
    <div className="col-md-12">
    <p className='textformat'>Top-up health insurance plans are separate and additional insurance coverage or policies that are normally purchased  and added to your existing base health insurance coverage. These top ups have large insurance coverage in exchange for a small premium. These top-up options can be utilized only if one covers the base hospitalization expense, known as (Deductibles) either from your own pocket or from the base insurance plan. In the market, several health insurance companies are offering affordable top-up health insurance plans. You can buy them if you are aged between 18 years and 80 years.
  
  Deductibles play a significant role in determining your top-up health insurance premium. A deductible is the amount you agree to pay before your health insurance starts. Normally, the deductible in a top-up plan matches your primary health insurance. It's essentially the safety net that bridges the gap between what your primary plan covers and the total medical expenses.
  
  Considering this common concern, insurance companies have introduced top-up plans. These plans greatly expand your current coverage, giving you peace of mind by adding extra financial protection when you need it most.
If you feel like your current insurance coverage isn't sufficient, then choose the best top-up health insurance for you.
</p>
    </div>
  </div>
</div>
</div>


<div className="fixwidthDivtext">
  <div className="container">
  <h4 className='healthHeading'>Top-up Health Insurance</h4>
  <div className="yellowline"></div>
    <div className="row">
      <div className="col-md-12">
      <div className={Readmoere ? "Readmoretopup" : "Readless"}>
<p className='textformat'>Get additional sum insured and top-up your existing policy at an affordable premium.
Top-up health insurance is an additional layer of protection to supplement your primary health coverage. When your health insurance plan falls short of covering your medical expenses, you may reach into your savings to bridge the gap. Ensuring you get the best possible treatment is crucial, and compromising on quality isn't an option. Considering this common concern, insurance companies have introduced top-up plans. These plans greatly expand your current coverage, giving you peace of mind by adding extra financial protection when you need it most.</p>
<p className='textformat'><span className='specialprice'>Example-</span>For an instance, an insured individual own a health insurance policy with a sum 
insured of Rs. 50 Lakhs with a deductible of 5 Lakhs. This top-up plan will cover your expense 
above the basic 5 Lakh, and up to 55 Lakhs This states that the top-up plan will cover the expense only when the medical expense bill at the hospital will surpass the amount of 5 Lakhs in each hospitalization. Individual insured may have another base health insurance plan or pay out of pocket for the initial 5 Lakh Health Insurance.
These add on top-up plans are substantially less expensive than standard base health insurance plans.

When availing of a health insurance plan, you opt for one that takes care of your current financial status and future financial balance and security. If you count on the inflation across the healthcare services industry, you cannot help but only get confused thinking if your current insurance coverage would satisfy your future medical requirements.
 </p>

<b style={{color:"rgb(122 119 119)"}}>You have 2 options in such scenarios</b>  
  
   
  <li className='liststyl2'>1. &nbsp; You have 2 options in such scenarios</li>
  <li className='liststyl2'>2. &nbsp; You request your insurance company to increase your coverage amount</li>

  <p className='textformat'>
  The issue is that there will be an enormous spike in your premium and hence loading a significant financial burden on your back. Now, what can be done in this situation.
  
  However, there is one more option, basically the 3rd option - Choose a top-up or super top-up policy on your existing insurance plan. Top-ups and super top-ups are an extension or an increase on the sum insured that becomes effective as soon as your claim amount is exhausted or drained.
  </p>

</div>
<div className="redbtn">

<button onClick={handelReadmore} className='Readmorebtn'>Read more</button>
</div>
      </div>
    </div>
  </div>


</div>








  
<div className="fixwidthDivtext">
  <div className="container">
  <h4 className='healthHeading'>Why Do You Need A Top-up Plan?</h4>
  <div className="yellowline"></div>
    <div className="row">
    <div className="col-md-6 col-12 d-flex justify-content-center align-items-center"> 
        <FastImage url='/Website%20Img/Privacy%20policy-pana.png?updatedAt=1721730470137' width={auto} height={auto} className='fmailimagegifs'/>
      </div>
      <div className="col-md-6 d-flex justify-content-center align-items-center">

     <div>
      {
        NeedPlane.map((data,index)=>{
          return(
            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <AccordionSummary
              expandIcon={<MdOutlineExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="accordingback"
            >
              <div>
              <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
              </div>
   <div>
   <li className='listcol'>{data.Q}</li>
   </div>

            </AccordionSummary>
            <AccordionDetails>
            <p className='textformat'>{data.A}</p> 
            </AccordionDetails>
          </Accordion>
          )
        })
      }
  
      </div>

      </div>
    </div>
  </div>
  </div> 








  <div className="fixwidthDivtext">
    <div className="container">
    <h4 className='healthHeading'>Health Top-Up Insurance Features</h4>
    <div className="yellowline"></div>
      <div className="row">
        <div className="col-md-12">
        <p className='textformat'>
            <span className='specialprice'>Convertible Flexibility:</span>
             With a top-up health insurance plan, you have the freedom to convert it into a basic health insurance plan whenever necessary. This allows you to easily adjust your coverage during times of heightened health needs or budget constraints.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Family Unity:</span>
            Opting for a top-up health insurance plan often means your children can be included under the same policy. This simplifies the process of protecting your entire family's health under a single insurance plan.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Freedom from Cost Limits:</span>
            Top-up health cover plans typically don't have restrictions on 
hospitalization costs, covering everything from doctor's fees to room charges. You can be rest 
assured that your insurance will take care of the expenses, no matter how high the medical bills 
climb
             </p>

              <p className='textformat'>
            <span className='specialprice'>Rewards for Wellness: </span>
            Top-up health policy offers a unique benefit: the longer you stay healthy and don't make claims, the more your coverage increases. It's like a loyalty bonus for maintaining good health and not needing frequent medical assistance.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Comparison Options: </span>
            Different platforms allow users to compare the best top-up health insurance plans, helping them make well-informed decisions.
             </p>

              <p className='textformat'>
            <span className='specialprice'>15-Day Grace Period: </span>
            After obtaining a top-up health insurance policy, you're given a 15-day grace period. This time allows you to thoroughly review and understand all the policy's terms and conditions, ensuring it fits your specific needs and preferences.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Flexible Policy Options: </span>
            You have the flexibility to choose between 1-year and 2-year policy terms, depending on what suits you best. Note that the availability of these options may vary among different insurers.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Lifetime Renewability: </span>
            Medical insurance top-up plans offer the advantage of lifetime renewability. This means you can keep your coverage in place and have a long-term safety net for your health throughout your lifetime, providing peace of mind and financial security.
             </p>
        </div>
      </div>
    </div>
</div>




<div className="fixwidthDivtext">
<div className="container">
<h4 className='theheadingcolor'>
How to Buy Health Insurance Plan From Beema1  </h4>
<div className="yellowline"></div>

<div className="row">

<div className="col-md-12 d-flex justify-content-center align-items-center hero-stepper">
<p className="textformat">
    In just a few clicks, you can secure your health policy with ease and convenience. Our streamlined online process is designed to save you time and effort, allowing you to compare various plans tailored to your needs. Whether you're looking for comprehensive coverage or specific benefits, our user-friendly platform makes it simple to find the right health insurance for you and your family. Plus, our dedicated support team is here to guide you every step of the way, ensuring that you make informed decisions about your healthcare.
</p>
</div>
  <div className="col-md-12 d-flex justify-content-center align-items-center hero-stepper">
  <FastImage url="/Website%20Img/25ppt%20(2).png?updatedAt=1727083979291" width={auto} height={auto} className="stepperimage"/>

  </div>
</div>
</div>
</div>


<div className="fixwidthDivtext">

<div className="container">
  <div className="row">


  <div className="col-md-12 d-flex justify-content-center align-items-center">

<div>


  {
  HowToBuyHealthInsurence.map((data)=>{
    return(
      <Accordion expanded={expanded === `panel${data.step}`} onChange={handleChange(`panel${data.step}`)} key={data.step}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
        >
          <li className='listcol'>
          {data.step}.&nbsp;
           {data.title}
          </li>
        </AccordionSummary>
        <AccordionDetails className="AnwsarSpan">
         {data.description}
        </AccordionDetails>
      </Accordion>
    )
  })
}
</div>

  </div>
  </div>
</div>

  </div>



<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Top-Up Health Insurance Plan Benefits</h4>
<div className="yellowline"></div>
 <p className='textformat'>Here are some of the reasons why you must buy a top-up health insurance plan:</p>

  <div className="row">
    <div className="col-md-12">
    <p className='textformat'>
            <span className='specialprice'>Emergency Coverage: </span>
            Best top-up health insurance acts as a financial safety net, swooping in when your regular policy's limits are reached, sparing you from unexpected financial strain.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Sustained Peace of Mind: </span>
            Even if your existing health insurance coverage maxes out, top-up plans keep you covered for the remainder of the period, ensuring your peace of mind remains undisturbed.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Cost-Effective Expansion: </span>
            These plans offer increased coverage at a pocket-friendly 
premium, making them a smart choice, more particularly for older policyholders who may need 
extensive coverage and beyond
             </p>

              <p className='textformat'>
            <span className='specialprice'>Combatting Medical Inflation: </span>
            With healthcare costs skyrocketing, top-up mediclaim policy helps improve the impact by shifting the risk to your insurance provider, easing your financial burden in the face of rising medical expenses.
             </p>


              <p className='textformat'>
            <span className='specialprice'>Tax Advantages:</span>
            Similar to traditional health insurance, top-up plans offer tax benefits under section 80D of the Income Tax Act. You can decrease your tax liability by up to Rs. 25,000 for plans covering yourself, your spouse, and your children, with the limit increasing to Rs. 50,000 if you're over 60.
             </p>

              <p className='textformat'>
            <span className='specialprice'>Coverage for Pre-Existing Conditions: </span>
            Unlike many health plans, top-up insurance often 
covers pre-existing illnesses. This feature is specifically essential for individuals with a family 
history of conditions like diabetes or hypertension, ensuring comprehensive coverage for 
existing health concerns
             </p>
    </div>
  </div>
</div>
 </div>



<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>A Top-up Health Insurance Plan Covers The Following:</h4>
<div className="yellowline"></div>
<p className='textformat'>Most top-up insurance plans typically cover a wide range of medical expenses, including:</p>
  <div className="row">


    <div className="col-md-6 d-flex justify-content-center align-items-center">
      <div>

  
    <div className="healthtagtoptup">
Pre-hospitalization expenses incurred before admission to the hospital
</div>
<div className="healthtagtoptup1">
Post-hospitalization expenses for follow-up treatments and recovery
</div>
<div className="healthtagtoptup2">
Daycare procedures that don't require a hospital stay exceeding 24 hours
</div>
<div className="healthtagtoptup3">
Expenses related to organ donor procedures
</div>
<div className="healthtagtoptup4">
Emergency ambulance charges for transport to the hospital
</div>
<div className="healthtagtoptup5">
Domiciliary treatment expenses for medical care provided at home when hospitalization isn't possible
</div>
</div>
    </div>


    <div className="col-md-6 d-flex justify-content-center align-items-center">
     <FastImage url='/Website%20Img/medical-insurance_2841367.png?updatedAt=1727507649749' className='fmailimagegifs'/>
    </div>
  </div>
</div>
</div>



<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Exclusions Under Top-Up Health Insurance Plans</h4>
<div className="yellowline"></div>
 <p className='textformat'>While top-up health insurance plans offer valuable protection, there are certain exclusions to be aware of. Here's what you won't find covered:</p>

  <div className="row">
    <div className="col-md-12">
    <p className='textformat'>
  <span className='specialprice'>Adventure Sports Mishaps: </span>
  If you get hurt doing adventurous stuff like mountain climbing or paragliding, your top-up health insurance won't pay for it. You'll need to pay those expenses on your own.
</p>


 <p className='textformat'>
  <span className='specialprice'>Self-Harm Expenses: </span>
  Medical costs arising from self-harm, including suicide attempts, are not covered by top-up health insurance.
</p>


 <p className='textformat'>
  <span className='specialprice'>Cosmetic Surgery: </span>
  Top-up health insurance doesn't pay for cosmetic surgery unless it's needed because of an injury or burn. That means elective cosmetic procedures aren't covered by your plan.
</p>

 <p className='textformat'>
  <span className='specialprice'>Sexually Transmitted Diseases: </span>
  Treatments involved in sexually transmitted diseases isn't 
covered by top-up health insurance. Treatment costs for such diseases has to be independently
handled by the individual
</p>
    </div>
  </div>
</div>




</div>



   






<PartnerCompany/>
<FAQ/>

   </>
  )
}

export default HealthTopupPlan