import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../CapitalGainCalculator/CapitalGain.css";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import Redirect from "../../../AllPageComponents/Rederict";
import { Helmet } from "react-helmet-async";

const CapitalGain = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
      const SelectValue = [
        { id: 0, Value: "Stock" },
        { id: 1, Value: "Equity Mutual Fund" },
        { id: 4, Value: "Gold" },
        { id: 5, Value: "Gold ETF" },
        { id: 6, Value: "Privately held stocks" },
        { id: 7, Value: "Property" },
      ];
    
      const [selectValueChange, setSelectValueChange] = useState(SelectValue[0]);
    
      const handleSelectChange = (event) => {
        const selectedValue = SelectValue.find(
          (item) => item.id === event.target.value,
        );
        setSelectValueChange(selectedValue);
      };
    
      const [netBuyAmount, setNetBuyAmount] = useState(0);
      const [netSellAmount, setNetSellAmount] = useState(0);
    
      const buyAmountNumber = Number(netBuyAmount);
      const sellAmountNumber = Number(netSellAmount);
    
      const [dateOfSell, setDateOfSell] = useState("");
      const handleDateChange = (e) => {
        const formattedDate = e.target.value;
        if (formattedDate.length === 10) {
          setDateOfSell(formattedDate);
        } else {
          setDateOfSell("");
        }
      };
    
      const [dateOfBuy, setdateOfBuy] = useState("");
      const handelBuyDateChange = (e) => {
        const formattedData = e.target.value;
        if (formattedData.length === 10) {
          setdateOfBuy(formattedData);
        } else {
          setdateOfBuy("");
        }
        // console.log(formattedData);
      };
    
      const [totalYear, setTotalYear] = useState(0);
      const calculateDifference = () => {
        if (dateOfSell && dateOfBuy) {
          const sellDate = new Date(dateOfSell);
          const buyDate = new Date(dateOfBuy);
          const differenceInTime = sellDate - buyDate;
          const differenceInYears = differenceInTime / (1000 * 3600 * 24 * 365.25);
          const totalYears = Math.round(differenceInYears);
    
          if (totalYears > 0) {
            setTotalYear(totalYears);
          } else {
            setTotalYear(0);
          }
        } else {
          setTotalYear(0);
        }
      };
    
      useEffect(() => {
        calculateDifference();
      }, [dateOfSell, dateOfBuy]);
    
      const indexationData = [
        { year: 2001, index: 100 },
        { year: 2002, index: 105 },
        { year: 2003, index: 109 },
        { year: 2004, index: 113 },
        { year: 2005, index: 117 },
        { year: 2006, index: 122 },
        { year: 2007, index: 129 },
        { year: 2008, index: 137 },
        { year: 2009, index: 148 },
        { year: 2010, index: 167 },
        { year: 2011, index: 184 },
        { year: 2012, index: 200 },
        { year: 2013, index: 220 },
        { year: 2014, index: 240 },
        { year: 2015, index: 254 },
        { year: 2016, index: 264 },
        { year: 2017, index: 272 },
        { year: 2018, index: 280 },
        { year: 2019, index: 289 },
        { year: 2020, index: 301 },
        { year: 2021, index: 317 },
        { year: 2022, index: 331 },
        { year: 2023, index: 348 },
      ];
    
      const buyYear = dateOfBuy.slice(0, 4);
      const sellYear = dateOfSell.slice(0, 4);
    
      const [Datefixd, setDatefixd] = useState("2025-12-09");
      useEffect(() => {
        if (selectValueChange.id === 0 || selectValueChange.id === 1){
          setDatefixd(`${parseInt(buyYear) + 1}-12-31`);
        } else if (selectValueChange.id === 4 || selectValueChange.id === 5) {
          setDatefixd(`${parseInt(buyYear) + 3}-12-31`);
        } else if (selectValueChange.id === 6 || selectValueChange.id === 7) {
          setDatefixd(`${parseInt(buyYear) + 2}-12-31`);
        }
      }, [selectValueChange, buyYear]);
    
      const findIndexForYear = (year) => {
        const indexData = indexationData.find((item) => item.year === year);
        return indexData ? indexData.index : 0;
      };
    
      const buyIndex = findIndexForYear(parseInt(buyYear));
      const sellIndex = findIndexForYear(parseInt(sellYear));
    
    //   console.log("Buy Index:", buyIndex);
    //   console.log("Sell Index:", sellIndex);
    
      const IndexSetionData = (netBuyAmount * sellIndex) / buyIndex;
      const RoundIndexsession = Math.round(IndexSetionData);


    
      const afterRemoveSellprice = netSellAmount - RoundIndexsession;



      console.log(afterRemoveSellprice)

    
      const [DedactionMoney, setDedactionMoney] = useState(0);
    
      useEffect(() => {
        if (
          (selectValueChange.id === 0 || selectValueChange.id === 1) &&
          afterRemoveSellprice > 100000 &&
          totalYear > 1
        ) {
          setDedactionMoney(afterRemoveSellprice - 100000);
        } else {
          setDedactionMoney(afterRemoveSellprice);
        }
      }, [afterRemoveSellprice, buyIndex, sellIndex, totalYear, selectValueChange]);
    
      var convertRupeesIntoWords = require("convert-rupees-into-words");
    
      const [Calculatingpersentage, setCalculatingpersentage] = useState(0);
    
      useEffect(() => {
        const calculatePercentage = () => {
          let percentage = 0;
    
          if (selectValueChange.id === 0 || selectValueChange.id === 1) {
                  percentage = 0.104;
            }
            if (selectValueChange.id === 4) {
                percentage = 0.208;
          }
          if(selectValueChange.id === 5){
            percentage = 0.104;
          }
          if(selectValueChange.id === 6 || selectValueChange.id === 7){
            percentage = 0.208;
          }
    
    
          return percentage;
        };
    
        const percentage = calculatePercentage();
        const calculatedValue = DedactionMoney * percentage;
        setCalculatingpersentage(calculatedValue);
      }, [DedactionMoney, totalYear, selectValueChange]);
      
      
      const TheRoundData = Math.round(Calculatingpersentage);
    
      const [result, setResult] = useState(0);
      const [NeetCapitalGain, setNeetCapitalGain] = useState(0)
      const handleCalculate = () => {
        setResult(TheRoundData);
       setNeetCapitalGain(DedactionMoney)
        
      };
    //   console.log("the final;", TheRoundData);
    
      useEffect(() => {
        setCalculatingpersentage(0);
        setNetBuyAmount(0);
        setNetSellAmount(0);
        setdateOfBuy("");
        setDateOfSell("");
        setTotalYear(0);
        setDedactionMoney(0)
      }, [selectValueChange]);
    
    
    
    
      const ActiveGraphbuy = buyAmountNumber === 0 ? 1000 : buyAmountNumber
      const ActiveGraphsell = sellAmountNumber === 0 ? 1000 : sellAmountNumber
      // console.log(ActiveGraphbuy,ActiveGraphsell)

    //   console.log("The Date fix",Datefixd)



    // const maxdate = new Date(Datefixd);
    // const dateSell = new Date(dateOfSell);

    const formatDate = (date) => {
        if (!(date instanceof Date) || isNaN(date)) {
          return "Invalid Date";
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
      const calculateYearGap = (date1, date2) => {
        const diffInMs = date2 - date1;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        const diffInYears = diffInDays / 365.25;  // Using 365.25 to account for leap years
        return Math.floor(diffInYears);
      };
    
    //   const [TotalYearGap, setTotalYearGap] = useState(true);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
      const calculateYearGap = (dateBuy, dateSell) => {
        // Assuming calculateYearGap is a function that calculates the year gap between two dates
        // Implement your logic to calculate year gap here
        return Math.abs(dateSell.getFullYear() - dateBuy.getFullYear());
      };
  
      const formatDate = (date) => {
        // Implement your formatDate function if needed
        return date.toISOString(); // Example formatting
      };
  
      if (dateOfBuy && dateOfSell) {
        const dateBuyObj = new Date(dateOfBuy);
        const dateSellObj = new Date(dateOfSell);
        const yearGap = calculateYearGap(dateBuyObj, dateSellObj);
        
        let disable = true;
  
        if ((selectValueChange.id === 0 || selectValueChange.id === 1) && yearGap <= 1) {
          disable = false;
        } else if ((selectValueChange.id === 4 || selectValueChange.id === 5) && yearGap <= 3) {
          disable = false;
        } else if ((selectValueChange.id === 6 || selectValueChange.id === 7) && yearGap <= 2) {
          disable = false;
        }
  
        setIsDisabled(disable);
        console.log("Year Gap:", yearGap);
      }
    }, [dateOfBuy, dateOfSell, selectValueChange]);
      

    const minsall = Number(Datefixd.slice(0, 4));
      
console.log(minsall+1)




const CapitalGainAmount = DedactionMoney




  return (
    <>
  <Helmet>
  <title>Long-term capital gain calculator</title>

        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
<section className="margincalculator"></section>
      <div className="headingcalculator">
        <p>
          Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;
          <Link to={"/calculator"}>calculator</Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;
          <span style={{ color: "green" }}>
            Long-Term-capital-gain-calculator
          </span>
        </p>

        <h1
         className="titlecalculator">
          Long-Term-Capital gain <span className='Mainheading'>Calculator</span>
        </h1>

    
        <span className="CalculatorDiscription">
          Long-term capital gain calculator is a financial tool used to compute
          the profit or loss earned from the sale of assets such as stocks,
          bonds, real estate, or mutual funds.
        </span>

      
      </div>
      
      <br />
      <div className="underdiv">
        <div className="cgcalculator">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="cgcartone">
                  <p>Select Asset Type</p>
                  <FormControl
                    sx={{
                      m: 1,
                      width: 300,
                      border: "1px solid #0166B3",
                      borderRadius: "10px",
                    }}
                  >
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      onChange={handleSelectChange}
                      value={selectValueChange.id}
                    >
                      {SelectValue.map((value) => (
                        <MenuItem
                          key={value.id}
                          value={value.id}
                          sx={{ color: " #0166B3" }}
                        >
                          {value.Value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="cgcalinput ">
                  <div className="amountdives">
                    <p>Net Buy Price</p>
                    <input
                      type="number"
                      value={netBuyAmount}
                      onChange={(event) => {
                        setNetBuyAmount(event.target.value);
                      }}
                    />
                  </div>
                  <div className="amountselectordiv">
                    <Slider
                      value={netBuyAmount}
                      onChange={(event) => {
                        setNetBuyAmount(event.target.value);
                      }}
                      min={100000}
                      max={100000000}
                      step={10000}
                      aria-label="Interest Rate"
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>

                <div className="cgcalinput ">
                  <div className="amountdives">
                    <p>Net Sell Price</p>
                    <input
                      type="number"
                      value={netSellAmount}
                      onChange={(event) => {
                        setNetSellAmount(event.target.value);
                      }}
                    />
                  </div>
                  <div className="amountselectordiv">
                    <Slider
                      value={netSellAmount}
                      onChange={(event) => {
                        setNetSellAmount(event.target.value);
                      }}
                      min={100000}
                      max={100000000}
                      step={10000}
                      aria-label="Interest Rate"
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>
                <div className="cgcaldate">
                  <div className="undercgdate">
                    <p>Date Of Buy</p>

                    <input
                      type="date"
                      min={dateOfSell}
                      className="ddyymms"
                      value={dateOfBuy}
                      onChange={(e) => {
                        handelBuyDateChange(e);
                      }}
           
                    />
                  </div>
                </div>

                <div className="cgcaldate ">
                  <div className="undercgdate">
                    <p>Date of Sell</p>

                    <input
                      type="date"
                      disabled={dateOfBuy === '' || null}
                      className="ddyymms"
                      value={dateOfSell}
                      onChange={(e) => {
                        handleDateChange(e);
                      }}
                      min={Datefixd}
                      max={'2099-01-01'}
                    />
                  </div>
                </div>

                <div className="cgcaldate ">
                  <div className="undercgdate">
                  <button 
  className={isDisabled ? 'calculatebtn' : 'disblebtn'} 
  onClick={handleCalculate} 
  disabled={!isDisabled}>
  Calculate
</button>
                    {/* {Datefixd < dateOfSell ? "This button is Disable" : null} */}
                    <span hidden={isDisabled} className="Warning">{isDisabled ? null : `Selected Year Must Be ${minsall+1}`}</span>
                  </div>
                </div>
              </div>

              <div className="flexcontainer col-md-5">
                <div className="undergraph">
                  <div className="graphdiv">
                    <Chart
                      type="donut"
                      width={400}
                      height={445}
                      series={[ActiveGraphbuy ? ActiveGraphbuy : 100, ActiveGraphsell ? ActiveGraphsell : 100]}
                      options={{
                        labels: ["Buy Amount", "Sell Amount"],
                      }}
                    />
                    <br />
                  </div>
                  {result !== null && (
                    <div className="cgcaldate">





                      <div className="undercgdate">
                 
{/* Calpital Gain amount */}
<p className="mainclass">
                          Capital Gain Amount: &nbsp;
                          <span style={{ color: "#0166B3", fontWeight: 600 }}>
                            {Math.round(parseFloat(NeetCapitalGain)).toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              },
                            )}
                          </span>
                        </p>
                        <span className="textword">
                          {convertRupeesIntoWords(NeetCapitalGain)}
                        </span>
                        <br />
                          <br />
                   
                        <p className="mainclass">
                          Capital Gain Tax: &nbsp;
                          <span style={{ color: "#0166B3", fontWeight: 600 }}>
                            {Math.round(parseFloat(result)).toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              },
                            )}
                          </span>
                        </p>
                        <span className="textword">
                          {convertRupeesIntoWords(result)}
                        </span>
                      </div>








                      
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="resultse">
          <Calculatorlist />
        </div>
      </div>
    </>
  );
};

export default CapitalGain;
