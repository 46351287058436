import React, { useState ,useEffect} from "react";
import Slider from "@mui/material/Slider";
import { Link } from "react-router-dom";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import Chart from "react-apexcharts";
import { Helmet } from "react-helmet-async";

function ULIPCalculator() {
  const [monthlyInvestment, setMonthlyInvestment] = useState(1000);

const MonthlyInvest = (e) =>{
  let value = e.target.value;

  if (value === '') {
    setMonthlyInvestment(''); // Set to an empty string if the input is cleared
  } else {
    value = Number(value);
    if (value > 1000000) {
      value = 1000000; // Set the value to 60 if it's greater than 60
    }
    setMonthlyInvestment(value);
  }
}










const [expectedReturn, setExpectedReturn] = useState(8);

const ExpectedReturnFunction = (e) =>{
  let value = e.target.value;
  if (value === '') {
    setExpectedReturn(''); // Set to an empty string if the input is cleared
  } else {
    value = Number(value);
    if (value > 15) {
      value = 15; // Set the value to 60 if it's greater than 60
    }
    setExpectedReturn(value);
  }
}






  const [term, setTerm] = useState(5);

const HandelTanurefunction = (e) =>{
  let value = e.target.value;
  if (value === '') {
    setTerm(''); // Set to an empty string if the input is cleared
  } else {
    value = Number(value);
    if (value > 30) {
      value = 30; // Set the value to 60 if it's greater than 60
    }
    setTerm(value);
  }
}


  const [maturityValue, setMaturityValue] = useState("");
  const [setType, setsetType] = useState("Monthly")

  const InvestedAmount = term * 12 * monthlyInvestment
  const calculateULIPMaturityValue = () => {



if(setType === "Monthly"){
    const monthlyInvestmentValue = parseFloat(monthlyInvestment);
    const expectedReturnValue = parseFloat(expectedReturn) / 100;
    const termValue = parseFloat(term);
    const monthlyInterestRate = expectedReturnValue / 12;
    const compoundingPeriods = termValue * 12;
    const maturityValueResult =
      (monthlyInvestmentValue *
        (Math.pow(1 + monthlyInterestRate, compoundingPeriods) - 1)) /
      monthlyInterestRate;
      setMaturityValue(maturityValueResult.toFixed(2));
    }
if(setType === "OneTime"){

    function calculateCompoundInterest(principal, rate, time) {
    rate = rate / 100;
    const amount = principal * Math.pow(1 + rate, time);

    return amount.toFixed(2);
}



const principal = monthlyInvestment; // Initial amount
const interestRate = expectedReturn; // Annual interest rate in percentage
const years = term; // Number of years

const totalAmount = calculateCompoundInterest(principal, interestRate, years);
setMaturityValue(totalAmount);
//console.log("Total amount after 5 years:", totalAmount);
}

    








        window.scrollBy({
        top: 560,
        behavior: 'smooth'
    });
  };

  const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR'
});

const RoundMaturityAmount = Math.round(maturityValue)
useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


      var convertRupeesIntoWords = require('convert-rupees-into-words');

      const AmounttoText = convertRupeesIntoWords(RoundMaturityAmount)
   


      const maturityValueNumber = parseFloat(maturityValue.replace(/[^0-9.-]+/g,""));
  return (
    <>
    <Helmet>
        <title>Ulip calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
<section className="margincalculator"></section>


      <div className="childeducation">
      <p className='breadcum'>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>ULIP Calculator</span></p>
        <h1>
          ULIPS Expense <span className="Mainheading">Calculator</span>
        </h1>
        <span className="spantext">
         ULIP Calculator is a financial tool designed to help you compare ULIP plans and estimate the maturity amount depending on the expected investment value and returns of future, helping you to reach your life objectives in the future.
        </span>
      </div>


      <div className="mainflex">

      <div className="latemaincalculator">
     <div className="selectcalclulatortype">
<div className={setType === "Monthly" ? "monthly" : "deactivate"} onClick={(e)=>{setsetType("Monthly")}}>Monthly</div>
<div className={setType === "OneTime" ? "reguler" : "deactivate"} onClick={(e)=> {setsetType("OneTime")}}>OneTime</div>
     </div>
      <div className="educationCalculators">
       {setType === "OneTime" ? <p>One Time (Reguler) ₹</p> : <p>Monthly Investment ₹</p>} 
        <div className="education-calculator">
          <div className="slider-lvh">
            <Slider
              value={monthlyInvestment}
              onChange={MonthlyInvest}
              min={1000}
              max={1000000}
              step={1000}
              aria-label="Monthly Investment"
              valueLabelDisplay="auto"
            />
          </div>
          <div className="value-lvh">
            <input
              type="number"
              value={monthlyInvestment}
              onChange={MonthlyInvest}
            />
          </div>
        </div>
        {/* The Second Section start hear */}

        <p>Expected Annual Return %</p>
        <div className="education-calculator">
          <div className="slider-lvh">
            <Slider
              value={expectedReturn}
              onChange={ExpectedReturnFunction}
              min={1}
              max={15}
              step={1}
              aria-label="Monthly Investment"
              valueLabelDisplay="auto"
            />
          </div>
          <div className="value-lvh">
            <input
              type="number"
              value={expectedReturn}
              onChange={ExpectedReturnFunction}
            />
          </div>
        </div>
        {/* Therd Section start hear */}

        <div>
          <p>Years</p>
          <div className="education-calculator">
            <div className="slider-lvh">
              <Slider
                value={term}
                onChange={HandelTanurefunction}
                min={1}
                max={30}
                step={1}
                aria-label="Current Education Price"
                valueLabelDisplay="auto"
              />
            </div>
            <div className="value-lvh">
              <input
                type="number"
                value={term}
                onChange={HandelTanurefunction}
              />
            </div>
          </div>
        </div>

        <div className="buttoncalculate">
          <button onClick={calculateULIPMaturityValue}>Calculate</button>
        </div>
      </div>
      <div>


        <div className="additnal" hidden={maturityValue === ''}>
<div className="row">


         <div className="col-md-6">

            <div className="calculatemoney">
                <span>You may get</span>
              <h2>{formatter.format(RoundMaturityAmount)}</h2>
              <span>{AmounttoText}</span>
              {setType === "monthly" ?  <span >If you invest  ₹{monthlyInvestment}  &nbsp;

for {term} Years at  {expectedReturn}% p.a.</span> :  <span >If you invest  ₹{monthlyInvestment}</span>}
             
            </div>
     
          </div>

          <div className="col-md-6">
  {
    setType === "Monthly" ? (
      <Chart
        type='donut'
        width={400}
        height={445}
        series={[RoundMaturityAmount, InvestedAmount]}
        options={{
          labels:['Expected Return', 'Investment Amount']
        }}
      />
    ) : (
      <Chart
      type='donut'
      width={400}
      height={445}
      series={[maturityValueNumber, monthlyInvestment*1]}
      options={{
        labels:['Expected Return', 'Investment Amount']
      }}
    />
    )
  }
</div>

        </div>

        </div>



      </div>
      </div>

      <div className="resultse">
      <Calculatorlist/>
      
      </div>

      </div>


    </>
  );
}

export default ULIPCalculator;
