import React, { useEffect, useState } from "react";
import "../../CommonCssFiles/planstyle.css";
import Filter from "./Filter";

import FetchPlan from "./FetchPlan";
import EditDetails from "./EditDetails";
import EditCity from "./EditCity";
import EditPED from "./EditPED";
import Loader from "../../../AllPageComponents/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Redirect from "../../../AllPageComponents/Rederict";
import { RxCross1 } from "react-icons/rx";
import { fetchQuickQuote } from "../../Api/Api";
import { useDispatch } from "react-redux";
import { countUniquePlans } from "../../helper/common";
import Swal from "sweetalert2";
import { IoMdArrowBack } from "react-icons/io";
import { Helmet } from "react-helmet-async";
import { updateQuickQuoteCompanywise } from "../../Api/Api";


// import { getSocket } from "../../../socket"; 
// import { io } from "https://cdn.socket.io/4.8.0/socket.io.esm.min.js";
// import { io } from "https://cdn.socket.io/4.8.1/socket.io.esm.min.js";
// import SocketManager from "../../../socket"

const Plans = ({socketManager}) => {
// const Plans = ({socketManager, isConnected}) => {
  const [apiResponse, setApiResponse] = useState();
  const [planData, setPlanData] = useState([]);
  const [showside, setShowside] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updateLoader, setUpdateLoader] = useState();
  const [nextstep, setNextStep] = useState([true, false, false]);
  const [sidedata, setSidedata] = useState({});
  const [planCount, setPlanCount] = useState();
  const [onloadingData, setOnLoadingData] = useState("");
  const [totalSkeletons, setTotalSkeletons] = useState(5);

  const [selectedCompanies, setSelectedCompanies] = useState("");
  

  const [companyData, setCompanyData] = useState("");

  const [stopLoading, setStopLoading] = useState(false);
  // const [dataSocket, setDataSocket] = useState(null);
  const [loadingPlanCode, setLoadingPlanCode] = useState(null);

  const [arrOrder, setArrOrder] = useState([
    "icici",
    "hdfc",
    "care",
    "reliance",
    "godigit",
  ]);
  // const [socket, setSocket] = useState(null);

  const [targetData, setTargetData] = useState({});

  const navigate = useNavigate();

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");


  const sortInsurancePlans = (data) => {
    const sortedData = {};
    Object.keys(data).forEach(key => {
        sortedData[key] = [...data[key]].sort((a, b) => a.premiumBreakup.totalPremium - b.premiumBreakup.totalPremium);
    });
    return sortedData;
};

  const fetchData = async () => {
    try {
      const response = await fetchQuickQuote(unique_id);
      if (response.data.response == null) {
        setStopLoading(true);

        handleError("Oops there is an Error");
      }

      // --------------------------- show all fetched plans 1 more at a time
      const keys = Object.keys(response.data.response); // Extract keys from the object
      let index = 0;

      // const updateData = () => {
      //   if (index < keys.length && index < 5) { // Ensure max 5 intervals
      //     const partialData = keys.slice(0, index + 1).map((key) => ({
      //       [key]: response.data.response[key],
      //     }));
      //     setPlanData(partialData);
      //     index++;
      //     setTimeout(updateData, 100); // Call again after 200ms
      //   }
      // };
      // ----------------------
      
      // setTimeout(
      //   setPlanData(response.data.response),
      //   100
      // )
      console.log("response.data.response",response.data.response)

      let sortedData = sortInsurancePlans(response.data.response);

      console.log("sortedData", sortedData);

      let apival = {
        request : response.data.request,
        response : sortedData
      }

      // setPlanData(response.data.response); //show all plans at once
      setPlanData(sortedData)
      // setApiResponse(response.data);
      setApiResponse(apival)
      setSidedata(response.data.request);
      // setOnLoadingData(response.data.response);
      setOnLoadingData(sortedData);
      // setCompanyData(response.data.response);
      setCompanyData(sortedData);

      let planNum = countUniquePlans(response.data.response);
      console.log("the plancount in fetchdata", planNum);

      setPlanCount(planNum);
    } catch (error) {
      console.log("Error in plans page data fetching", error);
      setStopLoading(true);

      handleError("Oops there is an Error");
    }
  };

  // --------------------------------------------  test  --------------------------------------------------------

  // function mergeData(targetInfo, incomingData) {

  //   console.log("the targetinfo", targetInfo);
  //   console.log("the incomingdata", incomingData);

  //   if(!targetInfo){
  //     return incomingData;

  //   }
  //   else{
  //     for (const key in incomingData) {
  //       if (Object.hasOwn(incomingData, key)) {
  //         if (targetInfo[key]) {

  //           targetInfo[key] = [...targetInfo[key], ...incomingData[key]];
  //           setTargetData(targetInfo[key]);
  //           return targetInfo
  //         } else {

  //           targetInfo[key] = incomingData[key];
  //           setTargetData(targetInfo[key])
  //           return targetInfo
  //         }
  //       }
  //     }
  //   }

  // }

  function mergeData(targetInfo, incomingData) {
    console.log("the targetInfo", targetInfo);
    console.log("the incomingData", incomingData);

    if (!targetInfo) {
      return incomingData;
    } else {
      for (const insurer in incomingData) {
        if (Object.hasOwn(incomingData, insurer)) {
          // If insurer already exists in targetInfo
          if (targetInfo[insurer]) {
            // Loop through incoming plans
            incomingData[insurer].forEach((newPlan) => {
              const existingPlanIndex = targetInfo[insurer].findIndex(
                (plan) => plan.planCode === newPlan.planCode
              );

              if (existingPlanIndex > -1) {
                // Replace existing plan with the new plan
                targetInfo[insurer][existingPlanIndex] = newPlan;
              } else {
                // Add the new plan if planCode doesn't match any existing plan
                targetInfo[insurer].push(newPlan);
                sortPlansByPremium(targetInfo)
              }
            });
          } else {
            // If insurer does not exist, add new insurer with plans
            targetInfo[insurer] = incomingData[insurer];
            sortPlansByPremium(targetInfo)
          }
        }
      }
      // Optionally update state if using React state management
      setTargetData({ ...targetInfo });
      return targetInfo;
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function rearrangeDataByOrder(updatedData, order) {
    const rearrangedData = {};

    order.forEach((insurer) => {
      if (updatedData[insurer]) {
        rearrangedData[insurer] = updatedData[insurer];
      }
    });

    let keyValues = Object.keys(rearrangedData);
    console.log("the keyValues", keyValues);
    keyValues.map((item, index) => {
      console.log("the key object", rearrangedData[item]);

      rearrangedData[item].map((data, ind) => {
        console.log("the one by one data", data);
      });
    });

    return rearrangedData;
  }

  function sortPlansByPremium(planArr) {
    return Object.keys(planArr).reduce((acc, insurer) => {
      // Sort each insurer's plans by totalPremium
      const sortedPlans = planArr[insurer].sort((a, b) => {
        const premiumA = parseFloat(
          a.premiumBreakup.totalPremium.toString().replace(/,/g, "")
        );
        const premiumB = parseFloat(
          b.premiumBreakup.totalPremium.toString().replace(/,/g, "")
        );
        return premiumA - premiumB;
      });

      // Add sorted plans to accumulator
      acc[insurer] = sortedPlans;
      return acc;
    }, {});
  }


  
  useEffect(() => {
    let orderArr = arrOrder;

  
    socketManager.subscribe('message',  (data) => {
      const { company_name, plan_code, emit_data } = data;
      console.log("----------------------called subscriber------------------")
      console.log("company-name", company_name);
      console.log("plan-code", plan_code);
      console.log("emit-data in plans", emit_data);
      
      console.log("debb",Object.keys(emit_data));

      // Check if emit_data is valid
      if (emit_data) {
        // Handle state updates for planData
        setPlanData((prevData) => {
          const updatedData = mergeData(prevData, emit_data);
          const newObj = rearrangeDataByOrder(updatedData, orderArr);
          const sortedArr = newObj; // Assuming rearrangeDataByOrder returns sorted data

          console.log("the sortedArr", sortedArr);
          return sortedArr;
        });

        setLoadingPlanCode(null);

        // Handle state updates for companyData
        setCompanyData((prevData) => {
          const updatedData = mergeData(prevData, emit_data);
          const newObj = rearrangeDataByOrder(updatedData, orderArr);
          const sortedArr = newObj; // Assuming rearrangeDataByOrder returns sorted data

          console.log("the sortedArr for company", sortedArr);
          return sortedArr;
        });
      }
    });
  }, []);

    
  // useEffect(() => {
  //   let orderArr = arrOrder;
  //   socketManager.subscribe('message',(data) => {
  //     const { company_name, plan_code, emit_data } = data;
  //     console.log("----------------------called subscriber------------------")
  //     console.log("company-name", company_name);
  //     console.log("plan-code", plan_code);
  //     console.log("emit-data", emit_data);
      
  //     console.log("debb",Object.keys(emit_data));

  //     // Check if emit_data is valid
  //     if (emit_data) {
  //       // Handle state updates for planData
  //       setPlanData((prevData) => {
  //         const updatedData = mergeData(prevData, emit_data);
  //         const newObj = rearrangeDataByOrder(updatedData, orderArr);
  //         const sortedArr = newObj; // Assuming rearrangeDataByOrder returns sorted data

  //         console.log("the sortedArr", sortedArr);
  //         return sortedArr;
  //       });

  //       // Handle state updates for companyData
  //       setCompanyData((prevData) => {
  //         const updatedData = mergeData(prevData, emit_data);
  //         const newObj = rearrangeDataByOrder(updatedData, orderArr);
  //         const sortedArr = newObj; // Assuming rearrangeDataByOrder returns sorted data

  //         console.log("the sortedArr for company", sortedArr);
  //         return sortedArr;
  //       });
  //     }
  //   });
  // }, []);

  useEffect(() => {
    let data = countUniquePlans(planData);
    setPlanCount(data);
  }, [planData]);

  if (!apiResponse && stopLoading == false) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else if (apiResponse ? apiResponse.response == null : "") {
    setStopLoading(true);

    handleError("Oops there is an Error !");
  }

  function getFirstName(fullName) {
    // Split the full name by spaces
    const nameParts = fullName.split(" ");

    // Return the first part of the name
    return nameParts[0];
  }

  const handleClicknextstep = (stepNumber) => {
    const updatedNextStep = [...nextstep];
    if (stepNumber === 1) {
      updatedNextStep[0] = false;
      updatedNextStep[1] = true;
      updatedNextStep[2] = false;
      updatedNextStep[3] = false;
      updatedNextStep[4] = false;
    } else if (stepNumber === 2) {
      updatedNextStep[0] = false;
      updatedNextStep[1] = false;
      updatedNextStep[2] = true;
      updatedNextStep[3] = false;
      updatedNextStep[4] = false;
    } else if (stepNumber === 3) {
      updatedNextStep[0] = false;
      updatedNextStep[1] = false;
      updatedNextStep[2] = false;
      updatedNextStep[3] = true;
      updatedNextStep[4] = false;
    } else if (stepNumber === 4) {
      updatedNextStep[0] = true;
      updatedNextStep[1] = false;
      updatedNextStep[2] = false;
      updatedNextStep[3] = false;
      updatedNextStep[4] = true;
    }
    setNextStep(updatedNextStep);
  };

  const handelsidebar = () => {
    setShowside(true);
  };

  const updateCompanyData = async (value, plancode, planname, insurerData) => {
    try {
      // setPlanData({})
      // setIsLoading(true);
      let payload = {
        ...apiResponse.request,
        sum_insured: value,
        PlanCode: plancode,
        CompanyName: planname,
      };

      const response = await updateQuickQuoteCompanywise(
        payload,
        unique_id
      ).then((response) => {
     
      });
    } catch (error) {
      console.error("Error updating company data:", error);
    }
  };

  const handleUpdatedData = async () => {

    let payload = {
      ...apiResponse.request,
      InsuredDetail:localStorage.getItem('InsuredDetail'),
      PlanCode: plan_id,
      CompanyName: c_id,
    };

    const response = await updateQuickQuoteCompanywise(
      payload,
      unique_id
    ).then((response) => {
     
    
    });
    setUpdateLoader(false);
  };



  const isConnected = ()=>{
    let data = socketManager.isConnected();
    console.log("is sicket connected", data);
    return data;
  }



  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {stopLoading == false && (
        <>
          <Helmet>
            <title>Healthinsurance plans</title>

            {/* <meta name="description" content="A brief description of your page for SEO." /> */}
            <meta
              name="keywords"
              content="Insurance Plan, Insurance Quotes, buy insurance"
            />
          </Helmet>

          <div className="container hight">
            <div className="backdivse">
              <button onClick={goBack} className="backbuttontwo">
                <IoMdArrowBack /> &nbsp; Back to Step4
              </button>
            </div>
            <div className="row">
              <div className="centerdiv ">
                <div className="edit">
                  <div className="d-flex justify-content-between showbarresult">
                    <p className="editp">
                      {" "}
                      {planCount} plan{planCount !== 1 ? "s" : ""} found for{" "}
                      {getFirstName(apiResponse.request.ProposalName)} at{" "}
                      {apiResponse.request.District
                        ? apiResponse.request.District
                        : ""}
                      {`(${
                        apiResponse.request.PinCode
                          ? apiResponse.request.PinCode
                          : ""
                      })`}
                    </p>
                    <button
                      onClick={() => {
                        handelsidebar();
                      }}
                      className="editb"
                    >
                      {" "}
                      <i
                        className="fa fa-pencil-square-o mx-2"
                        aria-hidden="true"
                      ></i>
                      Edit Details
                    </button>
                  </div>
                </div>
              </div>
              <Filter
                apiResponse={apiResponse}
                setApiResponse={setApiResponse}
                planData={planData}
                setPlanData={setPlanData}
                onloadingData={onloadingData}
                companyData={companyData}
                setCompanyData={setCompanyData}
                totalSkeletons={totalSkeletons}
                setTotalSkeletons={setTotalSkeletons}
                selectedCompanies={selectedCompanies}
                setSelectedCompanies={setSelectedCompanies}
                isConnected={isConnected}

              />
            </div>
          </div>

          <div className="container">
            <FetchPlan
              apiResponse={apiResponse}
              setApiResponse={setApiResponse}
              planData={planData}
              setPlanData={setPlanData}
              totalSkeletons={totalSkeletons}
              setTotalSkeletons={setTotalSkeletons}
              loadingPlanCode={loadingPlanCode}
              setLoadingPlanCode={setLoadingPlanCode}
              isConnected={isConnected}
            />
          </div>

          <div
            className={`container ${
              showside ? "fixedsidebar" : "fixedsidebar-off"
            }`}
            style={{ zIndex: "1200" }}
          >
            <div className="row pt-5">
              <div className="col-10">
                <h1 className="h1"> Edit Your Search</h1>
              </div>

              <div className="col-2">
                {/* <h1 onClick={handelsidebar}><i class="fa fa-window-close" aria-hidden="true"></i></h1> */}
                <RxCross1
                  style={{ height: "30px", width: "30px" }}
                  onClick={() => {
                    setShowside(false);
                  }}
                />
              </div>
            </div>
            <hr className="edit-line"></hr>
            <div className={!nextstep[0] ? "d-none" : "d-block"}>
              <div
                className="row inscard align-items-center"
                onClick={() => {
                  handleClicknextstep(1);
                }}
              >
                <div className="col-2">
                  <div className=" text-center ">
                    <i class="fa fa-user-o inscardicon" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="col-8">
                  <p className="m-0">
                    <span className="inscardp">Insured member</span> <br />
                    {Array.isArray(sidedata?.InsuredDetail) ? (
                      sidedata.InsuredDetail.map((detail, index) => (
                        <span key={index}>
                          {detail.InsuredRelation} ({detail.InsuredAge})
                        </span>
                      ))
                    ) : (
                      <span>
                        {sidedata?.InsuredDetail?.InsuredRelation} (
                        {sidedata?.InsuredDetail?.InsuredAge})
                      </span>
                    )}
                  </p>
                </div>
                <div className="col-2">
                  <i
                    class="fa fa-chevron-right faaroc10px"
                    aria-hidden="true"
                    style={{
                      fontSize: "15px",
                      fontWeight: "100",
                      color: "#0055a5",
                    }}
                  ></i>
                </div>
              </div>

              <div
                className="row inscard align-items-center"
                onClick={() => {
                  handleClicknextstep(2);
                }}
              >
                <div className="col-2">
                  <div className="insurmemicon text-center ">
                    <i
                      class="fa fa-map-marker inscardicon"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="col-8">
                  <p className="m-0">
                    <span className="inscardp">City</span> <br />
                    {sidedata?.District &&
                      sidedata?.State &&
                      `${sidedata.District} , ${sidedata.State}`}
                  </p>
                </div>
                <div className="col-2">
                  <i
                    class="fa fa-chevron-right faaroc10px"
                    aria-hidden="true"
                    style={{
                      fontSize: "15px",
                      fontWeight: "100",
                      color: "#0055a5",
                    }}
                  ></i>
                </div>
              </div>

              <div
                className="row inscard align-items-center"
                onClick={() => {
                  handleClicknextstep(3);
                }}
              >
                <div className="col-2">
                  <div className=" text-center  ">
                    <i
                      class="fa fa-stethoscope inscardicon"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="col-8">
                  <p className="m-0 ">
                    <span className="inscardp">Existing Illness</span>
                    <br />
                    <span>
                      {Array.isArray(sidedata?.PED) &&
                        sidedata.PED.map((item, index) => (
                          <span key={index}>{item} , </span>
                        ))}
                    </span>
                  </p>
                </div>
                <div className="col-2">
                  <i
                    class="fa fa-chevron-right faaroc10px"
                    aria-hidden="true"
                    style={{
                      fontSize: "15px",
                      fontWeight: "100",
                      color: "#0055a5",
                    }}
                  ></i>
                </div>
              </div>
              <div className="buttons-container">
                {/* <button
              className="updateplanbutton"
              onClick={() => handleUpdatedData()}
            >
              View Updated Plans
            </button> */}
              </div>
            </div>

            {/* nextstep 1st option */}

            {/* nextstep 1st option */}
            <div className={!nextstep[1] ? "d-none" : "d-block"}>
              <div className="row nextmember align-items-center">
                <EditDetails
                  handleUpdatedData={handleUpdatedData}
                  showSide={showside}
                  onBack={() => handleClicknextstep(4)}
                  setShowside={setShowside}
                  setApiResponse={setApiResponse}
                  setPlanData={setPlanData}
                  setSidedata={setSidedata}
                  apiResponse={apiResponse}
                  isConnected={isConnected}
                />
              </div>
            </div>
            {/* nextstep 2nd option */}
            <div className={!nextstep[2] ? "d-none" : "d-block"}>
              <div className="row nextmember align-items-center">
                <EditCity
                  showSide={showside}
                  onBack={() => handleClicknextstep(4)}
                  setShowside={setShowside}
                  setPlanData={setPlanData}
                  setSidedata={setSidedata}
                  setApiResponse={setApiResponse}
                  apiResponse={apiResponse}
                  isConnected={isConnected}
                />
              </div>
            </div>
            {/* nextstep 3rd option */}
            <div className={!nextstep[3] ? "d-none" : "d-block"}>
              <div className="row  align-items-center">
                <EditPED
                  onBack={() => handleClicknextstep(4)}
                  setApiResponse={setApiResponse}
                  apiResponse={apiResponse}
                  setSidedata={setSidedata}
                  setShowside={setShowside}
                  setPlanData={setPlanData}
                  isConnected={isConnected}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Plans;