import React, { useState } from "react";
import { TextField, Box, Typography, Grid } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./AnswerSetFive.css"; // Importing the CSS file
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import {
  updateRelianceQAlist,
  selectReliancecheckedQuestion,
} from "../../redux/Slice";

const AnswerSetFive = ({ pindex, qdata, parentqData ,
  updateRelianceQAlist,
  ReliancecheckedQuestion,
  setRelianceCheckedQuestio
 }) => {
  const [diagnose, setDiagnose] = useState("");
  const [consultationDate, setConsultationDate] = useState("");

  const [exactData, setExactData] = useState();
  const [hospitaldata, setHospitalData] = useState();
  const [pastData, setPastData] = useState();

  const [dataObj, setDataObj] = useState();

  // const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  console.log("the answer set one data", ReliancecheckedQuestion);

  console.log("the pindex", pindex);

  console.log("the q data in set one", qdata);

  useEffect(() => {
    if (ReliancecheckedQuestion) {
      console.log("hdfc checked question", ReliancecheckedQuestion);
      const keys = Object.keys(ReliancecheckedQuestion);
      const numbersToCheck = [5];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => ReliancecheckedQuestion[number.toString()]);

      console.log("the resultObjects", resultObjects);
      let val;

      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex)) {
          let data_one = resultObjects[key][pindex];
          // console.log("data_one",qdata.question_id)
          let val = data_one.find(
            (item) => item.Options[0].OptionId == qdata.QuestionSetCode
          );
          if (val != undefined) {
            console.log("val in for", val);
            let newobject = val;
            console.log("the newObject", newobject);

            if (newobject) {
              newobject.Options.map((item, index) => {
                if (item.OptionId == qdata.QuestionSetCode) {
                  setDataObj(item);
                  return;
                }
              });
            }
          }
        }
      }
    }
  }, [ReliancecheckedQuestion]);

  console.log("the final data in set five", dataObj);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("/");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };
  const parseDate = (dateStr) => {
    const dateParts = dateStr.split("/");
    // Check if the parts are valid
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // month is 0-indexed
      const year = parseInt(dateParts[2], 10);
      return new Date(year, month, day);
    }
    return null; // Handle invalid date format
  };

   useEffect(() => {
    if (dataObj && dataObj.SufferingSince) {
      const initialDate = parseDate(dataObj.SufferingSince);
      if (initialDate) {
        setExactData(initialDate);

      }
      if(dataObj && dataObj.ConsultationDate){
        let consultData = parseDate(dataObj.conConsultationDate)

        if(consultData){
          setConsultationDate(consultData)
        }
      }

    }
  }, []);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };



  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const convertMMDate = (dateString) =>{
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${month}/${year}`;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="set-five-body">
        {/* <Typography className='set-five-body-title'>
          Q. Hypertension/ High blood pressure
        </Typography> */}

        <Grid container spacing={2} className="lower-set-five-answers">
          <Grid item xs={6}>
            <TextField
              label="Exact Diagnosis"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              onClick={(e) => {
                handleClick(e);
              }}
              value={exactData ? exactData : dataObj?.ExactDiagnosis || ""}
              onChange={(e) => {
                setExactData(e.target.value);
                // dispatch(
                  updateRelianceQAlist({
                    name: "ExactDiagnosis",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                // );
              }}
            />
          </Grid>
          {/* <Grid item xs={6} className='set-five-date-upper'>
            <DesktopDatePicker
         
            InputLabelProps={{ shrink: true }}
              label="Diagnosis Date"
              inputFormat="MM/yyyy"
              value={sufferingSince}
              onChange={(newValue) => setSufferingSince(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth className='bordered-textField' />}
            />
          </Grid> */}
          <Grid
            item
            xs={6}
            className="set-five-date-upper"
            onClick={(event) => {
              handleClick(event);
            }}
          >
            {/* <DesktopDatePicker
              label="Diagnosis Date"
              inputFormat="MM/yyyy"
     
              value={diagnose}
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(newValue) => {
                setDiagnose(newValue);
                dispatch(
                  updateRelianceQAlist({
                    name: "DiagnosisDate",
                    value: formatDate(newValue),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className="bordered-textField"
                  // InputLabelProps={{ shrink: true }} // Ensuring the label is always visible
                />
              )}
            /> */}

<Flatpickr
              className="flat-date-five"
              value={diagnose ? diagnose : dataObj?.DiagnosisDate}
              options={{
                enableTime: false, // Disable the time picker
                dateFormat: "d/m/Y", // Set the desired date format
              }}
              placeholder="Diagnosis Date"
              onChange={(newValue) => {
                setDiagnose(newValue);

                // dispatch(
                  updateRelianceQAlist({
                    name: "DiagnosisDate",
                    value: convertMMDate(newValue[0]),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                // );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Hospital Name"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              value={hospitaldata ? hospitaldata : dataObj?.HospitalName || ""}
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(e) =>
             {
              setHospitalData(e.target.value)
              // dispatch(
                updateRelianceQAlist({
                  name: "HospitalName",
                  value: e.target.value,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                })
              // )
             }
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            className="set-five-date lower-date"
            onClick={(event) => {
              handleClick(event);
            }}
          >
            {/* <DesktopDatePicker
              defaultValue=""
              label="Consultation Date"
              inputFormat="MM/yyyy"
              value={consultationDate}
              // onChange={(newValue) => setConsultationDate(newValue)}
              onChange={(newValue) => {
                setConsultationDate(newValue);
                dispatch(
                  updateRelianceQAlist({
                    name: "ConsultationDate",
                    value: formatDate(newValue),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // InputLabelProps={{ shrink: true }}
                  fullWidth
                  className="bordered-textField"
                />
              )}
            /> */}

<Flatpickr
          
          style={{marginTop:"6px"}}
          className="flat-date-five"
          value={consultationDate ? consultationDate : dataObj?.ConsultationDate}
          options={{
            enableTime: false, // Disable the time picker
            dateFormat: "d/m/Y", // Set the desired date format
          }}
          placeholder="Consultation Date"
          onChange={(newValue) => {
            setConsultationDate(newValue);
            console.log("the array format data,",convertDate(newValue[0]));
            // dispatch(
              updateRelianceQAlist({
                name: "ConsultationDate",
                value: convertMMDate(newValue[0]),
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              })
            // );
          }}
        />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Details of your Past Medical Conditions"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              value={pastData ? pastData : dataObj?.DetailsOfYourPastMedicalCondition || ""}
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(e) =>
              {
                setPastData(e.target.value)
                // dispatch(
                  updateRelianceQAlist({
                    name: "DetailsOfYourPastMedicalCondition",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                // )
              }
              }
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default AnswerSetFive;
