import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader";
import axios from "axios";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

import {
  selectUserData,
  updatedUserdata,
  updatedLoginPopup,
  selectLoginForm,
  selectOtpForm,
  updatedOtpPopup,
  updatedPhone,
} from "../Health/redux/Slice";
const Login = ({ setShowLoginPop, showLoginPop }) => {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // Is Loading State When The Otp is Submited then the Loader is Working........

  const [isloadingpage, setisLoadingpage] = useState(false);

  // This is For The Showing gmail for Forgian Country

  const [showmail, setShowmail] = useState(91);
  const nav = useNavigate();

  const countrycheck = (data) => {
    const Datavalue = data.target.value;
    Datavalue === "91" ? setShowmail(true) : setShowmail(false);
  };

  // ////////////////////////////////////////////////////////////////////

  const handleCloseBtn = () => {
    //start hiding popup body, but not hide display container
    setShowLoginPop(false);

    //after some time display none for popup container
    setTimeout(() => {
      setShowLoginPop(false);
    }, 400);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendOtp();
    }
  };

  const closeModal = () => {
    dispatch(updatedLoginPopup(false));
  };
  const sendOtp = () => {
    if (phone === "") {
      toast.error("Number is Requird", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    setisLoadingpage(true);
    let data = JSON.stringify({
      mobileNumber: phone,
      email: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + "user/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        closeModal();
        dispatch(updatedOtpPopup(true));
      })
      .catch((error) => {
        //console.log(error);
        closeModal();
        setisLoadingpage(false);
      });
  };

  return (
    <>
      <div className="loader-container">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close loginMobileClose"
                onClick={(e) => {
                  closeModal();
                  setShowLoginPop(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="login-modal">
              <div className="modal-body">
                <div className="new-login-area">
                  <div id="sign-in" className="login-setup-section">
                    <h3 className="request-otp-header">
                      Login with mobile number
                    </h3>
                    <div className="form-group login-label">
                      <div className="custom-select-login">
                        <div className="select-cus">
                          <select
                            name="country"
                            id="country"
                            className="form-controls"
                            onChange={countrycheck}
                          >
                            <option value="966">+966</option>
                            <option value="91" selected={true}>
                              +91
                            </option>
                            <option value="47">+47</option>
                            <option value="46">+46</option>
                            <option value="79">+79</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="input-edit"
                          placeholder="Mobile Number"
                          id="userNumber"
                          name="userNumber"
                          maxLength="10"
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1");
                          }}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            dispatch(updatedPhone(e.target.value));
                          }}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                      <label
                        id="mobileNumberVal"
                        className="error"
                        style={{ display: "none" }}
                      ></label>
                      {showmail ? null : (
                        <input
                          type="email"
                          className="emailtop"
                          placeholder="Email"
                          id="emailId"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      )}

                      <label
                        id="emailVal"
                        className="error"
                        style={{ display: "none" }}
                      ></label>
                    </div>

                    <button
                      type="button"
                      onClick={() => sendOtp()}
                      className="btn btn-default btn-lg btn-block request-otp blue-btn finalfrmsubt"
                      id="userNumberButton"
                    >
                      <span>
                        {isloadingpage
                          ? "Connecting To Server...."
                          : "Send OTP"}
                      </span>
                      {isloadingpage ? null : (
                   

                        <FastImage
                  url={"Website%20Img/button-arrow.png?updatedAt=1718712281133"}
                  width={auto}
                  height={auto}
                  alt={"arrow"}
                  src={"Website%20Img/button-arrow.png?updatedAt=1718712281133"}
                />
                      )}
                    </button>

                    <div className="terms-conditions">
                      <p>
                        I agree to{" "}
                        <a href="{{ route('terms-conditions') }}">
                          terms & conditions
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <span className="bg-img-beema">
             
                <FastImage
                  url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                  width={auto}
                  height={auto}
                  alt={"popup"}
                  src={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
export default Login;
