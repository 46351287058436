import React,{useEffect, useState} from 'react';
import PartnerCompany from '../../../../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../../../../AllPageComponents/FAQ/FAQ';
import { Link } from 'react-router-dom';
import FastImage from '../../../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import Redirect from '../../../../AllPageComponents/Rederict';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { Helmet } from 'react-helmet-async';

const SeniorCitizenInsurance = () => {

  const initialText =
    'With Beema1, get top-rated health insurance plans online. You can compare online and buy the most suitable insurance plan for you and your family. You can also get your queries answered by our team of experts.';

  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };



  const SeniorPlane = [
    {id:0,Q:"Medical Coverage to Elderly People" ,A:'Senior citizens aged 60 and above get specialized health coverage through senior citizen health insurance plans. While typical family health insurance plans typically cover individuals up to the age of 65, these plans cater specifically to the needs of older adults'},
    {id:1,Q:'Home treatments are well-funded',A:"Imagine the luxury of receiving healthcare right in the comfort of your own home, surrounded by loved ones. This is what you will get in a senior citizen health insurance plan. This is when the treatment is taken at home instead of being hospitalized."},
    {id:2,Q:"Providing financial support to manage rising medical inflation over time",A:"Over the past ten years, we've seen a significant surge in medical costs, and there's no sign of that slowing down in the next decade. With the looming financial strain that healthcare needs can bring, it's crucial to secure funding that won't drain your life savings, especially during retirement when your income may be limited."},
    {id:3,Q:"Health assessment before insurance approval",A:"Unlike standard plans, most health insurance policies for seniors typically don't necessitate medical screenings before purchase."},
    {id:4,Q:"Cashless Treatment",A:"Senior Citizens can enjoy cashless medical treatment at the insurance company's network hospitals, provided they get admitted for a minimum of 24 hours."},
    {id:5,Q:"Reward for no insurance claims",A:"Senior citizens receive a No Claim Bonus, granting them a discount on renewal premiums for every year without a claim. This discount can vary from 20% to 100% in certain plans."},
    {id:6,Q:"Make sizeable claims for tax deductions",A:"Hey there, did you realize that even though senior citizen medical insurance plans might come with a slightly higher premium due to your age hitting the 60-year mark, there's a silver lining? You're eligible for a higher tax deduction on your annual premium. If you need a hand navigating those tax claims for your health insurance, we've got you covered"},

  ]

const Seniorcitizencover = [
  {
      "id": 1,
      "Q": "Hospitalization Expenses:",
      "A": "Hospitalization expenses, including room charges, doctor fees, nursing fees, cost of medicine and drugs, ICU charges, cost of surgical appliances, and operation theatre charges are covered under this plan. Additionally, it covers the cost of artificial limbs, orthopedic implants, prosthetic devices, and diagnostic tests required during hospitalization."
  },
  {
      "id": 2,
      "Q": "Day Care Treatments:",
      "A": "Treatments that require less than 24 hours of hospitalization, such as chemotherapy and dialysis, are covered under most plans."
  },
  {
      "id": 3,
      "Q": "Pre and Post-hospitalization Expenses:",
      "A": "Medical expenses incurred before hospitalization and after discharge are included in the policy."
  },
  {
      "id": 4,
      "Q": "COVID-19 Cover:",
      "A": "The policy covers the cost of coronavirus treatment up to a specified limit."
  },
  {
      "id": 5,
      "Q": "Organ Donor Expenses:",
      "A": "Donor expenses for organ transplants are covered by most mediclaim policies."
  },
  {
      "id": 6,
      "Q": "Ambulance Charges:",
      "A": "The policy covers ambulance costs for transporting the insured to the nearest hospital, up to a specified limit."
  },
  {
      "id": 7,
      "Q": "Pre-existing Diseases:",
      "A": "Coverage for pre-existing conditions is provided after a fixed waiting period."
  },
  {
      "id": 8,
      "Q": "AYUSH Treatment Cost:",
      "A": "Some policies cover AYUSH treatments (Ayurveda, Yoga, Unani, Siddha, and Homeopathy) taken in government-recognized hospitals or institutes."
  },
  {
      "id": 9,
      "Q": "Domiciliary Hospitalization Cover:",
      "A": "If home treatment is recommended by a doctor, the insurance company will cover the domiciliary hospitalization expenses."
  }
]




useEffect(() => {
  window.scrollTo(0, 0);
}, []);



  return (
    <>
            <Helmet>
        <title>Get medical Insurance For Senior Citizens</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="senior citizen insurance, Insurance For Senior Citizens, Mediclaim Policy For Senior Citizens" />
      </Helmet>
    <section className="car-insurance insurance-all main-health mainnargin-insurance">
  <div className="container">
    <Redirect/>
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one ">
        <div className="healthdiscription">
          {/* <h1>Health Insurance Plans for Senior Citizens</h1> */}
          <h1>Top Senior Citizen Insurance Policy with extra benifits </h1>
       <div>
      <span style={{ textAlign: 'justify' }}>
        {initialText}
      </span>
    </div>

        </div>
        <div className="insurance-btn-set">
          <button
            type="button"
            className="reminderbutton"
            data-toggle="modal"
            data-target="#reminderpopup"
          >
            {" "}
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              fontSize="15pt"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "rgb(251, 167, 27)", margin: "0px 5px" }}
            >
              <circle cx={12} cy={13} r={8} />
              <path d="M12 9v4l2 2" />
              <path d="M5 3 2 6" />
              <path d="m22 6-3-3" />
              <path d="M6.38 18.7 4 21" />
              <path d="M17.64 18.67 20 21" />
            </svg>{" "}
            Set Reminder
          </button>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
               <FastImage
                  url={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                  width={auto}
                  height={auto}
                  alt={"arrow"}
                  src={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                />
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
        <div className="form-main">
          <form id="insurance_form_get_plan">
            <div className="d-flex align-items-center form-input">
              <h6>
                Compare &amp; Buy Customized Health Plans starting at just
                <span className='specialprice'>Rs ₹257/month*</span>
              </h6>
              <div className="blue-btn">
              <Link to={"/healthStep1"}>
               
               <button
                 style={{
                   backgroundColor: "transparent",
                   border: "none",
                   color: "white",
                   fontWeight: 400
                 }}
               >
                 Get Your Quotes
               </button>{" "}
               <img alt="" src="" className="" />
      
             </Link>
                
              </div>
              <p className="insurance-text-light">
                By clicking, I agree to{" "}
                <a href="/termsandcondition">*terms &amp; conditions</a> and{" "}
                <a href="/privacy-policy">privacy policy</a>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<div className="inbulitmargine"></div>


<div className="fixwidthDivtext">


<div className="container">
<h5 className='healthHeading'>What is Senior Citizen Health Insurance?</h5>
<div className="yellowline"></div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center">   
                <p className="normaltext">
            
            Senior Citizen Health Insurance is designed specifically for individuals over 60, covering a wide range of medical expenses. These plans include preventive health check-ups and cashless hospitalization at network hospitals. They provide coverage for pre-existing conditions, critical illnesses, hospitalization costs, and both pre and post-hospitalization expenses, including coronavirus treatment.Senior citizens deserve the security of health insurance as they navigate the challenges of aging. Dealing with a sudden hospitalization for your parents can be incredibly tough, both emotionally and financially. 
            </p></div>
      
              </div>
            </div>
            </div>




    
       
        <div className="fixwidthDivtext">
        <div className="container">
        <h4 className='healthHeading'>Best Senior Citizens Medical Insurance Plans in India</h4>
        <div className="yellowline"></div>
          <div className="row">
            <div className="col-md-12">
            <p className="normaltext">
            With so many senior citizen health insurance  options available, finding the right one can be challenging. To make your decision easier, we’ve handpicked the top 5 health insurance plans for seniors, each packed with fantastic benefits to ensure you get the best coverage.
            </p>
            </div>
          </div>
        </div>
            </div>


            
<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>  Senior Citizen Health Insurance Plans Features</h4>
<div className="yellowline"></div>
<p className="normaltext">
The table below highlights the key features of the top senior citizen health insurance plans available on Beema1.com:
</p>
              <div className="row">
                <div className="col-md-12">

                <div className="tebildivs">
<table className="seniorsCitizenInsu-table">
  <thead>
    <tr className="seniorsCitizenInsu-header">
      <th className="seniorsCitizenInsu-headerCell">Categories</th>
      <th className="seniorsCitizenInsu-headerCell">Specifications</th>
    </tr>
  </thead>
  <tbody>
    {[
      { category: "Minimum Entry Age", specification: "No age limit" },
      { category: "Maximum Entry Age", specification: "100.00%" },
      { category: "Cashless Claims", specification: "Available at network hospitals" },
      { category: "Preventive Health Check-ups", specification: "Available" },
      { category: "Star Health and Allied Insurance Co. Ltd.", specification: "89.90%" },
      { category: "Renewability", specification: "Lifetime" },
      { category: "Zuno General Insurance Company Ltd.", specification: "94.00%" },
      { category: "Long-Term Policy Benefit", specification: "Up to 12.5% savings on a 2-year plan" },
      { category: "Tax Benefits", specification: "Up to Rs 75,000 in a financial year (Under section 80D of the IT Act)" },
    ].map((item, index) => (
      <tr key={index} className="seniorsCitizenInsu-row">
        <td className="seniorsCitizenInsu-cell">{item.category}</td>
        <td className="seniorsCitizenInsu-cell">{item.specification}</td>
      </tr>
    ))}
  </tbody>
</table>


</div>
                </div>
              </div>
            </div>
</div>
           


      <div className="fixwidthDivtext">
<div className="container">

<h4 className='healthHeading'>
Benefits of Senior Citizen Health Insurance Plans
</h4>
<div className="yellowline"></div>
<p className="normaltext">
The table below highlights the key features of the top senior citizen health insurance plans available on Beema1.com:
</p>
  <div className="row">
  <div className="col-md-6 col-12 d-flex justify-content-center align-items-center"> 
<FastImage url='/Website%20Img/Active%20elderly%20people-bro.png?updatedAt=1721732083105' width={auto} height={auto} className='fmailimagegifs'/>
    </div>
    <div className="col-md-6 d-flex justify-content-center align-items-center">


<div>


{
  SeniorPlane.map((data,index)=>{
    return(
      <>
         <Accordion expanded={expanded === `panel${data.id}`} onChange={handleChange(`panel${data.id}`)}>
      <AccordionSummary
        expandIcon={<MdOutlineExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        className="accordingback"
      >
        <div>
        <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
        </div>
 <div>
 <li className='listcol'>
       
       {data.Q}
     </li>
 </div>
 
      </AccordionSummary>
      <AccordionDetails>
      <p className="normaltext">{data.A}</p>
      </AccordionDetails>
    </Accordion>
      </>
    )
  })
}

</div>

    </div>
  </div>
</div>
</div>  





<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Why Do You Need Health Insurance Plans for Senior Citizens?</h4>
<div className="yellowline"></div>
<p className="normaltext">Senior citizens deserve the security of health insurance as they navigate the challenges of aging. Dealing with a sudden hospitalization for your parents can be incredibly tough, both emotionally and financially. That's where health insurance for parents steps in, providing a safety net to handle the financial strain of medical emergencies. Let's dive into some more compelling reasons why investing in a senior citizen health insurance plan is a wise move:</p>
  <div className="row">


  <div className="col-md-6 mb-2">
  <div className="boxstepseniour">
  <p className="normaltext">
  <span className='specialprice'>1.Tackling Rising Costs: &nbsp;</span>
  
  
  With medical expenses skyrocketing due to inflation, having a senior citizen health insurance policy is essential. It ensures elderly folks can afford the best healthcare when they need it most.
  </p>
  </div>
  </div>



  <div className="col-md-6 mb-4">
  <div className="boxstepseniour1">
  <p className="normaltext">
  <span className='specialprice'>2. Critical Illness Protection: &nbsp;</span>
  
  
  As we age, the risk of critical illnesses like heart disease and cancer looms larger. A senior citizen health insurance plan offers coverage for these serious conditions, either as part of the base policy or as an optional add-on.
  </p>
 </div>
  </div>

  <div className="col-md-6 mb-2">
  <div className="boxstepseniour2">
  <p className="normaltext">
  <span className='specialprice'>3. Annual Check-ups: &nbsp;</span>
  
  
  Many senior citizen health plans include complimentary annual health check-ups. This perk not only promotes proactive healthcare but also helps minimize out-of-pocket expenses for routine screenings.
</p>
 </div>
  </div>
  <div className="col-md-6 mb-2">
  <div className="boxstepseniour3">
  <p className="normaltext">
  <span className='specialprice'>4. Peace of Mind: &nbsp;</span>
  
  
  Hospital bills can be overwhelming, especially for seniors facing both minor and major health issues. Having a health insurance policy provides reassurance, allowing elderly individuals to focus on their well-being without worrying about financial strain.
</p>
 </div>
  </div>

  </div>
</div>
</div>


<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>What is Covered in a Health Insurance Plan for Senior Citizens?</h4>
<div className="yellowline"></div>
<p className="normaltext">
Here are some common coverages that you will get when you buy senior citizen health insurance plans:
</p>
  <div className="row">
    <div className="col-md-6 d-flex justify-content-center align-items-center">
      <div>


    {
  Seniorcitizencover.map((data,index)=>{
    return(
      <>
         <Accordion expanded={expanded === `panel${data.id}`} onChange={handleChange(`panel${data.id}`)}>
      <AccordionSummary
        expandIcon={<MdOutlineExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        className="accordingback"
      >
        <div>
        <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
        </div>
 <div>
 <li className='listcol'>
       
       {data.Q}
     </li>
 </div>
 
      </AccordionSummary>
      <AccordionDetails>
      <p className="normaltext">{data.A}</p>
      </AccordionDetails>
    </Accordion>
      </>
    )
  })
}
</div>
    </div>
    <div className="col-md-6 d-flex justify-content-center align-items-center">
      <FastImage url='/Website%20Img/Hospital%20patient-pana.png?updatedAt=1727426253729' width={auto} height={auto} className='fmailimagegifs'/>
    </div>
  </div>
</div>
</div>





<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>How to Choose The Best Senior Citizen Health Insurance Plan?</h4>
<div className="yellowline"></div>
 <p className='textformat'>Choosing the perfect health insurance policy for senior citizens can feel overwhelming with so many options available. Simplify your decision by keeping these key points in mind:</p>
  <div className="row">
    <div className="col-md-12">
    <p className='textformat'>
  <span className='specialprice'>
  Know the medical history:
  </span>
  Understanding the medical history of your parents or the senior citizens you wish to insure is vital. They may not fully know their health conditions, so it's important to double-check their medical records before providing this information to the insurance company. This ensures accurate coverage and avoids potential issues down the line.
</p>

 <p className='textformat'>
  <span className='specialprice'>
  Compare and choose a plan:
  </span>
  There are many senior citizen health insurance plans available in India, and making the right choice can be difficult. It's crucial to compare your options carefully. Look at the inclusions, exclusions, available riders/add-ons, and premiums to ensure you make a well-informed decision that best suits your needs
</p>


 <p className='textformat'>
  <span className='specialprice'>
  Fill out the proposal form:
  </span>
  Make sure you fill out the proposal form with accurate and relevant details. Mistakes can result in your application being rejected or cause problems when making future claims.
</p>


 <p className='textformat'>
  <span className='specialprice'>
  Do a medical check-up:
  </span>
  Once you've submitted the proposal form, a medical check-up is the next step. Doctors will review the provided health information and perform essential tests, including sugar levels, urine routine, sonography, and liver and kidney function assessments.
</p>
    </div>
  </div>
</div>
</div>





<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default SeniorCitizenInsurance