import React,{useEffect, useState} from 'react';
import PartnerCompany from '../../../../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../../../../AllPageComponents/FAQ/FAQ';
import FastImage from '../../../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import Redirect from '../../../../AllPageComponents/Rederict';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { MdExpandMore } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { CiCircleChevRight } from "react-icons/ci";
import HealthBenifits from '../../HealthInsurePage/HealthBenifits/HealthBenifits';
import { Helmet } from 'react-helmet-async';


const HealthCompareQuotes = () => {

  const initialText =
    'With Beema1, get top-rated health insurance plans online. You can compare online and buy the most suitable insurance plan for you and your family. You can also get your queries  promptly answered by our team of experts.';

  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };





  return (
    <>
         <Helmet>
        <title>Unbiased health insurance quote from top companies</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Compare Health insurance Quotes, Top Health cover quote, Best mediclaim quotes" />
      </Helmet>
<section className="car-insurance insurance-all main-health mainnargin-insurance">
  <div className="container">
<Redirect/>
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one ">
        <div className="healthdiscription">
          {/* <h1>Compare & Buy Health Insurance, Get Quotes from Top Companies</h1> */}
          <h1>Unbiased health insurance quote from top companies</h1>
          <div>
      <span style={{ textAlign: 'justify' }}>
        {initialText}
      </span>
    </div>
        </div>
        <div className="insurance-btn-set">
          <button
            type="button"
            className="reminderbutton"
            data-toggle="modal"
            data-target="#reminderpopup"
          >
            {" "}
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              fontSize="15pt"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "rgb(251, 167, 27)", margin: "0px 5px" }}
            >
              <circle cx={12} cy={13} r={8} />
              <path d="M12 9v4l2 2" />
              <path d="M5 3 2 6" />
              <path d="m22 6-3-3" />
              <path d="M6.38 18.7 4 21" />
              <path d="M17.64 18.67 20 21" />
            </svg>{" "}
            Set Reminder
          </button>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
        <img
          alt=""
          src="https://ik.imagekit.io/teofx8bxs/tr:f-webp,h-auto,w-auto/Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"
          className=""
        />
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
        <div className="form-main">
          <form id="insurance_form_get_plan">
            <div className="d-flex align-items-center form-input">
              <h6>
                Compare &amp; Buy Customized Health Plans starting at just
                <span className='specialprice'>Rs ₹257/month*</span>
              </h6>
              <div className="blue-btn">
             
                  <Link to={"/healthStep1"}>
                 
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "white",
                      fontWeight: 400
                    }}
                  >
                    Get Your Quotes
                  </button>{" "}
                  <img alt="" src="" className="" />
                  </Link>
                <br />
              </div>
              <p className="insurance-text-light">
                      By clicking, I agree to{" "}
                      <Link to={"/termsandcondition"}>
                        *terms &amp; conditions
                      </Link>{" "}
                      and <Link to={"/privacy-policy"}>privacy policy</Link>.
                    </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<div className="inbulitmargine"></div>
<div className="fixwidthDivtext">
<div className="container">
            <h4 className='healthHeading'> Factors Affect Health Insurance Premium</h4>
            <div className="yellowline"></div>
            <p className='textformat'>
            Health insurance costs vary for every individual, based on and individual's choice of coverage. More extensive policies with increased benefits typically result in higher insurance quotes. Understanding factors, influencing these quotes can shed light on how they are determined and how they fluctuate with common adjustments in health insurance plans.

Following are some of the factors that affect the premium quote of a health insurance plan -
            </p>
             <div className="row">

<div className="col-md-6"><FastImage url={'Website%20Img/Online%20Doctor-rafiki.png?updatedAt=1721292412550'} className='fmailimagegifs'width={auto} height={auto}/> </div>
<div className="col-md-6 d-flex justify-content-center align-items-center">


<div>


<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
        >
          <Typography>
          <li className='listcol'>
          <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
            

            Medical history:
                      </li>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <p className='textformat'>

          Some insurance companies determine best mediclaim quotes based on the applicant's medical history. After a health check-up, quotes are issued, with smokers typically facing higher premiums than non-smokers, Because smoking leads to several health hazards, and those health hazards can result in increased health insurance premiums.
    
        </p>
        </AccordionDetails>
      </Accordion>



      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
        >
               <Typography>
              <li className='listcol'>
              <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
         Entry age
              </li>
              </Typography>
  
        </AccordionSummary>
        <AccordionDetails>
        <p className='textformat'>



          The age at which you enter the policy affects your health insurance prices. Younger individuals typically receive lower premiums due to lower risk, while older individuals face higher premiums.

   
        </p>
        </AccordionDetails>
      </Accordion>



      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
        >
          <Typography>
          <li className='listcol'>
          <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
            Policy tenure
            </li>

            </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <p className='textformat'>

        
            Longer policy durations often come with lower costs compared to single-year policies.
        </p>
  
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
        >
          <Typography>
          <li className='listcol'>
          <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>
          Coverage options
          </li>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <p className='textformat'>

          Health insurance quotes are influenced by the coverage chosen. Additional features like maternity coverage in family health insurance increase prices as they are optional add-ons.
        </p>
      
        </AccordionDetails>
      </Accordion>
      </div>
  
      </div>
    </div>
 </div>
</div>



<div className="fixwidthDivtext">
<div className="container">
<h3 className='healthHeading'>Get Best Health Insurance Quotes </h3>
<div className="yellowline"></div>
<p className='textformat'>You can get instant quotes for your health insurance by following the steps given below:</p>
<div className="row">


<div className="col-md-6 d-flex justify-content-center align-items-center">
<div>


<div className="boxstepper">
  <div className='fledxnumbercontainer'>
  <div className="numberStepper">
    1
  </div>
  </div>
  <div>

  <div className="descriptionContainer">
Visit Beema1 and select the Health tab
  </div>
  </div>
</div>
<div className="boxstepper">
<div className='fledxnumbercontainer'>

  <div className="numberStepper">
    2
  </div>
</div>
  <div className="descriptionContainer">
  Enter your Personal Details
  </div>
</div>
<div className="boxstepper">
<div className='fledxnumbercontainer'>

  <div className="numberStepper">
    3
  </div>
</div>
  <div className="descriptionContainer">
  Click on the Get Quotes  Option
  </div>
</div>
<div className="boxstepper">
<div className='fledxnumbercontainer'>

  <div className="numberStepper">
    4
  </div>
</div>
  <div className="descriptionContainer">
  Select your Type of Occupation and Annual Income
  </div>
</div>
<div className="boxstepper">
<div className='fledxnumbercontainer'>

  <div className="numberStepper">
    5
  </div>
</div>
  <div className="descriptionContainer">
  Get Your Health Quotation form Top Insurence Company's
  </div>
</div>
</div>

</div>
<div className="col-md-6 d-flex justify-content-center align-items-center">
  <FastImage 
    url='/Website%20Img/Online%20document-bro.png?updatedAt=1727251714465' 
    width="auto" 
    height="auto" 
    className='fmailimagegifs' 

  />
</div>

</div>

  <br />
  <p className='textformat'>At Beema1, we have tried simplifying information regarding insurance and financial jargons for whoever visits our website. With reader-friendly content, we have built an easy-to-understand platform for staying updated about all kinds of insurance, such as; Health, Term, Car, Bike, Life, and Property. The aim is to serve you with the best advice.</p>




</div>
</div>

   

<HealthBenifits />
<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default HealthCompareQuotes