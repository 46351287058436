import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import "../SIP/SIP.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ConvertNumberToword } from "../CalculatortoWord";
import Redirect from "../../../AllPageComponents/Rederict";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import { Helmet } from "react-helmet-async";

const SWP = () => {

  var convertRupeesIntoWords = require('convert-rupees-into-words');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [totalInvestment, setTotalInvestment] = useState(12000);
  const [withdrawal, setWithdrawal] = useState(500);
  const [returnRate, setReturnRate] = useState(8);
  const [timePeriod, setTimePeriod] = useState(1);

  const nav = useNavigate();

  const calculate = () => {
    let corpus = totalInvestment;
    let totalWithdrawals = 0;

    for (let i = 1; i <= timePeriod * 12; i++) {
      corpus += (corpus * returnRate / 100 / 12); 
      corpus -= withdrawal; 
      totalWithdrawals += withdrawal; 
    }

    const remainingCorpus = corpus.toFixed(2);
    const totalValue = (totalInvestment - totalWithdrawals).toFixed(2); 


return {remainingCorpus , totalValue,totalWithdrawals}

  };
  //console.log("totalcalculatoir",calculate().remainingCorpus)

  useEffect(()=>{
    calculate()
  },[totalInvestment,withdrawal,returnRate,timePeriod])


const TotalInvestToWord = convertRupeesIntoWords(Math.ceil(totalInvestment))
const TotalWidthraltoWord = convertRupeesIntoWords(Math.ceil(calculate().totalWithdrawals))
const TotalRemainCoptoWord = convertRupeesIntoWords(Math.ceil(calculate().remainingCorpus))




  return (
    <div>


<Helmet>
        <title>Swp calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="best swp calculator,sip and swp calculator, swp mutual fund calculator" />
      </Helmet>




                <section className="margincalculator"></section>
    <div className="headingcalculator">
    <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>SWP Calculator</span></p>
  
  <h1 className="titlecalculator">SWP <span className='Mainheading'>Calculator</span></h1>
 
  <sapn className="CalculatorDiscription">
    An SWP calculator helps estimate regular withdrawals from an investment fund, aiding investors in managing their cash flow needs effectively.
</sapn>
  </div>
    <div className="calculatorhead">
    
  
    <div className="underdiv">



 
    <div className="calculatorse">
    {/* Calculator start div start hear */}
    <div className="calculaterse">
    <div className="calculator">
    

  
  

  
  {/* Amount calculator */}
  <div className="onecalculator">
  <div className="amountdives">
    <p>Total Investment </p>
    <input type="text" value={totalInvestment} onChange={(event)=> {setTotalInvestment((event.target.value))}} min={10000}/>
  </div>
  <div className="amountselectordiv">
    <Slider
      value={totalInvestment}
      onChange={(event)=> {setTotalInvestment(parseFloat(event.target.value))}}
      min={10000}
      max={5000000}
      step={1000} // optional, set the step value
      aria-label="Default"
      valueLabelDisplay="auto"
    />
  </div>
  </div>


  <div className="onecalculator">
  <div className="amountdives">
  <p>Widthral per month</p><input 
  type="number" 
  value={withdrawal}  
  onChange={(event) => {
    setWithdrawal(parseFloat(event.target.value));
  }}
  min="500"
/>


  </div>
  <div className="amountselectordiv">
  <Slider defaultValue={50} 
  aria-label="Default" 
  valueLabelDisplay="auto" 
  onChange={(event)=> {setWithdrawal((event.target.value))}}
  min={500}
  max={5000000}
  step={500} // optional, set the step value
  value={withdrawal}
  />
  </div>
  </div>
  
  {/* Amount calculator */}
  <div className="onecalculator">
  <div className="amountdives">
  <p>Expected return Year % (P.A)</p><input type="text" value={returnRate} onChange={(event)=>{setReturnRate(event.target.value)}}/>
  </div>
  <div className="amountselectordiv">
  <Slider defaultValue={50} 
  aria-label="Default" 
  valueLabelDisplay="auto" 
  onChange={(event)=>{
    setReturnRate(event.target.value)
  }}
  min={1}
  max={100}
  step={0.1} // Step remains at 0.5
  value={returnRate}
  />
  
  </div>
  </div>
  
  {/* Amount calculator */}
  <div className="onecalculator">
  <div className="amountdives">
  <p>Time Period (Year) </p><input type="text" value={timePeriod}  onChange={(event)=> {setTimePeriod(event.target.value)}}/>
  </div>
  <div className="amountselectordiv">
  <Slider defaultValue={50} 
  aria-label="Default" 
  valueLabelDisplay="auto" 
  onChange={(event)=> {setTimePeriod(event.target.value)}}
  min={1}
  max={50}
  value={timePeriod}
  />
  </div>
  </div>
  <div>
  <div className="calculateamountdiv">
                    <div>
                      <p>
                        Total  Investment:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(totalInvestment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
<p className='textword'>{TotalInvestToWord}</p>
                      <p>
                        Total Widthral:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(calculate().totalWithdrawals)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
<p className='textword'>{TotalWidthraltoWord}</p>
                      <p>
                        Fainal Valuet:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(calculate().remainingCorpus)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>       
                      <p className='textword'>{TotalRemainCoptoWord}</p>
                    
                    </div>
                  </div>
  </div>
  </div>
   
   
    </div>

    </div>







    {/* Result part start hear */}
    <div className="resultse">
   <Calculatorlist/>
    </div>
    
    
    </div>
    </div>
    </div>
  )
}

export default SWP