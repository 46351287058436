import { io } from "socket.io-client";

const serverUrl = process.env.REACT_APP_SOCKET_URL; // Replace with your server URL
let socket = null;
let userId = null;
let isRegistered = false;
const eventCallbacks = {};

let uid = localStorage.getItem('uid');
if(uid){
  connect(uid);
}

// HOW IT WORKS
// 1. We keep a list of events (like 'message') and its related data, in "eventCallbacks" variable
// 2. After that we connect >> then register
// 3. As soon as we register, server starts sending data for 'message', and we store it. This happens in 'handleIncomingEvent' function
// 4. When any component needs this data, it calls "subscribe" function with the name of event.
// 5. For example, Plans will call like:          subscribe('message', callback_function);
// 6. From the next time server sends any data, 'handleIncomingEvent' stores the data but also calls the callback_function that was passed in 'subscribe' in step5.


// Called to Initialize the socket connection
function connect(newUserId, onConnectCallback) {
  userId = newUserId;
  if (!socket) {
    socket = io(serverUrl);

    //connection listener
    socket.on("connect", () => {
      console.log("socket: Connected to the server");
      if (userId && !isRegistered) {
        localStorage.setItem('uid',userId);
        registerUser(userId, onConnectCallback);
      }
    });

    socket.on("connect_error", (error) => {
      console.error("socket: Connection error:", error);
    });

    socket.on("disconnect", (reason) => {
      console.warn("socket: Disconnected:", reason);
    });

    socket.on("message", (company_name, plan_code, emit_data) => {
      handleIncomingEvent("message", { company_name, plan_code, emit_data });
    });
  }
}

// Handle incoming events and store data
function handleIncomingEvent(event, data) {
  if (!eventCallbacks[event]) {
    eventCallbacks[event] = { callback: null, data: [] };
  }
  let found = false;
  for(var i=0;i<eventCallbacks[event].data.length;i++){
    let planData = eventCallbacks[event].data[i];
    if(planData.plan_code == data.plan_code && planData.company_name == data.company_name) {
      eventCallbacks[event].data[i] = {...data, used:false};
      found = true;
      break;
    }
  }
  if(!found) eventCallbacks[event].data.push({ ...data, used: false });
  triggerEventCallback(event);
}

// Register the user
function registerUser(newUserId, onConnectCallback) {
  if (isRegistered) {
    console.log("socket: User is already registered this session.");
    return;
  }

  socket.emit("register_user", newUserId, (response) => {
    if (response && response.success) {
      console.log("socket: User registered successfully:", newUserId);
      isRegistered = true;
      onConnectCallback && onConnectCallback(newUserId);
    } else {
      console.error("socket: Failed to register user:", response?.error);
      onConnectCallback && onConnectCallback(null);
    }
  });
}

// Subscribe to an event and set its callback
function subscribe(event, callback) {
  if (!eventCallbacks[event]) {
    eventCallbacks[event] = { callback: null, data: [] };
  }
  eventCallbacks[event].callback = callback;
  triggerEventCallback(event);
}

function getLatestData(event) {
  if(eventCallbacks[event]){
    return eventCallbacks[event].data;
  }
  return null;
}

// Trigger the callback for an event if it exists
function triggerEventCallback(event) {
  const eventData = eventCallbacks[event];
  if (eventData && eventData.callback) {
    eventData.data.forEach((item) => {
      if (!item.used) {
        // console.log("socket: CALLBACK for:", item);
        eventData.callback(item);
        item.used = true;
      }
    });
  }
}

// Cleanly disconnect the socket
function disconnect() {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
}

// Check if the socket is connected
function isConnected() {
  return socket ? socket.connected : false;
}


window.socket = {
  connect: connect,
  subscribe: subscribe,
  disconnect: disconnect,
  isConnected: isConnected,
  getLatestData: getLatestData
};

export const SocketManager = {
  connect: connect,
  subscribe: subscribe,
  disconnect: disconnect,
  isConnected: isConnected,
  getLatestData: getLatestData
};