import {feetAndInchesToCm} from "./common";

const payload = (proposerData,apidata,kycdata,addonsArray,godigitMedque) =>
{
	console.log('proposerData in payload',proposerData)
	console.log("kycdata", kycdata)
	// //console.log("PlanData ",PlanData)

	const heightdata = feetAndInchesToCm(proposerData.height_feet , proposerData.height_inch);

	//console.log("the bheightdata", heightdata);

	let tenure =  1;
  
	if(apidata.hasOwnProperty('premiumBreakup'))
	{
		tenure =  apidata.premiumBreakup.term
	}

	let ped = JSON.parse(localStorage.getItem('PED'));
	console.log("ped",ped.join(','))

   let output = {
		    "ProposalName": proposerData.ProposalName,
		    "Proposalphonenumber": proposerData.Proposalphonenumber,
		    "street": kycdata.street,
		    "PinCode": JSON.stringify(kycdata.PinCode),
		    "District": proposerData.District,
		    "City": proposerData.District,
		    "State": proposerData.Sate,
		    "PED": ped.join(","),
		    "Email": kycdata.Email,
		    "dateOfbirth": kycdata.KycDateOfbirth,
		    "height": proposerData.heightcm, 
		    "weight":  proposerData.weight,
		    "Occupation": proposerData.Occupation,
		    "GrossIncome": proposerData.AnnualIncome,
		    "Gender": proposerData.Gender,
		    "Premium": apidata.premiumBreakup.premium,
		    "SumInsured": apidata.sumInsured,
		    "PlanCode": apidata.planCode,
		    "PlanName": apidata.planName,
		    "Tenure": tenure+" Year",
		    "InsuredDetail": InsuredDetail(proposerData.InsuredDetail,kycdata,godigitMedque),
		    "nominee": {
		        "firstName": extractNames(kycdata.nomineeName).fname,
		        "middleName": null,
		        "lastName": extractNames(kycdata.nomineeName).lname,
		        "dateOfbirth": kycdata.nomineeDateOfbirth,
		        "profession": null,
		        "gender": kycdata.nomineerelationship == 'Self' ? kycdata.Gender : getGender(kycdata.nomineerelationship),
		        "relationship": kycdata.nomineerelationship,
				"street" : kycdata.nomineeAddress
		    },
		    "kycId": kycdata.kycId,
		    "cardNum": kycdata.cardNum,
		    "addons": addonsArray ? addonsArray.join() : "",
        "userToken":localStorage.getItem('userToken')
		}
	

	return output;
}

const InsuredDetail = (data,userdata,godigitMedque) => 
{
  let result = [];

  for (let i = 0; i < data.length; i++) 
  {
  	let obj = {
  		         InsuredName:data[i].InsuredName,
  		         InsuredRelation:data[i].InsuredRelation,
  		         dateOfbirth:data[i].dateOfbirth,
  		         Gender: data[i].InsuredRelation == 'Self' ? (userdata.Gender == 'M' ? 'Male' : 'Female') : getGender(data[i].InsuredRelation),
  		         InsuredAge:data[i].InsuredAge,
  		         height:data[i].heightcm,
  		         weight:data[i].weight,
  		         IPED: true,
  		         MaritalStatus:data[i].MaritalStatus,
  		         medicalQuestions:godigitMedque[i]
  		      };
  	result.push(obj);
  }
  return result;
}

const extractNames = (fullName) =>
{
  // Split the full name into an array of words
  const nameArray = fullName.split(' ');

  // Extract the first name (fname)
  const fname = nameArray[0];

  // Extract the last name (lname) if available
  const lname = nameArray.length > 1 ? nameArray.slice(1).join(' ') : '';

  return { fname, lname };
}
 
const getGender = (relation) =>
{
  const output = {
  	"Mother":'Female',
  	"Father":'Male',
  	"Grand Father":'Male',
  	"Grand Mother":'Female',
  	"Brother":'Male',
  	"Sister":'Female',
  	"Husband":'Male',
  	"Wife":'Female',
  	"Father In Law":'Male',
  	"Mother In Law":'Female',
  	"Uncle":'Male',
  	"Aunt":'Female',
  	"Grand Daughter":'Female',
  	"Grand Son":'Male',
  	"Son":'Male',
  	"Daughter":'Female'

  }
  return output[relation];
} 

export {payload}