import React, { useEffect, useState } from "react";
import "../../CommonCssFiles/planstyle.css";
import { useDispatch, useSelector } from "react-redux";
import { updatePed } from "../../redux/Slice";
import { fetchQuickQuote, updateQuickQuote } from "../../Api/Api";
import Loader from "../../../AllPageComponents/Loader";

const EditPED = ({ onBack, setShowside ,setSidedata, setPlanData , apiResponse , setApiResponse , isConnected}) => {
  const medical_history = [
    "Diabetes",
    "Blood Pressure",
    "Cholesterol",
    "Asthma",
    "Other disease",
    "None of these",
  ];

  const { userdata } = useSelector((state) => state.FetchPlan);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [apiResponseEdit, setApiResponseEdit] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [noneSelected, setNoneSelected] = useState(false);
  const [changesApplied, setChangesApplied] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userdata && userdata.PED) {
      setSelectedOptions(userdata.PED);
      if (userdata.PED.includes("None of these")) {
        setNoneSelected(true);
      }
    }
  }, [userdata]);

  const fetchApi = async () => {
    try {
      const response = await fetchQuickQuote(unique_id);
      setApiResponseEdit(response);
      setSelectedOptions(response.data.request.PED);
      setIsLoading(false);
      return response;
    } catch (error) {
      console.log("The error", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi();
      if (response) {
        setApiResponseEdit(response);
      }
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (event) => {
    const checkedItem = event.target.name;

    if (checkedItem === "None of these") {
      if (event.target.checked) {
        setNoneSelected(true);
        setSelectedOptions(["None of these"]);
      } else {
        setNoneSelected(false);
        setSelectedOptions([]);
      }
    } else {
      setNoneSelected(false);
      setSelectedOptions((prevOptions) => {
        if (prevOptions.includes("None of these")) {
          return [checkedItem];
        } else {
          if (event.target.checked) {
            return [...prevOptions, checkedItem];
          } else {
            return prevOptions.filter((item) => item !== checkedItem);
          }
        }
      });
    }
  };

  const handlePED = async () => {

    let data = isConnected();
    console.log("the data in isconnected", data)

    if(data){
      setPlanData({})
      if (isLoading) return; // Prevent execution while loading
  
      let result = selectedOptions;
  
      if (JSON.stringify(result) !== JSON.stringify(userdata.PED)) {
        localStorage.setItem("PED", JSON.stringify(result));
        setChangesApplied(true);
  
        let payloadData = {
          ...apiResponse.request,
          PED: selectedOptions,
        };
  
        console.log("apiResponse", apiResponse);
        console.log("payloadData",payloadData)
  
  
  
        // setIsLoading(true);
        await updateQuickQuote(payloadData, unique_id).then((res) => {
          console.log("the response", res);
          // setPlanData(res.data.response);
          // setApiResponse(res.data);
          setApiResponse((prevData)=>({
            ...apiResponse,
            request: res.data.request
          }))
          setSidedata(res.data.request)
          setShowside(false);
          setIsLoading(false);
        });
      } else {
        console.log("PED has not changed");
      }
  
      onBack();
    }
    else{
      return
    }
    
 
  };

  return (
    <>
      {isLoading && <Loader />}
      {changesApplied && (
        <div className="alert alert-success" role="alert">
          Your data has been updated successfully!
        </div>
      )}
      <div className="scrollable-container" style={{ marginBottom: "190px" }}>
        <div className="col-lg-12" style={{ margin: "0 auto" }}>
          {medical_history.map((item, index) => (
            <div
              key={index}
              className={
                selectedOptions.includes(item)
                  ? "checked-member editped-div"
                  : "input-group row groupdata editped-div"
              }
            >
              <input
                className="desiseinput"
                id={`option${index}`}
                name={item}
                type="checkbox"
                checked={
                  (item === "None of these" && noneSelected) ||
                  selectedOptions.includes(item)
                }
                onChange={handleCheckboxChange}
                disabled={isLoading} // Disable checkbox while loading
              />
              <label className="desieslebel">{item}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="searchbuttondiv">
        <button className="sidbtnn" onClick={onBack} disabled={isLoading}>
          Back
        </button>
        <button
          className="sidbtnn"
          onClick={handlePED}
          disabled={isLoading}
        >
          Apply Changes
        </button>
      </div>
    </>
  );
};

export default EditPED;
