import {feetAndInchesToCm , formatDate} from "./common";



const payload = (data,PlanData,kycdata,addonsArray,ReliancecheckedQuestion) =>
{


	console.log("data in reliance", data);
	console.log("apiresponse in reliance", PlanData);
	console.log("kycdata in reliance", kycdata);
	console.log("addons in reliance", addonsArray);


	// //console.log('kycdata',kycdata)
	// //console.log("PlanData ",PlanData)
	// const heightdata = feetAndInchesToCm(data.height_feet , data.height_inch);

	//console.log("the bheightdata", heightdata);


	// proposerData,
	// apiResponse.response,
	// kycData,
	// addonsArray,
	// []
	// ReliancecheckedQuestion

	let tenure ;
  
	if(PlanData.hasOwnProperty('premiumBreakup'))
	{
		tenure =  PlanData.premiumBreakup.term
	}

	let ped = JSON.parse(localStorage.getItem('PED'));

	
	// alert("the total discount" + PlanData.premiumBreakup.discountAmount)
   let output = {
		    "ProposalName": data.ProposalName,
		    "Proposalphonenumber": data.Proposalphonenumber,
		    "street": kycdata.street,
		    "PinCode": data.PinCode,
		    "District": data.District,
		    "City": data.District,
		    "State": data.Sate,
		    "PED": ped,
		    "Email": kycdata.Email,
		    "dateOfbirth": data.dateOfbirth,
		    "height": data.heightcm ,
			"heightfeet": data.height,
		    "weight":  data.weight ,
		    "Occupation": data.Occupation,
		    "GrossIncome": data.AnnualIncome,
		    "Gender": data.Gender == 'M' ? 'Male' : 'Female',
		    "Premium": PlanData.premiumBreakup.totalPremium,
		    "BasePremium" : PlanData.premiumBreakup.basePremium,
			"TotalDiscount" : PlanData.premiumBreakup.discountAmount,
		    "SumInsured": PlanData.sumInsured,
		    "PlanCode": PlanData.planCode,
		    "PlanName": PlanData.planName,
		    "Tenure": tenure+" Year",
		    "MaritalStatus": data.MaritalStatus == 'Unmarried' ? 'unmarried' : data.MaritalStatus,
		    "InsuredDetail": InsuredDetail(data.InsuredDetail,kycdata,ReliancecheckedQuestion),
		    "nominee": {
		        "firstName": extractNames(kycdata.nomineeName).fname,
		        "middleName": null,
		        "lastName": extractNames(kycdata.nomineeName).lname,
		        "dateOfbirth": formatDate(kycdata.nomineeDateOfbirth),
		        "profession": null,
		        "gender": kycdata.nomineerelationship == 'Self' ? data.Gender : getGender(kycdata.nomineerelationship,data.Gender),
				
		        "relationship": kycdata.nomineerelationship,
		        "PinCode":data.PinCode,
		        "State": data.Sate,
		        "City": data.District,
				"PhoneNumber" : kycdata.nomineePhoneNumber,
		        "street": kycdata.nomineeAddress
				    },
				"ApointeeDetails": {
            "FullName": data.ProposalName,
            "RelationWithNominee": kycdata.RelationWithNominee,
            "street": kycdata.street,
            "PinCode": kycdata.PinCode,
            "StateName": data.Sate,
            "CityName": data.District
        },    
		    "kycId": kycdata.kycId,
		    "cardNum": kycdata.cardNum,
		    "addons": addonsArray ? addonsArray.join() : "",
        "userToken":localStorage.getItem('userToken'),
		
		}
        console.log("the output", output)
	return output;
}

// const InsuredDetail = (data,kycdata,ReliancecheckedQuestion) => 
// {
//   let result = [];

//   for (let i = 0; i < data.length; i++) 
//   {
//   	let obj = {
//   		         InsuredName:data[i].InsuredName,
//   		         InsuredRelation:data[i].InsuredRelation,
//   		         dateOfbirth:formatDate(data[i].dateOfbirth),
//   		         Gender: data[i].InsuredRelation == 'Self' ? (kycdata.Gender == 'M' ? 'Male' : 'FeMale') : getGender(data[i].InsuredRelation),
//   		         InsuredAge:data[i].InsuredAge,
//   		         height:data[i].height,
//   		         weight:data[i].weight,
//   		         IPED: true,
//   		         MaritalStatus:data[i].MaritalStatus,
//   		         MedicalQuestionDetails:MedicalQuestionDetails(i,ReliancecheckedQuestion)

//   		      };
//   	result.push(obj);
//   }
//   return result;
// }

const InsuredDetail = (data, kycdata, ReliancecheckedQuestion) => {
	let result = [];
  
	for (let i = 0; i < data.length; i++) {
	  let medicalDetails = MedicalQuestionDetails(i, ReliancecheckedQuestion);
	  let obj = {
		InsuredName: data[i].InsuredName,
		InsuredRelation: data[i].InsuredRelation,
		dateOfbirth: formatDate(data[i].dateOfbirth),
		Gender: data[i].InsuredRelation == 'Self' ? (kycdata.Gender == 'M' ? 'Male' : 'Female') : getGender(data[i].InsuredRelation),
		InsuredAge: data[i].InsuredAge,
		height: data[i].height,
		heightcm: data[i].heightcm,
		weight: data[i].weight,
		IPED: true,
		MaritalStatus: data[i].MaritalStatus,
		// MedicalQuestionDetails: {
		  DiseaseList: medicalDetails.DiseaseList,
		  InsureQuestionsList: medicalDetails.InsureQuestionsList
		// }
	  };
	  result.push(obj);
	}
	return result;
  }
  

const extractNames = (fullName) =>
{
  // Split the full name into an array of words
  const nameArray = fullName.split(' ');

  // Extract the first name (fname)
  const fname = nameArray[0];

  // Extract the last name (lname) if available
  const lname = nameArray.length > 1 ? nameArray.slice(1).join(' ') : '';

  return { fname, lname };
}
 
const getGender = (relation,self_gender = null) =>
{
	if(relation == 'Spouse')
	{
     if(self_gender.toLowerCase() == 'male')
     {
     	return 'FeMale';
     }
     else
     {
     	return 'Male';
     }
	}

  const output = {
  	"Mother":'FeMale',
  	"Father":'Male',
  	"Grand Father":'Male',
  	"Grand Mother":'FeMale',
  	"Brother":'Male',
  	"Sister":'FeMale',
  	"Husband":'Male',
  	"Wife":'FeMale',
  	"Father In Law":'Male',
  	"Mother In Law":'FeMale',
  	"Uncle":'Male',
  	"Aunt":'FeMale',
  	"Grand Daughter":'FeMale',
  	"Grand Son":'Male',
  	"Son":'Male',
  	"Daughter":'FeMale'

  }
  return output[relation];
} 

// const MedicalQuestionDetails = (index,ReliancecheckedQuestion) => 
// {
// 	let array = [];
//   for (let key in ReliancecheckedQuestion) {
//    	if(ReliancecheckedQuestion[key].hasOwnProperty(index))
//    	{
//    		return ReliancecheckedQuestion[key][index];
//    		break;
//    	}
//    } 
// 	return array;

// }

function convertToNormalDate(dateStr) {
if(dateStr){
	const dateObj = new Date(dateStr);
  
	// Extract the day, month, and year
	const day = String(dateObj.getDate()).padStart(2, '0'); // Ensure two digits for the day
	const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
	const year = dateObj.getFullYear();
  
	// Return in dd/mm/yyyy format
	return `${day}/${month}/${year}`;
}
  }

const convertDateFormat = (dateString) => {
	if(dateString){// Split the input date by "/"

		console.log("the dateString",convertToNormalDate(dateString));
	const [day, month, year] = convertToNormalDate(dateString).split('/');
  
	// Return the date in mm/yyyy format
	return `${month}/${year}`;
}
else{
	return ""
}
  };
  

const MedicalQuestionDetails = (index, ReliancecheckedQuestion) => {
	let DiseaseList = [];
	let InsureQuestionsList = [];
  
	for (let key in ReliancecheckedQuestion) {
	  if (ReliancecheckedQuestion[key].hasOwnProperty(index)) {
		let questions = ReliancecheckedQuestion[key][index];
		questions.forEach(question => {
		  if (question.QuestionId == "1") { // Assuming "1" is for DiseaseList
			question.Options.forEach(option => {
			  let disease = {
				DiseaseID: option.OptionId,
				SufferingSince:option.SufferingSince || "",
				OtherDisease: option.OtherDisease || "",
				TreatmentTaken: option.TreatmentTaken || "",
				ExactDiagnosis: option.ExactDiagnosis || "",
				HospitalName: option.HospitalName || "",
				ConsultationDate: option.ConsultationDate || ""
			  };
			  DiseaseList.push(disease);
			});
		  } 
		  else {
			// Check if an entry already exists
			let existingQuestion = InsureQuestionsList.find(
			  iq => iq.QuestionId === question.QuestionId
			);
  
			if (!existingQuestion) {
			  // Create a new entry if it doesn't exist
			  existingQuestion = {
				IsSelected: "true", // Assuming IsSelected should be true, adjust if needed
				QuestionId: question.QuestionId,
				QuestionDetails: {}
			  };
			  InsureQuestionsList.push(existingQuestion);
			}
  
			question.Options.forEach(option => {
			  let optionDetails = {
				ExactDiagnosis: option.ExactDiagnosis || "",
				DiseaseName : option.DiseaseName || "",
				Description : option.Description || "",

				SurgeryDate:option.SurgeryDate || "",				
				DiagnosisDate: option.DiagnosisDate || "",
				ConsultationDate: option.ConsultationDate || "",
				HospitalName: option.HospitalName || "",
				MedicineName: option.MedicineName || "",
				TypeOfTests: option.TypeOfTests || "",
				DateOfTests: option.DateOfTests || "",
				FindingsOfTests: option.FindingsOfTests || "",
				DetailsOfYourPastMedicalCondition: option.DetailsOfYourPastMedicalCondition || "",
				ExpectedDeliveryDate: option.ExpectedDeliveryDate || "",
				HasFiledClaimWithCurrPreviousInsured: option.HasFiledClaimWithCurrPreviousInsured || "",
				HasChargedCancelledHighPremium: option.HasChargedCancelledHighPremium || "",
				
			  };
  
			  // Update the existing QuestionDetails
			  Object.keys(optionDetails).forEach(key => {
				if (optionDetails[key]) {
				  existingQuestion.QuestionDetails[key] = optionDetails[key];
				}
			  });
			});
		  }
		});
	  }
	}
  
		
  
	return { DiseaseList, InsureQuestionsList };
  }
  

export {payload}