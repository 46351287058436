import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import '../SIP/SIP.css'
import Slider from '@mui/material/Slider';
import { Link, useNavigate } from 'react-router-dom';
import { ConvertNumberToword } from '../CalculatortoWord';
import Redirect from '../../../AllPageComponents/Rederict';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import { Helmet } from 'react-helmet-async';


const Lumpsum = () => {

  var convertRupeesIntoWords = require('convert-rupees-into-words');

 const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


// Handel Year Change !!!!
    const [HanderYear, setHanderYear] = useState(10)
    const handleYearChange = (event) => {
      let newValue = parseInt(event.target.value, 10); 
  
      if (newValue === '') {
        setHanderYear('');
      } else if (newValue < 1) {
        setHanderYear(1);
      } else if (newValue > 40) {
        setHanderYear(40);
      } else {
        setHanderYear(newValue);
      }
    };







// Hnadel Persentage Change !!!
const [HandelParesntage, setHandelParesntage] = useState(12)
 const handelPersentage = (event) =>{
   if(event.target.value > 30){
    setHandelParesntage(30)
   }else{
    setHandelParesntage(event.target.value)
   }

 }










// Handel Amount Change !!!
const [HandelAmount, setHandelAmount] = useState(25000)

const handleAmountChange = (event) => {
  let newValue = parseInt(event.target.value, 10);
  if (newValue === '') {
    setHandelAmount('');
  } else if (newValue > 1000000) {
    setHandelAmount(1000000);
  } else {
    setHandelAmount(newValue);
  }
};





//console.log("Amount Moeny ",HandelAmount)
const TotalAmount = () => {
    const principalAmount = HandelAmount;
    const interestRate = HandelParesntage / 100; // Convert percentage to decimal
    const investmentYears = HanderYear;
  
    const totalAmount = principalAmount * (1 + interestRate) ** investmentYears;
    const totalReturn = totalAmount - principalAmount;
  
    return {
      totalAmount,
      totalReturn, // Now absolute return amount
      investmentAmount: principalAmount
    };

  };

const TotalInvestment = convertRupeesIntoWords(HandelAmount)
const totalAmount = convertRupeesIntoWords((Math.ceil(TotalAmount().totalAmount)))
const totalReturn = convertRupeesIntoWords(Math.ceil(TotalAmount().totalReturn))


console.log(TotalAmount().investmentAmount,TotalAmount().totalReturn)    



  return (
    <div>

<Helmet>
        <title>Online lumpsum calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="lump sum sip calculator , online lump sum calculator" />
      </Helmet>
                    <section className="margincalculator"></section>
    <div className="headingcalculator">
    <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>Lumpsum Calculator</span></p>

  <span className="titlecalculator">Lumpsum <span className='Mainheading'>Calculator</span></span>
</div>
  <div className="calculatorhead">
  

  <div className="underdiv">
  <div className="calculatorse">

  {/* Calculator start div start hear */}
  <div className="calculaterse">
  <div className="calculator">






{/* Amount calculator */}
<div className="onecalculator">
      <div className="amountdives">
        <p>Total Investment </p>
        <input
          type="number"
          value={HandelAmount} // Corrected variable name
          onChange={handleAmountChange}
        />
      </div>
      <div className="amountselectordiv">
        <Slider // Assuming you have the Slider component imported and configured
          onChange={handleAmountChange}
          value={HandelAmount} // Corrected variable name
          min={500}
          max={1000000}
          step={500} // Optional, set the step value
          aria-label="Investment Amount" // Improved accessibility label
          valueLabelDisplay="auto"
        />
      </div>
    </div>

{/* Amount calculator */}
<div className="onecalculator">
<div className="amountdives">
<p>Expected return Year % (P.A)</p><input type="number" value={HandelParesntage} onChange={handelPersentage}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={50} 
aria-label="Default" 
valueLabelDisplay="auto" 
onChange={handelPersentage}
min={1}
max={30}
step={0.1} // Step remains at 0.5
value={HandelParesntage}
/>

</div>
</div>

{/* Amount calculator */}
<div className="onecalculator">
      <div className="amountdives">
        <p>Time Period (Year) </p>
        <input
          type="number" // Use type="number" for numeric input
          value={HanderYear} // Corrected variable name
          onChange={handleYearChange}
          min={1} // Set minimum value in the input field
          max={40} // Set maximum value in the input field
        />
      </div>
      <div className="amountselectordiv">
        <Slider // Assuming you have the Slider component imported and configured
          onChange={handleYearChange}
          value={HanderYear} // Corrected variable name
          min={1}
          max={40} // Use consistent max value (40)
          step={1} // Optional: Set step value for the slider (default 1)
          aria-label="Investment Duration" // Improved accessibility label
          valueLabelDisplay="auto"
        />
      </div>
    </div>








<div className="calculateamountdiv">
<div>

<div>
  <p>Investment Amount: <span style={{ color: '#0166B3' }}>{String(TotalAmount().investmentAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })).slice(0,-3)}</span></p>
  <p className='textword'>{TotalInvestment}</p>
  <p>Total Return: <span style={{ color: '#0166B3' }}>{String(TotalAmount().totalReturn.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })).slice(0,-3)}</span></p>
  <p className='textword'>{totalReturn}</p>
  <p>Total Value: <span style={{ color: '#0166B3' }}>{String(TotalAmount().totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })).slice(0,-3)}</span></p>
  <p className='textword'>{totalAmount}</p>
</div>


</div>
</div>
  </div>
  <div className="graphdiv">

   <Chart
        type='donut'
        width={400}
        height={445}
        series={[
          Number(TotalAmount().investmentAmount) || 100,
          Number(TotalAmount().totalReturn) || 100
        ]}
          
        options={{
          labels:['Invested Amount', 'Return Value']
        }}
      />


  
  </div>
  </div>
  </div>
  {/* Result part start hear */}
  <div className="resultse">
  <Calculatorlist/>
  </div>
  
  
  </div>
  </div>
  </div>
  )
}

export default Lumpsum