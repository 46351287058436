import React,{useEffect, useState} from "react";
import PartnerCompany from "../../../../AllPageComponents/PartnerCompany/PartnerCompany";
import FAQ from "../../../../AllPageComponents/FAQ/FAQ";
import { Link } from "react-router-dom";
import FastImage from "../../../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import Redirect from "../../../../AllPageComponents/Rederict";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { Helmet } from "react-helmet-async";

const CriticalillnessInsurance = () => {
  const initialText =
    'With Beema1, get top-rated health insurance plans online. You can compare online and buy the most suitable insurance plan for you and your family. You can also get your queries answered by our team of experts.';





const Criticaliilness = [
  'Cancer','Kidney failureCancer','Coma','Stroke','Severe burns','Brain surgery','Blindness','Apallic syndrome','Goodpastures syndrome',
  'Heart valve replacement or repair',' Heart attack','Non-cancerous brain tumor',' Coronary artery bypass graft',
  'Alzheimer’s disease',' Primary Parkinsons disease','Major organ transplant','Bone marrow transplant','Permanent limb paralysis','Motor neuron disease',
  'Multiple sclerosis','Aplastic anemia','Bacterial meningitis',' Loss of speech','End-stage liver disease','Deafness','End-stage lung disease','Fulminant viral hepatitis','Severe burns','Muscular dystrophy'

]


const AdvantagesCl = [
  {id:0 , Q:'Income Replacement',A:'Critical illness not only impacts a patients health but also their financial stability and that of their family. With critical illness insurance, the insured individual receives a lump sum amount used to cover medical expenses and maintain household finances during this challenging time'},
  {id:1 , Q:'Tax Benefits',A:'Enjoy tax benefits under a critical illness insurance policy. The premiums paid towards such plans are eligible for tax deductions under Section 80D of the Income Tax Act, 1961, along with regular medical insurance'},
  {id:2 , Q:'Easy Claim Process:',A:' Unlike some insurance policies that require hospitalization for claims, critical illness insurance allows for claims based solely on diagnostic reports, simplifying the process for the insured.'},
  {id:3 , Q:'Short Waiting Period:',A:'Critical illness insurance typically comes with short waiting periods, often up to 3 months, allowing the insured to access policy benefits soon after coverage begins.'},
  {id:4 , Q:'Peace of Mind:',A:'Critical illness insurance provides peace of mind, enabling the insured to focus on their medical treatment without worrying about financial burdens.'},
  {id:5 , Q:'Add-On Covers',A:'Many insurers offer additional covers alongside critical illness plans, allowing insured individuals to opt for services such as wellness coaching or second opinions for added support during their health journey'},
]



const [expanded, setExpanded] = useState(null);

const handleChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : null);
};

const [readmore, setreadmore] = useState(true)
const HandelRead = () =>{
  setreadmore(!readmore)
}


   const UnderstandtheCriticalIllness = [
    {
      "id": 1,
      "Q": "Hospitalization Expense",
      "A": "You are covered through Beema1 for your hospitalization expenses that ideally include your hospital room rent, ICU, medical investigations, doctor consultation fees, surgeries etc due to illnesses and injuries."
    },
    {
      "id": 2,
      "Q": "Pre and Post-Hospitalization",
      "A": "This covers you with all your pre-hospitalization cover up to 60 days and post-hospitalization cover expenses up to 180 days after your discharge from the hospital."
    },
    {
      "id": 3,
      "Q": "Home Health Care Coverage",
      "A": "Cashless Home Health Care treatment facilities are provided if it is medically necessary and medically prescribed by treating medical practitioners. Beema1 helps you to explore those options with helpful assistance and guidance."
    },
    {
      "id": 4,
      "Q": "Day Care Treatment Facilities",
      "A": "Medical advancements and progress in technologies related to the medical industry help in speedy procedures related to any major surgeries or any kind of medical treatment in less than 24 hours. The good news is that through Beema1, you are covered for that as well."
    },
    {
      "id": 5,
      "Q": "Sum Insured Rebound",
      "A": "You get the cushion of this benefit through us because it adds to the sum insured, an amount equal and parallel to the claim amount, up to the basic sum insured, under the incidence of any hospitalization that happens thereafter."
    },
    {
      "id": 6,
      "Q": "Organ Donor Expenditures",
      "A": "Through the platform of Beema1, you are covered for the medical and surgical expenses of the organ donor while picking a major organ from a donor’s body."
    },
    {
      "id": 7,
      "Q": "Preventive Health Check-Up Facility",
      "A": "Through us, you will be getting introduced to those beneficial health insurance policies with useful critical illness coverages that actually offers you with preventive health checkup at renewal."
    },
    {
      "id": 8,
      "Q": "Recovery Benefit",
      "A": "If you are admitted in any network hospitals for a certain period of time then you are paid for other financial losses which invariably helps you to meet up the other expenses during your hospitalization."
    },
    {
      "id": 9,
      "Q": "Lifelong Renewability benefit",
      "A": "Once you are covered with any health insurance plan through the platform of Beema1, then your insurance plan will be renewed year on year. You will stay secured and assured with the medical expenses provided to you with every renewal."
    },
    {
      "id": 10,
      "Q": "Benefits of Ayush",
      "A": "Through Beema1, you are covered for hospitalization expenses that happen due to Ayush treatments as well. We believe in your beliefs. Alternate therapies like Ayurveda, Homeopathy, Unani, and Siddha are all included in the Ayush benefits."
    }
  ]

  const half = Math.ceil(UnderstandtheCriticalIllness.length / 2);
  const firstHalf = UnderstandtheCriticalIllness.slice(0, half);
  const secondHalf = UnderstandtheCriticalIllness.slice(half);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);




  return (
    <>
            <Helmet>
        <title>Fair advice for best Life and Critical Illness Coverage</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Fair advice for best Life and Critical Illness Coverage, Buy Critical Illness Policy, Best critical Illness Insurance" />
      </Helmet>
   <section className="car-insurance insurance-all main-health mainnargin-insurance">
  <div className="container">
    <Redirect/>
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one ">
        <div className="healthdiscription">
          {/* <h1>Critical Illness Insurance Policy</h1> */}
          <h1>Fair advice for best Life and Critical Illness Coverage</h1>


          <div>
      <span style={{ textAlign: 'justify' }}>
        {initialText}
      </span>
    </div>





        </div>
        <div className="insurance-btn-set">
          <button
            type="button"
            className="reminderbutton"
            data-toggle="modal"
            data-target="#reminderpopup"
          >
            
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              fontSize="15pt"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "rgb(251, 167, 27)", margin: "0px 5px" }}
            >
              <circle cx={12} cy={13} r={8} />
              <path d="M12 9v4l2 2" />
              <path d="M5 3 2 6" />
              <path d="m22 6-3-3" />
              <path d="M6.38 18.7 4 21" />
              <path d="M17.64 18.67 20 21" />
            </svg>
            Set Reminder
          </button>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
               <FastImage
                  url={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                  width={auto}
                  height={auto}
                  alt={"arrow"}
                  src={"Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"}
                />
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
        <div className="form-main">
          <form id="insurance_form_get_plan">
            <div className="d-flex align-items-center form-input">
              <h6>
                Compare &amp; Buy Customized Health Plans starting at just
                <span className='specialprice'>Rs ₹257/month*</span>
              </h6>
              <div className="blue-btn">
              <Link to={"/healthStep1"}>
               
               <button
                 style={{
                   backgroundColor: "transparent",
                   border: "none",
                   color: "white",
                   fontWeight: 400
                 }}
               >
                 Get Your Quotes
               </button>
               <img alt="" src="" className="" />
      
             </Link>
                
              </div>
              <p className="insurance-text-light">
                By clicking, I agree to
                <a href="/termsandcondition">*terms &amp; conditions</a> and 
                <a href="/privacy-policy"> privacy policy</a>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


<div className="inbulitmargine"></div>




         
          <div className="fixwidthDivtext">

       
<div className="container">
<h4 className='healthHeading'>
            Critical illness insurance
            </h4>
            <div className="yellowline"></div>
  <div className="row">
    <div className="col-md-12">
    <p className="textformat">
              Many health insurance plans available today include coverage for
              critical illnesses and provide additional benefits to
              policyholders. For more information about these plans, see the
              table below
            </p>
            
            <p className="textformat">
             stand apart from
              regular health insurance by covering severe, long-term illnesses
              that demand high-priced medical treatments, offering high-sum
              insured options. These plans are specifically designed to address
              the treatment expenses and the loss of income that can occur when
              the insured is diagnosed with a critical illness.
            </p>
              <p className="textformat">
              Below is a list of common critical illnesses typically covered by
              <b>critical illness insurance</b> policies:
            </p>
              <p className="textformat">
              Here are some critical illnesses commonly covered by insurance
  
            </p>
    
              <p className="textformat">
              Critical illness insurance plans cover specific diseases,
              which can differ between insurers. To maximize the benefits of a
              critical illness policy during an emergency, it's essential to be
              fully informed about the illnesses covered by your plan.
            </p>
    </div>
  </div>
</div>
        

          
       
            </div>


          <div className="fixwidthDivtext">
            <div className="container">
            <h4 className='healthHeading'>
              Types of Critical Illness Coverage In Health Insurance
            </h4>
            <div className="yellowline"></div>
              <p className="textformat">
            
               Critical illness coverage is available from both general
              and health insurance companies, and it comes in two primary forms:
            </p>
              <div className="row">
                <div className="col-md-12">
        
           
                
              <p className="textformat"> 
              <span className="liststyl2">
                  Rider
              </span>-
              A rider, or add-on, is an additional benefit you can attach to a
                standard health insurance policy by paying an extra premium.
                This coverage is defined by the base policy under which the
                rider is purchased, and the coverage amount cannot exceed that
                of the base policy.
                One prominent advantage of a rider is that it typically does not need a medical examination.
                </p>
              
                 <p className="textformat"> 
                <span className="liststyl2">
                  Standalone Policy:
                </span>-
                A standalone critical illness policy is a fixed-benefit policy
                that covers a wide range of critical illnesses. This type of
                policy offers more flexibility in terms of the insured amount
                and requires a medical exam for coverage. Additionally, a
                standalone critical illness policy can be transferred to a
                similar policy with another insurer, whereas a rider cannot be
                ported independently.
              </p>
             
                </div>
              </div>
            </div>
       

              </div>


              <div className="fixwidthDivtext">
              <div className="container">
              <h4 className='healthHeading'>
              Advantages Of Critical Illness Policy
             </h4>
            <div className="yellowline"></div>
              <p className="textformat">
              Critical illness health insurance plans provide the insured
              with the following advantages:
            </p>
                </div>
              </div>



         
<div className="fixwidthDivtext">
<div className="container">
  <div className="row">
  <div className="col-md-6 col-12 d-flex justify-content-center align-items-center"> 
      <FastImage url="/Website%20Img/Consultative%20sales-rafiki.png?updatedAt=1718791866934" width={auto} height={auto} className="fmailimagegifs"/>
    </div>
    <div className="col-md-6 d-flex justify-content-center align-items-center">

    <div>
      {
        AdvantagesCl.map((data,index)=>{
          return(
            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <AccordionSummary
              expandIcon={<MdOutlineExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="accordingback"
            >
            <li className="listcol"><IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/>{data.Q}</li>
            </AccordionSummary>
            <AccordionDetails>
            {data.A}
            </AccordionDetails>
          </Accordion>
          )
        })
      }
    

    </div>
    </div>

    </div>
  </div>
</div>






<div className="fixwidthDivtext">

<div className="container">
<h4 className='healthHeading'>
              Know From Us How To Choose The Optimal Critical Illness Health
              insurance plans?
            </h4>
            <div className="yellowline"></div>
  <div className="row">
    <div className="col-md-12">
    <div className={readmore ? "Readmorecritical" : "Readless"}>

<p className="textformat">
        <span className='specialprice'>
          The Number Of Critical Ailments Covered:-
        </span>
        First, you want to check how many critical illnesses the plan
        covers. It's not just about quantity though; you need to
        consider the quality too. Some insurers might boast about
        covering tons of rare diseases, but what you want are the common
        ones like cancer and heart conditions. Aim for a plan that
        covers at least these basics, and ideally, it should cover
        around 30 or more illnesses in total.
      </p>
      
      <p className="textformat">
        <span className='specialprice'>
          Staggered/Lump Sum payout option:-
        </span>

        Now, onto the payout options. When it comes to
        critical illness coverage you usually have two choices:
        staggered or lump sum.
      </p>
    
      <p className="textformat">
      <span className='specialprice'>
       Staggered:-
        </span>Imagine this - Vivek, at 23, decided to
   get critical illness health insurance. Fast forward 6
      years, he finds out he has end-stage renal failure.Now, here's the
      thing: Vivek's insurance plan had a sum insured of ₹30 lakhs, and
      it had a staggered
      
      claim payout. So instead of getting all the money at once, it's
      paid out to him and his family in installments they had agreed on
      beforehand. With this money, his family can cover Vivek's
      treatments, whether they're in India or abroad. Plus, they can
      take care of any other financial responsibilities that come up
      along the way.
      
      With this money, his family can cover Vivek's treatments, whether
      they're in India or abroad. Plus, they can take care of any other
      financial responsibilities that come up along the way.
    </p>
    <p className="textformat">
      <span className='specialprice'>
        Lump Sum:-
      </span>
      Let's imagine Anisha, who's 25, decides to get critical illness
      insurance. She goes for the lump sum payout option. Fast forward 4
      years, she finds out she has a benign brain tumor. So she sends
      all her medical reports to her insurer and files a claim.
      
      The insurer checks everything, then offers her the whole sum
      insured all at once. Now Anisha can use this money for her
      treatments, put some aside for savings or investments, save up for
      future family needs, and handle any loans or EMIs she might have.
  
    

      Now, here's the thing: If Anisha had initially chosen staggered
      payouts but later wanted a lump sum, she might be able to switch.
      But it depends on the insurer.
      
      In critical illness cases, it's smart to go for an insurance
      policy that gives you the option for either staggered or lump sum
      payouts or just the lump sum. That way, you have the flexibility
      to manage your money however works best for you.
  
    </p>

    <p className="textformat">
      <span className='specialprice'>
        Sum insured:-
      </span>
      when you're picking a sum insured for your critical illness
      insurance, you need to think about covering your treatment costs,
      and any debts or loans you might have, and also plan for your
      family's future needs. A policy with coverage of ₹25 lakhs or more
      is a solid option to make sure you've got all your bases covered.
    </p>

    <p className="textformat">
      <span className='specialprice'>
        The credibility of the insurer:-
      </span>
      Let's talk about critical illness coverage. It's all about
      getting a lump sum payout, but here's the catch: you only get that
      money when you're diagnosed with a critical illness. That's why
      it's crucial to choose an insurer you can trust. You don't want
      someone who's going to drag their feet when it's time to pay out.
    </p>
      <p className="textformat">
      <span className='specialprice'>
        Pre-existing ailment coverage/ waiting period:-
      </span>
      Alright, let's break it down. Typically, critical illness plans
      don't cover pre-existing conditions right off the bat, or they
      make you wait a while before you're fully covered. So, here's the
      thing to keep in mind: make sure your insurer does cover
      pre-existing conditions, and ideally, they should have short
      waiting periods. A waiting period of around 3 years is usually
      reasonable.
    </p>
      <p className="textformat">
      
      <span className='specialprice'>
        The survival period clause:-
      </span>
      Let's talk about something called the survival period in critical
      illness insurance policies. Here's the deal:
      
      Imagine Himon. He got himself a critical illness insurance plan,
      and it came with a survival period of 30 days. Now, fast forward a
      couple of years, he's diagnosed with a serious end-stage lung
      infection. But here's the catch: Himonhas to stay alive for at
      least 30 days after the diagnosis to get the lump sum payout. If
      heaven forbid, he passes away within that time frame, he won't get
      the benefit from the policy.
      
      
      So, when you're picking a plan, look for one with the shortest
      survival period possible. Now, taking all these factors into
      account, let's look at some of the top critical illness health
      insurance policies out there.
    </p>

</div>
<div className="redbtn">

<button onClick={HandelRead} className="Readmorebtn">{readmore ? "Read More" : "Read Less"}</button>
</div>
</div>
</div>
</div>
</div>       




        <div className="fixwidthDivtext">
          <div className="container">
          <h4 className='healthHeading'>
              Why Should You Buy Critical Illness Policy ?
            </h4>
              <div className="yellowline"></div>
            <div className="row">
              <div className="col-md-12">
              <p className="textformat">
              Always remember that you are braver than you believe, you are
              stronger than you seem, and smarter than you think. Getting over
              any critical illness requires a bit of courage and smartness to
              dig a little deep to find out.
              
              
              While regular health insurance covers your hospital expenses, a
              critical illness policy is like a safety net for those big and
              unanticipated situations in life. Think of it as a fixed benefit
              plan that gives you a lump sum payout 
              
              if you are detected with a serious illness like cancer or stroke.
              
              
              Now, here's where it gets interesting: Most health insurance plans
              don't automatically cover critical illnesses. And even if they
              offer it as an add-on, you usually have to pay extra, and
              sometimes it's not even enough to cover everything.
              
              
              But with a serious illness insurance plan, you're covered for all
              the big hitters listed in the policy—no add-ons needed. So, even
              if you already have health insurance through your job or
              elsewhere, it's worth considering a critical illness policy.
              Because let's face it, none of us are immune to life's curveballs,
              and the cost of treatment for a critical illness can be pretty
              mighty.
            </p>
              </div>
            </div>
          </div>
      
      
          
            </div>



            <div className="fixwidthDivtext">

            <div className="container">
            <h4 className='healthHeading'>
              Exclusions Under Critical Illness Insurance Plan
            </h4>
            <div className="yellowline"></div>
              <p className="textformat">
              As quoted by Martin Luther King Jr, “ We must accept finite
              disappointment, but never lose infinite hope.” So, with the very
              thought in mind let's go over some important points about what
              critical illness insurance plan typically doesn't cover:
            </p>
              <div className="row">
                <div className="col-md-12">
                <p className="textformat">
              
              <span className='specialprice'>
                Injuries from Adventure Sports:-
              </span>
              If you get hurt while doing adventurous stuff like paragliding
              or mountain climbing, your critical illness insurance probably
              won't cover the treatment costs.
            </p>
           
            <p className="textformat">
              
              <span className='specialprice'>
                
                Self-Inflicted Injuries:-
              </span>
              If you intentionally harm yourself, like attempting suicide,
              your critical illness insurance won't cover those injuries.
            </p>
        
            <p className="textformat">
              
              <span className='specialprice'>
                War And Riots:-
              </span>
              If you end up needing medical care because of war, riots, or
              similar situations, your critical illness insurance won't chip
              in for those expenses.
            </p>
       
            <p className="textformat">
              
              <span className='specialprice'>
                Sexually Transmitted Diseases:-
              </span>
              Diseases like HIV/AIDS, which are transmitted sexually, usually aren't covered by critical illness insurance.
            </p>
           
            <p className="textformat">
              
              <span className='specialprice'>
                Obesity Or Cosmetic Surgery:-
              </span>
              If you're looking into treatments for weight loss or cosmetic
              procedures, critical illness insurance typically won't cover
              those either.
            </p>
        
            <p className="textformat">
            It's important to keep these exclusions in mind when you're
            considering critical illness insurance.
          </p>
                </div>
              </div>
            </div>
         
          
          
            </div>





            <div className="fixwidthDivtext">
        
              <div className="container">
              <h4 className='healthHeading'>
              How Does A Critical Illness Policy Work?
            </h4>
            <div className="yellowline"></div>
            <p className="textformat">
              Here's how it works: Once you're diagnosed with a critical illness
              covered by your policy, you'll get a lump sum payout. When you
              file a claim under your critical illness plan, the benefits will
              be paid directly to you.
            </p>
                <div className="row">
                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <div>

                  <p className="textformat" style={{textAlign:'justify'}}>
              Now, what can you do with this money? Well, pretty much anything
              you need. It can be used to cover medical bills,  keep up with your
              mortgage or rent, pay for groceries and utilities, fund your
              child's education, or even seek treatment abroad if necessary. And
              if there's any experimental treatment that might help in your
              recovery, you can use the money for that too.
            </p>

              <p className="textformat" style={{textAlign:'justify'}}>
              In a nutshell, a critical illness policy is there to ease your
              worries and let you focus on getting better when faced with a
              serious illness.
            </p>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <FastImage url="/Website%20Img/fever_3893449.png?updatedAt=1729846141172" width={auto} height={auto} className="fmailimagegifs"/>
                  </div>
                </div>
              </div>

         
           
            </div>





            <div className="fixwidthDivtext">

          <div className="container">
          <h4 className='healthHeading'>
            Critical Illness Insurance Policy vs. Health Insurance Policy 
            </h4>
            <div className="yellowline"></div>
              <p className="textformat">
              Let me tell you about Ricky. When he found out he had a brain
              tumor, he was completely shattered.
            </p>
            <div className="row">
<div className="col-md-12">
<p className="textformat">
              Luckily, his
       
                health insurance policy

              covered his treatment costs with a sum assured of Rs. 10 Lakh. But
              even with that relief, things took a turn for the worse when his
              business revenues started dropping. Because his illness was so
              serious, he couldn't manage his business anymore, and that took a
              toll on both his health and his finances.
            </p>
              <p className="textformat">
              You see, a lot of folks like Neeraj don't realize that there's a
              big difference between a health insurance policy and a critical illness insurance plan.
            </p>

              <p className="textformat">
              Here are the differences between a
      
                critical illness insurance
       
              plan and a health insurance plan.
            </p>
      
</div>
            </div>
          </div>

           
          

           
            </div>



<div className="fixwidthDivtext">
<div className="container">
<div className="tableoverflow">
              <h4 className='healthHeading'>Below mentioned are the differences between a critical illness insurance plan and a health insurance plan.</h4>
                <div className="yellowline"></div>
          <table className="criticalIllnessVsFamilyHealth-table">
  <thead>
    <tr className="criticalIllnessVsFamilyHealth-header">
      <th className="criticalIllnessVsFamilyHealth-headerCell">Parameters</th>
      <th className="criticalIllnessVsFamilyHealth-headerCell">Critical Illness Insurance Plan</th>
      <th className="criticalIllnessVsFamilyHealth-headerCell">Health Insurance Plan</th>
    </tr>
  </thead>
  <tbody>
    {[
      {
        parameter: "Meaning",
        criticalIllness: "Critical illness insurance steps in when you're facing serious, life-threatening diseases like tumors or permanent paralysis.",
        healthInsurance: "Regular health insurance has your back for a wide range of medical needs, from hospitalization expenses to other healthcare costs.",
      },
      {
        parameter: "Benefits",
        criticalIllness: "You don't have to be hospitalized to get the benefits of your policy with critical illness insurance. Just getting diagnosed is all it takes to receive a lump sum payment.",
        healthInsurance: "If you do end up in the hospital, you can still get reimbursed for your expenses by submitting your bills. Plus, you might have the option for cashless treatment at hospitals that are part of your insurer's network.",
      },
      {
        parameter: "Coverage",
        criticalIllness: "It provides coverage for up to 36 critical illnesses.",
        healthInsurance: "It's got you covered for everything from pre-hospitalization expenses to post-hospitalization costs.",
      },
      {
        parameter: "Waiting Period",
        criticalIllness: "There is a waiting period that depends on the seriousness of the illness.",
        healthInsurance: "There is a waiting period of 30 days.",
      },
    ].map((item, index) => (
      <tr key={index} className="criticalIllnessVsFamilyHealth-row">
        <td className="criticalIllnessVsFamilyHealth-cell">{item.parameter}</td>
        <td className="criticalIllnessVsFamilyHealth-cell">{item.criticalIllness}</td>
        <td className="criticalIllnessVsFamilyHealth-cell">{item.healthInsurance}</td>
      </tr>
    ))}
  </tbody>
</table>

</div>
</div>
</div>          









<div className="fixwidthDivtext">


            <div className="container">
            <h4 className='healthHeading'>
            Process to File a Claim for Critical Illness Policy
            </h4>
            <div className="yellowline"></div>
            <p className="textformat">
              First things first, if you need to make a claim, get in touch with
              your insurance company and let them know what's up. They'll walk
              you through the process.
            </p>
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <div>

                <div className="criticalboxstepper">
              <div className="numberboxcritical">1</div>
            <span className="criticaltext">A Filled-Out Claim Form</span>
            </div>
            <div className="criticalboxstepper">
              <div className="numberboxcritical">2</div>
            <span className="criticaltext">A Copy Of Your ID Card</span>
            </div>
            <div className="criticalboxstepper">
              <div className="numberboxcritical">3</div>
            <span className="criticaltext">Medical certificate confirms your diagnosis</span>
            </div>
            <div className="criticalboxstepper">
              <div className="numberboxcritical">4</div>
            <span className="criticaltext">Detailed Discharge Summary</span>
            </div>

                </div>
                  </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <FastImage url="/Website%20Img/Medicine-pana.png?updatedAt=1727421057968" width={auto} height={auto} className="fmailimagegifs"/>
                </div>
              </div>
              <p className="textformat">
              Once you've handed over all the necessary documents, the claim
              team will take it from there. They'll double-check everything and
              let you know if your claim is good to go or if there are any
              issues. Either way, they'll keep you in the loop every step of the
              way.
            </p>
            </div>
       

            
           
            {/* <li>A copy of your ID card</li>
            <li>
              A medical certificate from your doctor confirming your critical
              illness diagnosis
            </li>
            <li>And a detailed discharge summary</li> */}
            
    
            </div>
      
  





            <div className="fixwidthDivtext">

         <div className="container">
         <h4 className='healthHeading'>
              How Can Beema1 Help You ?
            </h4>
            <div className="yellowline"></div>
              <p className="textformat">
              Facing a critical illness can drain your savings faster
              than you can imagine. But there's a way to protect yourself
              financially: a critical illness plan.
              
              
              With Beema1, an individual can compare and choose the best critical illness plan in a matter of a few clicks at Beema1. We will help you to browse across the plan that provides the best insurance benefits at the most cost effective insurance premium.  Our team is here to help you
              navigate through the options and make sure you get the right
              coverage at the best price.
            </p>
         </div>
         </div>
           



            
              
        
      
        
           
         <div className="fixwidthDivtext">
          
           <div className="container">
           <h4 className='healthHeading'>
              
              Understand The Critical Illness Coverage Benefits That You Receive Through Beema1
              </h4>
              <div className="yellowline"></div>
              <p className="textformat">
              
              <span className='specialprice'>
                Hospitalization Expense
              </span>
              - You are covered through Beema1 for your hospitalization expenses
              that ideally include your hospital room rent, ICU, medical
              investigations, doctor consultation fees, surgeries etc due to
              illnesses and injuries.
            </p>
            <div className="row">
            <div className="col-md-6">


   
{firstHalf.map((data, index) => (
  <Accordion key={`panel1-${index}`} expanded={expanded === `panel1-${index}`} onChange={handleChange(`panel1-${index}`)}>
    <AccordionSummary
      expandIcon={<MdOutlineExpandMore />}
      aria-controls={`panel1-${index}-content`}
      id={`panel1-${index}-header`}
      className="accordingback"
    >
      <li className="listcol">
        <IoMdAddCircle style={{color:'#2959B8', marginLeft:'5px', marginRight:'5px'}} fontSize={'15pt'} />
        {data.Q}
      </li>
    </AccordionSummary>
    <AccordionDetails>
      <p className="textformat">{data.A}</p>
    </AccordionDetails>
  </Accordion>
))}
</div>

<div className="col-md-6">
{/* Second Accordion */}
{secondHalf.map((data, index) => (
  <Accordion key={`panel2-${index}`} expanded={expanded === `panel2-${index}`} onChange={handleChange(`panel2-${index}`)}>
    <AccordionSummary
      expandIcon={<MdOutlineExpandMore />}
      aria-controls={`panel2-${index}-content`}
      id={`panel2-${index}-header`}
      className="accordingback"
    >
      <li className="listcol">
        <IoMdAddCircle style={{color:'#2959B8', marginLeft:'5px', marginRight:'5px'}} fontSize={'15pt'} />
        {data.Q}
      </li>
    </AccordionSummary>
    <AccordionDetails>
      <p className="textformat">{data.A}</p>
    </AccordionDetails>
  </Accordion>
))}

</div>
            </div>
           </div>
       
           </div> 



      
   


      <PartnerCompany />
      <FAQ />
    </>
  );
};

export default CriticalillnessInsurance;
