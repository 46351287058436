import React, { useState } from "react";
import { useEffect } from "react";
import "./PreviousPolicy.css";
import { MdPolicy } from "react-icons/md";
import axios from "axios";
import { Container } from "react-bootstrap";
import { fetchQuickQuote } from "../../Api/Api";
import Swal from "sweetalert2";
import Loader from "../../../AllPageComponents/Loader";
import { Box, Typography, Button, Card, CardContent } from '@mui/material';


const PreviousPolicy = () => {
  const [healthData, setHealthData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchDashboardData = async (userToken) => {
    try {
      if (!userToken) {
        console.warn("User token not found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/dashboard/dashboard_data`,
        {
          params: { token: userToken },
        }
      );
      if (response.data.status === false) {
        Swal.fire({
          title: "Session Expired",
          text: "Your is Session Expired",
          icon: "error",
          confirmButtonText: "Done",
        });
        localStorage.clear();
        window.location.href = "/";
      }
      return response;
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      let userToken = localStorage.getItem("userToken");
      if (userToken) {
        try {
          let data = await fetchDashboardData(userToken);
          console.log(
            "the data use",
            JSON.parse(data?.data?.data?.searchData[0]?.response)
          );
          setHealthData(JSON.parse(data?.data?.data?.searchData[0]?.response));
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching policy data", error);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mainmargin-insurance">
        <div className="health-insurance-container">
          {/* Header Section */}
          <header className="health-insurance-header">
            <h1 className="health-insurance-title">
              <MdPolicy />
              Your Recent Search
            </h1>
          </header>
  
          {/* Health Insurance Cards Section */}
          <section className="health-insurance-details">
            {Object.keys(healthData).map((key, index) => {
              const plan = healthData[key][0];
              if (!plan || Object.values(plan).some(value => !value)) {
                return null; 
              }
              return (
                <Box
                  key={index}
                  display="flex"
                  width="100%"
                  margin="10px auto"
                  boxShadow={3}
                  borderRadius={4}
                  overflow="hidden"
                  bgcolor="#fff"
                  sx={{ height: '140px' }} // Fixed height for both image and card
                >
                  {/* Left Side: Passport-Size Logo */}
                  <Box
                    flex={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor=" #2072bd;"
                    p={2}
                    padding="10px"
                    maxWidth="264px"
                  >
                    <img
                      src={plan?.insurerLogo}
                      alt="Company Logo"
                      style={{
                        height: '120px',
                        objectFit: 'contain',
                        borderRadius: '8px',
                        border: '2px solid #ddd',
                        width: '100%',
                        backgroundColor: 'white',
                        padding:"17px"
                      }}
                    />
                  </Box>
  
                  {/* Right Side: Plan Details with Matching Height */}
                  <Box flex={2} p={2} bgcolor="#f9f9f9" display="flex" alignItems="center" padding="0px">
                    <Card sx={{ boxShadow: 2, borderRadius: 2, width: '100%' }}>
                      <CardContent>
                        <Typography variant="h6" fontWeight="600" gutterBottom>
                          {plan?.planName}
                        </Typography>
  
                        <Typography variant="body2" color="textSecondary">
                          Coverage Amount: <strong>{(plan?.sumInsured)/100000}lakh</strong>
                        </Typography>
  
                        <Typography variant="body2" color="textSecondary">
                          Total Premium: <strong>{plan?.premiumBreakup?.totalPremium}</strong>
                        </Typography>
  
                        {/* Call to Action Button */}
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            mt: 1,
                            padding: '6px 12px',
                            fontSize: '12px',
                            borderRadius: '8px',
                          }}
                        >
                          Get a Quote
                        </Button>
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              );
            })}
          </section>
        </div>
      </div>
      )}
    </>
  );
};

export default PreviousPolicy;
