import React, { useState } from "react";
import { TextField, Box, Typography, Grid } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./AnswerSetSix.css"; // Importing the CSS file;
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import { updateRelianceQAlist , selectReliancecheckedQuestion} from "../../redux/Slice";

const AnswerSetSix = ({ pindex, qdata, parentqData,
  updateRelianceQAlist,
  ReliancecheckedQuestion,
  setRelianceCheckedQuestio
 }) => {
  const [expected, setExpected] = useState();


  const [dataObj, setDataObj] = useState();

  // const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  // const dispatch = useDispatch();

  const handleClick = (event) => {
    event.stopPropagation();
  };
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };




  useEffect(() => {
    if (ReliancecheckedQuestion) {

      console.log("hdfc checked question",ReliancecheckedQuestion )
      const keys = Object.keys(ReliancecheckedQuestion);
      const numbersToCheck = [6];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => ReliancecheckedQuestion[number.toString()]);


      console.log("the resultObjects", resultObjects) 
      let val;
      
 
      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex))
        {
          let data_one = resultObjects[key][pindex];
          // console.log("data_one",qdata.question_id)
          let val = data_one.find(item => item.Options[0].OptionId == qdata.QuestionSetCode );
          if (val != undefined)
          {
             console.log("val in for",val)
             let newobject = val;
             console.log("the newObject", newobject);
     
             if (newobject) {
     
               newobject.Options.map((item , index)=>{
                 if(item.OptionId == qdata.QuestionSetCode){
                  
                   
                     setDataObj(item);
                     return
                   }
               })
             }
          }
        }
     }

   
      
    }
  }, [ReliancecheckedQuestion]);

  console.log("the final data in set six", dataObj);

  const normalizeDate = (dateString) => {

    if (dateString) {
      const [year, month, day] = dateString.split("/");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    
    }
  };
  const parseDate = (dateStr) => {
    const dateParts = dateStr.split('/');
    // Check if the parts are valid
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // month is 0-indexed
      const year = parseInt(dateParts[2], 10);
      return new Date(year, month, day);
    }
    return null; // Handle invalid date format
  };
 // useEffect to set the initial date if dataObj is present
//  useEffect(() => {
//   if (dataObj && dataObj.ExpectedDeliveryDate) {
//     const initialDate = parseDate(dataObj.ExpectedDeliveryDate);
//     if (initialDate) {
//       setSufferingSince(initialDate);

//     }
  
//   }
// }, []);

const convertMMDate = (dateString) =>{
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="set-six-body">
        
        <Grid
          item
          xs={6}
          className="set-three-date-upper"
          onClick={(event) => {
            handleClick(event);
          }}
        >
          {/* <DesktopDatePicker
            label="Expected Delivery Date"
           inputFormat="MM/yyyy"
            value={sufferingSince ? sufferingSince : ""}
            onClick={(e) => {
              handleClick(e);
            }}
            onChange={(e) => {
              console.log("e.target.value", e)
              setSufferingSince(e);
              dispatch(
                updateRelianceQAlist({
                  name: "ExpectedDeliveryDate",
                  value: formatDate(e),
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                })
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                className="bordered-textField"
                InputLabelProps={{ shrink: true }}
              />
            )}
          /> */}
           <Flatpickr
              className="flat-date-six"
              value={expected ? expected : dataObj?.ExpectedDeliveryDate}
              options={{
                enableTime: false, // Disable the time picker
                dateFormat: "d/m/Y", // Set the desired date format
              }}
              placeholder="Expected Delivery Date"
              onChange={(newValue) => {
                setExpected(newValue);
                console.log("data in set six new", convertMMDate(newValue[0]))

                // dispatch(
                  updateRelianceQAlist({
                    name: "ExpectedDeliveryDate",
                    value: convertMMDate(newValue),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                // );
              }}
            />
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default AnswerSetSix;
