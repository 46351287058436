import React, { useState, useEffect } from "react";
import "./TermInsurence.css";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import FAQ from "../AllPageComponents/FAQ/FAQ";
import TermInsuranceForm from "./TermInsuranceForm";
import Redirect from "../AllPageComponents/Rederict";
import Typeofpolicy from "../AllPageComponents/TypeofPolicy/Typeofpolicy";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { LuAlarmClock } from "react-icons/lu";
import TermFormtwo from "./TermFormtwo";
import { GrDocumentUser } from "react-icons/gr";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { MdOutlineExpandMore } from 'react-icons/md';
import { IoMdAddCircle } from "react-icons/io";
import { Helmet } from "react-helmet-async";

const TermInsurence = () => {

  const [showFull, setShowFull] = useState(false);
  const [showFull2, setShowFull2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialText =
    'Beema1’s Term Insurance Plan Comparison tool empowers you to easily compare quotes from leading insurance providers in India, ensuring you find the perfect plan for your needs and budget. Get personalized quotes from insurers and secure your future.';

const [Readmorebtn, setReadmorebtn] = useState(true)

const Readmorebutton1 = () =>{
  setReadmorebtn(!Readmorebtn)
}

const [Readmorebtn2, setReadmorebtn2] = useState(true)

const Readmorebutton2 = () =>{
  setReadmorebtn2(!Readmorebtn2)
}


const Benifits = [
  {
      "id": 1,
      "text":  "Most pocket-friendly and simple life insurance plans. The main motto is to provide financial security to the policyholder’s family, at the time of unfortunate events of death."
  },
  {
      "id": 2,
      "text": "In case, policyholder passes away before the policy term is over, then the insurer provides their family with predetermined death benefit."
  },
  {
      "id": 3,
      "text": "Paying premiums for the complete coverage tenure is not required. Premium is paid until an individual is working. An individual has a free will to select a premium payment term which is aligned with the age of retirement."
  },
  {
      "id": 4,
      "text": "There are insurance companies who provide plans with return of premium benefits. In such type of plans, the policyholder survives the policy term and if no claim is raised, then the premiums are returned back to the policyholder."
  },
  {
      "id": 5,
      "text": "Complete malleability to opt for receiving payouts as 'monthly earnings' along with the lumpsum amount. This month on month gain is again divided into fixed monthly income plans or increasing monthly income plans."
  },
  {
      "id": 6,
      "text": "The most highlighted feature of the best term plans in India is that those plans generally offer huge sum assured, which can extend up to INR 1 crore, assessable at low cost premiums."
  },
  {
      "id": 7,
      "text": "The policy holders have the provision to choose rider options, to make the pure term plan more broadly comprehensive in coverage. The riders are mentioned below:"
  },
  {
      "id": 8,
      "text": "The policyholder has the convenience to boost up the coverage at various important life stages such as marriage, new born in the family, education of children or purchasing a home, acquiring a property. The benefit out of it is that one has the complete flexibility to increase/enhance their life cover without going through medicals or documentation"
  }
]



const boxClasses = ["boxterm", "boxterm2", "boxterm3","boxterm4","boxterm5","boxterm6","boxterm7","boxterm8"];



const [activeTabs, setactiveTabs] = useState(1)



const pointtoremember = [
  {
      id:1,
      Q:"Affordable term plan ",
      A:" The premium of a financially reasonable term plan is based on the age and health conditions of the policyholder. The premium amount is low for non-smokers and for the policy holders leading a healthy lifestyle and the premium would be charged at a higher rate if the policy holder is a smoker or leads an unhealthful lifestyle. Hence, it is advisable to select a term plan at an early age, preferably whenever you start earning, as one can get insured with higher coverage at a very pocket-friendly premium."
  },
  {
      id:2,
      Q:"Policy benefits",
      A:"Please refer to all the benefits and the living benefits those are the part of a term insurance plan before you purchase one. Most of the term plans includes terminal illness or accidental death rider at nil or zero cost. Hence, it is always beneficial to check such minor and major details regarding the policy benefits and how the nominee will receive the payment or how the policyholder will be paid at the time of unfortunate events."
  },
  {
      id:3,
      Q:"Avail term insurance plan through online platforms",
      A:" It is also wise to purchase the term plan through the online platforms rather than through any agent. Online plans are available with comparatively better options and reasonable offers or discounts than that of the offline options. Insurance plans you opt for through online platforms are available at lower premium rates. Therefore, instead of approaching any insurance agent you may conveniently avail the best term insurance plan through the online platforms."
  },
  {
      id:4,
      Q:"Claim settlement ratio of term insurance plans (CSR)",
      A:"It is vital to consider the CSR of the insurance company when you choose the best term insurance plan for yourself and which portrays the number of claims settled and paid by the insurance provider against the exact number of claims filed by the policyholder or their nominees. CSR higher than 80% is usually considered as a decent claim settlement ratio."
  },
  {
      id:5,
      Q:"Be smart while choosing your term insurance policy",
      A:"Entering the policy tenure and sum assured coverage as per your future needs and requirements is a must while applying for a term insurance plan online. Your policy term ends at 10,20, or 30 years, if you renew your term insurance plan up to a certain age, it might be expensive for you as you will be renewing your policy at a later age. An insurance advisor will advise you to renew your term insurance policy till the time you retire so that you do not end up paying high renewal expenses."
  }
]

const typeofterm = [ 
  {
      id:1,
      Q:"Level Term Plan",
      A:"Commonly it is known as a Level term plan. A kind of term plan that is provided by most of the insurance companies in India, to its policyholders. In this kind of term plan, the sum assured amount opted for remains constant at the beginning of the policy year and throughout the full policy term."
  },
  {
      id:2,
      Q:"Increasing Term Plan",
      A:"The policy holder has the option to heighten their scope of sum assured amount at different stages of life which can be marriage, purchasing a new property, children’s’ education and other financial responsibilities as well. This plan is constantly a good opportunity for helping to keep up stable with the ever-increasing prices and thus ensuring that the sum assured cover that one chooses for is satisfactory enough to cover inflation also."
  },
  {
      id:3,
      Q:"Decreasing Term Insurance",
      A:"This is the opposite of increasing term insurance plan. In this case, the sum assured coverage amount reduces at a prefixed rate as and when age increases. Therefore, as the age increases the financial obligations also decreases. Hence, the requirement for a higher sum assured can be avoided."
  },
  {
      id:4,
      Q:"Whole Life Plan",
      A:"Most of the term insurance policies provide cover for a period declared, and certain policies cover for lifetime as well."
  },
  {
      id:5,
      Q:"Convertible Term Insurance Plan:",
      A:"A convertible term insurance plan is a policy which can be reassigned or transferred with ease into another sort of life insurance plan. An insight, in a convertible plan, a term insurance plan can be transformed into a whole life plan or changed into an endowment plan at a later stage, based on an individual’s requirement. When a policyholder expects that their financial responsibilities might change in the future, in such situations they can opt for this type of term plan."
  },
  {
      id:6,
      Q:"Regular Term Plan",
      A:"Regular term plans offer the death benefit to the nominee of the policy holder, in case of the sudden demise of the policy holder."
  },
  {
      id:7,
      Q:"Hundred Percent Refund of Premium at Absolute No-Cost",
      A:"These 100% Premium Refund plans provide the option of exciting the plan at a certain stage and receiving the premiums"
  },
  {
      id:8,
      Q:"Return of Premium Plan",
      A:"These plans provide all the premiums paid off during the policy period back at the time of the policy. This is the new and additional feature which has been added to the term insurance plans in the last few years to enhance it into a more feasible and a lucrative option. A return of premium term plan provides with the options of some sort of savings component, which is generally not offered by the pure/conventional term plans. A term plan with return of premium component in it is designed to guarantee you with survival benefit, only if you survive the tenure of the policy plan. If you survive the policy term, in such a scenario all the paid premiums till the date of maturity are paid back to you in lump sum as a survival benefit. The return of premium can be assembled only if the policyholder has not raised any claim during the entire tenure of policy term."
  }
]

const [expanded, setExpanded] = useState(null);

const handleChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : null);
};





const Advantage = [
  {
      id: 1,
      name:"High Sum Assured At A Pocket Friendly Rate",
      Dis:" You can procure a high sum assured to secure yourself in a standard term insurance plan at a very pocket-friendly rate."
  },
  {
      id: 2,
      name:"Cost Effective Plans",
      Dis:"The beauty and affordability of term insurance is one of the major advantages of term insurance when you compare the advantages and disadvantages of term insurance. It is very much pocket-friendly."
  },
  {
      id: 3,
      name:"Benefits Of The Riders Added To Term Insurance",
      Dis:"You can extend and enhance the benefits of your term insurance with the Term  Insurance Rider benefits at a very nominal rate. When you attach certain riders to your base plan like critical illness rider accidental death benefit rider terminal illness rider accidental total and permanent disability rider accidental death and disability rider hospital care rider surgical care rider accidental death benefit plus and waiver of premiums you get the enhanced benefits on your base plan."
  },
  {
      id: 4,
      name:"Flexibility Of Availing Term Insurance Rider",
      Dis:"Two of the major flexibilities of availing term plans are the two aspects of convertible and renewable. The life assured can renew the term insurance plan without any medical examination. The life assured and also convert the term plan into an endowment plan for the benefit of the sum assured existing with the extended premium sum. As you stop  paying the premium sum you can easily opt out of the term plan.  The policy will stop but the insured need not worry about the payouts. A term insurance plan only provides a death benefit and no survival benefit"
  },
]

const disadvantage = [
  {
      id: 1,
      name:"No Investments Neither Long Term savings",
      Dis:"Term insurance plans are not for those who are looking for investment options, savings purposes, or long-term retirement plans, and long-term savings for education or marriage."
  },
  {
      id: 2,
      name:"No Profit Plan",
      Dis:"There are nil financial benefits for the policy holders whose term insurance planâ€™s term has already come to an end. This could be counted as one of the facts while discussing the advantages and disadvantages of Term Insurance Plan."
  },
  {
      id: 3,
      name:"No Hedging Against Inflation",
      Dis:"Term insurance plan does not help you in wealth creation and these are nil profit plans as they do not provide any hedgerow against inflation."
  },
  {
      id: 4,
      name:"No Survival Benefit Or Wealth Building",
      Dis:"This type of life insurance plan is crafted in a way that the policyholders or the life assured pays the specified sum only for acquiring life coverage value not for cash accumulation. In situations when the life insured or the policy owner is no more, the sum assured will be paid to the nominee. And the policyholder keeps paying off the payment to the insurance provider throughout the entire term. If the policy holder outlives the entire policy tenure then no benefit is given to the policy holder. The number of premiums paid by the policyholder during the entire policy term shall be paid back to the policyholder."
  },
]

  return (
    <div>
           <Helmet>
        <title>Compare term life insurance Online 2024</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" />
      </Helmet>
      <section className="car-insurance insurance-all mainnargin-insurance">
        <div className="container">
    <Redirect/>
          <div className="row insurance-row-one">
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="healthdiscription">
                {/* <h1>Compare Term Life Insurence Plans Online</h1> */}
                <h1>term life insurance</h1>
                 
                <p className="normaltext">
               {initialText}
      </p>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">

            <FastImage
            url={"/Website%20Img/terminsure.png?updatedAt=1718785227945"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />




    
            </div>
            <TermInsuranceForm/>
            {/* <TermFormtwo/> */}

         
          </div>
        </div>
      </section>
      <div className="inbulitmargine"></div>

<div className="inbulitmargine"></div>

      <div className="fixwidthDivtext">
      <div className="container">
      <h4 className='healthHeading'>What Is A Term Insurance?</h4>
      <div className="yellowline"></div>
        <div className="row">
          <div className="col-md-12">

            <div className={Readmorebtn ? "Readmore" : "Readless"}>

        
            <p className="normaltext" style={{textAlign:"justify"}}>
            Choosing and purchasing a suitable term insurance plan is significant for an individual having dependents and burdened with much financial liabilities. One should plan accordingly if he/she wishes to provide a financial blanket to their loved ones. It helps to shoulder financial debts such as specific indebtedness, mortgages, loans, personal financial borrowings, which can be smoothly settled in case of an unfortunate death of the policyholder. Spouses and dependent children are also covered under the same plan.
Best term insurance plans in India offer the best financial stability in exchange of reasonable premiums at lower rates. However, it would be bit time-taking or might be bit complicated for many to look for and select the best term insurance in India in 2024, that suits your requirements and provides the right life cover amount in lump sum to your family members at the time of unforeseen events like death. It might be quite brain-wrecking to find out the best term insurance in India. Beema1 provides easy-to-use comparison tools and professional advice to assist you in finding the plan that best meets your needs. Think of it as a financial backstop. You pay a relatively small premium each year to secure a much larger payout- potentially 1 crore, 5 crores, or even 10 crores - in the event of your death. This sum can act as a substitute for your income, ensuring your family’s financial stability during a difficult time.
Term life insurance offers clear benefits and strong protections. As long as you have been truthful to your application, the insurance company is obligated to pay off the full death benefit upon your passing. There’s a three-year window for them to investigate potential misrepresentation, but after that period, the payout is guaranteed with some exceptions. The policy won’t pay out if you die by suicide within the first year or while committing a crime.
While the core concept is straightforward, we will delve deeper into the key factors influencing term insurance purchase decisions in the following section. This revised passage uses more formal financial language, avoids informal expressions like “downside” and “hell” and clarifies technical points for a more professional tone. Unsure which term insurance plan is appropriate for you? Beema1 lets you easily compare and purchase term insurance for your needs.
"Term insurance" is a sort of life insurance offering coverage for a specific period, ranging from 5 to 30 years. It offers death benefit to the beneficiaries if the insured individual passes away during the policy term. Term insurance is known for its clarity as it’s economical as compared to other category of life insurances, such as whole life or universal life insurance.

            </p>
            </div>

            <div className="redbtn">
              <button className="Readmorebtn" onClick={Readmorebutton1}>{Readmorebtn ? "Read More" : "Read Less"}</button>
            </div>
          </div>
        </div>
      </div>
      </div>




   

<div className="fixwidthDivtext">
<div className="container">
      <h4 className='healthHeading'> Types of Term Insurance</h4>
      <div className="yellowline"></div>
  <div className="row">
        <div className="col-md-12">

      <div className={Readmorebtn2 ? "Readmore" : "Readless"}>
      <p className="normaltext">
      While we have discussed level term insurance, there are other term life options available. Beema1 simplifies the process of comparing and purchasing term insurance plans to meet your specific needs. Just enter your details and browse through a comprehensive range of quotes from leading insurers in India. Remember, every term insurance plan has its own advantages and disadvantages based on the policyholder’s and beneficiary’s needs. This makes it crucial to compare and purchase term insurance plans carefully. You will find the list of the best term insurance in India from the top term insurance companies in India in the year of 2024. These term insurance plans provide different life cover options for people belonging to different income group. Buy the most suitable one for yourself through the platform of Beema1 and keep your family financially secured. But before you go ahead and select and purchase the best term insurance plan, we should take you through the process of learning and understanding the definition of term insurance, the benefits of it and the functionalities of term insurance plans.
      A term insurance plan implies to be a pure life insurance policy which provides death benefit in lump sum to the policyholder’s nominee, in case of a sudden demise of the policyholder during the policy tenure. This financial instrument helps your family to be stable, even if you are not around. A term insurance plan is considered as a pure life insurance plan, because it only compensates you with the death benefit, with no.
      components of savings or investments, just the endowment plans or ULIP plans. One can select to buy the term insurance plans for a tenure of 10, 15 or 20 years. But, if the term insurance policy expires, the policyholder can either renew it or leave the policy as it is, at the policy will be lapsed in due course of time.

      </p>
      </div>
      <div className="redbtn">
              <button className="Readmorebtn" onClick={Readmorebutton2}>{Readmorebtn2 ? "Read More" : "Read Less"}</button>
            </div>
    
        </div>

  </div>
</div>
      </div>

<div className="fixwidthDivtext">
<div className="container">
      <h4 className='healthHeading'>Types Of Term Insurance Plans In India </h4>
      <div className="yellowline"></div>
      <p className="normaltext">How many types of term insurance plans exist in India ? Here is a brief description of the types of term insurance plans.</p>

  <div className="row">
  <div className="col-md-6 d-flex justify-content-center   align-items-center">
     <FastImage url="/Website%20Img/regulatory_12002075.png?updatedAt=1728987514340" width={auto} height={auto} className="fmailimagegifs"/>
      </div>
  <div className="col-md-6 d-flex justify-content-center   align-items-center">
        <div>

      {
        typeofterm.map((data)=>{
          return(
            <Accordion expanded={expanded === `panel${data.id}`} onChange={handleChange(`panel${data.id}`)} key={data.id}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
          >
          <li className='listcol'>
          <IoMdAddCircle/>
           {data.Q}
          </li>
        </AccordionSummary>
        <AccordionDetails>
          <p className="normaltext">
         {data.A}
          </p>
        </AccordionDetails>
      </Accordion>
          )
        })
      }
          </div>
     </div>
   
    
      </div>

  </div>
  </div>



  
      {/* <div className="container">
      <h5 style={{color:"#2959B8"}}>Various Features Of Term Insurance</h5>
    <div className="row">
        {
            Benifits.map((data, index) => {
                // Use modulo to cycle through the classes if you have more items
                const boxClass = boxClasses[index % boxClasses.length];

                return (
                    <div className="col-md-6" key={data.id}>
                        <div className={boxClass}>
                            <p className="normaltext">
                                {data.text}
                            </p>
                        </div>
                    </div>
                );
            })
        }
    </div>   
</div> */}
<Typeofpolicy/>



      
      <div className="fixwidthDivtext">
     <div className="container">
     <h3 className='healthHeading'>Points You Should Remember To Understand And Analyze The Best Term Insurance</h3>
      <div className="yellowline"></div>
      <p className="normaltext">When choosing term insurance, consider the coverage amount, policy term, premium costs, and the insurer’s claim settlement ratio. Look for flexible options, riders, and exclusions. Regularly review your policy, assess your needs, and compare plans to ensure the best fit for your financial situation and dependents' future.</p>
      <div className="row"> 
        <div className="col-md-6 d-flex justify-content-center   align-items-center">
          <div>
        {
        pointtoremember.map((data)=>{
          return(
            <Accordion expanded={expanded === `panel${data.id}`} onChange={handleChange(`panel${data.id}`)} key={data.id}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
          >
          <li className='listcol'>
          <IoMdAddCircle/>
           {data.Q}
          </li>
        </AccordionSummary>
        <AccordionDetails>
        <p className="normaltext">
         {data.A}
        </p>
        </AccordionDetails>
      </Accordion>
          )
        })
      }
      </div>
        </div>
        <div className="col-md-6 mt-4 d-flex justify-content-center   align-items-center">
      <FastImage url="/Website%20Img/Checklist-pana.png?updatedAt=1727249784622" width={auto} height={auto} className="fmailimagegifs"/>
        </div>
      </div>
     </div>


    </div>















<div className="fixwidthDivtext">
<div className="container">
      <h4 className='healthHeading'>People Need Term Plan For The Following Reasons:</h4>
      <div className="yellowline"></div>
  <div className="row">
    <div className="col-md-12">



      <p className="normaltext" style={{textAlign:'justify'}}>
      <span className="bulletpoint">Higher Coverage at Lower Premium:</span>
       A term insurance plan comes with a pocket-friendly premium range and provides great coverage. For the non-smokers or consumers leading a healthy lifestyle are charged with nominal premium but for people of higher age along with certain pre-existing diseases or smokers are charged with significantly higher premiums. Thus, it is recommendable to purchase a term plan at an early stage of life because if you wish to opt for higher coverage you will earn it in an affordable rate.
      </p>
    
      
      <p className="normaltext" style={{textAlign:'justify'}}>
      <span className="bulletpoint"> Benefits of a Term Plan:</span>
        Term plans are not pure life cover but are collaborated with other benefits and riders those are optional making life insurance more usable and beneficial. Like, many of the insurance plans include terminal illness and accidental death rider at zero cost. So, it’s crucial checking such data in detail regarding these benefits and how the lump sum benefit would be paid to the nominee or the policy holder at the time of emergency.</p>
      
      
      <p className="normaltext" style={{textAlign:'justify'}}>
      <span className="bulletpoint"> Protecting Your Family:</span>
      Protecting Your Family: If you are the one and only earning member in your family along with your old parents and children, then buying a term plan is much needed for you to provide financial safeguard to your family members. One definitely need a term plan because it protects your family with financial coverage, to the spouse, children and parents, in the event of the death of the policy holder. This life coverage may help the beneficiary to pay off debts, loans and several other financial commitments.
      </p>

  
      
      <p className="normaltext" style={{textAlign:'justify'}}>
      <span className="bulletpoint">
         Coverage for Longer Tenure:
      </span>
Term plans are inbuilt with long term policy tenure options such as 10, 15, 20, or 30 years. A term insurance is helpful as it is for a longer continuance of time period or for lifetime. Even though, your policy ends at 10, 20 or 30 years and then, if the plan is renewed up to a certain age, it would be costly as the policy will be renewed at a subsequent stage. An individual should keep renewing the policy up till the age of retirement to get rid of paying huge premium costs.
      </p>
      





      <p className="normaltext" style={{textAlign:'justify'}}>
      <span className="bulletpoint">
      Higher Discounts If You Purchase Through Online:
      </span>
      Great coverage at the rate of low-cost premiums. A term plan can be availed through an uncomplicated process. It would be a wise decision to purchase a term insurance plan only though online channels rather than approaching an agent because online plans come with reasonable premium and better discounts as compared to the offline options.
      </p>
      
      <div className="tableoverflow">
      <table className="termlandingtable">
      <thead>
        <tr>
          <th className="termlandingtable-th">AGE</th>
          <th className="termlandingtable-th">POLICY TENURE</th>
          <th className="termlandingtable-th">CITIZENSHIP</th>
          <th className="termlandingtable-th">MEDICAL TEST</th>
          <th className="termlandingtable-th">PREMIUM PAYOUT OPTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="termlandingtable-td">Minimum Entry Age - 18yrs</td>
          <td className="termlandingtable-td">Minimum Policy Tenure - 5yrs</td>
          <td className="termlandingtable-td">Resident of India - Yes</td>
          <td className="termlandingtable-td">Mandatory</td>
          <td className="termlandingtable-td">One-time single payment</td>
        </tr>
        <tr>
          <td className="termlandingtable-td">Maximum Entry Age - 65yrs</td>
          <td className="termlandingtable-td">Maximum Policy Tenure - Varies with insurance company</td>
          <td className="termlandingtable-td">Non-resident Indian - Yes</td>
          <td className="termlandingtable-td"></td>
          <td className="termlandingtable-td">
            Monthly Payout<br />
            Quarterly Payout<br />
            Yearly Payout
          </td>
        </tr>
      </tbody>
    </table>
    </div>



    </div>
  </div>
</div>
</div>

<div className="fixwidthDivtext">
<div className="container">
      <h4 className='healthHeading'>When An Individual Should Buy A Term Insurance ?</h4>
      <div className="yellowline"></div>
  <div className="row">
    <div className="col-md-12">


      <p className="normaltext" style={{textAlign:'justify'}}>
      A most suitable question that ponders on everyone’s mind that when would be the right time for an individual to purchase a term insurance policy. Insurance experts advise to buy a term plan is when you are young and healthy because they consider that to be the right time to take up a term insurance policy. The premium that you will pay would be also lesser when you are young, and the older you grow, the premium you will be charged would be at a higher rate because of your age and pre-existing diseases which prone to grow with age. When start your career or if you are not yet married then this is the correct time for you to purchase a term plan which would be affordable and also helps you to save on tax. The early you avail of a term insurance plan, the more beneficial you will be as you would be capable to provide a financial blanket to your family. People who are self-employed, working professionals, Young people who has just started their career, working women, parents and single parents, people who are retired, newly marrieds, all are eligible to avail for term insurance plan.
And, if the question arise that who should opt for a term plan, then we generally need to understand that term plan is a traditional life insurance policy that provides financial coverage and benefits to the nominee of the policyholder in the event of an unforeseen demise of the insured person. One financially supports a family is generally called a
provider. A provider can apply for a term insurance policy in his/her name and can enamor the dependents in the family with financial benefits received through the term insurance plan under unfortunate event of death. 
      </p>
      </div>

   
    </div>
  </div>
</div>


      
<div className="fixwidthDivtext">

     <div className="container">
   <h4 className='healthHeading'>Documents You Need For Applying For Term Insurance</h4>
   <div className="yellowline"></div>
<div className="col-md-12">
   <div className="row">
   
            <div className="col-md-4">
          <div className="incomeproofdiv">
            <div className="headingboxproof">
            <span className="docheading">Identity proof</span>
            </div>
            <span className="docrequird">Any One of Blow Document</span>
            <div className="cardbox">
            <li> <GrDocumentUser/>&nbsp;PAN card</li>
            <li> <GrDocumentUser/>&nbsp;Aadhaar Card</li>
            <li> <GrDocumentUser/>&nbsp;Driving License</li>
            <li> <GrDocumentUser/>&nbsp;Passport</li>
            </div>
          </div>
          </div>

          <div className="col-md-4">
        <div className="identyproofdiv">
        <div className="headingboxproof">
            <span className="docheading">Address proof</span>
            </div>
            <div className="cardbox">
            <li> <GrDocumentUser/>&nbsp;Aadhaar card</li>
            <li> <GrDocumentUser/>&nbsp;Driving license</li>
            <li> <GrDocumentUser/>&nbsp;Passport</li>
            <li> <GrDocumentUser/>&nbsp;Ration card</li>
            <li> <GrDocumentUser/>&nbsp;Electricity bill</li>
            </div>
          </div>
          </div>
 


          <div className="col-md-4">
        <div className="incomeproofdiv2">
        <div className="headingboxproof">
            <span className="docheading">Income proof</span>
            </div>
            <div className="cardbox">
            <li> <GrDocumentUser/>&nbsp;Last three month’s salary slip</li>
            <li> <GrDocumentUser/>&nbsp;Last 6 month's Bank Statement</li>
            <li> <GrDocumentUser/>&nbsp;Last three years’ ITR or From 16</li>
            </div>
          </div>
          </div>

          </div>
          </div>


        {/* <div className="col-md-6"> 
        <div className="incomeproofdiv3">
        <div className="headingboxproof">
            <span className="docheading">Documents for non-salaried applicants:</span>
            </div>
            <div className="cardbox">
            <li> <GrDocumentUser/>&nbsp;Last three years’ ITR with computation of income</li>

            </div>
        </div>
        </div> */}
 
     </div>
    
   </div>



<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Tax Benefits in Term Insurance Plan</h4>
<div className="yellowline"></div>
  <div className="row">



<div className="col-md-6 d-flex justify-content-center align-items-center">
<p className="normaltext" style={{textAlign:"justify"}}>As you pay your premium towards a term insurance plan, you are entitled to receive the tax benefits under Section 80C, Section 10 (10D) and Section 80D of the Income Tax Act.
Below explained are the few tax deductions which can be claimed only by individuals and a Hindu Undivided Family (HUF), for an example:
Section 80C: Tax deductions of up to INR 1.5 lakh under Section 80C on the premium paid for the term insurance plan.
Section 10 (10D): The lump sum death benefit which is offered to the family after the sudden death of the policyholder before the policy expires, the family receives the total death benefit amount without any taxes levied on it.
</p>
</div>
<div className="col-md-6 d-flex justify-content-center align-items-center">
<FastImage url="/Website%20Img/guarantee_8054243.png?updatedAt=1729850969937" width={auto} height={auto} className="fmailimagegifs"/>
</div>
</div>
</div>
</div>




      <div className="fixwidthDivtext">
        <div className="container">
        <h4 className='healthHeading'>Know About The Riders Included With A Term Insurance Plan</h4>
        <div className="yellowline"></div> 
          <div className="row">
            <div className="col-md-12">

            <div className="tabcontainer">

              <div className="undertabterm">
    <div className={activeTabs === 1 ? "doctabs" : 'doctabsde'} onClick={() => setactiveTabs(1)}>Accidental Death Benefit </div>
    <div className={activeTabs === 2 ? "doctabs" : 'doctabsde'} onClick={() => setactiveTabs(2)}>Critical Illness </div>
    <div className={activeTabs === 3 ? "doctabs" : 'doctabsde'} onClick={() => setactiveTabs(3)}>Permanent Disability </div>
    <div className={activeTabs === 4 ? "doctabs" : 'doctabsde'} onClick={() => setactiveTabs(4)}>Income Benefit </div>
    <div className={activeTabs === 5 ? "doctabs" : 'doctabsde'} onClick={() => setactiveTabs(5)}>Waiver of Premium </div>
              </div>

    {activeTabs === 1 && (
        <p className="normaltext">
            As you choose this rider clubbed with your term plan, you can avail extra coverage apart from your standard term plan, in case it’s a personal accidental death. Attached with the rider, the beneficiaries of the insured individual receive the additional coverage including the basic coverage, if the policyholder dies due to an unpredictable and unforeseen accident.
        </p>
    )}

    {activeTabs === 2 && (
        <p className="normaltext">
            This rider compensates the policyholder to receive the lump sum amount if they are detected with any of the listed critical illnesses covered under the plan, like heart attack, cancer, brain stroke, etc. Once the policyholder starts getting payouts on the basis of the critical illness rider, the insurance provider may find it essential to extend with a lower coverage or end the policy, based on the terms and conditions of the specific policy.
        </p>
    )}
       {activeTabs === 3 && (
        <p className="normaltext">
         This rider offers advantages to the policyholder, if they become forever incapacitated in an accident. For extended coverage, the policyholder can associate this rider along with the accidental death benefit rider. Any which ways, when one has opted for a permanent disability rider that’s included in the pure term insurance, the insurer has to pay a periodic amount to the policyholder, if an individual becomes disable and is incapable of working to accidents and accidental injuries. This payout money is for a certain period of time which  you the income to the policyholder when they are not capable to work under such accidental conditions of disability.
          </p>
    )}
           {activeTabs === 4 && (
             <p className="normaltext">
              If the policyholder is the sole earning member in the family, then this rider can is useless along with the basic term plan. If the beneficiaries receive extra income annually, for a pre-specified period, in case of an unfortunate demise of the policyholder. This is to be pre-decided and addressed that Income 
       benefit rider is the regular benefits which are available under basic insurance plan.
               </p>
    )}
           {activeTabs === 5 && (
            <p className="normaltext">
               The policyholder’s future premiums are waived-off by the insurance company, if the policyholder is not able to pay premium amount, because of loss of income or accidental disability. Your inability to pay the premium will not be impacted on the coverage and benefits, the policy remains the same and as per the policy.
              </p>
    )}
</div>

        



        

      
      

 
      
      
     
     
    
      
      
      
 

      
      

    
      
      </div>
      </div>
          </div>
        </div>



























<div className="fixwidthDivtext">
<div className="container">
<h4 className='healthHeading'>Factors That Impact Term Insurance Premium</h4>
<div className="yellowline"></div>
<div className="row">
<div className="col-md-12">





<p className="normaltext">
  <span className="bulletpoint">
  Age:
  </span>
 Younger individuals are proposed much lower premiums as compared to older people for a very specified medical reason that probabilities of suffering from lethal diseases are comparatively lesser in younger people.
Medical History: Any individual has a family medical trail history of severe illnesses such as diabetes, heart attack or cancer, then in such scenarios the policyholder has to pay higher premium based on their family medical background.
  </p>



<p className="normaltext">
<span className="bulletpoint">
Medical History:
</span>
 Any individual has a family medical history of critical illnesses such as diabetes, heart attack or cancer, then in such scenarios the policyholder might have to pay more premium because of their family medical background.
</p>




<p className="normaltext">
<span className="bulletpoint">
Lifestyle And Daily Habits:
</span>
 At the time of filling-up the form for applying for a term insurance, you will also need to provide the details about your smoking or drinking habits. The premium rates for the smokers and for those who consume alcohol is significantly higher as compared to non-smokers.
  </p>




<p className="normaltext">
<span className="bulletpoint">
Policy Tenure:
</span>
For life coverage for longer tenure, you need to shell out more on premium as insurance companies are covering you for much higher risks. Hence, a short term  policy will have nominal premiums.
Risk Related to Occupation: An individual in a risky job-profile and simultaneously working for industries, like shipping, gas, mining, oil, tend to pay more premium as compared to someone having a lesser risky job or desk job profile.
  </p>



<p className="normaltext">
<span className="bulletpoint">
Risk Related to Occupation:
</span>
 An individual in a risky job-profile as well as working for industries, just like shipping, gas, mining, oil, tend to pay more premium as compared to someone having a lesser risky job or desk job profile.
  </p>










    </div>
  </div>
</div>
</div>  















<div className="fixwidthDivtext">

<div className="container">
<h4 className='healthHeading'>Term Insurance Payouts Are Like</h4>
<div className="yellowline"></div>

<div class="payout-container">
  <div className="payoutboxs">
  <span className="bulletpoint">One-Time Payout</span>
  <p className="normaltext"> This is a basic payout that insurance companies provide in terms of a pure term plan. In a pure term plan, an insurer provides one single lump sum payment of death benefit to the beneficiaries of policyholder. Let’s say, if you opt for a term plan having life coverage of INR  1 crore, then the entire amount would be paid to your beneficiaries as a single payout at the time of your unfortunate demise.</p>
  </div>


  <div className="payoutboxs2">
  <span className="bulletpoint">One-Time Payout with A Pre-fixed Monthly Lump Sum Payment</span>
  <p className="normaltext"> In such a one-time payout, the beneficiary receives a single death coverage aid and the fixed amount of monthly payment for a specific  period as determined by the policyholder while availing the policy. This monthly payment helps the beneficiary to suffice all their necessary regular expenses.</p>
  </div>


  <div className="payoutboxs3">
  <span className="bulletpoint">One-time payout with Increasing Monthly Payouts</span>
  <p className="normaltext"> In this payout option, the beneficiary is supposed to receive for a one-time single payout which is the entire sum assured coverage amount at the event of the death of the insured. The beneficiary will also het monthly payouts which has a probability to increase with every passing year for a definite period of time.</p>
  </div>
  </div>


</div>
</div>






      {/* <section className="type-insurance">
       
        <Typeofpolicy/>
      </section> */}
<div className="fixwidthDivtext">
 <section className="adv-dis-of-insurance">
        <div className="container">
          <div className="row adv-dis-row">







          <div className="col-sm-12 col-md-6 col-lg-6">
      <div className="advantagedisadbantegdiv">
        <h4>Advantage</h4>
      </div>
      <div className="left-sid-adv adv-dis-box">
        {Advantage.map((data) => {

          const shortText = data.Dis.split(' ').slice(0, 17).join(' ');

          return (
            <div className="adv-dis-boxs" key={data.id}>
              <div className="adv-number">{data.id}</div>
              <div className="adv-dis-text-box">
                <h6>{data.name}</h6>
                <p className="normaltext">
                  {showFull ? data.Dis : shortText}
                  {data.Dis.split(' ').length > 50 && !showFull && '...'}
                  {data.Dis.split(' ').length > 50 && (
                  <a className="linktagreadmore"onClick={() => setShowFull(!showFull)}>
                    {showFull ? 'Show Less' : 'Read More'}
                  </a>
                )}
                </p>
            
              </div>
            </div>
          );
        })}
      </div>
    </div>
















            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="advantagedisadbantegdiv">
                <h4>Disadvantage</h4>
              </div>
              <div className="right-sid-dis adv-dis-box">
              {
                  disadvantage.map((data)=>{
                    const shortText = data.Dis.split(' ').slice(0, 17).join(' ');
                    return(
                      <div className="adv-dis-boxs">
                      <div className="adv-number">{data.id}</div>
                      <div className="adv-dis-text-box">
                        <h6>{data.name}</h6>
                        <p className="normaltext">
                  {showFull ? data.Dis : shortText}
                  {data.Dis.split(' ').length > 50 && !showFull && '...'}
                  {data.Dis.split(' ').length > 50 && (
                  <a className="linktagreadmore"onClick={() => setShowFull(!showFull)}>
                    {showFull ? 'Show Less' : 'Read More'}
                  </a>
                )}
                </p>
                      </div>
                    </div>
                    )
                  })
                }
             
              </div>
            </div>

            
            </div>
            </div>
            </section>
            </div>
      <PartnerCompany />
      <FAQ />
    </div>
  );
};

export default TermInsurence;