import React from "react";
import "./PartnerCompany.css";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

const PartnerCompany = () => {
  const logos = [
    {
      id: 1,
      name: "Website%20Img/company_logo1706170656.webp?updatedAt=1718713878384",
    },
    {
      id: 2,
      name: "Website%20Img/company_logo1706170642.webp?updatedAt=1718714012237",
    },
    {
      id: 3,
      name: "Website%20Img/company_logo1706170629.webp?updatedAt=1718714076744",
    },
    {
      id: 4,
      name: "Website%20Img/company_logo1706170620.webp?updatedAt=1718714157426",
    },
    {
      id: 5,
      name: "Website%20Img/company_logo1705572149.png?updatedAt=1718714240193",
    },
    {
      id: 6,
      name: "Website%20Img/company_logo1706170587.webp?updatedAt=1718714312525",
    },
    {
      id: 7,
      name: "Website%20Img/company_logo1706170577.webp?updatedAt=1718714439973",
    },
    {
      id: 8,
      name: "Website%20Img/company_logo1706170566.webp?updatedAt=1718714512253",
    },
    {
      id: 9,
      name: "Website%20Img/company_logo1706170558.webp?updatedAt=1718714565268",
    },
    {
      id: 10,
      name: "Website%20Img/company_logo1706170545.webp?updatedAt=1718714617158",
    },
    {
      id: 11,
      name: "Website%20Img/company_logo1706170534.webp?updatedAt=1718714671097",
    },
   
    {
      id: 12,
      name: "Website%20Img/tata_aig.png?updatedAt=1736154720786",
    },
    {
      
      id: 13,
      name: "Website%20Img/bandhan-logo.png?updatedAt=1736154946675",
    },
    
    {
      id: 14,
      name: "Website%20Img/natinal.png?updatedAt=1736155534261",
    },
   
    {
      id: 15,
      name: "Website%20Img/HDFC_Life_logo_PNG.png?updatedAt=1736155636428",
    },
  ];

  return (
    <div className="fixwidthDivtext">
      <div className="container">
        <div className="row first-section-of-nine">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="nine-dub-df">
              <div className="tile-text-with-text-shadow">
                <h2>OUR PARTNERS</h2>
                <br />
              </div>


              <div className="commonone">
              <p className="common-text-line-clamp">
                <p className="textformat">
                  ﻿Looking for the best insurance deals? Look no further! We've
                  partnered with top insurance providers to offer exclusive
                  deals and discounts. So, don't miss the chance to save big on
                  your insurance premiums.
                </p>
              </p>
              </div>

              
            </div>
          </div>
        </div>

        <div class="logos">
          <div class="logo_items">
            {logos.map((data, index) => {
              return (
                <>
                 

                  <FastImage
                  className={"partner-company-image"}
                    src={data.name}
                    url={data.name}
                    width={auto}
                    height={auto}
                    alt={"image"}
                  ></FastImage>
                </>
              );
            })}
          </div>

          <div class="logo_items">
            {logos.map((data, index) => {
              return (
                <FastImage
                 className={"partner-company-image"}
                  src={data.name}
                  url={data.name}
                  width={auto}
                  height={auto}
                  alt={"image"}
                ></FastImage>
              );
            })}
          </div>
        </div>
      </div>
      </div>
  );
};

export default PartnerCompany;
