import React from 'react';
import loadernew from "./loader.gif"

const Loader = () => {
  return (
    <>
        <div className="loader-container">
          {/* <div className="loader"></div> */}
     
          <img src={loadernew}  style={{ width:"250px"}}/>

   
        </div>
    </>
  )
}

export default Loader