import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import "../CommonCssFiles/planstyle.css"
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {getDate} from '../helper/common';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectProposal,
  updateICICIQuestion,
  updateICICIPersion,
  updateICICIQAlist,
  selectICICIcheckedQuestion
} from '../redux/Slice'

export const ICICIQuestionAnswer = ({data,index,insuredData,proposerData,ICICIcheckedQuestion , setICICICheckedQuestion}) => {
    // const dispatch = useDispatch()
    // const ICICIcheckedQuestion = useSelector(selectICICIcheckedQuestion)
    console.log('ICICIcheckedQuestion',ICICIcheckedQuestion);

    const updateICICIQuestion = (val) => {
        setICICICheckedQuestion((prevState) => {
          const updatedState = { ...prevState };
    
          if (updatedState.hasOwnProperty(val.val)) {
            // If the value exists, delete it
            delete updatedState[val.val];
          } else {
            // If it doesn't exist, add it with an empty object
            updatedState[val.val] = {};
          }
    
          return updatedState; // Return the updated state
        });
      };
    return (<>
        <div className='row  ppv10 my-3 col-md-12' >
            <div className='col pmd col-md-12'>
                <FormControlLabel 
                // style={{alignItems:"flex-start"}}
                 className="checkbox-div"
                 value = {data.question_id} onChange = {e =>
                    //  dispatch(updateICICIQuestion({val:e.target.value}))
                    updateICICIQuestion({val:e.target.value})
                    } 
                      control={<Checkbox className='checkbox-div-box' />} label={"Q."+data.question} checked = {ICICIcheckedQuestion.hasOwnProperty(data.question_id)} />
            </div>     
        </div>
    </>)
}

export const ICICIPersion = ({data,persion,pindex,proposerData,ICICIcheckedQuestion,setICICICheckedQuestion}) => {
   
    
    // const ICICIcheckedQuestion = useSelector(selectICICIcheckedQuestion)

    
    const check = () => 
    {
        if(ICICIcheckedQuestion.hasOwnProperty(data.question_id))
        {
           if(ICICIcheckedQuestion[data.question_id].hasOwnProperty(pindex))
           {
              return true;
           }
           else
           {
            return false;
           }
        }
        else
        {
            return false;
        }
    }

  

      const updateICICIPersion = (payloadData) => {
        const { question_id, pindex } = payloadData;
    
        setICICICheckedQuestion((prevState) => {
          // Create a shallow copy of the current state
          const updatedState = { ...prevState };
    
          // If the question_id exists in the state, toggle the pindex
          if (updatedState[question_id]) {
            if (updatedState[question_id].hasOwnProperty(pindex)) {
              // If pindex exists, delete it
              delete updatedState[question_id][pindex];
            } else {
              // If pindex doesn't exist, add it as an empty object
              updatedState[question_id][pindex] = {};
            }
          }
    
          // Return the updated state
          return updatedState;
        });
      };
    
    
 


    return(<>
	        <div className='col-md-5' style={{margin:"10px"}} hidden = {!ICICIcheckedQuestion.hasOwnProperty(data.question_id)} 
            // onClick = {e => dispatch(updateICICIPersion({pindex:pindex,question_id:data.question_id}))} 
            onClick={e => updateICICIPersion({pindex:pindex,question_id:data.question_id})}
            >
	            <div className={check() ? "row sumbox sumboxone icicisum" : "row sumbox"} style={{padding: "13px"}} >
	            <div className='col-10 '>
	                <span className='para'>{proposerData.InsuredDetail[pindex].InsuredName} ({persion.InsuredRelation}) </span>
	            </div>
	            <div className='col-3  text-right'> 
	            </div>
	           </div>
	       </div>
        </>)
}

