import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../CommonCssFiles/planstyle.css";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { changeDateFormat, getDate } from "../helper/common";
import "./HDFC.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProposal,
  updateHDFCQuestion,
  updateHDFCPersion,
  updateHDFCQAlist,
  selectHDFCcheckedQuestion,
  selectHDFCcheckedPersion,
} from "../redux/Slice";

export const HDFCQuestionAnswer = ({ data, index, insuredData , HDFCcheckedQuestion,setHDFCcheckedQuestion}) => {
  // const dispatch = useDispatch();
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  const updateHDFCQuestion = ({ val }) => {
    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };
  
      if (updatedState.hasOwnProperty(val)) {
        // If the key exists, delete it
        delete updatedState[val];
      } else {
        // Otherwise, add the key with an empty object
        updatedState[val] = {};
      }
  
      return updatedState;
    });
  };
  
console.log("HDFCcheckedQuestion", HDFCcheckedQuestion);


  return (
    <>
      <div
        className="ppv10 my-3 col-md-12 "
        style={{ paddingLeft: "0px", minWidth: "250px" }}
      >
        <div className="col-md-12 col-sm-12 col-12 pmd">
          <FormControlLabel
            value={data.question_id}
            onChange={(e) =>{
              updateHDFCQuestion({val: e.target.value})
            }
              // dispatch(updateHDFCQuestion({ val: e.target.value }))

            }
            control={<Checkbox />}
            label={"Q." + data.question}
            checked={HDFCcheckedQuestion.hasOwnProperty(data.question_id)}
          />
        </div>
      </div>
    </>
  );
};

export const HDFCPersion = ({ data, persion, pindex ,proposerData ,  HDFCcheckedQuestion, setHDFCcheckedQuestion}) => {
  // const proposalData = useSelector(selectProposal);
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  const updateHDFCPerson = (payload) => {
    const { question_id, pindex } = payload; // Destructure payload
    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      // Ensure the question exists in the state
      if (!updatedState[question_id]) {
        updatedState[question_id] = {};
      }

      const question = updatedState[question_id];

      // Toggle the pindex within the question
      if (question.hasOwnProperty(pindex)) {
        delete question[pindex];
      } else {
        question[pindex] = [];
      }

      return updatedState;
    });
  };

  const check = () => {
    if (HDFCcheckedQuestion.hasOwnProperty(data.question_id)) {
      if (HDFCcheckedQuestion[data.question_id].hasOwnProperty(pindex)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const dispatch = useDispatch();

  console.log("HDFCcheckedQuestion in person", HDFCcheckedQuestion);

  return (
    <>
      <div className="same-section-med ">
        <div
          hidden={!HDFCcheckedQuestion.hasOwnProperty(data.question_id)}
          onClick={(e) =>{
            updateHDFCPerson({
                  pindex: pindex,
                  question_id: data.question_id,
                })
          }
            // dispatch(
            //   updateHDFCPersion({
            //     pindex: pindex,
            //     question_id: data.question_id,
            //   })
            // )
          }
          style={{ minWidth: "220px" , cursor:"pointer"}}
        >
          <div
            className={
              check() ? "sumbox hdfc-sum col-md-12" : "sumbox hdfc-addon"
            }
            style={{ justifyContent: "center", minWidth: "250px" }}
          >
            <div>
              <span className="para">
                {proposerData.InsuredDetail[pindex].InsuredName} (
                {persion.InsuredRelation}){" "}
              </span>
            </div>
          </div>
        </div>
        {data.get_child_question.map((child_data) => {
          return (
            <SubQuestionAnswer
              data={child_data}
              parentData={data}
              pindex={pindex}
              proposerData={proposerData} 
               HDFCcheckedQuestion={HDFCcheckedQuestion}
               setHDFCcheckedQuestion={setHDFCcheckedQuestion}
            />
          );
        })}
      </div>
    </>
  );
};

export const SubQuestionAnswer = ({ data, pindex, parentData ,  proposerData, HDFCcheckedQuestion,setHDFCcheckedQuestion}) => {
  const proposalData = useSelector(selectProposal);
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  const dispatch = useDispatch();

  const check = () => {
    if (HDFCcheckedQuestion.hasOwnProperty(parentData.question_id)) {
      if (HDFCcheckedQuestion[parentData.question_id].hasOwnProperty(pindex)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const [first, setFirst] = useState([]);
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  useEffect(() => {
    // console.log('HDFCcheckedQuestion:', HDFCcheckedQuestion);
    const keysToCheck = [6, 19];
    const filteredObjects = keysToCheck
      .filter((key) => HDFCcheckedQuestion.hasOwnProperty(key))
      .map((key) => HDFCcheckedQuestion[key])
      .flat();

    // console.log('Filtered Objects:', filteredObjects);
    setFirst(filteredObjects);
  }, [HDFCcheckedQuestion]);

  const getDeliveryDate = () => {
    if (first.length > 0) {
      return first[0];
    }
  };

  const datadate = getDeliveryDate();

  // console.log("HDFCcheckedQuestion", first);
  // console.log("The Qustionset HDFC",HDFCcheckedQuestion)

  let deliveryDates = [];
  for (const key in datadate) {
    if (datadate.hasOwnProperty(key)) {
      const questions = datadate[key];

      questions.forEach((question) => {
        if (question.Options && question.Options.length > 0) {
          question.Options.forEach((option) => {
            if (option.DeliveryDate) {
              deliveryDates.push(option.DeliveryDate);
            }
          });
        }
      });
    }
  }

  // Output the delivery dates
  // console.log('The delivery date',deliveryDates);

  function HDFCquestionExist(qa, qid) {
    for (let i = 0; i < qa.length; i++) {
      if (qa[i].QuestionId == qid) {
        return true;
        break;
      }
    }
    return false;
  }
  function HDFCgetAnswerSet(qdata, name, value, parentQid) {
    if (parentQid == "14" || "5") {
      return {
        OptionId: qdata.question_id,
        OptionText: qdata.question,
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        CurrentStatus: name == "CurrentStatus" ? value : "",
        LineOfManagement: name == "LineOfManagement" ? value : "",
        TreatmentDetails: name == "TreatmentDetails" ? value : "",
      };
    } else if (parentQid == "18" || "1") {
      return {
        OptionId: qdata.question_id,
        OptionText: "Yes",
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        CurrentStatus: name == "CurrentStatus" ? value : "",
        LineOfManagement: name == "LineOfManagement" ? value : "",
        TreatmentDetails: name == "TreatmentDetails" ? value : "",
      };
    } else if (parentQid == "15" || "2") {
      return {
        OptionId: qdata.question_id,
        OptionText: "Yes",
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        SurgeryDate: name == "SurgeryDate" ? value : "",
        CurrentStatus: name == "CurrentStatus" ? value : "",
        SurgeryFor: name == "SurgeryFor" ? value : "",
      };
    } else if (parentQid == "16" || "3") {
      return {
        OptionId: qdata.question_id,
        OptionText: "Yes",
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        CurrentStatus: name == "CurrentStatus" ? value : "",
        TreatmentDetails: name == "TreatmentDetails" ? value : "",
      };
    } else if (parentQid == "17" || "4") {
      return {
        OptionId: qdata.question_id,
        OptionText: "Yes",
        TestFinding: name == "TestFinding" ? value : "",
        TestDate: name == "TestDate" ? value : "",
        TypeOfTest: name == "TypeOfTest" ? value : "",
      };
    } else if (parentQid == "19" || "6") {
      // alert(parentQid)
      return {
        OptionId: qdata.question_id,
        OptionText: "Yes",
        DeliveryDate: name == "DeliveryDate" ? value : "",
      };
    }
  }
  

  const updateHDFCQAlist = (payload) => {
    const { parentqData, pindex, qdata, name, value } = payload;
    const parentQid = parentqData.question_id;

    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      // Ensure parent question exists
      if (!updatedState[parentQid]) {
        updatedState[parentQid] = {};
      }

      const question = updatedState[parentQid];

      // Ensure pindex exists
      if (!question[pindex]) {
        question[pindex] = [];
      }

      const qa = question[pindex];

      if (!HDFCquestionExist(qa, parentqData.question_id)) {
        // Add new question if it doesn't exist
        const obj = {
          QuestionId: parentqData.question_id,
          QuestionText: parentqData.question,
          Options: [
            HDFCgetAnswerSet(qdata, name, value, parentqData.question_id),
          ],
        };
        qa.push(obj);
      } else {
        // Update existing question
        const objKey = qa.findIndex(
          (item) => item.QuestionId === parentqData.question_id
        );

        const options = qa[objKey]["Options"];
        const optionIndex = options.findIndex(
          (item) => item.OptionId === qdata.question_id
        );

        if (optionIndex === -1) {
          // Add new option
          qa[objKey]["Options"].push(
            HDFCgetAnswerSet(qdata, name, value, parentqData.question_id)
          );
        } else {
          // Update existing option
          qa[objKey]["Options"][optionIndex][name] = value;
        }
      }

      return updatedState;
    });
  };





  return (
    <>
      {!check() == false ? (
        <div
          className="ppv10 my-3 col-md-12"
          style={{
            flexWrap: "wrap",
            height: "auto",
            marginRight: "0px",
            minWidth: "248px",
            padding:"0px"
          }}
          hidden={!check()}
        >
          <div className="col-md-12 pmd" style={{ marginRight: "7px" }}>
            <span className="nfh1 cash" style={{ fontWeight: "600" }}>
              Q.{data.question}
            </span>
            {parentData.question_id == "14" ||
            parentData.question_id == "18" ||
            parentData.question_id == "1" ||
            parentData.question_id == "5" ? (
              <AnswerSetOne
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion} 
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
                // value={first}
              />
            ) : parentData.question_id == "15" ||
              parentData.question_id == "2" ? (
              <AnswerSetTwo
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion} 
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "16" ||
              parentData.question_id == "3" ? (
              <AnswerSetThree
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion} 
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "17" ||
              parentData.question_id == "4" ? (
              <AnswerSetFour
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion} 
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "19" ||
              parentData.question_id == "6" ? (
              <AnswerSetFive
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                deliverydate={deliveryDates}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion} 
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : null}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export const AnswerSetOne = ({ pindex, qdata, parentqData, value ,proposerData ,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion ,updateHDFCQAlist}) => {
  const dispatch = useDispatch();
  //  console.log('The Anwsar Indexing',pindex,qdata)

  const [dataObj, setDataObj] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the answer set one data",HDFCcheckedQuestion)

  console.log("the pindex", pindex);

  console.log("the q data in set one", qdata)


  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {

      console.log("hdfc checked question",HDFCcheckedQuestion )
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [14,18,1,5];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);


      console.log("the resultObjects", resultObjects) 
      let val;
      
 
      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex))
        {
          let data_one = resultObjects[key][pindex];
          // console.log("data_one",qdata.question_id)
          let val = data_one.find(item => item.QuestionId == parentqData.question_id );
          if (val != undefined)
          {
             console.log("val in for",val)
             let newobject = val;
             console.log("the newObject", newobject);
     
             if (newobject) {
     
               newobject.Options.map((item , index)=>{
                 if(item.OptionId == qdata.question_id){
                  
                   
                     setDataObj(item);
                     return
                   }
               })
             }
          }
        }
     }


  

      // let available = val.hasOwnProperty(pindex);

      // if (available) {
      //   console.log("pindex", pindex);

      //   console.log("resultObjects[0].pindex", val[pindex]);

      //   let data = val[pindex];

      //   console.log("the data here", data);

      //   if (data[0] == undefined) {
      //     return;
      //   }

      //   // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

     
      
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set one", dataObj);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };











  return (
    <>
      <div className="hdfc-question-set col-md-12">
        <div className="upper-question-para col-md-6 pr-0">
          <TextField
            id="standard-helperText"
            className="col-md-12 hdfc-disease"
            label="Disease Name"
            value={dataObj?.ExactDiagnosis || ""}
            variant="standard"
            name="ExactDiagnosis"
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "ExactDiagnosis",
              //     value: e.target.value,
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist({
                      name: "ExactDiagnosis",
                      value: e.target.value,
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    })
              }
            }
          />
          <div className="hdfc-date-one ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DesktopDatePicker"]}
                className="col-md-12"
              >
                <DesktopDatePicker
                  className="hdfc-date"
                  variant="standard"
                  id="standard-helperText"
                  label="Date of Diagnosis"
                  value={ dataObj?.DiagnosisDate ? dayjs(normalizeDate(dataObj?.DiagnosisDate)):"" }
                  format="DD-MM-YYYY"
                  name="Date of Diagnosis"
                  style={{ fontSize: "13px" }}
                  onChange={(e) =>
                    // dispatch(
                      // updateHDFCQAlist({
                      //   name: "DiagnosisDate",
                      //   value: getDate(e),
                      //   pindex: pindex,
                      //   qdata: qdata,
                      //   parentqData: parentqData,
                      // })
                      {
                        updateHDFCQAlist({
                              name: "DiagnosisDate",
                              value: getDate(e),
                              pindex: pindex,
                              qdata: qdata,
                              parentqData: parentqData,
                            })
                      }
                    
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div className="hdfc-date-one ">
            <LocalizationProvider dateAdapter={AdapterDayjs} className="setting-overflow">
              <DemoContainer
                components={["DesktopDatePicker"]}
                className="col-md-12"
              >
                <DesktopDatePicker
                  variant="standard"
                  className="hdfc-date"
                  id="standard-helperText"
                  label="Last Consultation Date"
                  value={ dataObj?.ConsultationDate ? dayjs(normalizeDate(dataObj?.ConsultationDate)) : ""}
                  format="DD-MM-YYYY"
                  name="Last Consultation Date"
                  style={{ fontSize: "13px" }}
                  onChange={(e) =>
                    // dispatch(
                    //   updateHDFCQAlist({
                    //     name: "ConsultationDate",
                    //     value: getDate(e),
                    //     pindex: pindex,
                    //     qdata: qdata,
                    //     parentqData: parentqData,
                    //   })
                    // )
                    {
                      updateHDFCQAlist({
                            name: "ConsultationDate",
                            value: getDate(e),
                            pindex: pindex,
                            qdata: qdata,
                            parentqData: parentqData,
                          })
                    }
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>

        <div className="lower-question-para col-md-6 pr-0">
          <TextField
            className="hdfc-treatment"
            id="standard-helperText"
            label="Details of Treatment given"
            defaultValue=""
            variant="standard"
            name="Details of Treatment given"
            value={dataObj?.TreatmentDetails ? dataObj?.TreatmentDetails : ""}
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "TreatmentDetails",
              //     value: e.target.value,
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist({
                      name: "TreatmentDetails",
                      value: e.target.value,
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    })
              }
            }
          />

          <TextField
            id="standard-select-currency-native"
            className="hdfc-select "
            select
            label=""
            SelectProps={{
              native: true,
            }}
            variant="standard"
            name="Current Status"
            value={dataObj?.CurrentStatus ? dataObj?.CurrentStatus : ""}
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "CurrentStatus",
              //     value: e.target.value,
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist({
                      name: "CurrentStatus",
                      value: e.target.value,
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    })
              }
            }
          >
            <option>Select Current Status</option>
            <option value="Cured">Cured</option>
            <option value="Not treated">Not treated</option>
            <option value="Ongoing">Ongoing</option>
          </TextField>
          <TextField
            id="standard-select-currency-native"
            className="hdfc-select"
            select
            label=""
            SelectProps={{
              native: true,
            }}
            // style={{
            //   maxHeight: "min-content",
            //   display: "flex",
            //   minWidth: "100% !important",
            //   maxWidth: "100% !important",
            //   textWrap: "wrap",
            //   fontSize: "13px"
            // }}
            variant="standard"
            name="Line of Management"
            value={dataObj?.LineOfManagement ? dataObj?.LineOfManagement : ""}
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "LineOfManagement",
              //     value: e.target.value,
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist({
                      name: "LineOfManagement",
                      value: e.target.value,
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    })
              }
            }
          >
            <option>Select Line of Management</option>
            <option value="Medical Management">Medical Management</option>
            <option value="Surgical Management">Surgical Management</option>
          </TextField>
        </div>
      </div>
    </>
  );
};

export const AnswerSetTwo = ({ pindex, qdata, parentqData, proposerData ,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion , updateHDFCQAlist }) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
  const [dataObj, setDataObj] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);


  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [15,2];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set two", dataObj);

  const normalizeDate = (dateString) => {
    console.log("datestring", dateString)
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };
  return (
    <>
      <TextField
        id="standard-helperText"
        label="Disease Name"
        value={dataObj?.Options[0]?.ExactDiagnosis || ""}
        variant="standard"
        name="Disease Name"
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "ExactDiagnosis",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist(
              {
                    name: "ExactDiagnosis",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  }
            )
          }
        }
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDatePicker"]}>
          <DesktopDatePicker
            variant="standard"
            id="standard-helperText"
            label="Last Consultation Date"
            value={dataObj?.Options[0]?.ConsultationDate ? dayjs(normalizeDate(dataObj?.Options[0]?.ConsultationDate)) : ""}
            format="DD-MM-YYYY"
            name="Last Consultation Date"
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "ConsultationDate",
              //     value: getDate(e),
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist(
                  {
                        name: "ConsultationDate",
                        value:getDate(e),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      }
                )
              }
            }
          />
        </DemoContainer>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDatePicker"]}>
          <DesktopDatePicker
            variant="standard"
            id="standard-helperText"
            label="Surgery Date"
            value={dataObj?.Options[0]?.SurgeryDate ? dayjs(normalizeDate(dataObj?.Options[0]?.SurgeryDate)) : ""}
            format="DD-MM-YYYY"
            name="Surgery Date"
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "SurgeryDate",
              //     value: getDate(e),
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist(
                  {
                        name: "SurgeryDate",
                        value: getDate(e),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      }
                )
              }
            }
          />
        </DemoContainer>
      </LocalizationProvider>

      <TextField
        id="standard-select-currency-native"
        select
        label=""
        SelectProps={{
          native: true,
        }}
        style={{
          maxHeight: "min-content",
          display: "flex",
          minWidth: "100% !important",
          maxWidth: "100% !important",
          textWrap: "wrap",
        }}
        variant="standard"
        name="Current Status"
        value={dataObj?.Options[0]?.CurrentStatus || ""}
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "CurrentStatus",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist(
              {
                    name: "CurrentStatus",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  }
            )
          }
        }
      >
        <option>Select Current Status</option>
        <option value="Cured">Cured</option>
        <option value="Not treated">Not treated</option>
        <option value="Ongoing">Ongoing</option>
      </TextField>
      <TextField
        id="standard-helperText"
        label="Surgery For"
        value={dataObj?.Options[0]?.SurgeryFor || ""}
        variant="standard"
        name="Surgery For"
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "SurgeryFor",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist(
              {
                    name: "SurgeryFor",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  }
            )
          }
        }
      />
    </>
  );
};

export const AnswerSetThree = ({ pindex, qdata, parentqData ,proposerData ,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion, updateHDFCQAlist  }) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
 
  //  console.log('The Anwsar Indexing',pindex,qdata)

  const [dataObj, setDataObj] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);


  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [16,3];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set three", dataObj);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };



  return (
    <>
      <TextField
        id="standard-helperText"
        label="Disease Name"
        value={dataObj?.Options[0]?.ExactDiagnosis || ""}
        variant="standard"
        name="Disease Name"
        style={{
          maxHeight: "min-content",
          display: "flex",
          minWidth: "100% !important",
          maxWidth: "100% !important",
          textWrap: "wrap",
        }}
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "ExactDiagnosis",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist(
              {
                    name: "ExactDiagnosis",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  }
            )
          }
        }
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDatePicker"]}>
          <DesktopDatePicker
            variant="standard"
            id="standard-helperText"
            label="Date of Diagnosis"
            value={dataObj?.Options[0]?.DiagnosisDate ? dayjs(normalizeDate(dataObj?.Options[0]?.DiagnosisDate)) : ""}
            format="DD-MM-YYYY"
            name="Date of Diagnosis"
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "DiagnosisDate",
              //     value: getDate(e),
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist(
                  {
                        name: "DiagnosisDate",
                        value: getDate(e),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      }
                )
              }
            }
          />
        </DemoContainer>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDatePicker"]}>
          <DesktopDatePicker
            variant="standard"
            id="standard-helperText"
            label="Last Consultation Date"
            value={dataObj?.Options[0]?.ConsultationDate ? dayjs(normalizeDate(dataObj?.Options[0]?.ConsultationDate)) : ""}
            format="DD-MM-YYYY"
            name="Last Consultation Date"
            style={{
              fontSize: "small !important",
              minWidth: "auto!important",
            }}
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "ConsultationDate",
              //     value: getDate(e),
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              
                {
                  updateHDFCQAlist(
                    {
                          name: "ConsultationDate",
                          value: getDate(e),
                          pindex: pindex,
                          qdata: qdata,
                          parentqData: parentqData,
                        }
                  )
                }
              
            }
          />
        </DemoContainer>
      </LocalizationProvider>
      <TextField
        id="standard-helperText"
        label="Details of Treatment given"
            value={dataObj?.Options[0]?.TreatmentDetails || ""}
        variant="standard"
        style={{
          maxHeight: "min-content",
          display: "flex",
          minWidth: "100% !important",
          maxWidth: "100% !important",
          textWrap: "wrap",
        }}
        name="Details of Treatment given"
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "TreatmentDetails",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist(
              {
                    name: "TreatmentDetails",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  }
            )
          }
        }
      />

      <TextField
        id="standard-select-currency-native"
        select
        label=""
        SelectProps={{
          native: true,
        }}
        value={dataObj?.Options[0]?.CurrentStatus}
        style={{
          maxHeight: "min-content",
          display: "flex",
          minWidth: "100% !important",
          maxWidth: "100% !important",
          textWrap: "wrap",
        }}
        variant="standard"
        name="Current Status"
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "CurrentStatus",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist(
              {
                    name: "CurrentStatus",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  }
            )
          }
        }
      >
        <option>Select Current Status</option>
        <option value="Cured">Cured</option>
        <option value="Not treated">Not treated</option>
        <option value="Ongoing">Ongoing</option>
      </TextField>
    </>
  );
};

export const AnswerSetFour = ({ pindex, qdata, parentqData, proposerData ,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion ,updateHDFCQAlist }) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)


 
  //  console.log('The Anwsar Indexing',pindex,qdata)

  const [dataObj, setDataObj] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);


  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [17,4];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set four", dataObj);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };




  return (
    <>
      <TextField
        id="standard-helperText"
        label="Test Finding"
        value={dataObj?.Options[0]?.TestFinding || ""}
        variant="standard"
        name="Test Finding"
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "TestFinding",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist({
              
                    name: "TestFinding",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  
            })
          }
        }
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDatePicker"]}>
          <DesktopDatePicker
            variant="standard"
            id="standard-helperText"
            label="Test Date"
            value={dataObj?.Options[0]?.TestDate ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate)) : ""}
            format="DD-MM-YYYY"
            name="Test Date"
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "TestDate",
              //     value: getDate(e),
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist({
                  
                        name: "TestDate",
                        value: getDate(e),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      
                })
              }
            }
          />
        </DemoContainer>
      </LocalizationProvider>

      <TextField
        id="standard-helperText"
        label="Type Of Test"
        variant="standard"
        name="Type Of Test"
        value={dataObj?.Options[0]?.TypeOfTest || ""}
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "TypeOfTest",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          
            {
              updateHDFCQAlist({
                
                      name: "TypeOfTest",
                      value: e.target.value,
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    
              })
            }
          
        }
      />
    </>
  );
};

export const AnswerSetFive = ({ pindex, qdata, parentqData, deliverydate , proposerData ,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion , updateHDFCQAlist }) => {
  const [dataObj, setDataObj] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);

  const dispatch = useDispatch();

  const currentDateStr = deliverydate[pindex];

  console.log("the Date of pregnency", currentDateStr);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [19, 6];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data ", dataObj);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDatePicker"]}>
          <DesktopDatePicker
            variant="standard"
            id="standard-helperText"
            label="Delivery Date"
            value={
              dataObj?.Options[0]?.DeliveryDate ? dayjs(normalizeDate(dataObj?.Options[0]?.DeliveryDate)) : ""
            }
            format="DD-MM-YYYY"
            name="Delivery Date"
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "DeliveryDate",
              //     value: getDate(e),
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist({
                      name: "DeliveryDate",
                      value: getDate(e),
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    })
              }
            }
          />
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};
