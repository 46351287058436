import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import "./Otp.css";
import {
  selectUserData,
  updatedUserdata,
  updatedLoginPopup,
  selectLoginForm,
  selectOtpForm,
  updatedOtpPopup,
  updatedLoginUser,
} from "../Health/redux/Slice";
import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { useRef } from "react";

const Otp = () => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [errorData, setErrorData] = useState(false);

  const loginPopup = useSelector(selectLoginForm);

  const closeModal = () => {
    dispatch(updatedOtpPopup(false));
  };

  const changeNumber = () => {
    dispatch(updatedOtpPopup(false));
    dispatch(updatedLoginPopup(true));
  };

  useEffect(() => {
    // Focus on the first input box when the component mounts
    if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
    }
}, []);

  const verifyOtp = () => {
    let data = JSON.stringify({
      mobileNumber: loginPopup.phone,
      otp: otp,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + "user/verify_otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const res = response.data;
        console.log("res", res)
        if (res.status) {
          dispatch(updatedOtpPopup(false));
          localStorage.setItem("userToken", res.data.token);
          checkLogin();
        } else {
          setErrorData(res.message);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const checkLogin = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const unique_id = params.get("req_id");
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      let data = JSON.stringify({
        token: userToken,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + "user/me",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log("line 100",JSON.stringify(response.data.first_name));
          let data = (response.data.data.first_name);
          let formattedData = data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();;
          console.log("formatted data", formattedData)
          localStorage.setItem("profile_name",formattedData)
          dispatch(updatedUserdata(response.data));
          dispatch(updatedLoginUser(response.data));
          localStorage.setItem(
            "Proposalphonenumber",
            response.data.data.mobile_number
          );

          if (unique_id) {
            updatePhoneNo(response.data.data.mobile_number, unique_id);
          }

          //console.log('maindata is now work',(response.data))
        })
        .catch((error) => {
          //console.log(error);
          dispatch(updatedUserdata(false));
        });
    } else {
      dispatch(updatedUserdata(false));
    }
  };

  const updatePhoneNo = (phone_no, token) => {
    let data = JSON.stringify({
      token: token,
      phone_no: phone_no,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + "health/update_phone_no",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const inputRefs = useRef([]);
  // const [otp, setOtp] = useState(''); // State to hold the OTP value as a string

  const handleChange = (index, event) => {
    const value = event.target.value;

    // Update state with new value
    const newOtp = otp.split(""); // Convert string to an array
    newOtp[index] = value; // Update the specific index
    setOtp(newOtp.join("")); // Convert back to string

    // Move to next input if length is 1
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
    // Move to previous input if cleared
    if (value.length === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  console.log(otp);

  const handleKeyDown = (index, event) => {
    // Move to previous input on backspace if current input is empty
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  if (otp.length === 4) {
    verifyOtp();
    console.log("Now call this");
  }

  return (
    <div className="loader-container">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content lopinotppopup" style={{ width: "380px" }}>
          <div className="modal-header">
            <button
              type="button"
              className="close OTP_Close"
              onClick={(e) => closeModal()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="login-modal">
            <div className="modal-body">
              <div id="verify-otp" className="login-setup-section">
                <h3 className="request-otp-header">
                  Enter the OTP sent <br />
                  {/* <div><a Style="color:red;">{errorData}</a></div> */}
                  <span className="user-number" id="userEnterdNumber">
                    {loginPopup.phone}
                  </span>{" "}
                  <a
                    type="button"
                    className="user-login"
                    onClick={(e) => changeNumber()}
                  >
                    change
                  </a>
                </h3>

                <div className="otpdivboxs">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      className="otpbox"
                      maxLength="1" // Limit input to 1 character
                      ref={(el) => (inputRefs.current[index] = el)}
                      value={otp[index] || ""} // Controlled input
                      onChange={(event) => handleChange(index, event)}
                      onInput={(e) =>
                        (e.target.value = e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1"))
                      }
                      onKeyDown={(event) => handleKeyDown(index, event)}
                    />
                  ))}
                </div>

                <label
                  id="otpVal"
                  className="error"
                  style={{ display: "none" }}
                ></label>

                {/* <button onClick = {e => verifyOtp()}   className="btn btn-default btn-lg btn-block request-otp blue-btn"
                                           ref={buttonRefbutton} // Reference for the button
                                           onKeyDown={handleKeyDownMain} // Attach key down handler
                                    ><span >Verify OTP</span> 
                                    </button> */}

                <div className="validatediv">
                  <span className="coloralert">{errorData}</span>
                </div>

                <div className="terms-conditions">
                  <p>
                    I agree to{" "}
                    <a href="{{ route('terms-conditions') }}">
                      terms & conditions
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <span className="bg-img-beema">
              <FastImage
                url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                width={auto}
                height={auto}
                alt={"popup"}
                src={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Otp;
