import {feetAndInchesToCm} from "./common";


const payload = (data,apiData,kycData,addonsArray,ICICIcheckedQuestion) =>
{
	// //console.log('userdata',userdata)
	// //console.log("apiData ",PlanData)

	// const heightdata = feetAndInchesToCm(data.height_feet , data.height_inch);

	// //console.log("the bheightdata", heightdata);
  
  let tenure ;

  // proposerData,
  // apiResponse.response,
  // kycData,
  // addonsArray,
  // ICICIcheckedQuestion
  
  if(apiData)
  {
    // console.log("apiData", apiData)
    tenure =  apiData.premiumBreakup.term
  }

  let ped = JSON.parse(localStorage.getItem('PED'));
  console.log("ped",ped.join(','))


  console.log("the data",data )
  console.log("the kycdata", kycData)
  
   let output = {
    "ProposalName": data.ProposalName,
    "Proposalphonenumber": data.Proposalphonenumber,
    "street": kycData.street,
    "PinCode": JSON.stringify(kycData.PinCode),
    "District": data.District,
    "City": data.District,
    "State": data.Sate,
    "PED": ped.join(','),
    "Email": kycData.Email,
    "dateOfbirth": kycData.KycDateOfbirth,
    // "height": data.height || heightdata || data.InsuredDetail[0].height,
    "height" : data.heightcm,
    // "weight": data.weight || data.InsuredDetail[0].weight,
    "weight" : data.weight,
    "Occupation": data.Occupation,
    "GrossIncome": data.AnnualIncome,
    "Gender": localStorage.getItem('Gender') == 'M' ? 'Male' : 'Female',
    "Premium": apiData.premiumBreakup.totalPremium,
    "SumInsured": apiData.sumInsured,
    "PlanCode": apiData.planCode,
    "Tenure": tenure+" Year",
    // "Tenure" : 1 + "Year",
    "InsuredDetail": InsuredDetail(data.InsuredDetail,kycData,ICICIcheckedQuestion),
    "nominee": {
        "firstName": extractNames(kycData.nomineeName).fname,
        "middleName": null,
        "lastName": extractNames(kycData.nomineeName).lname,
        "dateOfbirth": kycData.nomineeDateOfbirth,
        "profession": null,
        "gender": kycData.nomineerelationship == 'Self' ? kycData.Gender : getGender(kycData.nomineerelationship),
        "relationship": kycData.nomineerelationship == 'Husband' || kycData.nomineerelationship == 'Wife' ? 'Spouse' : kycData.nomineerelationship,
        "addons": addonsArray ? addonsArray.join() : "",
        "street" : kycData.nomineeAddress


        // "firstName": extractNames(data.nomineeName).fname,
        // "middleName": null,
        // "lastName": extractNames(data.nomineeName).lname,
        // "dateOfbirth": data.nomineeDateOfbirth,
        // "profession": null,
        // "gender": data.nomineerelationship == 'Self' ? userdata.Gender : getGender(data.nomineerelationship),
        // "relationship": data.nomineerelationship == 'Husband' || data.nomineerelationship == 'Wife' ? 'Spouse' : data.nomineerelationship,
        // "addons": addonsArray ? addonsArray.join() : "",
        // "street" : data.nomineeAddress
    },
    "kycId": kycData.kycId,
    "cardNum": kycData.cardNum,
    "GUID": "97180dc3-8d32-41d5-b033-5ad8395cc349",
    "userToken":localStorage.getItem('uid'),
    "addons": addonsArray ? addonsArray.join() : ""
}
	

	return output;
}

const InsuredDetail = (data,userdata,ICICIcheckedQuestion) => 
{
  let result = [];

  for (let i = 0; i < data.length; i++) 
  {
  	let obj = {
  		         InsuredName:data[i].InsuredName,
  		         InsuredRelation:data[i].InsuredRelation == 'Husband' || data[i].InsuredRelation == 'Wife' ? 'Spouse' : data[i].InsuredRelation,
  		         dateOfbirth:data[i].dateOfbirth,
  		         Gender: data[i].InsuredRelation == 'Self' ? (localStorage.getItem('Gender') == 'M' ? 'Male' : 'Female') : getGender(data[i].InsuredRelation),
  		         InsuredAge:data[i].InsuredAge,
  		         height:data[i].height,
  		         weight:data[i].weight,
  		         IPED: true,
  		         MaritalStatus:data[i].MaritalStatus,
  		         PEDIllness:MedicalQuestionDetails(i,ICICIcheckedQuestion)
  		      };
  	result.push(obj);
  }
  return result;
}

const extractNames = (fullName) =>
{
  // Split the full name into an array of words
  const nameArray = fullName.split(' ');

  // Extract the first name (fname)
  const fname = nameArray[0];

  // Extract the last name (lname) if available
  const lname = nameArray.length > 1 ? nameArray.slice(1).join(' ') : '';

  return { fname, lname };
}
 
const getGender = (relation) =>
{
  const output = {
  	"Mother":'Female',
  	"Father":'Male',
  	"Grand Father":'Male',
  	"Grand Mother":'Female',
  	"Brother":'Male',
  	"Sister":'Female',
  	"Husband":'Male',
  	"Wife":'Female',
  	"Father In Law":'Male',
  	"Mother In Law":'Female',
  	"Uncle":'Male',
  	"Aunt":'Female',
  	"Grand Daughter":'Female',
  	"Grand Son":'Male',
  	"Son":'Male',
  	"Daughter":'Female'

  }
  console.log("the output in getgender", relation )
  return output[relation];
} 

const MedicalQuestionDetails = (index,HDFCcheckedQuestion) => 
{
	let array = [];
  for (let key in HDFCcheckedQuestion) {
   	if(HDFCcheckedQuestion[key].hasOwnProperty(index))
   	{
   		array.push(key);
   		
   	}
   } 
	return array;

}


export {payload}