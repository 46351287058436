import React, { useEffect, useRef, useState } from "react";
import StepSlider from "../../commonComponents/StepSlider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./step2.css";
import { useDispatch, useSelector } from "react-redux";
import { updateInsuredData } from "../../redux/Slice";
import relation from "../../../relation.json";
import { useSpring, animated } from "@react-spring/web";
import { GiUmbrella } from "react-icons/gi";
import { selectProposal } from "../../redux/Slice";
// material Component
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Helmet } from "react-helmet-async";

const HealthStep2 = () => {
  const dispatch = useDispatch();
  const [familyData, setFamilyData] = useState([]);
  const [selectedAge, setSelectedAge] = useState(
    Array(familyData.length).fill("")
  );
  const [isSelectOpen, setIsSelectOpen] = useState({});
  const [insuredRelation, setInsuredRelation] = useState([]);
  const navigate = useNavigate();
  const selectRef = useRef(null);
  const ages = Array.from({ length: 121 }, (_, i) => i);
  const selfWifeAges = Array.from({ length: 82 }, (_, i) => i + 18);
  const parentAges = Array.from({ length: 60 }, (_, i) => i + 40);
  const sonDaughterAges = Array.from({ length: 30 }, (_, i) =>
    i === 0 ? "<91" : i
  );
  const grandparentAges = Array.from({ length: 46 }, (_, i) => i + 54);

  const proposalData = useSelector(selectProposal);

  const personType = localStorage.getItem("selectedType");

  useEffect(() => {
    const retrievedInsuredDetail = localStorage.getItem("InsuredDetail");
    if (retrievedInsuredDetail) {
      const parsedInsuredDetail = JSON.parse(retrievedInsuredDetail);
      setFamilyData(parsedInsuredDetail);
    }

    const storedInsuredDetail = localStorage.getItem("InsuredDetail");
    if (storedInsuredDetail) {
      const parsedInsuredDetail = JSON.parse(storedInsuredDetail);
      const storedInsuredRelation = parsedInsuredDetail.map(
        (detail) => detail.InsuredRelation
      );
      const storedInsuredAge = parsedInsuredDetail.map((detail) =>
        String(detail.InsuredAge)
      );
      setInsuredRelation(storedInsuredRelation);
      setSelectedAge(storedInsuredAge);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAgeChange = (index, event) => {
    const { value } = event.target;
    dispatch(
      updateInsuredData({
        index: index,
        data: [{ key: "InsuredAge", value: value }],
      })
    );
    const updatedInsuredRelation = [...insuredRelation];
    updatedInsuredRelation[index] = familyData[index].InsuredRelation;
    localStorage.setItem("ageadded", value);
    setSelectedAge((prevSelectedAge) => {
      const updatedSelectedAge = [...prevSelectedAge];
      updatedSelectedAge[index] = value;
      return updatedSelectedAge;
    });
    setInsuredRelation(updatedInsuredRelation);
  };

  const toggleSelect = (index) => {
    setIsSelectOpen((prevIsSelectOpen) => ({
      ...prevIsSelectOpen,
      [index]: !prevIsSelectOpen[index],
    }));
  };

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsSelectOpen(false);
    }
  };

  const validateAgeSelection = () => {
    const isAllAgesSelected = selectedAge.every((age) => age !== "");
  
    if (!isAllAgesSelected) {
      toast.error("Please select the age for all family members to proceed.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return false;
    }
  
    // Helper function to check if a person is in a higher hierarchy than the current person
    const isInHigherHierarchy = (currentRelation, comparisonRelation) => {
      const hierarchy = {
        "Grand Father": 5,
        "Grand Mother": 5,
        "Father": 4,
        "Mother": 4,
        "Father In Law": 4,
        "Mother In Law": 4,
        "Self": 3,
        "Son": 2,
        "Daughter": 2,
        "Brother": 1,
        "Sister": 1,
        "Wife": 3,
        "Husband": 3,
      };
  
      return hierarchy[comparisonRelation] > hierarchy[currentRelation];
    };
  
    // Loop through selected ages
    for (let i = 0; i < selectedAge.length; i++) {
      const currentRelation = insuredRelation[i];
      const currentAge = parseInt(selectedAge[i]);
  
      // 1. Ensure 'Self' is at least 18 years old
      if (currentRelation === "Self" && currentAge < 18) {
        toast.error("You must be at least 18 years old to select yourself.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return false;
      }
  
      // 2. Validate relationships for age hierarchy
      for (let j = 0; j < selectedAge.length; j++) {
        if (i !== j) {
          const comparisonRelation = insuredRelation[j];
          const comparisonAge = parseInt(selectedAge[j]);
  
          // 3. Grandparent must be older than Self, Son, Daughter, and Parents
          if (
            (currentRelation === "Grand Father" || currentRelation === "Grand Mother") &&
            (
              comparisonRelation === "Self" ||
              comparisonRelation === "Son" ||
              comparisonRelation === "Daughter" ||
              comparisonRelation === "Father" ||
              comparisonRelation === "Mother" ||
              comparisonRelation === "Father In Law" ||
              comparisonRelation === "Mother In Law"
            ) &&
            currentAge <= comparisonAge
          ) {
            toast.error("Grandparent's age must be greater than Self, Son, Daughter, or Parent's age.", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return false;
          }
  
          // 4. Parent and Parent-in-Law must be older than Self, Son, Daughter
          if (
            (currentRelation === "Father" || currentRelation === "Mother" || currentRelation === "Father In Law" || currentRelation === "Mother In Law") &&
            (
              comparisonRelation === "Self" ||
              comparisonRelation === "Son" ||
              comparisonRelation === "Daughter"
            ) &&
            currentAge <= comparisonAge
          ) {
            toast.error("Parent's age must be greater than Self, Son, or Daughter's age.", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return false;
          }
  
          // 5. Son's age must always be less than all higher hierarchy members (Self, Father, Mother, Grandparents, Father-in-law, Mother-in-law)
          if (
            currentRelation === "Son" &&
            (comparisonRelation === "Self" || comparisonRelation === "Father" || comparisonRelation === "Mother" || comparisonRelation === "Grand Father" || comparisonRelation === "Grand Mother" || comparisonRelation === "Father In Law" || comparisonRelation === "Mother In Law") &&
            currentAge >= comparisonAge
          ) {
            toast.error("Son's age must be less than Self, Father, Mother, Grandfather, or Grandmother.", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return false;
          }
  
          // 6. Daughter's age must always be less than all higher hierarchy members (Self, Father, Mother, Grandparents, Father-in-law, Mother-in-law)
          if (
            currentRelation === "Daughter" &&
            (comparisonRelation === "Self" || comparisonRelation === "Father" || comparisonRelation === "Mother" || comparisonRelation === "Grand Father" || comparisonRelation === "Grand Mother" || comparisonRelation === "Father In Law" || comparisonRelation === "Mother In Law") &&
            currentAge >= comparisonAge
          ) {
            toast.error("Daughter's age must be less than Self, Father, Mother, Grandfather, or Grandmother.", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return false;
          }
  
          // 7. Sibling's ages (Brother, Sister) must not be the same
          if (
            (currentRelation === "Brother" || currentRelation === "Sister") &&
            (comparisonRelation === "Brother" || comparisonRelation === "Sister") &&
            currentAge === comparisonAge
          ) {
            toast.error("Siblings' ages should not be the same.", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return false;
          }
  
          // 8. Ensure Brother or Sister's age is less than all higher relations (Self, Father, Mother, Grandparents, Father-in-law, Mother-in-law)
          if (
            (currentRelation === "Brother" || currentRelation === "Sister") &&
            (comparisonRelation === "Self" || comparisonRelation === "Father" || comparisonRelation === "Mother" || comparisonRelation === "Grand Father" || comparisonRelation === "Grand Mother" || comparisonRelation === "Father In Law" || comparisonRelation === "Mother In Law") &&
            currentAge >= comparisonAge
          ) {
            toast.error("Brother's or Sister's age must be less than Self, Father, Mother, Grandfather, or Grandmother.", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return false;
          }
        }
      }
    }
  
    // If all validations pass, proceed
    return true;
  };
  
  
  
  
  
  
  

  const handleSetValue = () => {
    if (!validateAgeSelection()) {
      return; // Stop if validation fails
    }

    const InsuredDetail = selectedAge.map((age, index) => ({
      InsuredRelation: insuredRelation[index],
      InsuredAge: age ? parseInt(age) : "",
    }));

    localStorage.setItem("InsuredDetail", JSON.stringify(InsuredDetail));
    navigate("/healthStep3");
  };

  const getPersonImage = (persion , typedata) => {
    const familydata = relation.find((value) =>(value.level == persion )&&(value.type == typedata));
    return familydata["image1"];
  };

  // Animation
  const [isVisible, setIsVisible] = React.useState(false);

  const animations = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0%)" : "translateY(50%)",
    config: { mass: 1, tension: 100, friction: 20, duration: 500 },
  });

  useEffect(() => {
    setIsVisible(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>HealthStep2</title>
      </Helmet>

      <h1
        style={{
          textAlign: "center",
          padding: "20px",
          color: "#0055A5",
          marginTop: 20,
        }}
      >
        We Offer You Best Insurance Plan{" "}
        <span style={{ color: "#E50318" }}>In Your </span>
        <span style={{ color: "#FD9202" }}>Budget</span>
        <sup>**</sup>
      </h1>

      <StepSlider activeStep={2} />

      <animated.div style={animations}>
        <div className="health-insurance-view-plan">
          <div className="modal-body">
            <div className="modal-content">
              <div className="modle-title">
                <h6 className="selectmemberh6">
                  Select Members You Want To Insure
                </h6>
              </div>

              <div className="container tooltipposition">
                <div className="tooltips">
                  <div>
                    <div className="toolchild">
                      <span className="umbrellaspan">
                        <GiUmbrella />
                      </span>
                      <h6>Best Discount Only for you in limited Price </h6>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-8" style={{ margin: "0 auto" }}>
                    {familyData.map((item, index) => (
                      <div key={index} className="row agerow">
                        <div className="col-xs-9 helthtwoimagediv">
                          <img
                            src={getPersonImage(item.InsuredRelation , personType)}
                            alt=""
                          />
                          <span className="font20">
                            {item.InsuredRelation}{" "}
                          </span>
                        </div>
                        <div className="col-xs-3 text-right selectdiv">
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            ref={selectRef}
                          >
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                              <Select
                                className="selectvalues"
                                id={`age.${item}`}
                                name={`age.${item}`}
                                value={selectedAge[index] || ""}
                                onChange={(event) => handleAgeChange(index, event)}
                                onClick={() => toggleSelect(index)}
                                onBlur={() =>
                                  setIsSelectOpen({
                                    ...isSelectOpen,
                                    [index]: false,
                                  })
                                }
                                sx={{
                                  boxShadow: "none",
                                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                  },
                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                  },
                                }}
                                displayEmpty
                              >
                                <MenuItem value="" disabled>Age</MenuItem>
                                
                                {["Self", "Wife","Husband", "Brother", "Sister"].includes(item.InsuredRelation)
                                    ? selfWifeAges.map((value) => (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        ))
                                    : null}

                                { ["Son","Daughter"].includes(item.InsuredRelation)
                                  ? sonDaughterAges.map((value) => (
                                        <MenuItem value={value} key={value}>
                                          {value}
                                        </MenuItem>
                                      ))
                                  : null}

                                {item.InsuredRelation == "Grand Father" || item.InsuredRelation === "Grand Mother" 
                                  ? grandparentAges.map((value) => (
                                        <MenuItem value={value} key={value}>
                                          {value}
                                        </MenuItem>
                                      ))
                                  : null}
                              
                                { ["Father","Mother","Uncle", "Aunt", "Father In Law", "Mother In Law"].includes(item.InsuredRelation)
                                  ? parentAges.map((value) => (
                                        <MenuItem value={value} key={value}>
                                          {value}
                                        </MenuItem>
                                      ))
                                  : null}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="buttons-container">
          <button className="continuebutton myBtn" onClick={handleSetValue}>
            Continue
          </button>
        </div>

              
              </div>
            </div>
          </div>
        </div>
      </animated.div>
      <ToastContainer />
    </>
  );
};

export default HealthStep2;
