import React, { useEffect, useState, useCallback } from "react";
import "../HomePage/Homepage.css";
import Blog from "./Blog";
import { Link } from "react-router-dom";
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import Preferredpolicy from "../AllPageComponents/ Preferredpolicy";

import LoginPop from "../AllPageComponents/LoginPop";
import ComparePolicyPop from "./ComparePolicyPop/ComparePolicy";
import { FaArrowRotateRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { updatedLoginPopup } from "../Health/redux/Slice";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { BiLogoTelegram } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import Understandpolicy from "./UnderStandpolicy/Understandpolicy";
import Stepper from "./Stepper/Stepper";
import Homepagecalculator from "./HomepageCalculator/Homepagecalculator";
import ImportenceOfInsurence from "../CommonPages/ImportenceOfInsurence/ImportenceOfInsurence";
import AdviceFromExpert from "./AdviceFromExperts/AdviceFromExpert";
import NoteWarthyAdvice from "../AllPageComponents/NoteWarthyAdvice/NoteWarthyAdvice";
import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import Testamonial from "../CommonPages/Testamonial/Testamonial";
import BeemaAdvantage from "../CommonPages/BeemaAdvantage/BeemaAdvantage";
import Howbeemaone from "../CommonPages/HowBeemaoneWorks/Howbeemaone";
import { Helmet } from "react-helmet-async";
import FAQ2 from "../AllPageComponents/FAQ/FAQ2";



const Homepage = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  const imageData = ["Website%20Img/two.jpg?updatedAt=1718705583128","Website%20Img/three.jpg?updatedAt=1718705583082","Website%20Img/one.jpg?updatedAt=1718705583042"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [popup, setPopup] = useState({ isOpen: false });
  const [showGetPop, setShowGetPop] = useState(false);
  const [showpop, setShowPop] = useState(false);
  const [showLoginPop, setShowLoginPop] = useState(false);

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [formvalues, setFormValues] = useState();
  const [captchaTrue, setCaptchaTrue] = useState(false);
  const [newCaptcha, setNewCaptcha] = useState("");
  const [healthData , setHealthData] = useState();
  const [healthpath , setHealthPath] = useState();

  const [formData, setFormData] = useState({
    name: "",
    product_name: "",
    mobile_number: "",
    time_slot: "",
    message: "",
    code: "",
  });

  const nav = useNavigate();
  const dispatch = useDispatch();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };




  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  function handleChange(event) {
    setUserInput(event.target.value);
    setIsValid(event.target.value === captcha);
  }

  // Post Api For form submit and

  function handleRegenerate() {
    setCaptcha(generateCaptcha());
  }

  const checkLogin = () => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      //console.log("User is logged in");
      return true;
    } else {
      //console.log("User is not logged in");
      return false;
    }
  };

  const handleButtonClick = async () => {
    if (!isValid) {
      Swal.fire({
        title: "Error!",
        text: "Invalid captcha code",
        icon: "error",
        confirmButtonText: "Back",
      });
      return; // Prevent form submission if captcha is not valid
    }

    const data = {
      name: formData.name,
      product_name: formData.product_name,
      mobile_number: formData.mobile_number,
      time_slot: formData.time_slot,
      message: formData.message,
      code: formData.code,
    };
    setFormValues([formData]);

    if (
      formData.name === "" ||
      formData.product_name === "" ||
      formData.mobile_number === "" ||
      formData.time_slot === "" ||
      formData.message === "" ||
      formData.code === ""
    ) {
      Swal.fire({
        title: "Error!",
        text: "All Fields Are Required",
        icon: "error",
        confirmButtonText: "Back",
      });
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}cms/user_callback_request`,
          data
        );
        //console.log("Post Successful", response);
        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Your Request Submitted Successfully",
            icon: "success",
            confirmButtonText: "Done",
          });
          setFormData({
            name: "",
            product_name: "",
            mobile_number: "",
            time_slot: "",
            message: "",
            code: "",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Something Went Wrong",
            icon: "error",
            confirmButtonText: "Back",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong",
          icon: "error",
          confirmButtonText: "Back",
        });
      }
    }
  };



  const fetchPolicyData = async (userToken) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/dashboard/policies`, {
        params: { token: userToken },
      });
      if (response.data.status === false) {
        localStorage.clear();
        window.location.href = '/';
        return;
      }
      return response;
    } catch (error) {
      console.error('Error fetching policy data:', error);
      
    }
  };
  


useEffect(() => {
  const fetchData = async () => {
      let userToken = localStorage.getItem('userToken');
      if (userToken) {
          try {
              let data = await fetchPolicyData(userToken);
              console.log("the data", data.data.data.health_data);
              if(data.data.data.health_data.length != 0){
                setHealthData(data.data.data.health_data)
                setHealthPath("/previouspolicy")
              }
              else{
                setHealthPath("/health-insurance")
              }

          } catch (error) {
              console.error("Error fetching policy data", error);
          }
      }
  };

  fetchData(); 
}, []);








  return (
    <div className={showGetPop ? "noscroll" : ""}>
             <Helmet>
        <title>Compare, Buy Discount Insurance Policy - Health, Term, Car & Bike</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
      <section className="home-first-section home-section">
        <div className="container">
          <div className="row first-section-of-first second-section-of-first">
            <div className="col-sm-12 col-md-7 col-lg-7 hero-main-one">
              <h6 className="tag_line">
                <span className="blue">#WE DON'T SELL</span>&nbsp;
                <span>WE GUIDE</span>
              </h6>
              <h1>
                <span className="tomato-red"><span className="heighlighittext">Best Insurance </span><span className="policy-home">Policy in India</span></span>
                <span className="normal-blue">Health, Life, Term, Car & Bike</span>
                
              </h1>

              <p className='textformat'>
              We Beema1, help you to understand insurance in simple words, we guide you through various elements of insurance, 
              identify and grasp your policy. Here you can easily compare and buy the best insurance policy in India,get your queries addressed, and secure yourself—all conveniently under one umbrella.
              </p>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5 marginslider">
              <div className="owl-stage-outer" style={{cursor:"pointer"}}>
                <Slider {...settings}>
                  {imageData.map((item,index) => (
                    <div  key={index} onClick={()=>{
                      if(index == 0){
                        nav("/health-insurance")
                      }
                      else if(index == 1){
                        nav("/term-insurance")

                      }
                      else{
                        // nav("/health-insurance")

                      }
                    }}>
                      <FastImage
                        url={item}
                        width={470}
                        height={260}
                        src={item}
                     
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          <div className="row third-section-of-first">
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link className="ins-type-box" to={"/car-insurence"}>
                <div className="arrow-hover">
                 
                  <FastImage
                      
                  url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                  width={auto}
                  height={auto}
                  alt={"right-arrow"}
                  src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                  />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                    

                    <FastImage
                      
                      url={"/Website%20Img/car-insurance.png?updatedAt=1718706593007"}
                      width={auto}
                      height={auto}
                      alt={"car-insurance"}
                      src={"/Website%20Img/car-insurance.png?updatedAt=1718706593007"}
                      />
                  </div>
                  <h4>Car</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link className="ins-type-box" to={"/Bike-insurence"}>
                <div className="arrow-hover">
                 

                  <FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      width={auto}
                      height={auto}
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                   

                    <FastImage
                      
                      url={"/Website%20Img/bike-insurance.png?updatedAt=1718707037051"}
                      width={auto}
                      height={auto}
                      alt={"bike-logo"}
                      src={"/Website%20Img/bike-insurance.png?updatedAt=1718707037051"}
                      />
                  </div>
                  <h4>Bike</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link className="ins-type-box"
              //  to={healthpath}
              to={"/health-insurance"}
              >
                <div className="arrow-hover">
                  
                  <FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      width={auto}
                      height={auto}
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                   
                    <FastImage
                      
                      url={"/Website%20Img/health-insurance.png?updatedAt=1718709127282"}
                      width={auto}
                      height={auto}
                      alt={"health-logo"}
                      src={"/Website%20Img/health-insurance.png?updatedAt=1718709127282"}
                      />
                  </div>
                  <h4>Health</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link to={"/term-insurence"} className="ins-type-box">
                <div className="arrow-hover">
                  
                  <FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      width={auto}
                      height={auto}
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                   
                    <FastImage
                      
                      url={"/Website%20Img/term-insurance.png?updatedAt=1718709323751"}
                      width={auto}
                      height={auto}
                      alt={"term"}
                      src={"/Website%20Img/term-insurance.png?updatedAt=1718709323751"}
                      />
                  </div>
                  <h4>Term</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
            {/* <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link className="ins-type-box" to={"/travel-insurence"}>
                <div className="arrow-hover">
                
                  <FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      width={auto}
                      height={auto}
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                   
                    <FastImage
                      
                      url={"/Website%20Img/travel-insurance.png?updatedAt=1718709460988"}
                      width={auto}
                      height={auto}
                      alt={"term"}
                      src={"/Website%20Img/travel-insurance.png?updatedAt=1718709460988"}
                      />
                  </div>
                  <h4>Travel</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </section>


      <ImportenceOfInsurence />
      <BeemaAdvantage/>
      <Stepper />
      <AdviceFromExpert />

      {/* <NoteWarthyAdvice /> */}
      {/* <Howbeemaone/> */}




      {/* Section fifth start hear */}

      <Preferredpolicy />

  {/* <section className="home-fourth-section home-section">
        <div className="container">
          <div className="row align-items-center first-section-of-fourth">
            <div className="col-sm-12 col-md-8 col-lg-8 for-width">
              <div className="d-flex new-rc-policy">
                <a
                  className="policy-rc-box"
                  data-toggle="modal"
                  data-target="#NewUserLogin"
                  href="#"
                  onClick={() => {
                    if (!checkLogin()) {
                      // setShowLoginPop(true);
                      dispatch(updatedLoginPopup(true));
                    } else {
                      nav("/dashbord");
                    }
                  }}
                >
                  <div className="new-rc-img">
                    <FastImage
                      
                      url={"/Website%20Img/renew_images1677848897.png?updatedAt=1718709615808"}
                      width={auto}
                      height={auto}
                      alt={"renew"}
                      src={"/Website%20Img/renew_images1677848897.png?updatedAt=1718709615808"}
                      />
                  </div>
                  <div className="rc-head align-items-center ">
                    <h3 className="lr">Renew Policy</h3>
                  </div>
                  <p>
                    <span style={{ fontFamily: "Poppins" }}>
                      Continue to receive the advantages of your policy by
                      paying the annual premium. You can now renew your policy
                      with Beema1.
                    </span>
                    <br />
                  </p>
                </a>
                {showLoginPop && (
                  <LoginPop
                    showLoginPop={showLoginPop}
                    setShowLoginPop={setShowLoginPop}
                  />
                )}
                <a
                  className="policy-rc-box"
                  data-toggle="modal"
                  data-target="#PolicyPopUpModel"
                  // href="#"
                  onClick={() => {
          
                    setShowPop(true);
                  }}
                >
                  <div className="new-rc-img">
                    <FastImage
                      
                      url={"/Website%20Img/renew_images1677848897.png?updatedAt=1718709615808"}
                      width={auto}
                      height={auto}
                      alt={"renew"}
                      src={"/Website%20Img/renew_images1677848897.png?updatedAt=1718709615808"}
                      />
                  </div>
                  <div className="rc-head align-items-center justify-content-end">
                    <h3 className="pr">Compare Policy</h3>
                  </div>
                  <p>
                    <span style={{ fontFamily: "Poppins" }}>
                      Compare insurance plans and premium rates from top-rated
                      insurance providers.
                    </span>
                  </p>
                </a>
                {showpop && <ComparePolicyPop setShowPop={setShowPop} />}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="new-rc-img-right">
                <FastImage
                 
                  url={"/Website%20Img/agent-img%20(2).webp?updatedAt=1718706083170"}
                  width={auto}
                  height={auto}
                  alt={"callback-agent"}
                  src={"/Website%20Img/call-back-agent.png?updatedAt=1718704826908"}
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Section sixth start hear */}

      <PartnerCompany />
      {/* Section ten start hear */}



      <FAQ />
      {/* <FAQ2/> */}

      {/* Section eleven start hear */}

    <Testamonial/>

      {/* Section twelvw start hear */}

      <Homepagecalculator />
      <Blog />
    </div>
  );
};

export default Homepage;
